import { Component, OnInit, Renderer2 } from '@angular/core';
import { Profile, Webinar, WebinarButton } from './webinar.type';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { ViewModeService } from '@services/view-mode.service';
import { WidenService } from '@services';

@Component({
  selector: 'ft-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.scss'],
})
export class WebinarComponent extends AbstractBaseComponent implements OnInit {
  // Formatted Webinar Data
  public webinarData: Webinar;

  // Bloomreach document edit button
  public isEditMode = false;

  constructor(
    private viewModeService: ViewModeService,
    private widenService: WidenService,
    private renderer: Renderer2
  ) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  /**
   * On initialization
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.webinarData = this.getWebinarData();
  }

  /**
   * Formats bloomreach data into webinar component usable form
   * @returns Formatted Webinar Data
   */
  private getWebinarData(): Webinar {
    let rawWebinar;
    const webinar: Webinar = { title: '' };
    const webinarBloomreachdocument = this.component?.getModels()?.document;
    if (webinarBloomreachdocument) {
      rawWebinar = this.page?.getContent(webinarBloomreachdocument)?.getData();
    }
    // Mapping bloomreach properties to webinar core component.
    if (rawWebinar?.title) {
      // Webinar Fields
      webinar.sectionId = rawWebinar?.sectionId;
      webinar.title = rawWebinar?.title;
      webinar.preTitle = rawWebinar?.preTitle;
      webinar.title = rawWebinar?.title;
      webinar.description = rawWebinar?.summary;
      webinar.date = rawWebinar?.date;
      webinar.duration = rawWebinar?.duration;
      webinar.time = rawWebinar?.time;
      webinar.webinarPreTitleIconUrl = this.widenService.getWidenAssetUrl(
        rawWebinar?.widenAssetButton?.widenAsset
      );
      // Webinar Buttons
      webinar.buttons = this.generateButtons(rawWebinar?.buttons[0]?.buttons);

      // Webinar Profiles Section
      webinar.speakerProfiles = this.generateProfiles(
        rawWebinar?.webinarRightSectionCompound?.profiles?.profiles
      );

      // Webinar Image Section
      webinar.simpleImageUrl = this.widenService.getWidenAssetUrl(
        rawWebinar?.webinarRightSectionCompound?.widenAsset?.widenAsset
      );
      webinar.simpleImageAlt =
        rawWebinar?.webinarRightSectionCompound?.widenAsset?.alt;

      // Webinar Video Section
      webinar.embedScript =
        rawWebinar?.webinarRightSectionCompound?.embeddedVideo?.videoEmbedCode;
    }
    return webinar;
  }

  /**
   *
   * @param rawButtons Buttons received from bloomreach
   * @returns Formatted buttons usable by webinar component
   */
  private generateButtons(rawButtons) {
    const watchNowButtons: WebinarButton[] = [];
    if (rawButtons?.length) {
      rawButtons?.forEach((button) => {
        const link = button?.linkCompound?.linkCollection[0];
        if (link?.displayText) {
          const rawWebinarButton: WebinarButton = {
            href: link?.url,
            btnLabel: link?.displayText,
            isExternalLink: link?.external,
            isNewWindow: link?.target === '_blank',
          };
          watchNowButtons.push(rawWebinarButton);
        }
      });
    }
    return watchNowButtons;
  }

  /**
   * This function will format profiles from bloomreach data and returns it.
   * @param profiles Raw speaker profiles containing bloomreach data
   */
  private generateProfiles(profiles): Profile[] {
    const formattedProfiles: Profile[] = [];
    if (profiles?.length) {
      profiles?.forEach((profile) => {
        const profileDocument = profile?.$ref;
        const rawProfile = this.page.getContent(profileDocument)?.getData();
        // We need to format speaker title because sometimes we receive bloomreach response encapsulated
        // in a <p> tag or sometimes its a string. Hence, using a div and its innerHTML to
        // extract the text.
        const speakerTitleFormatted: HTMLDivElement = this.renderer.createElement(
          'div'
        );
        speakerTitleFormatted.innerHTML = rawProfile?.overview;
        if (rawProfile?.fullName) {
          formattedProfiles.push({
            profileImage: this.widenService.getWidenAssetUrl(
              rawProfile?.cardImage?.widenAsset
            ),
            profileImageAlt: rawProfile?.cardImage?.alt,
            speakerName: rawProfile?.fullName,
            speakerTitle: speakerTitleFormatted?.innerText,
          });
        }
      });
    }
    return formattedProfiles;
  }
}
