<div class="container">
  <div class="content">
    <div edsPadding edsPaddingBottom="lg">
      <p><b>System status:</b></p>
      <span
        >Backend system:
        <span
          *ngIf="statusJson.hosts[0].status === ''"
          class="has-text-warning"
        >
          ...
        </span>
        <span
          *ngIf="statusJson.hosts[0].status === 'up'"
          class="has-text-success"
          >{{ statusJson.hosts[0].status }}</span
        >
        <span
          *ngIf="statusJson.hosts[0].status === 'down'"
          class="has-text-danger"
          >{{ statusJson.hosts[0].status }}</span
        > </span
      ><br />
      <span
        >Backend system details:
        <span class="has-text-success">{{
          statusJson.config | json
        }}</span></span
      ><br />
      <span *ngIf="statusJson.error" class="has-text-danger"
        >Error details: {{ statusJson.error }}</span
      >
    </div>
    <div>
      <div edsPadding edsPaddingBottom="lg">
        <p><b>Authors</b></p>
        <a
          class="has-text-dark"
          href="mailto:piotr.orzechowski@franklintempleton.com"
          ><u>Piotr Orzechowski</u></a
        >,
        <a
          class="has-text-dark"
          href="mailto:Sathishkumar.Murugesan@franklintempleton.com"
          ><u>Sathishkumar Murugesan</u></a
        >
      </div>
    </div>
  </div>
</div>
