import { Injectable, OnDestroy } from '@angular/core';
import {
  BMPerformanceDetail,
  PerformanceDetail,
  Product,
  ShareClass,
} from '@models';

import {
  BenchmarksToShow,
  SiteConfigService,
} from '@services/site-config.service';
import {
  EMDASH,
  PRODUCT_TYPE_SMA,
} from '@products/utils/constants/product.constants';
import {
  AllShareClassesAnnualizedData,
  CalcTypeStd,
  FundId,
  FundPerformanceAnnualizedPeriodEndData,
  FundPerformanceAnnualizedState,
  FundPerformanceAnnualizedStateData,
  FundPerformanceAnnualizedTableData,
  PerformanceSuppressionSet,
  PerformanceType,
  PerformanceView,
  ProductDetailConfiguration,
  ShareClassCode,
} from '@types';
import { Logger } from '@utils/logger';
import { Subject, Observable } from 'rxjs';
import fundPerformanceAnnualizedQuery from '@graphql/fund-performance/annualized.graphql';
import fundPerformanceAnnualizedSnapshotQuery from '@graphql/fund-performance/annualized-snapshot.graphql';
import { BaseFundPerformanceService } from './base-fund-performance.service';
import { FundDetailService } from '@products/services/fund-detail.service';
import { stringCompare } from '@utils/sort-utils';
import kebabCase from 'lodash/kebabCase';
import { FundOverviewService } from '@products/services/fund-overview.service';
import { getCached5YearAgoStd } from '@utils/il8n/moment-il8n';
import { TranslateService } from '@shared/translate/translate.service';
import { hasValue } from '@products/utils/mappers/mapper.utils';
import { HighchartsThemeService, ISOYearMonth } from '@frk/eds-components';
import { CUSTOM_DATE_CHANGE, PEER_RANKINGS_KEY } from '@utils/app.constants';
import { takeUntil } from 'rxjs/operators';
import { ProfileService } from '@services';
import { PerformanceComponentId } from '../performance-component-id.enum';
import dayjs from 'dayjs';

const logger = Logger.getLogger('FundPerformanceAnnualizedService');

@Injectable({
  providedIn: 'root',
})
export class FundPerformanceAnnualizedService
  extends BaseFundPerformanceService
  implements OnDestroy {
  private state: FundPerformanceAnnualizedState = {};
  private fundPerformanceStateSubject$: Subject<FundPerformanceAnnualizedState>;
  private product: Product;
  private currentShareClass: ShareClass;
  private hideCurrentShareClassPerformanceData: boolean;
  private maxNumberOfBenchmarksToShow: number;
  private benchmarkOrder: string[];
  private suppressedBenchmarks: string[];

  private initialColumns = [
    'oneYear',
    'twoYear',
    'threeYear',
    'fourYear',
    'fiveYear',
    'sevenYear',
    'tenYear',
    'fifteenYear',
    'twentyYear',
    'twentyFiveYear',
    'thirtyFiveYear',
    'sinceInception',
  ];

  private unsubscribe$: Subject<void> = new Subject<void>();
  isLoggedIn: boolean;

  constructor(
    private fundDetailService: FundDetailService,
    private fundOverviewService: FundOverviewService,
    private config: SiteConfigService,
    translateService: TranslateService,
    protected highchartsTheme: HighchartsThemeService,
    protected profileService: ProfileService
  ) {
    super(translateService, highchartsTheme, profileService);
    this.fundPerformanceStateSubject$ = new Subject();
  }

  public get fundPerformanceState$(): Observable<FundPerformanceAnnualizedState> {
    return this.fundPerformanceStateSubject$.asObservable();
  }

  /**
   * Call register method of fund detail service.
   */
  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    this.product = null;
    this.currentShareClass = null;
    this.hideCurrentShareClassPerformanceData = false;
    this.maxNumberOfBenchmarksToShow = null;
    this.benchmarkOrder = null;
    this.suppressedBenchmarks = null;

    this.isLoggedIn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      });

    this.fundDetailService
      .register(fundPerformanceAnnualizedQuery, {
        fundId: productDetailConfiguration.fundId,
        shareClassCode: productDetailConfiguration.shareClassCode,
      })
      .subscribe(
        (product) => {
          logger.debug(product);
          this.currentShareClass = this.findShareClassByShareClassCode(
            product,
            productDetailConfiguration.shareClassCode
          );
          if (
            this.currentShareClass &&
            this.isPerformanceDataAvailable(this.currentShareClass)
          ) {
            this.mapState(product);
            this.state.isError = false;
          } else {
            logger.error(`No Fund Performance Annualized data found for fund id ${productDetailConfiguration.fundId}
           and share class ${productDetailConfiguration.shareClassCode} `);
            this.state.isError = true;
          }
          this.fundPerformanceStateSubject$.next(this.state);
        },
        (error) => {
          logger.error(
            `Error in getting Fund Performance Annualized data for fund id ${productDetailConfiguration.fundId}
        and share class ${productDetailConfiguration.shareClassCode} `,
            error
          );
          this.state.isError = true;
          this.fundPerformanceStateSubject$.next(this.state);
        }
      );
  }

  populateHistoricalData(toDate: string, callType?: string) {
    this.fundOverviewService
      .register(fundPerformanceAnnualizedSnapshotQuery, {
        fundId: this.product.fundId,
        shareClassCode: this.currentShareClass.identifiers.shareClassCode,
        toDate,
      })
      .subscribe(
        (product: Product) => {
          logger.debug(product);
          this.state.data.historicalData = this.getCurrentShareClassPerformancePeriodEndData(
            product.shareClasses[0]?.performance?.historical,
            product.shareClasses[0]?.benchmarkPerformance?.historical,
            'products.perf-date-selected'
          );
          if (callType === CUSTOM_DATE_CHANGE) {
            this.state.config.periodEnd = PerformanceView.CUSTOM;
          }
          this.fundPerformanceStateSubject$.next(this.state);
        },
        (error) => {
          logger.error(
            `Error in getting Fund Performance Annualized historical data for fund id ${this.product.fundId}
             and share class ${this.currentShareClass.identifiers.shareClassCode} `,
            error
          );
        }
      );
  }

  /**
   * Map the fund performance response.
   * @param product received product performance details
   */
  mapState(product: Product) {
    this.state.perfStatusCaveat = this.getPerfStatusCaveat(
      this.currentShareClass
    );
    this.state.heading = this.translateService.instant(
      'products.performance-annualized-heading' +
        (this.config.getMifidAge(
          this.currentShareClass.performanceInceptionDateStd
        ) || '')
    );
    this.product = product;
    this.hideCurrentShareClassPerformanceData = this.config.mustHidePerformanceData(
      this.currentShareClass.performanceInceptionDateStd,
      this.isLoggedIn
    );
    this.maxNumberOfBenchmarksToShow = this.config.getMaxNumberOfBenchmarksToShow(
      this.product.fundId
    );
    this.benchmarkOrder = this.config.getBenchmarkOrder(
      this.product.productTaxonomy
    );
    this.suppressedBenchmarks = this.config.getSuppressedBenchmarks(
      this.product.productTaxonomy
    );

    this.state.data = this.mapPerformanceDetailsToState();
    this.state.config = {};
    this.populateConfig();
  }

  private populateConfig() {
    this.state.config.periodEnds = this.config.getPeriodEnds(
      this.product.productType,
      PerformanceType.AVG_ANNUAL
    );
    this.state.config.periodEnd = this.state.config.periodEnds[0];
    this.state.config.showCurrency = !this.config.hidePerformanceCurrency(
      this.product.productTaxonomy
    );
    this.state.config.showMifid = this.hideCurrentShareClassPerformanceData;
    this.setHideChartConfig();
    this.state.config.showHistorical = this.config.product.performance
      ?.acHistorical
      ? true
      : false;
  }

  private setHideChartConfig() {
    // To check the suppression set config
    const suppressionSet: PerformanceSuppressionSet = this.config.getPerformanceComponentSuppressionSet(
      PerformanceComponentId.ANNUALIZED,
      this.product.fundId
    );

    if (suppressionSet || this.hideCurrentShareClassPerformanceData) {
      this.state.config.hideChart = true;
    } else {
      const tableData = this.state.data.monthEnd.tableData;
      if (
        !tableData ||
        tableData.length === 0 ||
        (!this.config.product.performance?.showCharts1YearLess &&
          this.isDataNotAvailable(tableData[0].oneYear))
      ) {
        this.state.config.hideChart = true;
      }
    }
  }

  setPeriodEnd(periodEnd: string) {
    this.state.config.periodEnd = periodEnd;
    this.fundPerformanceStateSubject$.next(this.state);
  }

  mapPerformanceDetailsToState(): FundPerformanceAnnualizedStateData {
    const monthEndPerformanceData = this.getCurrentShareClassPerformancePeriodEndData(
      this.currentShareClass.performance.monthEnd,
      this.currentShareClass.benchmarkPerformance.monthEnd,
      'products.month-end'
    );
    const quarterEndPerformanceData = this.getCurrentShareClassPerformancePeriodEndData(
      this.currentShareClass.performance.quarterEnd,
      this.currentShareClass.benchmarkPerformance.quarterEnd,
      'products.quarter-end'
    );
    if (
      !this.config.hideAdditionalShareClassLinks(this.product.productTaxonomy)
    ) {
      const allShareClassData = this.getAllShareClassesPerformancePeriodEndData();

      monthEndPerformanceData.allShareClassesTableData = this.createAllShareClassData(
        allShareClassData
          .get(PerformanceView.MONTHLY)
          .sort((current, next) =>
            stringCompare(current.name, next.name, null, null, false)
          )
      );

      quarterEndPerformanceData.allShareClassesTableData = this.createAllShareClassData(
        allShareClassData
          .get(PerformanceView.QUARTERLY)
          .sort((current, next) =>
            stringCompare(current.name, next.name, null, null, false)
          )
      );
    }

    return {
      fundName: this.product.fundName,
      monthEnd: monthEndPerformanceData,
      quarterEnd: quarterEndPerformanceData,
    };
  }

  private createAllShareClassData(
    rows: FundPerformanceAnnualizedTableData[]
  ): AllShareClassesAnnualizedData {
    // get column to remove
    const clmsToRemoveM = this.getColumnsToRemove(rows);
    // Remove colums from Data
    rows = this.removeColumnsFromData(rows, clmsToRemoveM);
    const tableColumns = this.getLabelsForChart(clmsToRemoveM);
    const columns = tableColumns.filter((col) => {
      return col !== 'sinceInception';
    });
    const isInceptionM = tableColumns.includes('sinceInception');

    return {
      tableData: rows,
      tableColumns: columns,
      showInception: isInceptionM,
    };
  }

  private getAllShareClassesPerformancePeriodEndData(): Map<
    PerformanceView,
    FundPerformanceAnnualizedTableData[]
  > {
    const allShareClassesData: Map<
      PerformanceView,
      FundPerformanceAnnualizedTableData[]
    > = new Map();
    allShareClassesData.set(PerformanceView.MONTHLY, []);
    allShareClassesData.set(PerformanceView.QUARTERLY, []);
    this.product.shareClasses.forEach((shareclass) => {
      if (
        shareclass.identifiers.shareClassCode !==
        this.currentShareClass.identifiers.shareClassCode
      ) {
        const hidePerformanceData = this.config.mustHidePerformanceData(
          shareclass.performanceInceptionDateStd,
          this.isLoggedIn
        );
        const defaultCalcType = this.config.getDefaultCalcType(
          this.product.productType
        );
        if (shareclass.performance.monthEnd?.length > 0) {
          const performanceData = this.getPerformanceData(
            shareclass,
            defaultCalcType,
            hidePerformanceData,
            shareclass.performance.monthEnd
          );
          allShareClassesData
            .get(PerformanceView.MONTHLY)
            .push(performanceData);
        }
        if (shareclass.performance.quarterEnd?.length > 0) {
          const performanceData = this.getPerformanceData(
            shareclass,
            defaultCalcType,
            hidePerformanceData,
            shareclass.performance.quarterEnd
          );
          allShareClassesData
            .get(PerformanceView.QUARTERLY)
            .push(performanceData);
        }
      }
    });
    return allShareClassesData;
  }

  private getPerformanceData(
    shareclass: ShareClass,
    defaultCalcType: string,
    hidePerformanceData: boolean,
    performances: PerformanceDetail[]
  ) {
    let performance = performances[0];
    performances.forEach((perf) => {
      if (perf.calcTypeStd === defaultCalcType) {
        performance = perf;
      }
    });
    const performanceData = this.getSHTableRow(
      performance,
      shareclass,
      hidePerformanceData
    );
    performanceData.link = shareclass.link;
    return performanceData;
  }
  sortRowsOnName(
    performanceData: FundPerformanceAnnualizedTableData[]
  ): FundPerformanceAnnualizedTableData[] {
    return performanceData.sort((current, next) => {
      return stringCompare(current.name, next.name, null, null, false);
    });
  }

  private getCurrentShareClassPerformancePeriodEndData(
    performanceArray: PerformanceDetail[],
    benchmarkArray: BMPerformanceDetail[],
    label: string
  ): FundPerformanceAnnualizedPeriodEndData {
    const inceptionDate = this.currentShareClass.performanceInceptionDate;
    const inceptionDateStd = this.currentShareClass.performanceInceptionDateStd;
    const asOfDate = performanceArray[0]?.performanceAsOfDate;
    const asOfDateStd = performanceArray[0]?.performanceAsOfDateStd;

    // NGC-6135: filter bmArray to restrict duplicate entries.
    const filteredBenchmarkArray: BMPerformanceDetail[] = [];
    benchmarkArray.forEach((benchmarkObj) => {
      const index: number = filteredBenchmarkArray.findIndex(
        (filteredBenchmarkObj) =>
          filteredBenchmarkObj.benchmarkLabel === benchmarkObj.benchmarkLabel
      );

      if (index === -1) {
        filteredBenchmarkArray.push(benchmarkObj);
      }
    });
    let rows: FundPerformanceAnnualizedTableData[] = this.getPerformanceTableData(
      performanceArray,
      filteredBenchmarkArray,
      this.product.fundId,
      this.currentShareClass.shareClassCode
    );

    // get column to remove
    const clmsToRemove = this.getColumnsToRemove(rows);
    // Remove colums from Data
    rows = this.removeColumnsFromData(rows, clmsToRemove);

    let graphData = null;
    const chartColumns = this.getLabelsForChart(clmsToRemove);
    if (!this.hideCurrentShareClassPerformanceData) {
      graphData = this.convertTableToChartData(rows, chartColumns);
      graphData = this.convertToHighchartsColumnSeries(graphData);
    }
    const graphLabels = chartColumns.map((colnname) => `products.${colnname}`);
    const columns = chartColumns.filter((col) => {
      return col !== 'sinceInception';
    });
    const isInception = chartColumns.includes('sinceInception');

    return {
      graphData,
      graphLabels,
      tableData: rows,
      tableColumns: columns,
      showInception: isInception,
      tableLabel: label,
      performanceDates: {
        asOfDate,
        asOfDateStd,
        inceptionDate,
        inceptionDateStd,
        asOfDateMonthStd: dayjs(asOfDateStd, 'YYYY-MM-DD').format(
          'YYYY-MM'
        ) as ISOYearMonth,
        inceptionDateMonthStd: dayjs(inceptionDateStd, 'YYYY-MM-DD').format(
          'YYYY-MM'
        ) as ISOYearMonth,
      },
    };
  }

  private getPerformanceTableData(
    performanceArray: PerformanceDetail[],
    benchmarkArray: BMPerformanceDetail[],
    fundId: FundId,
    shareClassCode: ShareClassCode
  ): FundPerformanceAnnualizedTableData[] {
    const rows: FundPerformanceAnnualizedTableData[] = [];
    let filteredPfArray = this.config.isSouthAfrica()
      ? this.filterPerformanceArrayByCurrencyCode(
          performanceArray,
          this.currentShareClass.shareClassCurrency
        )
      : performanceArray;

    // WDE-4201 remove POP rows for CPREIF and for TOF(product type)
    if (
      this.config.hidePerformanceTableRowPop(
        shareClassCode,
        this.product.productType
      )
    ) {
      filteredPfArray = this.filterOutCalcType(filteredPfArray, 'POP');
    }

    this.addShareClassRows(filteredPfArray, rows);
    this.addBenchmarkRows(benchmarkArray, rows);
    this.addChartColors(rows);
    this.movePeerRankingsToEnd(rows);
    return rows;
  }

  getColumnsToRemove(rows: FundPerformanceAnnualizedTableData[]) {
    const clmToRemove = [];
    // Search in data if the particular column has no data
    this.initialColumns.forEach((clm: any) => {
      if (
        !rows.some((row) => {
          return hasValue(row, clm);
        })
      ) {
        clmToRemove.push(clm);
      }
    });

    // Configuration
    // For 5 year ago data SMA All
    if (
      !(
        this.product.productType === PRODUCT_TYPE_SMA &&
        this.currentShareClass.performanceInceptionDateStd >
          getCached5YearAgoStd()
      )
    ) {
      clmToRemove.push('twoYear');
      clmToRemove.push('fourYear');
    }

    // show only in case of SMA US
    if (
      this.config.isSiteInternational() ||
      (!this.config.isSiteInternational() &&
        this.product.productType !== PRODUCT_TYPE_SMA)
    ) {
      clmToRemove.push('sevenYear');
      clmToRemove.push('twentyYear');
      clmToRemove.push('twentyFiveYear');
      clmToRemove.push('thirtyFiveYear');
    }

    // Hide only in case of US Non SMA
    if (
      !this.config.isSiteInternational() &&
      this.product.productType !== PRODUCT_TYPE_SMA
    ) {
      clmToRemove.push('fifteenYear');
    }

    return clmToRemove;
  }

  private removeColumnsFromData(
    rows: FundPerformanceAnnualizedTableData[],
    clmToRemove: string[]
  ) {
    // Remove empty columns from row
    rows.forEach((row) => {
      if (row !== undefined) {
        clmToRemove.forEach((clm) => {
          delete row[clm];
        });
      }
    });
    rows = rows.filter((row) => this.initialColumns.some((key) => row[key]));
    return rows;
  }

  private addShareClassRows(
    performanceArray: PerformanceDetail[],
    rows: FundPerformanceAnnualizedTableData[]
  ) {
    if (performanceArray) {
      if (performanceArray.length > 0) {
        const defaultCalcType = this.config.getDefaultCalcType(
          this.product.productType
        );
        const defaultCalcTypeRow = performanceArray.find(
          (perf) => perf.calcTypeStd === defaultCalcType
        );

        if (defaultCalcTypeRow) {
          performanceArray.splice(
            performanceArray.indexOf(defaultCalcTypeRow),
            1
          );
          performanceArray.unshift(defaultCalcTypeRow);
        }
        performanceArray.forEach((perf: PerformanceDetail) => {
          if (perf.calcTypeStd !== PEER_RANKINGS_KEY) {
            // Filter peer Rankings from performance data
            rows.push(
              this.getSHTableRow(
                perf,
                this.currentShareClass,
                this.hideCurrentShareClassPerformanceData
              )
            );
          }
        });
      }
    }
  }

  private addBenchmarkRows(
    benchmarkArray: BMPerformanceDetail[],
    rows: FundPerformanceAnnualizedTableData[]
  ) {
    if (benchmarkArray) {
      if (this.maxNumberOfBenchmarksToShow !== BenchmarksToShow.ZERO) {
        benchmarkArray.sort((bm1, bm2) =>
          this.compareBenchmarks(bm1, bm2, this.benchmarkOrder)
        );
      }
      benchmarkArray = benchmarkArray.slice(
        0,
        this.maxNumberOfBenchmarksToShow
      );
      benchmarkArray.forEach(
        (benchmark: BMPerformanceDetail, index: number) => {
          if (benchmark.calcTypeStd === PEER_RANKINGS_KEY) {
            // Display peer rankings only for funds for which it has been configured to show
            if (
              this.config.showPeerGroupCategoryAverageAddition(
                this.product.productTaxonomy
              )
            ) {
              const bmRow = this.getBMTableRow(
                benchmark,
                rows[0],
                this.suppressedBenchmarks.includes(benchmark.benchmarkLabel),
                index
              );
              rows.push(bmRow);
            }
          } else {
            const bmRow = this.getBMTableRow(
              benchmark,
              rows[0],
              this.suppressedBenchmarks.includes(benchmark.benchmarkLabel),
              index
            );
            rows.push(bmRow);
          }
        }
      );
    }
  }

  private movePeerRankingsToEnd(
    rows: FundPerformanceAnnualizedTableData[]
  ): void {
    if (rows?.length) {
      const index: number = rows.findIndex(
        (row: FundPerformanceAnnualizedTableData) => row.isPeerRankingsRow
      );
      rows.push(rows.splice(index, 1)[0]);
    }
  }

  private getSHTableRow(
    performanceData: PerformanceDetail,
    shareClass: ShareClass,
    hideShareClassPerformanceData: boolean,
    hideOnChart: boolean = false
  ): FundPerformanceAnnualizedTableData {
    const basicShareClass = this.extractBasicShareClassFromPerformanceRow(
      performanceData,
      this.product.fundName,
      shareClass
    );
    const oneYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnual1YearReturn;
    const twoYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnual2YearReturn;
    const threeYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnual3YearReturn;
    const fourYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnual4YearReturn;
    const fiveYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnual5YearReturn;
    const sevenYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnual7YearReturn;
    const tenYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnual10YearReturn;
    const fifteenYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnual15YearReturn;
    const twentyYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnual20YearReturn;
    const twentyFiveYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnual25YearReturn;
    const thirtyFiveYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnual35YearReturn;
    const sinceInception = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.avgAnnualSinceInceptionReturn;

    const fundPerformanceTableData: FundPerformanceAnnualizedTableData = Object.assign(
      basicShareClass,
      {
        oneYear,
        twoYear,
        threeYear,
        fourYear,
        fiveYear,
        sevenYear,
        tenYear,
        fifteenYear,
        twentyYear,
        twentyFiveYear,
        thirtyFiveYear,
        sinceInception,
        asOfDate: performanceData.performanceAsOfDate,
        hideOnChart,
        placement: BaseFundPerformanceService.fundPerformancePlacement,
        calcTypeLabel: `products.${this.getProductTypeLabel(
          this.product.productType
        )}-performance-calctype-${kebabCase(performanceData.calcTypeStd)}`,
        isPeerRankingsRow: performanceData.calcTypeStd === PEER_RANKINGS_KEY,
        calcTypeStd: performanceData.calcTypeStd,
      }
    );

    return fundPerformanceTableData;
  }

  private getBMTableRow(
    bmPerformanceData: BMPerformanceDetail,
    fundRow: FundPerformanceAnnualizedTableData,
    suppressBMData: boolean,
    index: number
  ): FundPerformanceAnnualizedTableData {
    const name = bmPerformanceData.benchmarkName;
    const oneYear =
      suppressBMData && this.isDataNotAvailable(fundRow.oneYear)
        ? EMDASH
        : bmPerformanceData.avgAnnual1YearReturn;
    const twoYear =
      suppressBMData && this.isDataNotAvailable(fundRow.twoYear)
        ? EMDASH
        : bmPerformanceData.avgAnnual2YearReturn;
    const threeYear =
      suppressBMData && this.isDataNotAvailable(fundRow.threeYear)
        ? EMDASH
        : bmPerformanceData.avgAnnual3YearReturn;
    const fourYear =
      suppressBMData && this.isDataNotAvailable(fundRow.fourYear)
        ? EMDASH
        : bmPerformanceData.avgAnnual4YearReturn;
    const fiveYear =
      suppressBMData && this.isDataNotAvailable(fundRow.fiveYear)
        ? EMDASH
        : bmPerformanceData.avgAnnual5YearReturn;
    const sevenYear =
      suppressBMData && this.isDataNotAvailable(fundRow.sevenYear)
        ? EMDASH
        : bmPerformanceData.avgAnnual7YearReturn;
    const tenYear =
      suppressBMData && this.isDataNotAvailable(fundRow.tenYear)
        ? EMDASH
        : bmPerformanceData.avgAnnual10YearReturn;
    const fifteenYear =
      suppressBMData && this.isDataNotAvailable(fundRow.fifteenYear)
        ? EMDASH
        : bmPerformanceData.avgAnnual15YearReturn;
    const twentyYear =
      suppressBMData && this.isDataNotAvailable(fundRow.twentyYear)
        ? EMDASH
        : bmPerformanceData.avgAnnual20YearReturn;
    const twentyFiveYear =
      suppressBMData && this.isDataNotAvailable(fundRow.twentyFiveYear)
        ? EMDASH
        : bmPerformanceData.avgAnnual25YearReturn;
    const thirtyFiveYear =
      suppressBMData && this.isDataNotAvailable(fundRow.thirtyFiveYear)
        ? EMDASH
        : bmPerformanceData.avgAnnual35YearReturn;

    return {
      name,
      oneYear,
      twoYear,
      threeYear,
      fourYear,
      fiveYear,
      sevenYear,
      tenYear,
      fifteenYear,
      twentyYear,
      twentyFiveYear,
      thirtyFiveYear,
      sinceInception: bmPerformanceData.avgAnnualSinceInceptionReturn,
      asOfDate: bmPerformanceData.performanceAsOfDate,
      inceptionDate: EMDASH,
      currency: bmPerformanceData.currencyCode,
      placement: this.getBemchmarkPerformancePlacement(index),
    };
  }

  private getLabelsForChart(clmToRemove: string[]): string[] {
    // Remove columns
    const columns = [];
    this.initialColumns.forEach((clm) => {
      if (!clmToRemove.includes(clm)) {
        columns.push(clm);
      }
    });

    return columns;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
