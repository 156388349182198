import { Component, OnInit } from '@angular/core';
import { Logger } from '@utils/logger';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ViewModeService } from '@services/view-mode.service';
import { replaceTokens } from '@utils/text/string-utils';
import { EnvConfigService, IUserProfile, ProfileService } from '@services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Logger
 */
const logger = Logger.getLogger('CodeComponent');

/**
 * Interface for the Code Content
 */
interface CodeContent {
  content: CodeValue;
}

interface CodeValue {
  value: string;
}

/**
 * FT Code component
 *
 * This component is used at the top of a template and has it's own associated document type.
 *
 * Selector: `ft-code`
 *
 * Exported as: `CodeComponent`
 */
@Component({
  selector: 'ft-code',
  templateUrl: './code.component.html',
})
export class CodeComponent extends AbstractBaseComponent implements OnInit {
  content$: Observable<SafeHtml>;
  public visible = false;
  /**
   * Constructor
   */
  constructor(
    private sanitizer: DomSanitizer,
    private viewModeService: ViewModeService,
    private envConfigService: EnvConfigService,
    private profileService: ProfileService
  ) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  public onVisible(): void {
    this.visible = true;
  }

  /**
   * Angular init hook
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.content$ = this.sanitizeContent$(
      this.document?.getData<CodeContent>()?.htmlCode?.value
    );
  }

  /**
   * Sanitazing HTML content
   * @param input - HTML content
   */
  private sanitizeContent$(input: string): Observable<SafeHtml> {
    if (/\$[A-Z]+\[[A-Za-z]+]/.test(input)) {
      return this.profileService.getUserProfile$().pipe(
        map((profile: IUserProfile) => {
          input = replaceTokens(
            input,
            this.envConfigService.getEnvConfig(),
            profile
          );
          return this.sanitizer.bypassSecurityTrustHtml(input);
        })
      );
    }
    return of(this.sanitizer.bypassSecurityTrustHtml(input));
  }
}
