<ng-container [ngSwitch]="content.type">
  <!-- Intro Text Block -->
  <ng-template ngSwitchCase="IntroBlock">
    <div edsRow>
      <div edsCol [columnNumbers]="colNumbers">
        <p class="large">
          {{ content.content }}
        </p>
      </div>
    </div>
  </ng-template>
  <!-- Paragraph block -->
  <ng-template ngSwitchCase="ParagraphBlock">
    <div edsRow>
      <div edsCol>
        <ng-container *ngIf="spaBaseURL; else textBlockContent">
          <div
            class="ck-editor"
            [innerHTML]="spaBaseURLContent | safeHtml"
          ></div>
        </ng-container>
        <ng-template #textBlockContent>
          <div class="ck-editor" [innerHTML]="content.content | safeHtml"></div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</ng-container>
