import { Component, Input, OnInit } from '@angular/core';

interface BlockquoteBlockContent {
  quote: string;
  type: 'Blockquote';
  team: string;
  quoteName: string;
}

@Component({
  selector: 'ft-blockquote-block',
  templateUrl: './blockquote-block.component.html',
})
export class BlockquoteBlockComponent implements OnInit {
  /**
   * Is Block in a Two Col layout
   */
  @Input()
  isTwoCol: boolean;

  /**
   * Content to render
   */
  @Input()
  content: BlockquoteBlockContent;

  /**
   * Column numbers
   */
  colNumbers: any;

  /**
   * Constructor
   */
  constructor() {}

  /**
   * On Init
   */
  ngOnInit(): void {
    if (!this.isTwoCol) {
      // 4 = 100%, 5 = 0.625%, 6 = 50%
      this.colNumbers = { s: 4, m: 5, l: 6 };
    } else {
      this.colNumbers = { s: 'auto', m: 'auto', l: 'auto' };
    }
  }
}
