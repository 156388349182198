import { RiskStatisticsAnalysisState, RiskMeasuresState } from '@types';

export const RISK_MEASURES_INITIAL_STATE: RiskMeasuresState = {
  shareClassName: null,
  benchmarkName: null,
  asOfDate: null,
  config: {
    isFundOlderThan10Yrs: null,
    show1YrPerformance: false,
    showSinceInception: false,
  },
  stdDev: [],
  sharpeRatio: [],
  statistics: [],
};

export const RISK__EFFICIENCY_ANALYSIS_INITIAL_STATE: RiskStatisticsAnalysisState = {
  shareClassName: null,
  benchmarkName: null,
  asOfDate: null,
};

export const PERIODS_MAPPER = {
  MONTHLY: 'monthEnd',
  QUARTERLY: 'quarterEnd',
};
