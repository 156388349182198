import { ChargesDTO } from '@types';
import { Mapper, MapperParams } from './type.mapper';
import { MapperFactory } from './mapper-factory';
import { Charges } from '@models';

export class ChargesMapper extends Mapper<Charges> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(chargesDto: ChargesDTO): Charges {
    return {
      expenseRatioNet: chargesDto.expratnet,
      expenseRatioGross: chargesDto.expratgross,
      cdsc: chargesDto.cdsc,
      maxIntialSalesCharge: chargesDto.maxinitchrg,
      annualCharge: chargesDto.annchrg,
      waiverType: chargesDto.waivertype,
      waiverEndDate: chargesDto.waiverenddate,
      performanceFeeCharge: chargesDto.prfrmncefee,
      maintananceCharge: chargesDto.fundadminfee,
      feeAsOfDate: chargesDto.feeasofdate,
      ongoingCharge: chargesDto.ongoingcharge,
      asOfDate: chargesDto.asofdate,
      mer: chargesDto.mer ? chargesDto.mer : null,
      managementFee: chargesDto.managementfee ? chargesDto.managementfee : null,
    };
  }
}
