import { Inject, Injectable, OnDestroy } from '@angular/core';
import { GlobalConfigService } from '@services/global-config-service';
import {
  GlobalConfig,
  LanguageData,
  MultilingualMap,
  SimplyLinkItem,
} from '@types';
import { TranslateService } from '@shared/translate/translate.service';
import { WINDOW } from '@ng-web-apis/common';
import { Page } from '@bloomreach/spa-sdk';
import { LanguageSelector } from '@frk/eds-components';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

export type Language = {
  label: string;
  language: string;
  url: string;
};

@Injectable({
  providedIn: 'root',
})
export class LanguageSelectorService implements OnDestroy {
  private languages: Language[] = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  private pageLink: string;
  constructor(
    private globalConfigService: GlobalConfigService,
    private translateService: TranslateService,
    private router: Router,
    @Inject(WINDOW) readonly windowRef: Window
  ) {
    this.globalConfigService
      .getGlobalConfigSubject$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.setLanguages);
  }

  /**
   * Set languages array
   */
  private setLanguages = (globalConfig: GlobalConfig): void => {
    const languageData = globalConfig.siteConfiguration?.languages;

    if (languageData) {
      this.languages = languageData.map((language: LanguageData) => {
        return {
          label:
            this.translateService.instant('languages.' + language.locale) ||
            language.locale,
          language: language.locale,
          url: this.remapLanguageUrl(language.url),
        };
      });
    }
  };

  /**
   * Remapping language URL to get correct page link
   * @param langUrl string - language URL from site configuration
   */
  private remapLanguageUrl(langUrl: string): string {
    const isMappingOn: boolean = this.globalConfigService.getMultilingualMapping();
    const multilingualMap: MultilingualMap[] =
      this.globalConfigService.config?.multilingualMap || [];
    if (isMappingOn) {
      if (multilingualMap.length > 0) {
        const mappedURL: string =
          multilingualMap.find((map: MultilingualMap) => {
            return map.languageVersion === langUrl.replace(/\//g, '');
          })?.pathToPage || '';
        return (langUrl + mappedURL).replace(/\/\//g, '/');
      }
      // If Mapping is set in config, but not for Page Data, just replace locale in URL
      this.pageLink = this.router.url;
      const urlToSlice: number = langUrl.length;
      if (this.pageLink.length > urlToSlice) {
        return langUrl + this.pageLink.slice(urlToSlice);
      }
    }
    // Just not map anything.
    return langUrl;
  }
  /**
   * Get Language options for language selector (eds-flyout-menu)
   */
  public getLanguageOptions(page: Page): LanguageSelector {
    const convertedLanguages: SimplyLinkItem[] = this.getConvertedLanguages();
    if (convertedLanguages.length > 0) {
      return {
        title: this.getChannelLanguageLabel(page),
        items: convertedLanguages,
      };
    }
  }

  /**
   * Returns languages array
   */
  public getLanguages(): Language[] {
    return this.languages;
  }

  /**
   * Set Channel language label
   * @param page - BR page
   */
  public getChannelLanguageLabel(page: Page): string {
    const channelParams = page.getChannelParameters();
    return (
      this.translateService.instant('languages.' + channelParams?.Language) ||
      'Language'
    );
  }

  /**
   * Returns languages array required for EDS component
   */
  public getConvertedLanguages(): SimplyLinkItem[] {
    const languagesEds = [];
    this.languages.forEach((language: Language) => {
      const simplyItem: SimplyLinkItem = {
        link: language.label,
        href: this.windowRef.location.origin + (language.url || '#'),
        eventParams: { link_text: language.label },
      };
      languagesEds.push(simplyItem);
    });
    return languagesEds;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
