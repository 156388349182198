import { Nominal } from 'simplytyped';
import { FundId } from './fund-identifier.type';
import { ShareClassCode } from './share-class.type';

export enum ConfigurationId {
  GW = 'GW',
  ETF = 'ETFs',
  CEF = 'Closed-End Funds',
  MF = 'Mutual Funds',
  MMF = 'Money Market Funds',
  TIF = 'TIF',
  VIP = 'Variable Insurance',
  _529 = '529 Portfolios',
  SMA = 'Managed Accounts',
  IF = 'Interval Funds',
  US = 'US',
  TOF = 'Tender Offer Funds',
  INDICES = 'Indices',
  SEGREGATED = 'Segregated',
  UAFF = 'UAE Feeder Funds',
  CIT = 'Collective Investment Trust',
}

export interface ProductDetailConfiguration {
  componentType?: string;
  fundId: FundId;
  shareClassCode: ShareClassCode;
  location?: string;
  configurationName?: ConfigurationId;
  displayCapGainRecords?: boolean;
  showHistAvgeLifeMaturity?: boolean;
}

export type ConfigurationCountry = Nominal<string, 'ConfigurationCountry'>; // 2 digit country code e.g. 'LU'
export type ConfigurationLocale = Nominal<string, 'ConfigurationLocale'>; // locale code e.g. 'en_GB'
