import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService, EnvConfigService } from '@services';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('Auth0LogoutCallbackComponent');

@Component({
  selector: 'ft-auth0-logout-callback',
  templateUrl: './auth0-logout-callback.component.html',
  styleUrls: ['./auth0-logout-callback.component.scss'],
})
export class Auth0LogoutCallbackComponent implements OnInit {
  constructor(
    private router: Router,
    private appStateService: AppStateService,
    private envConfig: EnvConfigService
  ) {}

  ngOnInit(): void {
    // always go to home page on logout
    this.envConfig.loadEnvConfig().then(() => {
      this.appStateService.getApiUrls();
      const home = this.appStateService.getSpaBaseUrl();
      this.router.navigate([home]);
    });
  }
}
