import { Injectable, OnDestroy } from '@angular/core';
import { NavigationService } from '@frk/eds-components';
// TODO: these services are imported individually until SignInservice is refactored to avoid circular dependency
import { AppStateService } from '@services/app-state.service';
import { IUserProfileInfo } from '@services/profile.interface';
import { SiteConfigService } from '@services/site-config.service';
import { TranslateService } from '@shared/translate/translate.service';
import {
  CartActionPerformed,
  CartDetails,
  CookieResponse,
  LiteratureData,
  LiteratureDocument,
  LiteratureDocumentData,
  LiteratureDocumentListingData,
  LiteratureDocumentListingState,
  StorageItems,
} from '@types';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  forkJoin,
  of,
} from 'rxjs';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { LiteratureDataService } from './literature-data.service';
import { LiteratureDocumentCartService } from './literature-document-cart.service';
import { LiteratureGlobalConfigService } from './literature-global-config.service';
import { LiteratureRulesHelperService } from './literature-rules-helper.service';
/**
 * service for cart operations and cart state management.
 */
@Injectable({ providedIn: 'root' })
export class CartHandlerService implements OnDestroy {
  subscription: Subscription;
  emailSubject = new Subject<boolean>();
  unsubscribe$: Subject<void> = new Subject<void>();
  private state: LiteratureDocumentListingState = {
    isError: false,
    cartItems: [],
    cartAction: '',
  };
  private cartStateSubject = new BehaviorSubject(this.state);
  // WDE-178 we need second subject, because cart behavior is not consistent after adding/removing.
  // this subject is only to inform 'add to cart' button that action was cmpleted
  private cartAddedSubject$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private isInternational: boolean;
  constructor(
    private dataService: LiteratureDocumentCartService,
    private navService: NavigationService,
    private appStateService: AppStateService,
    private literatureDataService: LiteratureDataService,
    private literatureRules: LiteratureRulesHelperService,
    private siteConfig: SiteConfigService,
    private literatureConfig: LiteratureGlobalConfigService,
    private translateService: TranslateService
  ) {
    this.siteConfig
      .getIsPopulated$()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((isPopulated) => !!isPopulated),
        switchMap(() => {
          const showCart =
            this.siteConfig.literature.fundDocumentGridColumn?.indexOf(
              'ADD_TO_CART'
            ) !== -1;
          if (showCart) {
            return forkJoin([
              this.getCartFromCookie(),
              this.getCartFromStorage(),
            ]);
          } else {
            return of([]);
          }
        })
      )
      .subscribe((response) => {
        const cookieDocIds: string[] = response[0]?.items;
        const docs: StorageItems[] = response[1] ? response[1] : [];
        this.checkCookieItems(cookieDocIds, docs);
        if (docs?.length > 0) {
          this.prepareCartState(docs);
        } else {
          this.cartStateSubject.next(this.state);
        }
      });
    this.isInternational = this.literatureConfig.isInternational;
  }
  /**
   *
   * @param cookieDocIds docIds which are added from account side
   * @param docs docs which are added from marketing side
   * check if there is new docs are added from account side  need to add same to local storage
   */
  private checkCookieItems(cookieDocIds: string[], docs: StorageItems[] = []) {
    const cartItemsFromCookie: StorageItems[] = [];
    if (cookieDocIds?.length > 0) {
      cookieDocIds.forEach((cookieId) => {
        if (docs.findIndex((doc) => doc.docId === cookieId) === -1) {
          const cartItem = this.cartMapper(cookieId);
          cartItemsFromCookie.push(cartItem);
          docs.push(cartItem);
        }
      });
      if (cartItemsFromCookie.length > 0) {
        this.dataService.addToStorage(cartItemsFromCookie);
      }
    }
  }
  /**
   *
   * @param documentId added in cart
   * @returns cart object after mapping to cart storage mapper type
   */
  public cartMapper(
    documentId: string,
    data?: LiteratureDocument
  ): StorageItems {
    return {
      docId: documentId,
      quantity: data?.hardCopyNotAvailable ? 0 : 1,
    };
  }

  /**
   *
   * @param documents added from literature pages and all other places
   * @param addedFrom action to identify fromwhere this doc is added
   * create the cart state after mapping to cart object and add same to local storage
   */
  addToCart(
    documents: LiteratureDocumentListingData[],
    addedFrom: string
  ): void {
    let documentsArray: StorageItems[] = [];
    for (const document of documents) {
      documentsArray.push(
        this.cartMapper(document.literatureData.docId, document.literatureData)
      );
      if (document.literatureData.mandatoryMaterial) {
        const docIds = document.literatureData.mandatoryMaterial?.map((data) =>
          this.cartMapper(data)
        );
        documentsArray = [...documentsArray, ...docIds];
      }
    }

    this.prepareCartState(documentsArray, true, addedFrom);
  }
  /**
   * update the cart items when cart item quantity is modified from my cart page.
   */
  updateCartItemQuantity(items: Map<string, number>) {
    this.state.cartItems.forEach((cartItem) => {
      if (items.has(cartItem.docId)) {
        cartItem.quantity = cartItem.hardCopyNotAvailable
          ? 0
          : items.get(cartItem.docId);
      }
    });
    this.dataService.updateStorageItemQuantity(items);
  }
  /**
   *
   * @param id docID
   * @param fromWhere action is performed
   * delete the cart item from local storage and update cart state
   */
  removeFromCart(id: string[], fromWhere?: string): void {
    const ids = this.inclueMandatoryItems(id);
    this.dataService.removeFromStorage(ids);
    this.updateCartState(fromWhere, ids);
  }
  /**
   *
   * @param ids docs ids added in cart
   * @returns list of mandatory items
   * if any cart doc has mandatory items availbale then add those items also in cart
   */
  inclueMandatoryItems(ids: string[]): string[] {
    let mandatoryIds: string[] = [];
    ids.forEach((id) => {
      const litData = this.state.cartItems.find((data) => data.docId === id);
      if (litData && litData.mandatoryMaterial?.length > 0) {
        mandatoryIds = [...mandatoryIds, ...litData.mandatoryMaterial];
      }
    });
    ids = [...ids, ...mandatoryIds];
    return ids;
  }
  /**
   * delete the whole cart in one go and update the cart state.
   */
  emptyCartStorage(): void {
    this.dataService.emptyStorage();
    this.updateCartState(CartActionPerformed.DELETE_ALL_FROM_YOUR_CART);
  }
  getCartFromStorage(): Promise<StorageItems[]> {
    return this.dataService.getFromStorage();
  }
  getCartCount(): Observable<number> {
    return this.dataService.cartCountSubject.asObservable();
  }
  getDeletedDocId(): Observable<string[]> {
    return this.dataService.deletedDocSubject.asObservable();
  }
  navigateToUrl(url: string): void {
    const link = `${this.appStateService.getSpaBaseUrl()}/${url}`;

    this.navService.navigateByUrl(link);
  }
  /**
   *
   * @returns the download url for zip documents
   */
  getDownloadUrl(): string {
    return `${this.appStateService.getLiteratureDownloadZipUrl()}?channel=${this.appStateService.getChannel()}`;
  }
  /**
   *
   * @param data literture object
   * @param recipientId whome to send docs
   * @returns the success or failure of email ack
   * send the mail for selected documents
   */
  // ToDo: fix return type
  sendEmail$(
    data: LiteratureDocument[],
    recipientId: string,
    profile?: IUserProfileInfo
  ): Observable<any> {
    const litCodes = [];
    const mandatoryMaterialLitCodes = [];
    const recipients = [recipientId];

    data.forEach((node) => {
      if (node.downloadUrl && node.displayInEmail) {
        litCodes.push(node.docId);
      }
    });

    return this.literatureDataService.emailDocuments(recipients, [...litCodes]);
  }

  /**
   *
   * @param data cart items
   * @param addToStorage flag to add to add to storage or not
   * @param cartActionPerformed from where
   * check duplicate items in cart and prepare cart state and also add docs to local storage
   * Emits the newly added docs- consumed by all subscribers to open add to cart popup after successfully addition of items
   * make the literature api calls for selected/added cart items to get the current state from api
   */
  prepareCartState(
    data: StorageItems[],
    addToStorage = false,
    cartActionPerformed?: string
  ): void {
    const map = new Map<string, string>();
    const litList: LiteratureDocument[] = [];

    // To handle duplicate addition in storage and cart state
    // Do nothing if all added items are duplicate i.e data.length === 0
    if (addToStorage) {
      this.handleDuplicateCartItems(data);
      if (data.length === 0) {
        this.state.cartAction = cartActionPerformed;
        this.cartStateSubject.next(this.state);
        this.cartAddedSubject$.next(true);
      }
    }

    if (data.length) {
      // added to handle special characters in the api
      map.set(
        'literatureCode',
        encodeURIComponent(
          data.map((cart: StorageItems) => cart.docId).toString()
        )
      );
      // adding cache param as true
      map.set('cache', 'true');
      this.literatureDataService
        .loadData(map, true, true)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (litData: LiteratureData) => {
            litData?.document.forEach(
              (documentData: LiteratureDocumentData) => {
                const cartData = this.literatureDataService.literatureMapper(
                  documentData
                );
                cartData.fundName =
                  cartData?.fundName ||
                  cartData?.dctermsTitle ||
                  this.translateService.instant(
                    'literature.' + cartData.dctermsType
                  );

                this.literatureRules.checkLiteratureRules(cartData);
                // in case of marketing docs need to map
                // international flag is not working properly thats the reason need to map here also.
                // siteconfig issue (double loading)
                cartData.docId = cartData.customFilePath;
                this.literatureDataService.mapLiteratureUrl(cartData);
                this.literatureDataService.getLiteratureDetailLink(cartData);

                this.handleDuplicateCartItems(litList);
                litList.push(cartData);
              }
            );
            data.forEach((cartData) => {
              litList.forEach((listData) => {
                if (listData.docId === cartData.docId) {
                  listData.quantity = cartData.quantity;
                }
              });
            });
            this.handleDuplicateCartItems(litList);
            this.state.cartItems = [...litList, ...this.state.cartItems];
            this.state.cartAction = cartActionPerformed;
            this.cartStateSubject.next(this.state);
            if (addToStorage) {
              this.dataService.addToStorage(data);
              // needed for WDE-178 - it is required, because buttons are not refrehed for mandatory documents
              this.cartAddedSubject$.next(true);
            }
          },
          (err) => {
            // Handling of Errors
            if (err) {
              this.state.cartAction = cartActionPerformed;
              this.cartStateSubject.next(this.state);
            }
          }
        );
    }
  }

  // Remove items from data array which already exists in cart state

  handleDuplicateCartItems(data: StorageItems[]) {
    this.state.cartItems.forEach((cartState) => {
      const index = data.findIndex((item) => item.docId === cartState.docId);
      if (index > -1) {
        data.splice(index, 1);
      }
    });
  }
  /**
   * update the cart state for deleted/modifying cart operations
   */
  updateCartState(fromWhere?: string, docIds?: string[]): void {
    if (docIds?.length > 0) {
      docIds.forEach((docId: string) => {
        const index = this.state.cartItems.findIndex(
          (data: LiteratureDocument) => data?.docId === docId
        );
        if (index > -1) {
          this.state.cartItems.splice(index, 1);
        }
      });
    } else if (CartActionPerformed.DELETE_ALL_FROM_YOUR_CART === fromWhere) {
      this.state.cartItems = [];
    }
    this.state.cartAction = fromWhere;
    // WDE-178 changing button status
    if (CartActionPerformed.DELETED_FROM_SEARCH === fromWhere) {
      this.cartStateSubject.next(this.state);
    }
    this.cartAddedSubject$.next(false);
  }
  getCartstate(): LiteratureDocumentListingState {
    return this.state;
  }
  getCartItems(): Observable<LiteratureDocumentListingState> {
    return this.cartStateSubject.asObservable();
  }
  getAddedObservable$(): Observable<boolean> {
    return this.cartAddedSubject$.asObservable();
  }
  /**
   *
   * @param data - litertaure object
   * place the order for selected cart items
   */
  checkoutOrder(data: CartDetails[], checkoutMode?) {
    this.literatureDataService.prepareDataToPlaceOrder(data, checkoutMode);
  }
  /**
   * get the cookie stored documents from api if available
   */
  getCartFromCookie(): Observable<CookieResponse> {
    return this.dataService.getCartCookie().pipe(
      catchError(() => {
        const error: CookieResponse = { items: [], isError: true };
        return of(error);
      })
    );
  }
  /**
   * get the total quantity if cart items -per item
   * used in my cart page
   */
  getOverAllQuantity(cartItems: LiteratureDocument[]): number {
    let overAllQty = 0;
    if (cartItems) {
      cartItems.forEach((item) => {
        overAllQty += item.quantity;
      });
    }
    return overAllQty;
  }

  /**
   *
   * @param data consists the cart items state.
   * This method does not change cart state globally as we are passing deep clone of data as parameter.
   * It just remove data from state list where quantity = 0 (Hard Copy not available scenario)
   * @returns new state by removing items which is not eligible for checkout
   */
  checkCheckoutEligibilty(data: LiteratureDocumentListingState) {
    data?.cartItems?.forEach((item) => {
      const index = data.cartItems.findIndex(
        (cartItem) => cartItem.quantity === 0
      );
      if (index > -1) {
        data.cartItems.splice(index, 1);
      }
    });
    return data;
  }
  /**
   * check the cart status if docuemnt is already in cart
   */

  checkCartStatus(docId: string): boolean {
    if (this.state?.cartItems?.length > 0) {
      return (
        this.state.cartItems.findIndex(
          (data: LiteratureDocument) => data.docId === docId
        ) > -1
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
