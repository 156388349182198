import {
  ChangeDetectorRef,
  Compiler,
  Component,
  ComponentRef,
  Injector,
  Input,
  NgModuleRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import { Component as BrComponent } from '@bloomreach/spa-sdk/';
import { Logger } from '@utils/logger';
import { InteractiveContentComponentConfig } from 'src/app/ft-components/interactive-content/interactive-content-component.config';
import {
  InteractiveContentDisplayComponent,
  InteractiveContentLazyModule,
} from 'src/app/ft-components/interactive-content/interactive-content-display-component.interface';
import isEmpty from 'lodash/isEmpty';

const logger = Logger.getLogger('InteractiveContentBlockComponent');

@Component({
  selector: 'ft-interactive-content-block',
  templateUrl: './interactive-content-block.component.html',
  styleUrls: ['./interactive-content-block.component.scss'],
})
export class InteractiveContentBlockComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  @Input() content: any;
  @ViewChild('dynamicCmp', { read: ViewContainerRef, static: false }) container;
  private componentRef: ComponentRef<InteractiveContentDisplayComponent>;
  public componentType: string;
  public componentConfig: InteractiveContentComponentConfig;
  public isLoading = true;
  public errorMsg: string;
  private labels = {};

  constructor(
    private changeDetector: ChangeDetectorRef,
    private compiler: Compiler,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.componentType = this.content?.componentType;
    this.componentConfig = InteractiveContentComponentConfig.getConfig(
      this.componentType
    );
    this.changeDetector.detectChanges(); // at this point show component if componentType exists

    if (this.componentConfig) {
      if (this.componentConfig.loaded && this.componentConfig.moduleRef) {
        this.createComponent(this.componentConfig.moduleRef);
      } else {
        if (!this.componentConfig.multiple) {
          this.componentConfig.loaded = true;
        }
        this.componentConfig
          .loader()
          .then((moduleObj) => moduleObj[Object.keys(moduleObj)[0]])
          .then((module) => this.compiler.compileModuleAsync(module))
          .then((moduleFactory) => moduleFactory.create(this.injector))
          .then((moduleRef) => {
            this.componentConfig.moduleRef = moduleRef;
            this.createComponent(this.componentConfig.moduleRef);
          });
      }
    } else {
      logger.error('No component found: ', this.componentType);
      this.errorMsg = `No component found: ${this.componentType}`;
    }
  }
  createComponent(moduleRef: NgModuleRef<unknown>) {
    if (moduleRef) {
      const componentFactory = (moduleRef.instance as InteractiveContentLazyModule).resolveComponent();
      this.container.clear();
      this.componentRef = this.container.createComponent(
        componentFactory,
        null,
        this.injector
      );
      this.isLoading = false;
      this.errorMsg = null;
      const bundles = this.component
        ?.getParameters()
        ?.resourceBundles?.split(',');
      if (bundles) {
        bundles.forEach((bundle) => {
          this.labels = {
            ...this.labels,
            ...this.component?.getModels()[bundle],
          };
        });
      }
      this.componentRef.instance.populate(
        this.getConfig(),
        this.labels,
        this.component,
        this.page
      );
      this.changeDetector.detectChanges(); // to remove loading message
    } else {
      logger.error(`failed to load module for ${this.componentType}`);
    }
  }

  getConfig(): any {
    // DCE-2113 If jsonConfiguration property doesn't have ref then use browseToJsonFile data
    let brConfig = !isEmpty(JSON.parse(this.content.jsonConfiguration))
      ? this.content.jsonConfiguration
      : this.content.browseToJsonFile;
    const enableDownloadOfHighcharts = this.content?.enableDownloadOfHighcharts;
    const additionalConfig = this.component?.getModels()?.additionalConfig;
    if (brConfig) {
      try {
        // DCE-2113 get the Json data for StrategyKeyStats component
        if (this.content?.componentType === 'StrategyKeyStats') {
          brConfig = this.page.getContent(brConfig)?.getData().json;
        }

        brConfig = JSON.parse(brConfig);
        if (enableDownloadOfHighcharts) {
          brConfig.enableDownloadOfHighcharts = enableDownloadOfHighcharts;
        }
      } catch (e) {
        brConfig = { error: e, errorSrc: brConfig };
      }
    }
    return brConfig;
  }

  ngOnDestroy() {
    // clean up dynamic component to avoid memory leaks
    this.componentRef?.destroy();
  }
}
