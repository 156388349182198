import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { BaseProductDetailComponent } from '@products/base-product-detail.component';
import { TranslateService } from '@shared/translate/translate.service';
import { ProductDetailConfiguration } from '@types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FundPerformanceComponentConfig } from '../fund-performance-component.config';
import { FundPerformanceDisplayComponent } from '../fund-performance-display-component.interface';
import {
  RiskReturnProfileService,
  RiskReturnProfileState,
} from '../services/risk-return-profile.service';

@Component({
  selector: 'ft-risk-return-profile',
  templateUrl: './risk-return-profile.component.html',
  styleUrls: ['./risk-return-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskReturnProfileComponent
  extends BaseProductDetailComponent
  implements FundPerformanceDisplayComponent, OnInit, OnDestroy {
  unsubscribe$: Subject<any>;
  populated = false;
  hasData = false;
  subTitle: string;
  points: Highcharts.SeriesScatterOptions[];
  pointHoverFormat: string;
  tooltips: { [name: string]: string } = {};
  xSoftMin: number;
  ySoftMin: number;

  constructor(
    private riskReturnProfileService: RiskReturnProfileService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
    this.unsubscribe$ = new Subject();
  }

  ngOnInit() {
    this.populated = false;
    this.pointHoverFormat = `${this.translateService.instant(
      'products.risk-return-profile-x'
    )} <b>{point.x}</b><br/>${this.translateService.instant(
      'products.risk-return-profile-y'
    )} <b>{point.y}</b>`;
  }
  populate(
    componentConfig: FundPerformanceComponentConfig,
    config: ProductDetailConfiguration,
    component: BrComponent,
    page: Page
  ) {
    this.initialize(config, component, page);
    this.setTooltips();
    this.riskReturnProfileService
      .getState$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => this.mapState(state));
    this.riskReturnProfileService.populate(config);
  }

  private setTooltips() {
    this.tooltips[
      'products.risk-return-profile-section'
    ] = this.translateService.tooltip('products.risk-return-profile-section');
  }

  mapState(state: RiskReturnProfileState) {
    this.subTitle = state.subTitle;
    this.points = state.profile.points;
    this.hasData = !!this.points?.length;
    this.populated = true;
    this.changeDetector.detectChanges();
    this.xSoftMin = 0;
    this.ySoftMin = 0;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
