import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import dayjs from 'dayjs';
import { SiteConfigService } from '@services/site-config.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@search/services/config.service';
import { first, map, tap } from 'rxjs/operators';
import { FtSearchOptions } from '@search/interfaces/search.interface';
import {
  FtInsightsResults,
  RelatedArticleResults,
} from '@search/interfaces/insights.interface';
import { PageMonitor } from '@services/page-monitor';
import { Observable } from 'rxjs';
import { generateUUID } from '@utils/text/string-utils';
import { EnvConfigService, SegmentService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class InsightsService {
  options: FtSearchOptions = {
    page: 0,
    term: '*',
    dataSource: 'dev',
    articleType: 'insight',
    config: {
      ftSearchUrl: '',
      ftInsightsUrl: '',
      httpParams: new HttpParams(),
      httpOptions: {},
    },
    // TODO verify if we use it and move to config
    counters: {
      funds: 4,
      literature: 4,
      pages: 3,
    },
  };

  constructor(
    private siteConfigService: SiteConfigService,
    private configService: ConfigService,
    private http: HttpClient,
    private envConfig: EnvConfigService,
    private segmentService: SegmentService
  ) {}

  /**
   * get insights logo object including
   * logo asset path, alt text and logo theme
   * @param simName - Sim name as string
   * @param isInverse - If inverse logo is required
   * @simResource - SIM logos from resource bundle
   */
  public getSIMLogo(
    simName: string,
    isInverse?: boolean,
    simResource?: object
  ) {
    const logoMetaData = {
      logoSrc: '',
      logoAltText: '',
      logoTheme: '',
      logoBackgroundColor: '',
    };
    let colorWidenUrl: string;
    let inverseWidenUrl: string;
    let colorStaticUrl: string;
    let inverseStaticUrl: string;

    if (simName) {
      logoMetaData.logoAltText = simName.split('-').join(' ');
      colorStaticUrl = '/assets/images/SIMLogos/logo-' + simName + '.png';
      inverseStaticUrl = '/assets/images/SIMLogos/' + simName + '_inverse.svg';
      if (simResource) {
        logoMetaData.logoBackgroundColor = simResource[`logo-${simName}-bg`];
        simResource[`logo-${simName}`]?.includes('widen.net')
          ? (colorWidenUrl = simResource[`logo-${simName}`])
          : (colorWidenUrl = '');
        simResource[`logo-${simName}-inverse`]?.includes('widen.net')
          ? (inverseWidenUrl = simResource[`logo-${simName}-inverse`])
          : (inverseWidenUrl = '');
      }

      if (isInverse) {
        inverseWidenUrl
          ? (logoMetaData.logoSrc = inverseWidenUrl)
          : (logoMetaData.logoSrc = inverseStaticUrl);
      } else {
        colorWidenUrl
          ? (logoMetaData.logoSrc = colorWidenUrl)
          : (logoMetaData.logoSrc = colorStaticUrl);
      }
      if (!logoMetaData.logoBackgroundColor) {
        switch (simName) {
          case 'martin-currie':
            logoMetaData.logoTheme = 'green2';
            break;
          case 'brandywine-global':
            logoMetaData.logoTheme = 'teal1';
            break;
          case 'royce-investment-partners':
          case 'clarion-partners':
            logoMetaData.logoTheme = 'dark-grey';
            break;
          case 'economist-impact':
          case 'the-economist':
            logoMetaData.logoTheme = 'sims-the-economist';
            break;
          default:
            logoMetaData.logoTheme = 'primary-blue';
            break;
        }
      }
    }
    return logoMetaData;
  }

  /**
   * get the long date based on site locale
   * from dayjs js
   * @param date - iso date as string
   */
  public getLongDate(date: string) {
    if (this.siteConfigService?.common?.locale) {
      dayjs.locale(this.siteConfigService.common.locale);
    } else {
      dayjs.locale('en-us');
    }
    return dayjs(date).format('LL');
  }

  /**
   * get the filtered article API data
   * @param preFilters - Object of selected filters
   * @param pageSize - No of records to fetch
   * @returns - Article api response
   */
  public getAricleAPIData$(preFilters: any, pageSize: number) {
    let apiParameters: any;
    this.configService
      .getConfig('insights')
      .pipe(first())
      .subscribe((config) => {
        this.options.config = config;
        apiParameters = {
          ...this.options.config,
          httpParams: this.options.config.httpParams
            .set('query', this.options.term.trim())
            .set('start', '0')
            .set('number', pageSize.toString())
            .set('articleType', this.options.articleType)
            .set('filters', this.getFilter(preFilters)),
        };
      });
    const req = PageMonitor.startAjaxRequest(
      `insights - ${this.options.term} - ${this.options.articleType}`
    );
    return this.http
      .post<FtInsightsResults>(
        apiParameters.ftInsightsUrl,
        apiParameters.httpParams,
        apiParameters.httpOptions
      )
      .pipe(
        tap(() => {
          PageMonitor.endAjaxRequest(req);
        }),
        map((response) => response) // what does this actually do?
      );
  }

  public getRelatedArticleData$(
    currentArticleUrl: string,
    maxLimit: number
  ): Observable<RelatedArticleResults> {
    const url: string = this.configService.getRelateArticleUrl();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }),
    };
    return this.http.post<RelatedArticleResults>(
      url,
      this.getRelatedArticleHttpParams(currentArticleUrl, maxLimit),
      httpOptions
    );
  }

  private getRelatedArticleHttpParams(
    currentArticleUrl: string,
    maxLimit: number
  ): HttpParams {
    return new HttpParams()
      .set('collection', 'pages')
      .set('audience', this.segmentService.getCurrentSegment()?.id)
      .set('start', '0')
      .set('number', maxLimit)
      .set('locale', this.configService.getLocale())
      .set('env', this.envConfig.getEnvConfig().environment)
      .set('reference', currentArticleUrl)
      .set('sort', 'auto');
    // .set('sessionId', generateUUID()) ; DCE-876
  }

  /**
   * Get filters data in string for article API
   */
  getFilter(selectedFilters): string {
    const filters = selectedFilters;
    const preFilters = [];
    if (filters) {
      const keys = [
        'assetclass',
        'investmentteam',
        'investmenttheme',
        'publicationseries',
        'speciality',
      ];
      Object.entries(filters).forEach(([key, value]) => {
        if (keys.includes(key.toLowerCase())) {
          if (value) {
            const arr = [];
            arr.push(value);
            if (key === 'assetClass' || key === 'assetclass') {
              preFilters.push({
                fieldName: 'assetclassPage.exact',
                fieldValue: arr,
              });
            } else {
              preFilters.push({
                fieldName: key.toLowerCase() + '.exact',
                fieldValue: arr,
              });
            }
          }
        }
      });
    }
    return JSON.stringify(preFilters);
  }

  /**
   * Convert JSON data into HTML table
   * @param data JSON data
   * @returns HTML table
   */
  public convertJSONToTable(data: any, tableId: string) {
    const col = [];

    data.map((dataRow) => {
      Object.keys(dataRow).forEach((key) => {
        if (col.indexOf(key) === -1) {
          col.push(key);
        }
      });
    });

    const table = document.createElement('table');
    table.id = tableId;
    table.classList.add('table', 'table-striped');
    let tr = table.insertRow(-1);

    col.map((column) => {
      const th = document.createElement('th');
      th.classList.add('table__th');
      th.innerHTML = column;
      tr.appendChild(th);
    });

    data.map((dataRow) => {
      tr = table.insertRow(-1);
      tr.classList.add('table__tr');
      col.map((column) => {
        const tabCell = tr.insertCell(-1);
        tabCell.classList.add('table__td');
        if (dataRow[column]) {
          tabCell.innerHTML = dataRow[column];
        }
      });
    });
    return table.outerHTML;
  }
}
