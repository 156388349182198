import dayjs from 'dayjs';

import get from 'lodash/get';

import { SiteLabels } from '@shared/footer/footer.interfaces';
import { LanguageData, SimplyLinkItem } from '@types';

/**
 * Return EDS SVG icon name set in menu
 * @param menuItem - menu item selected
 */
export function setMenuIcon(menuItem: any): string {
  const menuIcon: string = get(menuItem, `parameters['Icon']`, null);
  return menuIcon;
}

/**
 * Return external link status
 * @param menuItem menu item selected
 */
export function isExternalLink(menuItem: any): boolean {
  const externalLinkFlag: string = get(
    menuItem,
    `parameters['Leaving Site (True or False)']`,
    'False'
  );
  return externalLinkFlag && externalLinkFlag.trim().toLowerCase() === 'true';
}

/**
 * Return desktop or tablet, based on navigation item setting
 * @param menuItem menu item selected
 */
export function getBreakpointType(menuItem: any): string {
  const checkBreakpointTypeFlag: string = get(
    menuItem,
    `parameters['Display only in: (tablet / desktop)']`,
    'False'
  );
  let breakpointType = '';

  if (!checkBreakpointTypeFlag) {
    return '';
  }
  if (checkBreakpointTypeFlag.trim().toLowerCase() === 'tablet') {
    return 'tablet';
  }

  if (checkBreakpointTypeFlag.trim().toLowerCase() === 'desktop') {
    breakpointType = 'desktop';
  }

  return breakpointType;
}

/**
 * Return new window/tab status
 * @param menuItem menu item selected
 */
export function openInNewTab(menuItem: any): boolean {
  const newTabFlag: string = get(
    menuItem,
    `parameters['Open in New Tab (True or False)']`,
    'False'
  );
  return newTabFlag && newTabFlag.trim().toLowerCase() === 'true';
}

/**
 * Return overwrite title
 * @param menuItem menu item selected
 */
export function overwriteTitle(menuItem: any): string {
  const overwriteTitleText: string = get(
    menuItem,
    `parameters['Override 3rd Level Link In Mobile']`,
    ''
  );
  return overwriteTitleText?.trim().toLowerCase();
}
/**
 * Returns target _blank when menu parameter for open in new window selected
 * @param menuItem menu item selected
 */

export function getTargetVal(menuItem: any): string {
  let targetValue: string;
  if (isExternalLink(menuItem)) {
    targetValue = '_blank';
  }
  return targetValue;
}

/**
 * Returns external-link class when menu parameter for leaving site selected
 * @param menuItem - menu item selected
 */
export function getLeavingSiteClass(menuItem: any): string {
  let leavingSiteClass: string;
  if (isExternalLink(menuItem)) {
    leavingSiteClass = ' external-link';
  }
  return leavingSiteClass;
}

/**
 * Converting page languages to EDS interface
 * @param languages - page languages from site config
 * @param siteLabels - site labels
 * @deprecated - moved to language-selector-service
 */
export function convertLanguages(
  languages: Array<LanguageData>,
  siteLabels: SiteLabels
): SimplyLinkItem[] {
  if (!languages) {
    return [];
  }
  const languagesEds = [];
  languages.forEach((language) => {
    const locale = get(language, 'locale');
    const simplyItem: SimplyLinkItem = {
      link: get(siteLabels.languageLabels, locale, `[${locale}]`),
      // TODO: should be injecting WINDOW reference instead of using global window object directly
      href: window.location.origin + get(language, 'url', '#'),
    };
    languagesEds.push(simplyItem);
  });
  return languagesEds;
}

export function getMonthDiffrence(date: number | string) {
  const today = dayjs();
  const oldDate = date;
  const diff = today.diff(oldDate, 'month', true);
  return diff;
}
