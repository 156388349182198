<div *ngIf="isEditMode" class="no-document">
  <div edsGrid class="no-document__component">
    <div class="no-document__overlay">
      Click to configure <strong>Subscriptions Manage Component</strong>
    </div>
  </div>
</div>
<div *ngIf="isLoading && !isEditMode">
  <ngx-spinner
    name="userDataLoadingSpinner"
    color="#000"
    size="medium"
    type="line-scale-party"
    [fullScreen]="true"
  >
  </ngx-spinner>
</div>
<ng-container *ngIf="!isLoggedIn">
  <div class="subscription-manage__login-note">
    {{ "subscriptions.login-note" | translate }}
  </div>
</ng-container>

<ng-container *ngIf="!isLoading && isLoggedIn">
  <ft-subscriptions-products
    [params]="subscriptionsParams"
  ></ft-subscriptions-products>
</ng-container>
