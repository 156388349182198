import { Logger } from '@utils/logger';

const logger = Logger.getLogger('PageMonitor');

export type AjaxRequest = {
  name: string;
  start?: number;
  finish?: number;
  duration?: number;
};

/** TODO we dont need this solution any more because is supported by datadog. need to remove. */
export class PageMonitor {
  private static instance: PageMonitor;
  private url: string;
  private ajaxRequests: AjaxRequest[];
  private resourceRequests: any[];
  private initialTime: number;
  private firstTime = true;
  private wasFirstTime = true;

  private constructor() {
    this.ajaxRequests = [];
    this.resourceRequests = [];
  }

  public static getMonitor() {
    if (!PageMonitor.instance) {
      PageMonitor.instance = new PageMonitor();
    }
    return PageMonitor.instance;
  }

  public static startAjaxRequest(name: string): AjaxRequest {
    const monitor = PageMonitor.getMonitor();
    const req = {
      name,
      start: monitor.getRelativeTimestamp(),
    };
    monitor.ajaxRequests.push(req);
    return req;
  }

  public static endAjaxRequest(req: AjaxRequest): void {
    const monitor = PageMonitor.getMonitor();
    req.finish = monitor.getRelativeTimestamp();
    req.duration = req.finish - req.start;
  }

  /**
   * Called when a call to a new page is initiated
   */
  public newPage(url: string) {
    if (this.firstTime) {
      this.firstTime = false;
      this.processResourceRequests();
    } else {
      this.finish(); // finish analysis of old page and clean up
    }
    this.url = url;
    this.initialTime = this.getTimestamp();
    this.ajaxRequests = [];
    this.resourceRequests = [];
  }

  public getRelativeTimestamp() {
    return this.getTimestamp() - this.initialTime;
  }

  private finish() {
    this.processResourceRequests();
    logger.event(this.processResults());

    logger.debug('requests', Object.assign({}, this));
    this.wasFirstTime = false;
  }

  private getTimestamp(): number {
    return performance ? performance.now() : Date.now();
    // return Date.now();
  }

  private processResourceRequests() {
    // Get a list of "resource" performance entries
    const resources: PerformanceResourceTiming[] = performance.getEntriesByType(
      'resource'
    ) as PerformanceResourceTiming[];
    performance.clearResourceTimings();
    if (resources === undefined || resources.length <= 0) {
      // logger.debug("NO `resource` performance records");
      return;
    }

    for (const resource of resources) {
      const start = resource.requestStart;
      if (start > 0) {
        this.resourceRequests.push({
          name: resource.name,
          start: resource.requestStart - this.initialTime,
          finish: resource.responseEnd - this.initialTime,
          duration: resource.responseEnd - resource.responseStart,
        });
      }
    }
  }

  private processResults() {
    const ret = {
      // url: this.url,
      // full: this.wasFirstTime,
      last: 0,
      load: 0,
      ra: 0,
      ac: this.ajaxRequests.length,
      rc: this.resourceRequests.length,
      left: Math.round(this.getRelativeTimestamp()),
    };
    let la = 0;
    for (let i = 0; i < ret.ac; i++) {
      if (this.ajaxRequests[i].finish > la) {
        la = this.ajaxRequests[i].finish;
      }
    }
    let lr = 0;
    let fr = 0;
    for (let i = 0; i < ret.rc; i++) {
      if (this.resourceRequests[i].finish > lr) {
        lr = this.resourceRequests[i].finish;
      }
      if (this.resourceRequests[i].start < fr) {
        fr = this.resourceRequests[i].start;
      }
      if (
        !ret.ra &&
        this.resourceRequests[i].name.indexOf('resourceapi') !== -1
      ) {
        ret.ra = Math.round(this.resourceRequests[i].finish);
      }
    }
    ret.last = la > lr ? Math.round(la) : Math.round(lr);
    if (this.wasFirstTime) {
      ret.load -= Math.round(fr);
    }
    return ret;
  }
}
