import { SalesChargeBreakPoint } from '@models';
import {
  ConfigurationCountry,
  ConfigurationLocale,
  FundId,
  SalesChargeBreakPointDTO,
  ShareClassCode,
} from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class SalesChargeBreakPointsMapper extends Mapper<
  SalesChargeBreakPoint[]
> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    saleschargebreakpointsDto: SalesChargeBreakPointDTO[]
  ): SalesChargeBreakPoint[] {
    const saleschargebreakpoints: SalesChargeBreakPoint[] = [];

    saleschargebreakpointsDto.map(
      (saleschargebreakpointDto: SalesChargeBreakPointDTO) => {
        const saleschargebreakpoint: SalesChargeBreakPoint = this.createMapper(
          SalesChargeBreakPointMapper
        ).toDomain(saleschargebreakpointDto);

        if (saleschargebreakpoint) {
          saleschargebreakpoints.push(saleschargebreakpoint);
        }
      }
    );

    return saleschargebreakpoints;
  }
}

export class SalesChargeBreakPointMapper extends Mapper<SalesChargeBreakPoint> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    salesChargeBreakPointsDto: SalesChargeBreakPointDTO
  ): SalesChargeBreakPoint {
    return {
      fundId: salesChargeBreakPointsDto.fundid as FundId,
      shareClassCode: salesChargeBreakPointsDto.shclcode as ShareClassCode,
      countryCode: salesChargeBreakPointsDto.cntrycode as ConfigurationCountry,
      languageCode: salesChargeBreakPointsDto.langcode as ConfigurationLocale,
      asOfDate: salesChargeBreakPointsDto.asofdate,
      asOfDateStd: salesChargeBreakPointsDto.asofdatestd,
      breakPointYearPercentage: salesChargeBreakPointsDto.brkpnttyprng,
      popBreakPoint: salesChargeBreakPointsDto.popbrkpnt,
      distributionRateBreakPoint: salesChargeBreakPointsDto.distribratebrkpnt,
      dataCategory: salesChargeBreakPointsDto.datacategory,
      breakPointTypeKey: salesChargeBreakPointsDto.brkpnttypkey
        ? Number(salesChargeBreakPointsDto.brkpnttypkey)
        : salesChargeBreakPointsDto.brkpnttypkey,
    };
  }
}
