import { Component, Input, OnInit } from '@angular/core';
import { TitleTheme } from '@frk/eds-components';

interface TitleBlockContent {
  preTitle: string;
  title: string;
  level: string;
  size: string;
}

@Component({
  selector: 'ft-title-block',
  templateUrl: './title-block.component.html',
})
export class TitleBlockComponent implements OnInit {
  /**
   * Is Block in a Two Col layout
   */
  @Input()
  isTwoCol: boolean;

  /**
   * Content to render
   */
  @Input()
  content: TitleBlockContent;

  @Input() titleColorTheme: TitleTheme = 'primary';

  /**
   * Column numbers
   */
  colNumbers: any;

  /**
   * Constructor
   */
  constructor() {}

  /**
   * On Init
   */
  ngOnInit(): void {
    if (!this.isTwoCol) {
      // 4 = 100%, 5 = 0.625%, 6 = 50%
      this.colNumbers = { s: 4, m: 5, l: 6 };
    } else {
      this.colNumbers = { s: 'auto', m: 'auto', l: 'auto' };
    }
  }
}
