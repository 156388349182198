<div [ngClass]="{ 'has-edit-button': isEditMode }">
  <ft-gateway-modal
    #gatewayModal
    (termsAccepted)="onTermsDecision($event)"
    (customTermsAccepted)="onCustomTermsDecision($event)"
    *ngIf="!isEditMode"
    [page]="page"
    [component]="component"
  ></ft-gateway-modal>

  <div
    *ngIf="isEditMode"
    [ngStyle]="{
      background: '#f5f5f5',
      'border-bottom': '1px solid #e0e0e0',
      'padding-top': '16px',
      'padding-left': '16px'
    }"
  >
    <p class="bold small">{{ previewMessage }}</p>
  </div>
</div>
