import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { RoutingGuardService } from '@services/routing-guard.service';
import { OktaLoginCallbackComponent } from '@shared/okta-login-callback/okta-login-callback.component';
import { Auth0LoginCallbackComponent } from '@shared/auth0-login-callback/auth0-login-callback.component';
import { Auth0LogoutCallbackComponent } from '@shared/auth0-logout-callback/auth0-logout-callback.component';
import { PageContainerComponent } from './ft-pages/page-container/page-container.component';
import { PagesModule } from './ft-pages/pages.module';
import { Auth0SignUpComponent } from '@shared/auth0-sign-up/auth0-sign-up.component';

// Modules

// Components

// Services

const routes: Routes = [
  // first 4 paths are called from Auth0
  {
    path: ':channel/customerlogin/callback',
    component: Auth0LoginCallbackComponent,
  },
  {
    path: 'customerlogin/callback',
    component: Auth0LoginCallbackComponent,
  },
  {
    path: ':channel/customerlogout/callback',
    component: Auth0LogoutCallbackComponent,
  },
  {
    path: 'customerlogout/callback',
    component: Auth0LogoutCallbackComponent,
  },
  {
    path: ':channel/customerlogin/signup',
    component: Auth0SignUpComponent,
  },
  {
    path: 'customerlogin/signup',
    component: Auth0SignUpComponent,
  },
  // next 2 paths are called from Okta
  {
    path: ':channel/login/callback',
    component: OktaLoginCallbackComponent,
  },
  {
    path: 'login/callback',
    component: OktaLoginCallbackComponent,
  },
  {
    path: '**',
    component: PageContainerComponent,
    canActivate: [RoutingGuardService],
    canDeactivate: [RoutingGuardService],
    // this means guards will run on #fragment changes too
    runGuardsAndResolvers: 'always',
  },
];
const routeOptions: ExtraOptions = {
  // NB: need this in Core project to scoll to product detail sections
  anchorScrolling: 'enabled',
  // use to debug navigation events
  enableTracing: false,
  // needed to trigger scrolling when clicking on the selected sticky tab
  onSameUrlNavigation: 'reload',
  // TODO: Decide what works best when clicking Back button
  scrollPositionRestoration: 'top',
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routeOptions), PagesModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
