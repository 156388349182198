import { Injectable } from '@angular/core';
import { LiteratureDocType, LiteratureDocument } from '@types';

@Injectable({ providedIn: 'root' })
export class LiteratureRulesHelperService {
  /**
   *
   * @param data literature document
   * set the metaData based on rules (metadata info)
   * allow show/hide different alert msg on listing/detail/fundDocuments/cart modal/cart page
   * global used function to control some rules
   */
  public checkLiteratureRules(data: LiteratureDocument): void {
    /**
     * DCE-2160 - Issue with US Documents Order When (Is Order Itself) = No
     */
    if (!data.clientUse || data.isInv === 'N' || data.isOrdItself === 'N') {
      if (data?.mandatoryMaterial?.length > 0) {
        /**
         * If isOrdItself is No and data.mandatoryMaterial.length is greate then zero than data.hardCopyNoAvailable should be true
         */
        data.hardCopyNotAvailable = false;
      } else {
        /**
         * If data.mandatoryMaterial.length is euqal to Zero then data.hardCopyNotAvailable should be false
         */
        data.hardCopyNotAvailable = true;
      }
    }
    if (data.mandatoryMaterial?.length > 0) {
      data.isMandatoryMaterial = true;
    }
    this.lsfBasedMandatoryMaterailDisplay(data);
  }

  /**
   * Lsf Based Mandatory Materail Display
   * @param data: LiteratureDocument
   */
  private lsfBasedMandatoryMaterailDisplay(data: LiteratureDocument): void {
    /**
     * DCE-2608 : US Literature - Adding Mandatory Materials to Print / Email and Zip Download based on the Widen Metadata
     * if dctermsType literature print and isInv is yes and avaibleForLsfEmailOrder is yes than it should be visbile in all 3
     * if dctermsType literature print and isInv is yes and avaibleForLsfEmailOrder is No than it should visible only in mail
     * if dctermsType literature print and isInv is No and avaibleForLsfEmailOrder is yes than it should be visible in email and download
     * if dctermsType literature print and isInv is No and avaibleForLsfEmailOrder is No than it should not be visible anywhere
     */

    /**
     * To Do: need to create three saperate object for all three tabs
     */

    data.displayInDownload = false;
    data.displayInEmail = false;
    data.displayInMail = false;
    if (data.dctermsType === LiteratureDocType.LITERATURE_print) {
      if (data.isInv === 'Y' && data?.availableForLSFEmailOrder === 'Yes') {
        data.displayInDownload = true;
        data.displayInEmail = true;
        data.displayInMail = true;
      } else if (
        data.isInv === 'Y' &&
        (data?.availableForLSFEmailOrder === 'No' ||
          data?.availableForLSFEmailOrder === undefined)
      ) {
        data.displayInMail = true;
      } else if (
        data.isInv === 'N' &&
        data?.availableForLSFEmailOrder === 'Yes'
      ) {
        data.displayInDownload = true;
        data.displayInEmail = true;
      }
    } else {
      data.displayInDownload = true;
      data.displayInEmail = true;
      data.displayInMail = true;
    }
  }
}
