<div edsGrid [ngClass]="{ 'has-edit-button': isEditMode }">
  <ng-container
    *ngIf="isEditMode"
    [brManageContentButton]="document"
    documentTemplateQuery="new-Tabs-document"
    folderTemplateQuery="new-Tabs-folder"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>
</div>

<div
  *ngIf="tabsData"
  [ngClass]="tabTheme === 'grey' ? 'tabs__wrapper--grey' : ''"
  [edsSpacing]="spacingBottom"
>
  <div edsGrid>
    <div [class]="containerClass">
      <eds-section-title
        [title]="title"
        [preTitle]="preTitle"
        [subTitle]="summary"
      ></eds-section-title>
      <eds-tabs
        [followFocus]="true"
        [isNavigation]="true"
        [cacheActive]="true"
        [vertical]="tabsType === 'vertical'"
        [darkBackground]="tabTheme === 'grey'"
        [fullWidth]="true"
        dropdownTheme="handheld"
      >
        <ng-container *ngFor="let tab of tabsData">
          <eds-tab [heading]="tab.tabTitle" [tabId]="tab.tabIdField">
            <ng-container
              *ngFor="let contentBlock of tab.tabContent"
              [ngSwitch]="contentBlock.contentType"
            >
              <ng-template ngSwitchCase="ftcore:ContentBlock">
                <ft-one-col-block
                  [contentBlock]="contentBlock"
                  [page]="page"
                ></ft-one-col-block>
              </ng-template>
              <ng-template ngSwitchCase="ftcore:TwoColumnBlock">
                <ft-two-col-block
                  [contentBlock]="contentBlock"
                  [page]="page"
                ></ft-two-col-block>
              </ng-template>
              <ng-template ngSwitchCase="ftcore:CardGroupCompound">
                <ft-media-card-group
                  [contentBlock]="contentBlock"
                  [page]="page"
                ></ft-media-card-group>
              </ng-template>
            </ng-container>
          </eds-tab>
        </ng-container>
      </eds-tabs>
    </div>
  </div>
</div>
