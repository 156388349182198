<div edsGrid edsSpacing="xl" edsPadding edsPaddingTop="lg">
  <div class="{{ container }} container">
    <eds-title
      *ngIf="true"
      edsSpacing="md"
      [size]="'h2'"
      [theme]="'primary'"
      [isLight]="false"
      [title]="'common.product-contact-info-title' | translate"
    >
    </eds-title>
    <eds-search
      [label]="'common.product-contact-info-search-label' | translate"
      [placeholder]="
        'common.product-contact-info-search-placeholder' | translate
      "
      [clearText]="'common.product-contact-info-search-clear' | translate"
      [hasDropdown]="true"
      [items]="searchItems"
      [autocompleteState]="'off'"
      (searchTerm)="findAFund($event)"
      (selectedItem)="selectFund($event)"
      edsSpacing="xl"
    ></eds-search>
    <div *ngIf="!selectedProduct?.infoNotFound">
      <a [href]="selectedProduct?.fundLink">
        <eds-title
          [level]="4"
          size="h4"
          [isLight]="true"
          [theme]="'primary'"
          [isLight]="false"
          edsSpacing="md"
        >
          {{ selectedProduct?.fundTitle }}
        </eds-title>
      </a>
      <ng-container *ngIf="!selectedProduct?.contentBlocks?.itemsTwo?.length">
        <ft-one-col-block
          [contentBlock]="selectedProduct?.contentBlocks"
        ></ft-one-col-block>
      </ng-container>

      <ng-container *ngIf="selectedProduct?.contentBlocks?.itemsTwo?.length">
        <ft-two-col-block
          [contentBlock]="selectedProduct?.contentBlocks"
        ></ft-two-col-block>
      </ng-container>
    </div>
    <div
      *ngIf="selectedProduct?.infoNotFound"
      edsFlexbox
      justifyContent="center"
    >
      <p>{{ "common.product-contact-info-not-found" | translate }}</p>
    </div>
  </div>
</div>
