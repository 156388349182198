<div [ngClass]="{ 'has-edit-button': isEditMode }">
  <!-- Bloomreach Orange edit button -->
  <ng-container
    *ngIf="isEditMode"
    [brManageContentButton]="document"
    documentTemplateQuery="webinar-document"
    folderTemplateQuery="webinar-folder"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>

  <eds-webinar
    [theme]="webinarData?.theme"
    [preTitle]="webinarData?.preTitle"
    [title]="webinarData?.title"
    [description]="webinarData?.description"
    [date]="webinarData?.date"
    [duration]="webinarData?.duration"
    [time]="webinarData?.time"
    [buttons]="webinarData?.buttons"
    [embedScript]="webinarData?.embedScript"
    [simpleImageUrl]="webinarData?.simpleImageUrl"
    [simpleImageAlt]="webinarData?.simpleImageAlt"
    [speakerProfiles]="webinarData?.speakerProfiles"
    [preTitleIcon]="webinarData?.webinarPreTitleIconUrl"
    edsPadding
    edsPaddingBottom="xl"
  >
  </eds-webinar>
</div>
