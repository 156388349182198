<div class="breadcrumb">
  <div edsGrid>
    <div
      edsRow
      class="breadcrumb"
      [ngClass]="{ 'has-edit-button': isEditMode }"
    >
      <!-- EDS Breadcrumb -->
      <eds-breadcrumbs edsCol *ngIf="showBreadcrumb" [items]="breadcrumbItems">
      </eds-breadcrumbs>
      <!-- share component 
    <div *ngIf="showShare" class="breadcrumb__share">
        <ft-share></ft-share>
    </div>-->
    </div>
  </div>
</div>
