<div edsGrid [ngClass]="{ 'has-edit-button': isEditMode }">
  <ng-container
    *ngIf="isEditMode"
    [brManageContentButton]="document"
    documentTemplateQuery="new-widget-document"
    folderTemplateQuery="new-widget-folder"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>
  <ng-container *ngIf="interactiveWidgets">
    <!-- condition is specifically when we are getting the variant from launch darkly
       and showing it accordingly otherwise it will always return true -->
    <ng-container
      *ngIf="
        launchDarklyFlagValue?.length
          ? launchDarklyFlagValue === interactiveWidgetsParams?.variation
          : true
      "
    >
      <div
        #interactiveWidgetsComponent
        class="interactive-widgets"
        *ngFor="let widget of interactiveWidgets"
        [class.interactive-widgets--add-padding]="
          interactiveWidgetsParams.addPadding
        "
      >
        <ng-container
          [ngTemplateOutlet]="widgetTemplate"
          [ngTemplateOutletContext]="{
            widget: widget,
            patToolVariation: interactiveWidgetsParams?.variation
          }"
        >
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
<!-- show the cta basic widget-->
<ng-template
  #widgetTemplate
  let-widget="widget"
  let-patToolVariation="patToolVariation"
>
  <eds-cta-basic
    *ngIf="widget.isFirmAllowed && (widget.isDisplayed$ | async)"
    [theme]="widget.theme"
    [class]="
      'interactive-widgets__cta-interactive-widget' + widget?.marginBottom
    "
    [customBgColor]="widget.customBgColor"
  >
    <ng-container>
      <div
        class="interactive-widgets__main-content"
        [class.interactive-widgets__main-content--one-column]="
          (widget.columnLayout$ | async) === 1
        "
        [class.interactive-widgets__main-content--two-columns]="
          (widget.columnLayout$ | async) === 2
        "
        [class.interactive-widgets__main-content--three-columns]="
          (widget.columnLayout$ | async) === 3
        "
      >
        <!-- Add img/video component -->
        <div
          *ngIf="!!widget.promoAsset"
          class="interactive-widgets__promo-asset"
          [class.interactive-widgets__promo-asset--three-columns]="
            (widget.columnLayout$ | async) === 3
          "
        >
          <ft-widen-img-video
            [widenAssetJson]="widget.promoAsset"
          ></ft-widen-img-video>
        </div>
        <div
          class="interactive-widgets__center-content"
          [class.interactive-widgets__center-content--three-columns]="
            (widget.columnLayout$ | async) === 3
          "
        >
          <div
            class="interactive-widgets-interactive-content"
            [innerHTML]="widget?.description?.value | safeHtml"
          ></div>
          <!-- widget custom button link -->
          <ng-container
            *ngIf="widget.buttonLink"
            [ngTemplateOutlet]="buttonLink"
            [ngTemplateOutletContext]="{
              buttonParams: {
                theme: widget.theme,
                buttonLink: widget.buttonLink,
                buttonText: widget.button.linkCollection[0]?.displayText,
                buttonTitle: widget.button.linkCollection[0]?.title,
                buttonTarget: widget.button.linkCollection[0]?.target,
                buttonExternal: widget.button.linkCollection[0]?.external
              }
            }"
          ></ng-container>
          <!-- PCS tools-->
          <div
            *ngIf="
              widgetType === widgetTypes.PCS && widget?.interactiveConfigImpl
            "
          >
            <!-- PCS tool will be not displayed in 'Edit Mode'-->
            <ft-pcs-tool
              *ngIf="
                (isLoggedIn$ | async) &&
                !isBypassUser &&
                widget?.labelsImpl &&
                !isEditMode
              "
              [pcsConfig]="widget.interactiveConfigImpl"
              [pcsLabels]="widget.labelsImpl"
              [patToolVariation]="patToolVariation"
              [class]="'interactive-widgets__pcs-tool-interactive-widget'"
            ></ft-pcs-tool>
            <ng-container
              *ngIf="(isLoggedIn$ | async) === false"
              [ngTemplateOutlet]="completeActivationTemplate"
              [ngTemplateOutletContext]="{ widgetTheme: widget.theme }"
            >
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="!!widget.imgSrc"
          class="interactive-widgets__logo-asset"
          [class.interactive-widgets__logo-asset--three-columns]="
            (widget.columnLayout$ | async) === 3
          "
        >
          <img
            [src]="widget.imgSrc"
            alt=""
            class="interactive-widgets__logo-asset-image"
          />
        </div>
      </div>
    </ng-container>
  </eds-cta-basic>
</ng-template>

<ng-template #completeActivationTemplate let-widgetTheme="widgetTheme">
  <!-- Activation panel -->
  <eds-panel
    *ngIf="(isProfileActivated$ | async) === false"
    [hasPadding]="false"
  >
    <button
      [edsButton]="widgetTheme === 'dark' ? 'secondary' : 'primary'"
      size="compact"
      data-analytics-no-event="true"
      (click)="goToAuth0('signup')"
    >
      <span class="ft__btn__txt">
        {{ "signin.fastTrackRegistrationButton" | translate }}
      </span>
    </button>
  </eds-panel>
  <!-- Sign in button  -->
  <div *ngIf="(isProfileActivated$ | async) === true">
    <button
      edsButton="secondary"
      size="compact"
      data-analytics-no-event="true"
      (click)="goToAuth0('signin')"
    >
      <span class="ft__btn__txt">
        {{ "signin.signIn" | translate }}
      </span>
    </button>
  </div>
</ng-template>

<ng-template #buttonLink let-buttonParams="buttonParams">
  <a
    [class]="'ft__btn'"
    [class.ft__btn--primary]="buttonParams.theme !== 'dark'"
    [class.ft__btn--secondary]="buttonParams.theme === 'dark'"
    [class.external-link]="buttonParams.buttonExternal"
    [href]="buttonParams.buttonLink"
    [title]="buttonParams.buttonTitle"
    [target]="buttonParams.buttonTarget ? buttonParams.buttonTarget : '_self'"
    data-analytics-no-event="true"
    (click)="trackClickEvent(buttonParams.buttonLink, buttonParams.buttonTitle)"
    ><span class="ft__btn__txt">{{ buttonParams.buttonText }}</span></a
  >
</ng-template>
