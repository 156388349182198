import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import { TabContents } from '@marketing/tabs/tabs.interface';

@Component({
  selector: 'ft-media-card-group',
  templateUrl: './media-card-group.component.html',
})
export class MediaCardGroupComponent {
  /**
   * Bloomreach page object
   */
  @Input() page: Page;

  /**
   * Content block content
   */
  @Input() contentBlock: TabContents;
}
