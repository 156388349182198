import { Mapper, MapperParams } from './type.mapper';
import { MapperFactory } from './mapper-factory';
import {
  BMCummPerfHistCategoryDTO,
  BMCummPerfHistDTO,
  ChartsDTO,
  ChartTypeDTO,
  CummPerfHistCategoryDTO,
  CummPerfHistTimePeriodDTO,
  FundCummPerfHistDTO,
} from '@products/types/dto/historical.dto.type';
import {
  BMCumulativePerformanceHistory,
  BMCumulativePerformanceHistoryCategory,
  CumulativePerformanceHistory,
  CumulativePerformanceHistoryCategory,
  CumulativePerformanceHistoryTimePeriod,
  FundCumulativePerformanceHistory,
  PerformanceHistory,
} from '@products/models/performance-history';
import { FundId, ShareClassCode } from '@types';

export class PerformanceHistoryMapper extends Mapper<PerformanceHistory> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(perfHistDto: ChartsDTO): PerformanceHistory {
    return {
      cumulative: perfHistDto.cummperfhist
        ? this.createMapper(CumulativePerformanceHistoryMapper).toDomain(
            perfHistDto.cummperfhist
          )
        : null,
    };
  }
}

export class CumulativePerformanceHistoryMapper extends Mapper<CumulativePerformanceHistory> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(cummPerfHistDto: ChartTypeDTO): CumulativePerformanceHistory {
    return {
      _100: cummPerfHistDto._100
        ? this.createMapper(
            CumulativePerformanceHistoryTimePeriodMapper
          ).toDomain(cummPerfHistDto._100)
        : null,
      _10k: cummPerfHistDto._10k
        ? this.createMapper(
            CumulativePerformanceHistoryTimePeriodMapper
          ).toDomain(cummPerfHistDto._10k)
        : null,
    };
  }
}

export class CumulativePerformanceHistoryTimePeriodMapper extends Mapper<CumulativePerformanceHistoryTimePeriod> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    cummPerfHistTimePeriodDto: CummPerfHistTimePeriodDTO
  ): CumulativePerformanceHistoryTimePeriod {
    return {
      _1yr: cummPerfHistTimePeriodDto._1yr
        ? this.createMapper(
            CumulativePerformanceHistoryCategoryMapper
          ).toDomain(cummPerfHistTimePeriodDto._1yr)
        : null,
      _3yr: cummPerfHistTimePeriodDto._3yr
        ? this.createMapper(
            CumulativePerformanceHistoryCategoryMapper
          ).toDomain(cummPerfHistTimePeriodDto._3yr)
        : null,
      _5yr: cummPerfHistTimePeriodDto._5yr
        ? this.createMapper(
            CumulativePerformanceHistoryCategoryMapper
          ).toDomain(cummPerfHistTimePeriodDto._5yr)
        : null,
      _10yr: cummPerfHistTimePeriodDto._10yr
        ? this.createMapper(
            CumulativePerformanceHistoryCategoryMapper
          ).toDomain(cummPerfHistTimePeriodDto._10yr)
        : null,
      _15yr: cummPerfHistTimePeriodDto._15yr
        ? this.createMapper(
            CumulativePerformanceHistoryCategoryMapper
          ).toDomain(cummPerfHistTimePeriodDto._15yr)
        : null,
      sinceInception: cummPerfHistTimePeriodDto.sinceinception
        ? this.createMapper(
            CumulativePerformanceHistoryCategoryMapper
          ).toDomain(cummPerfHistTimePeriodDto.sinceinception)
        : null,
      custom: cummPerfHistTimePeriodDto.custom
        ? this.createMapper(
            CumulativePerformanceHistoryCategoryMapper
          ).toDomain(cummPerfHistTimePeriodDto.custom)
        : null,
    };
  }
}

export class CumulativePerformanceHistoryCategoryMapper extends Mapper<CumulativePerformanceHistoryCategory> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    cummPerfHistCategoryDto: CummPerfHistCategoryDTO
  ): CumulativePerformanceHistoryCategory {
    return {
      fund: cummPerfHistCategoryDto.fund?.map((perf) =>
        perf
          ? this.createMapper(FundCumulativePerformanceHistoryMapper).toDomain(
              perf
            )
          : null
      ),
      benchmark: cummPerfHistCategoryDto.benchmark
        ? this.createMapper(
            BMCumulativePerformanceHistoryCategoryMapper
          ).toDomain(cummPerfHistCategoryDto.benchmark)
        : null,
    };
  }
}

export class FundCumulativePerformanceHistoryMapper extends Mapper<FundCumulativePerformanceHistory> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    fundCummPerfHistDto: FundCummPerfHistDTO
  ): FundCumulativePerformanceHistory {
    return {
      asOfDateStd: fundCummPerfHistDto.asofdatestd,
      asOfDate: fundCummPerfHistDto.asofdate,
      fundId: fundCummPerfHistDto.fundid as FundId,
      shareClassCode: fundCummPerfHistDto.shclcode as ShareClassCode,
      cumulativeMonthlyReturnCalculated: fundCummPerfHistDto.cummmonthlyretcalc,
      cumulativeMonthlyReturnCalculatedStd:
        fundCummPerfHistDto?.cummmonthlyretcalcstd ||
        fundCummPerfHistDto?.cummmonthlyretactstd,
      calcName: fundCummPerfHistDto.calcname,
    };
  }
}

export class BMCumulativePerformanceHistoryCategoryMapper extends Mapper<BMCumulativePerformanceHistoryCategory> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    bmCummPerfHistCategoryDto: BMCummPerfHistCategoryDTO
  ): BMCumulativePerformanceHistoryCategory {
    return {
      primary: bmCummPerfHistCategoryDto.primary?.map((perf) =>
        perf
          ? this.createMapper(BMCumulativePerformanceHistoryMapper).toDomain(
              perf
            )
          : null
      ),
      secondary: bmCummPerfHistCategoryDto.secondary?.map((perf) =>
        perf
          ? this.createMapper(BMCumulativePerformanceHistoryMapper).toDomain(
              perf
            )
          : null
      ),
    };
  }
}

export class BMCumulativePerformanceHistoryMapper extends Mapper<BMCumulativePerformanceHistory> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    bmCummPerfHistDto: BMCummPerfHistDTO
  ): BMCumulativePerformanceHistory {
    return {
      asOfDateStd: bmCummPerfHistDto.asofdatestd,
      asOfDate: bmCummPerfHistDto.asofdate,
      fundId: bmCummPerfHistDto.fundid as FundId,
      shareClassCode: bmCummPerfHistDto.shclcode as ShareClassCode,
      cumulativeMonthlyReturnCalculated: bmCummPerfHistDto.cummmonthlyretcalc,
      cumulativeMonthlyReturnCalculatedStd:
        bmCummPerfHistDto?.cummmonthlyretcalcstd ||
        bmCummPerfHistDto?.cummmonthlyretactstd,
      bmLabel: bmCummPerfHistDto.bmlabel,
    };
  }
}
