import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '@bloomreach/spa-sdk/';
import { ToggleButton } from '@frk/eds-components';
import { AppStateService, SiteConfigService } from '@services';
import {
  AsyncComponent,
  AsyncContentManagerService,
} from '@services/async-content-manager.service';
import { DebugService } from '@services/debug.service';
import { ProfileConfig } from '@services/profile.config';
import {
  IUserProfile,
  LoginSource,
  ProfileConfigFromBR,
  TestProfile,
} from '@services/profile.interface';
import { ProfileService } from '@services/profile.service';
import { SegmentService } from '@services/segment.service';
import { SignInService } from '@services/sign-in.service';
import { StorageService } from '@services/storage.service';
import { ViewModeService } from '@services/view-mode.service';
import { TranslateService } from '@shared/translate/translate.service';
import { SegmentId } from '@types';
import { Logger } from '@utils/logger';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

const logger = Logger.getLogger('DebugComponent');

const TOGGLEVALUES = {
  PROFILE: 'profile',
  DEBUG: 'debug',
  MISC: 'misc',
};
@Component({
  selector: 'ft-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
})
export class DebugComponent implements OnInit {
  @Input() page: Page;
  public isDebugWidgetEnabled = false;
  public labels: any;
  public showLabelKeysDisabled = false;
  public showInsightsDataDisabled = false;
  public showTooltipKeysDisabled = false;
  public showInsightsReportDisable = false;
  public testProfileId: string;
  public testProfiles: ProfileConfig[] | TestProfile[];
  public segmentId$: Observable<SegmentId>;
  public isLoggedIn$: Observable<boolean>;
  public profile$: Observable<IUserProfile>;
  public unvalidatedProfile$: Observable<IUserProfile>;
  public profileKey$: Observable<string>;
  public asyncComponentsReady$: Observable<boolean>;
  public imagesReady$: Observable<boolean>;
  public isPageReady$: Observable<boolean>;
  public pageComponentsStatus$: Observable<AsyncComponent[]>;
  public toggleButtons: ToggleButton[];
  public activeToggle: string;
  public isExpanded = false;
  public isSiteIntl = false;

  constructor(
    private viewModeService: ViewModeService,
    private debugService: DebugService,
    private router: Router,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private segmentService: SegmentService,
    private signInService: SignInService,
    private storageService: StorageService,
    private acm: AsyncContentManagerService,
    @Inject(DOCUMENT) private documentRef: Document,
    private siteConfigService: SiteConfigService,
    private appStateService: AppStateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (
      this.router?.url?.includes('debug=web-platform') ||
      this.documentRef?.URL.includes('ftsites') // for non prod sites we will always show testing panel
    ) {
      this.isDebugWidgetEnabled = true;
      this.storageService.store<boolean>('isDebugWidgetEnabled', true, true);
      this.debugService.debugOverride = true;
      this.initialise();
    } else {
      this.storageService
        .retrieve<boolean>('isDebugWidgetEnabled', true)
        .then((val) => {
          this.isDebugWidgetEnabled = val || this.viewModeService.isEditMode();
          if (this.isDebugWidgetEnabled) {
            this.initialise();
          }
        });
    }
  }

  private initialise() {
    this.storageService
      .retrieve<string>('testProfileId', true)
      .then((testProfileId) => {
        this.testProfileId = testProfileId;
      });
    this.isSiteIntl = this.siteConfigService.isSiteInternational();
    this.segmentId$ = this.segmentService.getCurrentSegmentId$();
    this.isLoggedIn$ = this.profileService.isLoggedIn$();
    this.unvalidatedProfile$ = this.profileService.getUnvalidatedUserProfile$();
    this.profile$ = this.profileService.getUserProfile$();
    this.isPageReady$ = this.acm.isPageReady$();
    this.pageComponentsStatus$ = this.acm.getPageComponentsStatus$();
    this.asyncComponentsReady$ = this.acm.getComponentsReady$();
    this.imagesReady$ = this.acm.getImagesReady$();
    // reformats profileKey json string onto multiple lines
    this.profileKey$ = this.profileService
      .getProfileChanges$()
      ?.pipe(
        map((profileKey: string): string =>
          JSON.stringify(JSON.parse(profileKey), null, 2)
        )
      );
    this.setToggleButtons();
  }

  private setToggleButtons() {
    this.activeToggle = TOGGLEVALUES.PROFILE;
    this.toggleButtons = [
      {
        active: true,
        text: 'Profile',
        value: TOGGLEVALUES.PROFILE,
      },
      {
        active: false,
        text: 'Debug',
        value: TOGGLEVALUES.DEBUG,
      },
      {
        active: false,
        text: 'Misc',
        value: TOGGLEVALUES.MISC,
      },
    ];
  }

  /**
   * on toggle change update the active toggle value
   */
  public onFilterToggle($event: ToggleButton) {
    this.activeToggle = $event.value;
  }

  public clear(): void {
    this.storageService.remove('isDebugWidgetEnabled', true);
    this.isDebugWidgetEnabled = false;
    this.debugService.clearAll();
  }

  get debugCaveats$(): Observable<boolean> {
    return this.debugService.isCaveatDebug$();
  }
  public toggleCaveatDebug(): void {
    this.debugService.toggleCaveatDebug();
    this.reloadPage();
  }

  get showDummyCaveats$(): Observable<boolean> {
    return this.debugService.isShowDummyCaveats$();
  }
  public toggleShowDummyCaveats(): void {
    this.debugService.toggleShowDummyCaveats();
  }
  get debugPortfolio$(): Observable<boolean> {
    return this.debugService.isPortfolioDebug$();
  }
  public togglePortfolioDebug(): void {
    this.debugService.togglePortfolioDebug();
  }

  get showLabelKeys$(): Observable<boolean> {
    return this.debugService.isShowLabelKeys$();
  }
  public toggleShowLabelKeys(): void {
    this.debugService.toggleShowLabelKeys();
    this.showLabelKeysDisabled = true;
    this.reloadPage();
  }
  public showLabels(): void {
    this.labels = this.translateService.getAllLabels();
  }

  get showInsightsData$(): Observable<boolean> {
    return this.debugService.isShowInsightsData$();
  }
  public toggleShowInsightsData(): void {
    this.debugService.toggleShowInsightsData$();
    this.showInsightsDataDisabled = true;
    this.reloadPage();
  }

  get showInsightsReport$(): Observable<boolean> {
    return this.debugService.isShowInsightsReport$();
  }
  public toggleShowInsightsReport(): void {
    this.debugService.toggleShowInsightsReport$();
    this.showInsightsReportDisable = true;
    this.reloadPage();
  }

  get showTooltipKeys$(): Observable<boolean> {
    return this.debugService.isShowTooltipKeys$();
  }
  public toggleShowTooltipKeys(): void {
    this.debugService.toggleShowTooltipKeys();
    this.showTooltipKeysDisabled = true;
    this.reloadPage();
  }
  showTooltips(): void {
    this.labels = this.translateService.getAllLabels();
  }

  get debugExpandAll$(): Observable<boolean> {
    return this.debugService.getExpandAllComponents$();
  }
  public toggleExpandAll(): void {
    this.debugService.toggleExpandAll();
  }

  public changeProfile(value: string): void {
    this.storageService.store<string>('testProfileId', value, true);
    if (
      this.profileService.isLoggedIn() &&
      this.profileService.getUserProfile().loginSource === LoginSource.OAUTH
    ) {
      this.signInService.signOut();
    } else {
      if (this.isSiteIntl) {
        const profile: ProfileConfig = (this
          .testProfiles as ProfileConfig[]).find(
          (p: ProfileConfig): boolean => p.name === value
        );
        if (profile) {
          this.profileService.setProfileFromDebug(profile);
        }
      } else {
        const profile = (this.testProfiles as TestProfile[]).find(
          (p: TestProfile) => p.name === value
        );
        this.profileService.forgetMe();
        if (profile.ftToken) {
          this.documentRef.location.href = `${this.appStateService.getHomePageUrl()}?role=fp&ft_token=${
            profile.ftToken
          }`;
        } else if (profile.firmToken) {
          this.documentRef.location.href = `${this.appStateService.getHomePageUrl()}?role=fp&firm_token=${
            profile.firmToken
          }`;
        }
      }
    }
  }

  private reloadPage(timeout = 200): void {
    setTimeout(() => this.documentRef.location.reload(), timeout);
  }

  public forgetMe() {
    this.profileService.forgetMe();
    this.storageService.remove('testProfileId', true);
    this.reloadPage(500);
  }

  private setTestProfiles(): void {
    this.debugService
      .getTestProfilesFromBR$()
      .pipe(take(1))
      .subscribe((testProfiles: ProfileConfigFromBR) => {
        this.testProfiles = testProfiles.profiles;
        this.cdr.detectChanges();
      });
  }

  public toggleExpanded() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      if (!this.testProfiles && !this.isSiteIntl) {
        this.setTestProfiles();
      } else {
        this.testProfiles = ProfileConfig.allConfigs;
      }
    }
  }
}
