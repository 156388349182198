<div edsGrid *ngIf="tableCreation" [edsSpacing]="spacingBottom">
  <div
    *ngIf="(isHandheld$ | async) === false || tableCreation.length > 1"
    class="table__wrapper {{ containerClass }}"
    [class.table__wrapper--has-shadows]="hasDropShadows"
  >
    <table
      class="table"
      [ngClass]="tableTheme === 'secondary' ? 'table--secondary' : ''"
    >
      <thead class="table__thead">
        <tr class="table__tr">
          <th
            class="table__th"
            *ngFor="let header of tableHeader"
            [style.width]="header.columnWidth"
          >
            <strong>{{ header.title }}</strong>
          </th>
        </tr>
      </thead>
      <tbody class="table__body">
        <ng-container *ngFor="let rows of tableCreation; let first = first">
          <tr
            class="table__tr"
            [ngClass]="!first ? 'table__tr--border-top' : ''"
            [class.table__tr--no-bg]="!isTableStriped"
          >
            <td
              *ngIf="rows.rowGroupName"
              class="table__td table__td--middle table__td--highlightedPrimary"
              [attr.rowspan]="rows.tableStyleRow.length"
            >
              {{ rows.rowGroupName }}
            </td>
            <!-- striped background for the row groups tables rendering first row elements with the row group column element -->
            <td class="table__td" *ngFor="let colData of rows.tableStyleRow[0]">
              <eds-icon
                *ngIf="colData.icon"
                edsPadding
                edsPaddingRight="sm"
                [type]="colData.icon"
                size="small"
              ></eds-icon>
              <span
                *ngIf="colData.addColumn"
                [ngClass]="colData.addColumn ? 'table__td-text-wrapper' : ''"
              >
                {{ colData.addColumn }}</span
              >
              <a
                *ngIf="colData.linkCompound?.linkCollection[0]?.displayText"
                edsLink
                [target]="colData.linkCompound.linkCollection[0]?.target"
                [href]="colData.linkCompound.linkCollection[0]?.url"
                [innerHTML]="colData.linkCompound.linkCollection[0].displayText"
                [attr.data-doc-id]="
                  colData.linkCompound.linkCollection[0]?.widenParameters?.id
                "
                [attr.data-title]="
                  colData.linkCompound.linkCollection[0]?.widenParameters
                    ?.document_title
                "
                [attr.rel]="
                  colData.linkCompound.linkCollection[0]?.widenParameters
                    ?.noindex
                    ? 'noindex nofollow'
                    : null
                "
              ></a>
            </td>
          </tr>
          <!-- slicing the first row as it was rendered above-->
          <tr
            class="table__tr"
            [class.table__tr--no-bg]="!isTableStriped"
            *ngFor="let row of rows.tableStyleRow | slice: 1"
          >
            <td class="table__td" *ngFor="let colData of row">
              <eds-icon
                *ngIf="colData.icon"
                edsPadding
                edsPaddingRight="sm"
                [type]="colData.icon"
                size="small"
              ></eds-icon>
              <span
                *ngIf="colData.addColumn"
                [ngClass]="colData.addColumn ? 'table__td-text-wrapper' : ''"
              >
                {{ colData.addColumn }}</span
              >
              <a
                *ngIf="colData.linkCompound?.linkCollection[0]?.displayText"
                edsLink
                [ngClass]="colData.linkCompound.externalLinkClass"
                [target]="colData.linkCompound.linkCollection[0]?.target"
                [href]="colData.linkCompound.linkCollection[0]?.url"
                [innerHTML]="colData.linkCompound.linkCollection[0].displayText"
                [attr.data-doc-id]="
                  colData.linkCompound.linkCollection[0]?.widenParameters?.id
                "
                [attr.data-title]="
                  colData.linkCompound.linkCollection[0]?.widenParameters
                    ?.document_title
                "
                [attr.rel]="
                  colData.linkCompound.linkCollection[0]?.widenParameters
                    ?.noindex
                    ? 'noindex nofollow'
                    : null
                "
              ></a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div *ngIf="(isHandheld$ | async) && tableCreation.length === 1">
    <ft-table-selection-card
      [rowData]="tableCreation"
      [headerData]="tableHeader"
    ></ft-table-selection-card>
  </div>
</div>
