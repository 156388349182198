import dayjs from 'dayjs';
import esConfig from 'dayjs/locale/es';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

dayjs.locale('es-es', { ...esConfig });
export const customEsEsConfig = dayjs.updateLocale('es-es', {
  ...esConfig,
  formats: {
    ...esConfig.formats,
    L: 'DD.MM.YYYY',
  },
});
