import { Injectable } from '@angular/core';
import { Logger } from '@utils/logger';
import { AppStateService } from './app-state.service';
import { SegmentService } from './segment.service';

/**
 * Logger
 */
const logger = Logger.getLogger('LocalLinkService');
@Injectable({
  providedIn: 'root',
})
export class LocalLinkService {
  constructor(
    private segmentService: SegmentService,
    private appStateService: AppStateService
  ) {}
  /**
   * Convert local-link tag in to anchor tag based on audience
   * @param content - HTML markup as a string
   */
  getLocalizeLinks(content: string) {
    const siteAudience = this.segmentService
      .getCurrentSegment()
      ?.insightAudiences?.split(',');
    const div = document.createElement('div');
    let localLinks;
    div.innerHTML = content.trim();
    localLinks = div.querySelectorAll('local-link');
    localLinks.forEach((localLink: any) => {
      const link = localLink?.getAttribute('value');
      const linkTab = localLink?.getAttribute('newtab');
      const modalTab = localLink?.getAttribute('externallink');
      const linkValue = link?.substring(
        link?.lastIndexOf('[') + 1,
        link?.lastIndexOf(']')
      );
      const linkList = linkValue.split('*');
      for (let i = 0; i <= linkList?.length; i++) {
        const linkHref = linkList[i]?.split(';')[0];
        const linkLabel = linkList[i]?.split(';')[1];
        const linkAudience = linkList[i]?.split(';')[2];
        const allowedLinkAudience = siteAudience?.filter((audience) =>
          linkAudience?.includes(audience)
        );
        if (allowedLinkAudience?.length) {
          const anchorTag = document.createElement('a');
          const linkPattern = new RegExp('(^http:|^https:)');
          let finalLink;
          if (linkPattern.test(linkHref)) {
            finalLink = linkHref;
          } else {
            finalLink = '/' + linkHref;
          }
          anchorTag.innerText = linkLabel;
          if (linkTab === 'true') {
            anchorTag.setAttribute('href', finalLink);
            anchorTag.setAttribute('target', '_blank');
          } else {
            anchorTag.setAttribute('href', finalLink);
          }
          if (modalTab === 'true') {
            anchorTag.setAttribute('href', finalLink);
            anchorTag.setAttribute('class', 'external-link');
          }
          anchorTag.setAttribute('alt', linkLabel);
          localLink.parentNode?.replaceChild(anchorTag, localLink);
          break;
        } else {
          if (i === linkList.length) {
            localLink.parentNode?.removeChild(localLink);
          }
        }
      }
    });
    return div.innerHTML;
  }
}
