import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LinkListContent, LinkListItem, NavLink } from './quick-links-type';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { LinkCollection } from '@types';
import { LinkService } from '@services/link.service';
import { Logger } from '@utils/logger';
import { SpacingValue } from '@frk/eds-components';
import { ViewModeService } from '@services';

/**
 * Logger
 */
const logger = Logger.getLogger('QuickLinksComponent');

/**
 * FT Quick Links component
 *
 * This component is used at the top of a template and has it's own associated document type.
 *
 * Selector: `ft-quick-links`
 *
 * Exported as: `QuickLinksComponent`
 */
@Component({
  selector: 'ft-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
})
export class QuickLinksComponent
  extends AbstractBaseComponent
  implements OnInit {
  /**
   * hasImage
   */
  public hasImage: boolean;
  /**
   * quickLinks main Heading
   */
  public quickLinksHeading: string;

  /**
   * quickLinks pre title
   */
  public quickLinksPreTitle: string;

  /**
   * adds component spacing bottom
   */
  public spacingBottom: SpacingValue;

  /**
   * Array with quick links data
   */
  public quickLinksCard: NavLink[];

  constructor(
    private ctaLinkService: LinkService,
    private cdr: ChangeDetectorRef,
    private viewModeService: ViewModeService
  ) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  ngOnInit(): void {
    this.getQuicklinks();
    const marginBottom = this.component.getParameters()?.marginBottom;
    this.spacingBottom =
      marginBottom && marginBottom !== 'none' ? marginBottom : 'lg';
    this.cdr.detectChanges();
  }

  /**
   * get Quick links Array
   */
  public getQuicklinks(): void {
    const content = this.document?.getData<LinkListContent>();
    if (content) {
      this.quickLinksCard = this.getLinkItems(content);
      this.quickLinksHeading = content.title;
      this.quickLinksPreTitle = content.preTitle;
    }
  }

  /**
   * Format Quick Links
   */
  public getLinkItems(content: LinkListContent): NavLink[] {
    const links = content?.linkListItem?.map((item: LinkListItem) => {
      const link: LinkCollection = item?.link?.linkCollection[0];
      const linkItem = {
        title: link?.displayText,
        altText: link?.title,
        href: this.ctaLinkService.getCTALink(this.page, link),
        isNewWindow: link?.target === '_blank' ? true : false,
        externalLink: link?.external,
        imageSrc: JSON.parse(item?.image?.widenDocument)?.url,
      };
      if (linkItem.imageSrc) {
        this.hasImage = true;
      }
      return linkItem;
    });
    return links;
  }
}
