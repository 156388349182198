import {
  CardModule,
  GridModule,
  LinkModule,
  SpacingModule,
  TabsModule,
  TabsStickyModule,
  TitleModule,
  NotificationsModule,
  AccordionModule,
  DialogModule,
} from '@frk/eds-components';

import { BrSdkModule } from '@bloomreach/ng-sdk';
import { CommonModule } from '@angular/common';
import { EqualColsComponent } from './equal-cols/equal-cols.component';
// Featured Modules
import { MarketingModule } from '@marketing/marketing.module';
import { AccountsModule } from '@accounts/accounts.module';
// marketing.module.ts
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OneColComponent } from './one-col/one-col.component';
import { OneColTabbedComponent } from './one-col-tabbed/one-col-tabbed.component';
import { OneColAccordionComponent } from './one-col-accordion/one-col-accordion.component';
// Components
import { PageContainerComponent } from './page-container/page-container.component';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { TwoColsComponent } from './two-cols/two-cols.component';
import { TwoColsExtendedComponent } from './two-cols-extended/two-cols-extended.component';
import { LayoutEVerticalTabsSidebarComponent } from './layout-e-vertical-tabs-sidebar/layout-e-vertical-tabs-sidebar.component';
// Services
import { ModuleLoaderService } from '@services/module-loader.service';
import { PdsLabelLoaderService } from '@products/services/pds-label-loader.service';
// Directives
import { PageEndDirective } from './page-container/directives';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { DocViewerModalModule } from '@marketing/doc-viewer-modal/doc-viewer-modal.module';
import { EmeaLayoutComponent } from './emea-layout/emea-layout.component';
import { LayoutModule as AltsLayoutModule } from '@ft-alts/ui-components/src/lib/layout-lib';

import {
  LabelLoader as AltsLabelLoader,
  LabelsService as AltsLabelService,
} from '@ft-alts/ui-components/src/lib/common-lib';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    LinkModule,
    BrSdkModule,
    RouterModule,
    // Feature Modules
    MarketingModule,
    AccountsModule,
    SharedModule,
    // EDS Modules
    GridModule,
    SpacingModule,
    TitleModule,
    CardModule,
    TabsModule,
    TabsStickyModule,
    NgxSkeletonLoaderModule,
    NgxSpinnerModule,
    HammerModule,
    NotificationsModule,
    AccordionModule,
    DocViewerModalModule,
    AltsLayoutModule,
  ],
  declarations: [
    EqualColsComponent,
    PageContainerComponent,
    TwoColsComponent,
    OneColComponent,
    OneColTabbedComponent,
    OneColAccordionComponent,
    CustomLayoutComponent,
    PageEndDirective,
    EmeaLayoutComponent,
    TwoColsExtendedComponent,
    LayoutEVerticalTabsSidebarComponent,
  ],
  providers: [
    ModuleLoaderService,
    PdsLabelLoaderService,
    AltsLabelLoader,
    AltsLabelService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule {}
