import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Logger, LogLevel } from '@utils/logger';
import { environment } from '../../environments/environment';
import prodEnvConfig from '../../assets/data/config.ENVPROD.json';
import { WINDOW } from '@ng-web-apis/common';
import { LiteratureApimFlows } from './app-state.service';

export enum Environments {
  DEV = 'dev',
  STG = 'staging',
  CONTENT_PREVIEW = 'content-preview',
  PRE = 'pre',
  PROD = 'prod',
}

export interface DataDogConfig {
  rumEnabled?: boolean;
  logsEnabled?: boolean;
  applicationId?: string;
  service?: string;
  site?: string;
  token?: string;
  allowedOrigins?: (string | RegExp)[];
  sampleRate?: number;
  premiumSampleRate?: number;
  trackInteractions?: boolean;
  trackFrustrations?: boolean;
}
export interface EnvConfig {
  apimFlags?: LiteratureApimFlows;
  accountsApiUrl?: string;
  canadaAccountsApiUrl?: string;
  canadaAccountsValidateSessionUrl?: string;
  canadaSignOutApiURL?: string;
  accountsDomain?: string;
  apiPort?: number;
  apiScheme?: string;
  apiUrl?: string;
  bookApptBaseUrl?: string;
  cmsBaseUrl?: string;
  commentaryContentUrl?: string;
  cookieApiUrl?: string;
  defaultLogLevel?: LogLevel;
  defaultPds?: string;
  environment?: string;
  searchEnvironment?: string;
  fundCommentaryKey?: string;
  hypoBaseUrl?: string;
  hypoSavedPresentationsUrl?: string;
  hypoLaunchUrl?: string;
  insightsUrl?: string;
  autoCompleteUrl?: string;
  instrumentationKey?: string;
  literatureApiUrl?: string;
  literatureBaseApiUrl?: string;
  literatureDownloadBaseUrl?: string;
  literatureDownloadPreviewUrl?: string;
  literatureDownloadFinraUrl?: string;
  literatureDownloadUrl?: string;
  literatureDownloadZipUrl?: string;
  literatureImageUrl?: string;
  literatureOrderHistoryList?: string;
  literatureRecentOrderHistoryList?: string;
  personalisationApi?: string;
  profileDomain?: string;
  searchStatusUrl?: string;
  searchUrl?: string;
  preSearchUrl?: string;
  fundObjective?: string;
  singleHost?: boolean;
  spabaseurl?: string;
  ssoUrl?: string;
  ssoPing?: string;
  widenUrl?: string;
  widenProxyUrl?: string;
  resourceApiTimeoutMs?: number;
  errorPage?: string;
  salesTeamUrl?: string;
  useLiteratureAPIMFlow?: boolean;
  orderHistoryAPIMUrl?: string;
  orderHistoryDetailAPIMUrl?: string;
  recentOrderAPIMUrl?: string;
  submitOrderAPIMUrl?: string;
  literatureAuthAPIAPIMKey?: string;
  marketingAuthAPIMKey?: string;
  literatureAPIMKey?: string;
  launchDarklyKey?: string;
  salesTeamListUrl?: string;
  dynamicsClientId?: string;
  dynamicsClientSecret?: string;
  LiteratureBaseAPIMUrl?: string;
  LiteratureEmailDocsAPIMUrl?: string;
  literatureCodeGenerationApi?: string;
  LiteratureDownloadZipAPIMUrl?: string;
  germanCalculatorOnetimeInvestmentApiUrl?: string;
  germanCalculatorSavingPlanApiUrl?: string;
  germanCalculatorWithdrawlPlanApiUrl?: string;
  germanCalculatorPensionApiUrl?: string;
  germanCalculatorPensionEstimatorApiUrl?: string;
  shortfallClosureApiUrl?: string;
  favoriteFundApimHeaderKey?: string;
  useLocalStorageFavoriteFunds?: boolean;
  calcFtApiKey?: string;
  ocpApimTrace?: boolean;
  datadog?: DataDogConfig;
  literatureDownloadAutoNumberingUrl?: string;
  useLiteratureOverrideListFor?: string[];
  damUrlParam?: string;
  muleSoftApi?: MuleSoftApi;
  customFormEmailUrl?: string;
  stockData?: string;
  biographiesUrlKey?: string;
  biographiesUrl?: string;
  fetchBiosFromElasticFlag?: boolean;
  brBiosDocPath?: string;
  oneFranklinIdApac?: Auth0Api;
  oneFranklinIdEmea?: Auth0Api;
  oneFranklinIdUs?: Auth0Api;
  internalFranklinId?: OktaApi;
  googleCaptchSitekey?: string;
  strategySpotlightUrl?: string;
  mostViewedFundsMuleApiUrl?: string;
  mostViewedClientId?: string;
  relatedArticleUrl?: string;
  latestBlogsApiUrl?: string;
  literatureCodesApi?: string;
  myfundsDomain?: string; // need to keep separate as used in tokens
  myfunds?: {
    apiUrl?: string;
    apiBase?: string;
    apiKey?: string;
    idpUrl?: string;
  };
  baseApimUrl?: string;
}

export interface StockDataDTO {
  GetStockQuoteListResult?: Array<StockQuoteListResultDTO>;
}
export interface StockQuoteListResultDTO {
  Change?: number;
  High?: number;
  High52?: number;
  Low?: number;
  Low52?: number;
  Open?: number;
  PercChange?: number;
  PreviousClose?: number;
  TradeDate?: string;
  TradePrice?: number;
  Volume?: number;
  Volume2?: number;
}

interface MuleSoftApi {
  apiKey: string;
  apiID: string;
}

interface OktaApi {
  key: string;
  issuer: string;
}

interface Auth0Api {
  domain: string;
  clientId: string;
}

/**
 * Environmental Config loaded from config.json
 */
@Injectable({
  providedIn: 'root',
})
export class EnvConfigService {
  private configName: string = environment.config;
  private envConfig: EnvConfig;
  private readonly isContentPre: boolean;
  private readonly isProd: boolean;
  private readonly isPreEnv: boolean;
  private loadedPromise: Promise<boolean>;

  constructor(
    private http: HttpClient,
    @Inject(WINDOW) readonly windowRef: Window
  ) {
    const hostname = windowRef.location.hostname;
    this.isContentPre = hostname.startsWith('content-preview');
    this.isPreEnv = hostname.startsWith('pre');
    this.isProd = hostname.startsWith('www') || this.isPreEnv;
    if (this.isProd) {
      this.initialize(prodEnvConfig);
      this.loadedPromise = Promise.resolve(true);
    }
  }

  public loadEnvConfig(): Promise<boolean> {
    if (!this.loadedPromise) {
      this.loadedPromise = new Promise((resolve) => {
        this.http
          .get<EnvConfig>('/assets/data/' + this.configName)
          .subscribe((data) => {
            this.initialize(data);
            resolve(true);
          });
      });
    }
    return this.loadedPromise;
  }

  private initialize(data: EnvConfig) {
    this.envConfig = data;
    Logger.addConfiguration({
      defaultLevel: this.envConfig.defaultLogLevel || LogLevel.DEBUG,
    });
  }

  public getEnvConfig(): EnvConfig {
    return this.envConfig;
  }

  public isContentPreview(): boolean {
    return this.isContentPre;
  }

  public isProduction(): boolean {
    return this.isProd;
  }

  public isPre(): boolean {
    return this.isPreEnv;
  }
}
