<a
  edsLink
  [eventId]="eventId"
  [eventParams]="eventParams"
  action="facebook"
  data-analytics-no-event="true"
  [href]="facebookUrl"
  class="external-link"
  [attr.aria-label]="'common.social-share.facebook' | translate"
  (click)="fireAnalyticsEvent('Facebook')"
>
  <eds-icon type="facebook"></eds-icon>
</a>
<a
  edsLink
  [eventId]="eventId"
  [eventParams]="eventParams"
  action="linkedIn"
  data-analytics-no-event="true"
  [href]="linkedInUrl"
  class="external-link"
  [attr.aria-label]="'common.social-share.linkedin' | translate"
  (click)="fireAnalyticsEvent('LinkedIn')"
>
  <eds-icon type="linkedin"></eds-icon>
</a>
<a
  edsLink
  [eventId]="eventId"
  [eventParams]="eventParams"
  action="twitter"
  data-analytics-no-event="true"
  [href]="twitterUrl"
  class="external-link"
  [attr.aria-label]="'common.social-share.twitter' | translate"
  (click)="fireAnalyticsEvent('Twitter')"
>
  <eds-icon type="twitter"></eds-icon>
</a>
<a
  edsLink
  [eventId]="eventId"
  [eventParams]="eventParams"
  action="email"
  data-analytics-no-event="true"
  [href]="emailUrl"
  class="external-link"
  [attr.aria-label]="'common.social-share.email' | translate"
  (click)="fireAnalyticsEvent('email')"
>
  <eds-icon type="mail"></eds-icon>
</a>
