import { Injectable } from '@angular/core';
import { extractFileInfoFromLink } from '@utils/link-utils';
import { Logger } from '@utils/logger';
import { CardContent, CardLinkClickEvent } from '@types';
import { LinkService } from '@services/link.service';
import { WidenService } from '@services/widen.service';
import { AppStateService } from '@services/app-state.service';
import { Page } from '@bloomreach/spa-sdk';

/**
 * Logger
 */
const logger = Logger.getLogger('ThumbnailsSectionComponent');

@Injectable({
  providedIn: 'root',
})
export class CardsService {
  private PDF_ICON_PATH = '/assets/icons/file-icons/ft-global-icon-pdf.svg';
  private XLS_ICON_PATH = '/assets/icons/file-icons/ft-global-icon-xls.svg';
  private DOC_ICON_PATH = '/assets/icons/file-icons/ft-global-icon-doc.svg';
  private CSV_ICON_PATH = '/assets/icons/file-icons/ft-global-icon-csv.svg';
  private PPT_ICON_PATH = '/assets/icons/file-icons/ft-global-icon-ppt.svg';
  private PREVIEW_ICON_PATH = '/assets/icons/file-icons/pdf-preview.png';

  constructor(
    private appStateService: AppStateService,
    private linkService: LinkService,
    private widenService: WidenService
  ) {}

  /**
   * Split cards into groups
   * @param cards - cards array
   * @param columns - number of columns
   */
  public createCardGroups(
    cards: CardContent[],
    columns: number = 3
  ): CardContent[] {
    const cardGroups = [];
    let index = 0;
    while (index < cards.length) {
      cardGroups.push(cards.slice(index, columns + index));
      index += columns;
    }
    return cardGroups;
  }

  /**
   * Users are entering content inconsistently for PDF links
   * Here we apply a simple set of rules to try and determine
   * what text to show.
   * @param cards - cards object
   */
  public formatCards(cards: CardContent[]): CardContent[] {
    const formatted: Array<CardContent> = [];
    for (const card of cards) {
      // title
      card.title = this.getTitle(card);
      // default image
      card.imgSrc = this.getPreviewIcon(card);
      const linkUrl = card.link?.url;
      if (linkUrl) {
        const {
          fileName,
          fileExtension,
          externalId,
        }: {
          fileName: string;
          fileExtension: string;
          externalId: string;
        } = extractFileInfoFromLink(linkUrl);

        card.eventParams = {
          file_name: fileName,
          file_extension: fileExtension,
          link_url: linkUrl,
        };
        if (card.link?.widenAssetCompound) {
          card.widenParams = this.linkService.getWidenDocumentParams(
            card.link.widenAssetCompound?.widenDocument
          );
          // To handle backward compatibility
          card.widenParams.external_id =
            card.widenParams?.external_id || externalId;
          card.widenParams.document_title =
            card.widenParams?.document_title || fileName;

          card.eventParams.document_source = 'widen';
          card.eventParams.embed_id = card.widenParams.external_id;
          card.eventParams.document_title = card.widenParams.document_title;
        }
      }
      // button text
      if (card.link) {
        card.link.displayText = this.getDisplayText(card);
      }
      if (linkUrl?.includes('bcove.video')) {
        // Set event name
        card.eventId = 'linkOrButtonClick';
      } else if (card.link?.url?.includes('widen.net')) {
        // don't set eventId for widen
      } else {
        card.eventId = 'file_download';
      }

      // file icon
      card.docTypeSrc = this.getFileTypeIcon(card);
      formatted.push(card);
    }
    return formatted;
  }

  /**
   * Format Cards URL
   * @param cards - cards object
   * @param page - BR page object
   */
  public formatCardURL(cards: CardContent[], page): CardContent[] {
    return cards.map(
      (card: CardContent): CardContent => ({
        ...card,
        href: this.linkService.getHref(page, card?.link, null),
      })
    );
  }

  /**
   * Set Download location
   * @param event - click event
   */
  public setDownloadLocation(event: CardLinkClickEvent): void {
    this.linkService.setUpdatedLinkData(event.downloadLocation);
  }

  /**
   * formatting the Card Group Compound data as
   * per the eds media cards and thumbnail cards requirement
   * @param content - media card BR object
   * @param page - BR Page object
   */
  public formatDataForMediaCards(content: any, page: Page) {
    if (content) {
      if (content.contentType === 'ftcore:CardGroupCompound') {
        if (content.cardCompound) {
          // Format each card into EDS format
          for (const contentCard of content.cardCompound) {
            // EDS expects 'bodyContent'
            if (contentCard.content) {
              contentCard.bodyContent = contentCard.content;
            }
            // Handle Widen assets
            if (contentCard.image && contentCard.image.widenAsset) {
              contentCard.imageSrc = this.widenService.getWidenAssetUrl(
                contentCard.image.widenAsset
              );
              if (contentCard.imageSrc.includes('.svg')) {
                contentCard.isIcon = true;
              }
            }
            // Process Card CTA
            if (contentCard.link?.linkCollection?.length) {
              contentCard.link = contentCard.link.linkCollection[0];
              contentCard.link.displayText =
                contentCard.link?.displayText || null;
              contentCard.link.url = this.linkService.getCTALink(
                page,
                contentCard.link
              );
              // Convert for eds
              contentCard.linkText = contentCard.link?.displayText;
              contentCard.url = contentCard.link?.url;
              contentCard.widenParams = this.linkService.getWidenDocumentParams(
                contentCard.link?.widenAssetCompound?.widenDocument
              );
            }
          }
        }
      }
    }
    return content;
  }

  /**
   * A simplistic check to fix content which has incorrectly been linked to pdf svg.
   * @param imgPath - path to widen image
   */
  private isPdfIcon(imgPath: string): boolean {
    return (
      imgPath?.includes('ft-global-icon-pdf') || imgPath?.includes('PDF_file')
    );
  }

  /**
   * Users are entering content inconsistently for PDF links
   * Here we apply a simple set of rules to try and determine
   * what text to show.
   * @param card - card object
   */
  private getDisplayText(card: CardContent): string {
    let label: string;
    // If button has display text
    if (card.link?.displayText) {
      label = card.link?.displayText;
    } else if (card.link?.title) {
      label = card.link?.title;
    }
    // If label is still blank
    if (!label) {
      label = card.title;
    }
    return label;
  }

  /**
   * Users are entering content inconsistently for PDF links
   * Here we apply a simple set of rules to try and determine
   * what text to show.
   * @param card - card object
   */
  private getPreviewIcon(card: CardContent) {
    // Ignore pdf icons and set preview image instead
    let src = this.isPdfIcon(card.imageSrc) ? null : card.imageSrc;
    if (!src && card.content) {
      src = this.PREVIEW_ICON_PATH;
    }
    logger.debug(src);
    return src;
  }

  /**
   * Users are entering content inconsistently for PDF links
   * Here we apply a simple set of rules to try and determine
   * what text to show.
   * @param card - card object
   */
  private getTitle(card: CardContent): string {
    let label;
    // If card has title
    if (card.title) {
      label = card.title;
    } else if (card.link?.title) {
      label = card.link?.title;
    }

    // If label is still blank
    if (!label) {
      label = card.link?.displayText;
    }
    return label;
  }

  /**
   * Apply some logic to determine if file is PDF
   * TODO: Add a file type option to the content
   */
  private getFileTypeIcon(card: CardContent): string {
    // Simplistic check
    logger.debug('getFileTypeIcon; ' + card.link?.url);
    if (
      card.link?.url?.endsWith('pdf') ||
      card.link?.url?.includes('/literature/info/') ||
      card.link?.url?.includes('/downloadLit/') ||
      card.link?.url?.includes('widen')
    ) {
      if (card.link?.url?.includes('xls') || card.link?.url?.includes('xlsx')) {
        return this.XLS_ICON_PATH;
      }
      if (card.link?.url?.includes('csv')) {
        return this.CSV_ICON_PATH;
      }
      if (card.link?.url?.includes('ppt') || card.link?.url?.includes('pptx')) {
        return this.PPT_ICON_PATH;
      }
      if (card.link?.url?.includes('doc') || card.link?.url?.includes('docx')) {
        return this.DOC_ICON_PATH;
      }
      if (card.link?.url?.includes('pdf')) {
        return this.PDF_ICON_PATH;
      }
    }
  }
}
