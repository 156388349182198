import { RiskReturnProfile } from '@products/models/risk-return-profile';
import { RiskReturnProfileDTO } from '@products/types/dto/risk-return-profile.dto.type';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class RiskReturnProfileMapper extends Mapper<RiskReturnProfile> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(riskReturnProfileDto: RiskReturnProfileDTO[]): RiskReturnProfile {
    const taxonomy = this.mapperParams.taxonomy;
    const maxNumberOfBenchmarksToShow = this.mapperParams.config.getMaxNumberOfBenchmarksToShow(
      this.mapperParams.fundId
    );

    let i = 0;
    const benchmarks = this.mapperParams.config
      .getBenchmarkOrder(taxonomy)
      .filter((bm) => {
        const ret = i < maxNumberOfBenchmarksToShow;
        if (ret) {
          i++;
        }
        return ret;
      });

    return {
      asOfDate: riskReturnProfileDto?.length
        ? riskReturnProfileDto[0]?.asofdate
        : null,
      chartLabel: riskReturnProfileDto?.length
        ? riskReturnProfileDto[0]?.chartlabel
        : null,
      points: this.generatePoints(riskReturnProfileDto, benchmarks),
    };
  }

  private generatePoints(
    riskReturnProfileDto: RiskReturnProfileDTO[],
    benchmarks: string[]
  ) {
    const tracking = {};
    const ret = [];
    const bms = [];
    if (riskReturnProfileDto) {
      for (const profile of riskReturnProfileDto) {
        if (!tracking[profile.calcname]) {
          const point = {
            name: this.mapperParams.translateService.instant(
              `products.risk-return-profile-${profile.calcname}`
            ),
            type: 'scatter',
            data: [
              [Number(profile.stddeviation10yr), Number(profile.avgannret10yr)],
            ],
          };
          tracking[profile.calcname] = point;
          ret.push(point);
        }
      }
      for (const profile of riskReturnProfileDto) {
        if (
          benchmarks.includes(profile.bmlabel) &&
          !tracking[profile.bmlabel]
        ) {
          const point = {
            name: profile.bmname,
            type: 'scatter',
            data: [
              [
                Number(profile.bmstddeviation10yr),
                Number(profile.bmavgannret10yr),
              ],
            ],
          };
          tracking[profile.bmlabel] = point;
          bms.push(point);
        }
      }
      bms.sort((a, b) => {
        return benchmarks.indexOf(a) - benchmarks.indexOf(b);
      });
      ret.push(...bms);
    }
    return ret;
  }
}
