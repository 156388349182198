import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  ColumnApi,
  GridReadyEvent,
} from '@ag-grid-community/core';
import { Logger } from '@utils/logger';
import {
  HeaderRendererComponent,
  ResponsiveService,
} from '@frk/eds-components';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubscriptionProductsService } from '@marketing/subscriptions-manage/services/subscription-products.service';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  SubscribedFundsDTO,
  SubscriptionsParams,
} from '../subscription-manage.interface';
import { WatchlistCellRendererComponent } from '../watchlist-cell-renderer/watchlist-cell-renderer.component';
import { ConfigurationId } from '@types';

/**
 * Logger
 */
const logger = Logger.getLogger('SubscriptionsProductsIiComponent');

@Component({
  selector: 'ft-subscriptions-products',
  templateUrl: './subscriptions-products.component.html',
  styleUrls: ['./subscriptions-products.component.scss'],
})
export class SubscriptionsProductsComponent implements OnInit, OnDestroy {
  @ViewChild('subscriptionForm') ngForm: NgForm;
  @Input() params!: SubscriptionsParams;
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private subscribedFundData: SubscribedFundsDTO[];
  private rowHeight: number;
  private isMobile$: Observable<boolean>;
  public rowData!: SubscribedFundsDTO[];
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public frameworkComponents: {
    watchlistCellRendererComponent: typeof WatchlistCellRendererComponent;
  };
  public isLoading = true;

  constructor(
    private subscriptionsProductsService: SubscriptionProductsService,
    private spinner: NgxSpinnerService,
    private changeDetector: ChangeDetectorRef,
    private responsiveService: ResponsiveService
  ) {
    this.frameworkComponents = {
      watchlistCellRendererComponent: WatchlistCellRendererComponent,
    };
    this.isMobile$ = this.responsiveService.isMobile$();
  }

  ngOnInit(): void {
    this.spinner
      .show('countLoadingSpinner')
      .then(() => logger.debug('Count Spinner set'));
    this.subscriptionsProductsService
      .getProductsUserData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (subscribedfunds: SubscribedFundsDTO[]) => {
          this.isLoading = false;
          this.subscribedFundData = subscribedfunds;
          if (this.subscribedFundData) {
            this.setTableData();
          } else {
            this.setLoadingEnd();
          }
        },
        (error) => {
          logger.debug('User data load error', error);
        }
      );
    logger.debug('Component initialised');
  }

  public addToWatchlist() {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  // ###############
  // PRIVATE METHODS
  // ###############
  // Ag-Grid setup

  /**
   * set the column data
   */
  private setTableData() {
    this.defaultColDef = this.subscriptionsProductsService.getDefaultColDefs();
    this.columnDefs = this.getColDefs();
    this.rowData = this.subscribedFundData;
    this.changeDetector.detectChanges();
  }

  /**
   * Get column definition for table
   */
  private getColDefs(): ColDef[] {
    const colDefs: ColDef[] = [
      {
        colId: 'NAME',
        field: 'NAME',
        headerName: 'Fund Name',
        cellClass: ['ag-cell-frk--highlightedPrimary', 'ag-cell-frk--middle'],
        valueGetter: (params) => {
          return params.data.productName;
        },
        minWidth: 200,
        maxWidth: 2000,
        autoHeight: true,
        sortingOrder: ['asc', 'desc'],
        headerComponentFramework: HeaderRendererComponent,
        sort: 'asc',
        flex: 2,
      },
      {
        colId: 'PRODUCT_TYPE',
        field: 'PRODUCT_TYPE',
        headerName: 'Product Type',
        valueGetter: (params) => {
          return ConfigurationId[params.data.productType];
        },
        minWidth: 150,
        maxWidth: 200,
        autoHeight: true,
        flex: 2,
        sortingOrder: ['asc', 'desc'],
        headerComponentFramework: HeaderRendererComponent,
        cellClass: ['ag-cell-frk--middle'],
      },
      {
        colId: 'SHARE_CLASS',
        field: 'SHARE_CLASS',
        headerName: 'Share Class',
        cellClass: ['ag-cell-frk--highlightedPrimary', 'ag-cell-frk--middle'],
        valueGetter: (params) => {
          return params.data.shareClass;
        },
        minWidth: 200,
        maxWidth: 2000,
        autoHeight: true,
        sortable: false,
        headerComponentFramework: HeaderRendererComponent,
        flex: 2,
      },
      {
        colId: 'Watchlist',
        field: 'Watchlist',
        headerName: '',
        cellClass: ['ag-cell-frk--middle'],
        cellRenderer: 'watchlistCellRendererComponent',
        minWidth: 200,
        maxWidth: 300,
        autoHeight: true,
        sortable: false,
        headerComponentFramework: HeaderRendererComponent,
        flex: 2,
      },
    ];
    return colDefs;
  }

  /**
   * Set Loading Data End
   */
  private setLoadingEnd = (): void => {
    this.isLoading = false;
    this.changeDetector.detectChanges();
  };

  /**
   * Execute during grid first load
   * @param $event - FirstDataRenderedEvent
   */
  public onFirstDataRendered($event: FirstDataRenderedEvent): void {
    logger.debug('First render:', $event);
    this.gridColumnApi = $event.columnApi;
    this.gridColumnApi?.autoSizeColumns(['PRODUCT_TYPE'], true);
  }

  /**
   * Execute when Grid ready
   * @param $event - GridReadyEvent
   */
  public onGridReady($event: GridReadyEvent): void {
    this.gridApi = $event.api;
    this.isMobile$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.gridApi?.sizeColumnsToFit();
      this.gridApi?.resetRowHeights();
    });
    logger.debug('Grid Ready', $event);
  }

  /**
   * Returns Row Height
   */
  public getRowHeight = (): number => this.rowHeight;
}
