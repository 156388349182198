<div class="literature-dialog">
  <eds-simple-modal
    [modalId]="modalIdEnum"
    modalAriaLabel="{{ 'literature.modal-label' | translate }}"
    modalAriaLabelledby="literaturePageTitle"
    closeAriaLabel="{{ 'common.close' | translate }}"
    aria-modal="true"
    (onClose)="handleEdsClose($event)"
    [size]="size"
    edsTrapFocus
  >
    <div modal-header>
      <!--modal Cart table header-->
      <ng-container *ngIf="dialogId === 'cartModal'">
        <eds-title
          id="literaturePageTitle"
          [level]="3"
          [size]="'h4'"
          [isLight]="true"
          [theme]="'dark'"
        >
          {{ "literature.lit-you-have" | translate }} {{ gridData.length }}
          <ng-container *ngIf="gridData.length > 1; else oneItemTemplate">
            {{ "literature.items-in-cart" | translate }}
          </ng-container>
          <ng-template #oneItemTemplate>
            {{ "literature.item-in-cart" | translate }}
          </ng-template>
        </eds-title>
      </ng-container>

      <!-- mandatory modal header-->
      <ng-container *ngIf="dialogId === 'mandatoryDailog'">
        <eds-title
          id="literaturePageTitle"
          [level]="3"
          [size]="'h4'"
          [isLight]="true"
          [theme]="'dark'"
        >
          {{ "literature.mandatory-item-removed" | translate }}
        </eds-title>
      </ng-container>

      <!-- select All modal header-->
      <ng-container *ngIf="dialogId === 'selectionAllModal'">
        <eds-title
          id="literaturePageTitle"
          [level]="3"
          [size]="'h3'"
          [isLight]="true"
        >
          {{ "literature.lit-select-all-modal-title" | translate }}
        </eds-title>
        <eds-title
          [level]="3"
          [size]="'h4'"
          [isLight]="true"
          edsPadding
          edsPaddingTop="sm"
        >
          {{ "literature.lit-select-all-modal-message" | translate }}
        </eds-title>
      </ng-container>

      <!-- max limit header-->
      <ng-container *ngIf="dialogId === 'maxSelectionLimit'">
        <eds-title
          id="literaturePageTitle"
          [level]="3"
          [size]="'h3'"
          [isLight]="true"
        >
          {{ "literature.lit-max-select-limit-title" | translate }}
        </eds-title>
        <eds-title
          [level]="3"
          [size]="'h4'"
          [isLight]="true"
          edsPadding
          edsPaddingTop="sm"
        >
          {{ "literature.lit-max-limit-selection-message" | translate }}
        </eds-title>
      </ng-container>

      <!-- modal common table subtitle -->
      <ng-container *ngIf="modalHeaderCommonTableSubTitleExp">
        <ng-container
          [ngTemplateOutlet]="modalTextTemplate"
          [ngTemplateOutletContext]="modalHeaderCommonTableSubTitle"
        >
        </ng-container>
      </ng-container>
    </div>

    <div modal-body>
      <!--modal Cart table body-->
      <!-- <ng-container *ngIf="dialogId === 'cartModal'">
      <p
        *ngIf="isMandatoryAvailable"
        style="font-weight: bold; font-style: normal"
      >
        {{ "literature.cart-mandatory-alert-body-msg" | translate }}
      </p>
    </ng-container> -->

      <!-- mandatory modal-->
      <ng-container *ngIf="dialogId === 'mandatoryDailog'">
        <ng-container *ngIf="dialogMandatoryMaterials?.length > 0">
          <div edsSpacing="md">
            <p>
              {{ dialogMandatoryMaterials[0].fundName }}
              {{ "literature.associated-with-following" | translate }}
            </p>
          </div>
          <div *ngFor="let data of dialogMandatoryMaterials; index as i">
            <p *ngIf="i !== 0">{{ data.fundName }}</p>
          </div>
        </ng-container>
      </ng-container>

      <!--modal Recipients Detail-->
      <ng-container *ngIf="dialogId === 'recipientsDetailModal'">
        <form [formGroup]="recipientArrayForm">
          <div
            *ngFor="
              let x of getFormArray().controls;
              let i = index;
              trackBy: track
            "
            formArrayName="recipients"
          >
            <div edsFlexbox flexDirection="row">
              <eds-text-input
                edsFlexItem
                [flexBasis]="getFormArray().length === 1 ? '100%' : '90%'"
                placeholder="{{
                  'literature.form-field-placeholder-recipient-mail-id'
                    | translate
                }}"
                value=""
                label="{{
                  'literature.form-field-recipient-mail-id' | translate
                }}"
                type="text"
                [hasHelperText]="false"
                [required]="true"
                [invalid]="
                  recipients.at(i).get('recipientEmailId').invalid &&
                  recipients.at(i).get('recipientEmailId').touched
                "
                aria-label="inputText"
                [inputControl]="recipients.at(i).get('recipientEmailId')"
                [invalidText]="
                  recipients.at(i).get('recipientEmailId').invalid &&
                  recipients.at(i).get('recipientEmailId').touched
                    ? validatorFunction(
                        recipients.at(i).get('recipientEmailId').errors
                          ?.required,
                        'recipientEmailId'
                      )
                    : ''
                "
              >
              </eds-text-input>
              <div
                *ngIf="!(getFormArray().length === 1)"
                edsPadding
                edsFlexItem
                edsPaddingTop="md"
                flexBasis="10%"
                edsPaddingLeft="sm"
              >
                <eds-link-item
                  title="{{
                    'literature.lit-sticky-delete-button' | translate
                  }}"
                  (click)="deleteRecipient($event, i)"
                ></eds-link-item>
              </div>
            </div>
          </div>

          <button
            type="button"
            edsButton="secondary"
            edsSpacing="md"
            size="compact"
            (click)="addRecipients()"
            [disabled]="isDisabled"
          >
            <span class="ft__btn__txt">
              {{
                "literature.lit-sticky-add-recipient-button" | translate
              }}</span
            >
            <!--icon-->
            <eds-icon
              class="modal-close__icon"
              type="add"
              size="small"
              aria-hidden="true"
            ></eds-icon>
          </button>
        </form>
      </ng-container>

      <!-- modal common table body -->
      <ng-container *ngIf="modalBodyCommonTableExp">
        <ng-container
          [ngTemplateOutlet]="modalTable"
          [ngTemplateOutletContext]="modalBodyCommonTableData"
        >
        </ng-container>
      </ng-container>

      <!-- common Modal text -->
      <ng-container *ngIf="modalBodyCommonTextExp">
        <ng-container
          [ngTemplateOutlet]="modalTextTemplate"
          [ngTemplateOutletContext]="modalBodyCommonTextData"
        >
        </ng-container>
      </ng-container>
    </div>

    <div modal-footer>
      <!--modal common footer-->
      <ng-container
        [ngTemplateOutlet]="footerButtonLayout"
        [ngTemplateOutletContext]="modalFooterCommonData"
      >
      </ng-container>
    </div>
  </eds-simple-modal>
</div>

<!-- modal table template -->
<ng-template #modalTable let-tableData="tableData">
  <table class="table table--secondary">
    <thead class="table__thead">
      <tr class="table__tr">
        <th class="table__th">
          <span>{{
            "literature." + tableData[dialogId].labels.nameHeader | translate
          }}</span>
        </th>
        <th class="table__th text-align-right">
          <span>{{
            "literature." + tableData[dialogId].labels.actionHeader | translate
          }}</span>
        </th>
      </tr>
    </thead>
    <tbody class="table__body">
      <ng-container
        *ngIf="tableData[dialogId].modalId === 'cartModal'; else nonCartModal"
      >
        <ng-container *ngIf="tableData[dialogId].gridData?.length; else loader">
          <tr
            class="table__tr"
            *ngFor="let data of tableData[dialogId].gridData"
          >
            <td class="table__td">
              <div edsFlexbox>
                <div
                  *ngIf="isSiteIntl"
                  edsPadding
                  edsPaddingRight="md"
                  class="image-width"
                >
                  <ng-container *ngIf="data.imageUrl; else icon">
                    <eds-image
                      [imgSrc]="data.imageUrl"
                      altText="altText"
                      class="spacingWrapper"
                    >
                    </eds-image>
                  </ng-container>
                  <ng-template #icon>
                    <eds-icon
                      [type]="data.fileExtension"
                      size="large"
                    ></eds-icon>
                  </ng-template>
                </div>
                <div>
                  <ng-container
                    *ngIf="data.clientUse && isSiteIntl; else disableNameLink"
                  >
                    <eds-link-item
                      [title]="data.fundName"
                      [href]="data?.literatureDetailUrl"
                    ></eds-link-item>
                  </ng-container>
                  <ng-template #disableNameLink>
                    <p class="spacing--none ag-cell-fund-name">
                      {{ data.fundName }}
                    </p>
                  </ng-template>
                  <p
                    style="font-style: normal"
                    class="small"
                    edsPadding
                    edsPaddingBottom="xs"
                    edsPaddingTop="xs"
                    *ngIf="data.mandatoryMaterialPrimary"
                  >
                    {{ mandatoryMaterialMsg }}
                    {{ data.mandatoryMaterialPrimary }}
                  </p>
                  <eds-notifications
                    *ngIf="data.clientUse === 'No'"
                    [title]="clientNotApprovedMsg"
                    [notificationType]="'warning'"
                    [inline]="true"
                    [canClose]="false"
                    [small]="true"
                  >
                  </eds-notifications>
                  <eds-notifications
                    *ngIf="data.hardCopyNotAvailable"
                    [title]="hardCopyNotAvailableTitle"
                    [notificationType]="'warning'"
                    [inline]="true"
                    [canClose]="false"
                    [small]="true"
                  >
                  </eds-notifications>
                </div>
              </div>
            </td>
            <td class="table__td text-align-right">
              <div edsFlexbox flexDirection="row" justifyContent="flex-end">
                <eds-link-item
                  *ngIf="!data.mandatoryMaterialPrimary"
                  edsSpacing="md"
                  data-analytics-no-event="true"
                  title="{{
                    'literature.' + tableData[dialogId].labels.linkLabel
                      | translate
                  }}"
                  (click)="
                    tableData[dialogId].clickHandler(
                      data.rowId,
                      data.fundName,
                      $event
                    )
                  "
                >
                </eds-link-item>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #nonCartModal>
        <tr class="table__tr" *ngFor="let data of tableData[dialogId].gridData">
          <td class="table__td">{{ data.name }}</td>
          <td class="table__td text-align-right">
            <div edsFlexbox flexDirection="row" justifyContent="flex-end">
              <eds-link-item
                *ngIf="!data.mandatoryMaterialPrimary"
                edsSpacing="md"
                data-analytics-no-event="true"
                title="{{
                  'literature.' + tableData[dialogId].labels.linkLabel
                    | translate
                }}"
                (click)="
                  tableData[dialogId].clickHandler(
                    tableData[dialogId].modalId,
                    data.rowId,
                    data.name,
                    $event
                  )
                "
              >
              </eds-link-item>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template #loader>
        <tr class="literature-dialog__loader">
          <ngx-spinner
            name="cart-spinner"
            bdColor="rgba(0,0,0,0)"
            size="medium"
            color="darkgrey"
            type="line-scale-party"
            [fullScreen]="false"
          >
          </ngx-spinner>
        </tr>
      </ng-template>
    </tbody>
  </table>
</ng-template>

<!-- text template-->
<ng-template #modalTextTemplate let-textData="textData">
  <div
    edsFlexbox
    [flexDirection]="(isHandheld$ | async) ? 'column' : 'row'"
    justifyContent="space-between"
  >
    <p>
      {{ "literature." + textData.labels[dialogId] | translate }}
      <ng-container *ngIf="dynamicBodyTextToAppend">
        <b data-dd-privacy="mask">{{ dynamicBodyTextToAppend }}</b>
      </ng-container>
      <ng-container *ngIf="dialogId === 'cartModal'">
        <p *ngIf="isMandatoryAvailable" class="bold spacing--none">
          {{ "literature.cart-mandatory-alert-body-msg" | translate }}
        </p>
      </ng-container>
    </p>
    <ng-container *ngIf="dialogId === 'cartModal'">
      <eds-link-item
        (click)="clearCart($event)"
        title="{{ 'literature.empty-cart' | translate }}"
        edsSpacing="md"
      ></eds-link-item>
    </ng-container>
  </div>
</ng-template>

<!-- footer layout template -->
<ng-template #footerButtonLayout let-footerData="footerData">
  <div edsFlexbox [justifyContent]="footerData[dialogId]?.justifyContent">
    <ng-container *ngFor="let buttonData of footerData[dialogId]?.buttonData">
      <button
        [edsButton]="buttonData.edsButtonType"
        [size]="buttonData.size"
        data-analytics-no-event="true"
        (click)="buttonData.clickhandler()"
        [disabled]="
          buttonData.checkForDisable ? recipientArrayForm?.invalid : false
        "
      >
        <span
          >{{ "literature." + buttonData.label | translate }}
          <ng-container
            *ngIf="dialogId === 'selectionAllModal' && buttonData?.secondLabel"
          >
            {{ buttonData.secondLabel }}
          </ng-container>
        </span>
      </button>
    </ng-container>
  </div>
</ng-template>
