import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DividerComponent } from './divider.component';
import { DividerModule, GridModule } from '@frk/eds-components';
import {
  DynamicModule,
  ComponentMapping,
} from '@services/module-loader.config';

@NgModule({
  declarations: [DividerComponent],
  imports: [CommonModule, DividerModule, GridModule],
  exports: [DividerComponent],
})
export class DividerCompModule implements DynamicModule {
  getComponentMapping(): ComponentMapping {
    return {
      Divider: DividerComponent,
    };
  }
}
