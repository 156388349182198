import { NotificationOptions } from '@frk/eds-components/lib/models/notification.model';
import { LinkCollections } from '@pages/page-container/types';
import { SegmentId } from '@types';

export enum NotificationTypes {
  ALERT = 'alert',
  CONFIRM = 'confirm',
  ERROR = 'error',
  WARNING = 'warning',
}

export interface AccountsLabels {
  notificationLabels: NotificationLabels;
}

interface NotificationLabels {
  'accounts-offline-fp'?: string;
  'no-access-sh'?: string;
  'upgrade-form-fp'?: string;
  'api-error'?: string;
  'api-error-title'?: string;
  'no-access-fp'?: string;
  'upgrade-form-sh'?: string;
  'block-dashboard-user'?: string;
  'block-dashboard-system'?: string;
  'accounts-offline-sh'?: string;
  'cancel-upgrade-fp'?: string;
  'dont-remind-me'?: string;
  'upgraded-fp'?: string;
  'manual-logout'?: string;
  'auto-logout'?: string;
  'password-fail-logout'?: string;
  'sec-question-fail-logout'?: string;
  'mos-password-fail-logout'?: string;
  'mos-sec-question-fail-logout'?: string;
  'manual-no-accounts-logout'?: string;
  'logout-title'?: string;
  'session-expired-title'?: string;
  'security-re-authentication-title'?: string;
}
export interface NotificationDetails {
  id?: string;
  title: string;
  subTitle: string;
  notificationType: NotificationTypes;
  notificationLabel: string;
  active: boolean;
  canClose?: boolean;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  tertiaryBtnText?: string;
  ctaText?: string;
  segment?: SwitchSegment;
  firms?: string[];
  pageTypes?: string[];
  segmentTarget?: string[];
  expirationType?: string;
  primaryBtnData?: LinkCollections;
  secondaryBtnData?: LinkCollections;
  tertiaryBtnData?: LinkCollections;
  notificationButton?: NotificationOptions;
  isHeaderNotification?: boolean;
}

export interface NotificationMessage {
  id?: string;
  expires?: number;
  messageType: 'add' | 'remove';
  notificationDetails: NotificationDetails;
}
export interface AlertObj {
  logout?: boolean;
  logoutType?: string;
  accounts?: string;
  apiType?: boolean;
  type?: string;
  notify?: boolean;
  role?: SegmentId;
  segmentType?: string;
  verifyEmail?: boolean;
  accountBlocked?: boolean;
  userNotFound?: boolean;
}
export interface SwitchSegment {
  id: SegmentId;
  segmentType: string;
}

export interface AccountNotificationOptions {
  UPGRADE_BTN: string;
  NOTNOW_BTN: string;
  NEVER_BTN: string;
  OPEN_BTN: string;
}

export interface UpgradePayload {
  userId: string;
  userSysNo: string;
  upgradeRemindFlag?: string;
  userGroup?: string;
}

export interface SpinnerDetails {
  spinnerName: string;
  spinnerLoadingText?: string;
  spinnerStatus?: boolean;
}
