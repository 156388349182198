import { Injectable, OnDestroy, Optional } from '@angular/core';
import { DebugService } from '@services/debug.service';
import { FundId } from '@types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnvConfig } from '@services/env-config.service';
import { Logger } from '@utils/logger';
import { replaceTokens } from '@utils/text/string-utils';

const logger = Logger.getLogger('TranslateService');

@Injectable({
  providedIn: 'root',
})
export class TranslateService implements OnDestroy {
  labels: { [key: string]: string } = {};
  fundOverrides: { [key: string]: any[] } = {};
  private unsubscribe$: Subject<void> = new Subject<void>();
  private debugTooltipKeys = false;
  private envConfig: EnvConfig;

  constructor(@Optional() private debugService?: DebugService) {
    if (this.debugService) {
      this.debugService
        .isShowTooltipKeys$()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((debug: boolean): void => {
          this.debugTooltipKeys = debug;
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setTranslation(
    translations: any,
    ignoreOverrides = true,
    envConfig?: EnvConfig
  ) {
    Object.getOwnPropertyNames(translations).forEach((key) => {
      if (envConfig) {
        this.envConfig = envConfig;
      }
      if (typeof translations[key] === 'object') {
        Object.getOwnPropertyNames(translations[key]).forEach((subkey) => {
          const fullKey = `${key}.${subkey}`;
          if (ignoreOverrides || this.labels[fullKey] === undefined) {
            // dont set if already overwritten
            this.labels[fullKey] = translations[key][subkey];
          }
        });
      } else {
        this.labels[key] = translations[key];
      }
    });
  }

  isLoaded(): boolean {
    return Object.keys(this.labels).length > 0;
  }

  /**
   * transform to a map of arrays keyed by key for performance
   */
  setFundOverrides(overrides: any[]) {
    if (overrides) {
      for (const override of overrides) {
        const key = override.key;
        if (override.funds?.length) {
          const current = this.fundOverrides[key];
          if (current) {
            current.push(override);
          } else {
            this.fundOverrides[key] = [override];
          }
        } else {
          // global override
          this.labels[key] = override.value;
        }
      }
    }
  }

  instant(key: string, fundId?: FundId, ...args: any[]): string {
    if (fundId) {
      const fundOverride = this.getFundOverride(fundId, key);
      if (fundOverride) {
        return fundOverride;
      }
    }
    const value = this.labels[key];
    if (value !== undefined) {
      // TODO: Cover solution for PROFILE tokens in labels.
      if (
        this.envConfig &&
        value.match(/\$(ENV|CONFIG)(\(|\[)(\w+(?:-\w+)*)(\)|\])/g)
      ) {
        // Replacing tokens in labels if exists.
        logger.debug('Replace token in label: ', value);
        return replaceTokens(value, this.envConfig);
      }
      return value;
    }
    return this.missingLabel(key);
  }

  getAllLabels(): any {
    return this.labels;
  }

  /**
   * Get Prefixed Labels Array
   * @param labelKeyPrefix - string with key prefix for array labels
   * @returns - array of labels groups
   */
  public getPrefixedLabelsArray(
    labelKeyPrefix: string
  ): { [key: string]: string }[] {
    return Object.entries(this.labels).reduce(
      (acc: { [key: string]: string }[], [key, value]: [string, string]) => {
        const [_, index, property] = key.split('_');
        const arrayIndex = parseInt(index, 10) - 1;
        if (key.startsWith(labelKeyPrefix) && /_\d+/g.test(key) && !!value) {
          acc[arrayIndex] = {
            ...acc[arrayIndex],
            [property]: value,
          };
        }
        return acc;
      },
      []
    );
  }

  // adds '-tooltip' suffix to key
  // NB: importantly, this will return undefined if no label, instead of missing label string i.e. undefined NOT '[some-key-tooltip]'
  tooltip(key: string): string {
    const tooltipKey: string = key + '-tooltip';
    if (this.debugTooltipKeys) {
      return this.missingLabel(tooltipKey);
    }
    return this.labels[tooltipKey];
  }

  private missingLabel(key: string): string {
    return `[${key}]`;
  }

  private getFundOverride(fundId: FundId, key: string): string {
    const overrides = this.fundOverrides[key];
    if (overrides) {
      for (const override of overrides) {
        if (override.funds?.includes(fundId)) {
          return override.value;
        }
      }
    }
    return null;
  }
}
