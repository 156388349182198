import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SkiptoHeaderService {
  private skipToHeaderActive$: Subject<boolean> = new Subject<boolean>();

  getSkipToHeaderBoolean$(): Observable<boolean> {
    return this.skipToHeaderActive$.asObservable();
  }

  setSkipToHeaderBoolean(value: boolean) {
    // Update the current value of the boolean.
    this.skipToHeaderActive$.next(value);
  }

  scrollToMainContent(mainContent: ElementRef) {
    // move the focus to the element.
    mainContent?.nativeElement?.focus();
    // TODO: WindowScrollService needs a way to scroll without offset
    // scrolling is done natively, to avoid the sticky tabs offset
    mainContent?.nativeElement?.scrollIntoView({
      behavior: 'smooth',
    });
  }
}
