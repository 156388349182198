import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import { ModalService } from '@frk/eds-components';
import { SignInService } from '@services/sign-in.service';
import { TranslateService } from '@shared/translate/translate.service';
import { Logger } from '@utils/logger';
const logger = Logger.getLogger('ConfirmationModalComponent');

@Component({
  selector: 'ft-confirmation',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent implements OnInit {
  @Input() page: Page;
  simpleModalId = 'confirmationModal';
  confirmationBody: string;
  confirmationHeader: string;
  constructor(
    private signInService: SignInService,
    private modalService: ModalService,
    private translate: TranslateService
  ) {}
  ngOnInit(): void {
    this.initialize();
  }
  initialize() {
    this.confirmationHeader = this.translate.instant(
      'signin.confirmation-header'
    );
    this.confirmationBody = this.translate.instant('signin.confirmation-body');
  }

  close() {
    this.modalService?.close(this.simpleModalId);
  }

  public signOut(): void {
    logger.debug('sign out');
    this.signInService.signOut();
  }
}
