<div
  class="exact-match"
  edsPadding
  edsPaddingAll="lg"
  *ngIf="(isHandheld$ | async) !== true"
>
  <!--results page-->
  <div class="exact-match__results">
    <ng-container *ngIf="exactMatch?.type === 'fund'">
      <div edsPadding edsPaddingBottom="lg">
        <ft-fund-details [exactMatch]="exactMatch"> </ft-fund-details>
      </div>
    </ng-container>

    <ng-container *ngIf="exactMatch.type === 'fund_manager'">
      <ft-fund-manager> </ft-fund-manager>
    </ng-container>

    <ng-container *ngIf="exactMatch.type === 'thematic_topic'">
      <ft-search-general [exactMatch]="exactMatch"> </ft-search-general>
    </ng-container>

    <ng-container *ngIf="exactMatch.type === 'status'">
      <ft-status> </ft-status>
    </ng-container>

    <p
      class="exact-match__disclaimer"
      edsSpacing="none"
      *ngIf="exactMatch.type === 'thematic_topic' && hasFundsItems"
    >
      {{ disclaimer }}
    </p>
  </div>
</div>
