import { HealthCheckService } from '@accounts/services/health-check.service';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  AnalyticsService,
  DataWindow,
  DialogConfig,
} from '@frk/eds-components';
import { LOCATION, WINDOW } from '@ng-web-apis/common';
import { SiteConfigService } from '@services';
import { TranslateService } from '@shared/translate/translate.service';
import {
  Channel,
  PROFILE_PARTNER_CHANNELS,
  SegmentId,
  PersonalisationPersonalData,
  PersonalisationToken,
  GlobalId,
} from '@types';
import {
  BYPASS,
  FIRM_PARTNER_COOKIE,
  ROLE,
  USER_TYPE_COOKIE,
  CRAWLERROLE,
  FIRM_TOKEN,
} from '@utils/app.constants';
import { Logger } from '@utils/logger';
import { combineLatest, timer, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { AppStateService } from './app-state.service';
import { DatadogService } from './datadog.service';
import { FirmConfig } from './firm.config';
import { PersonalisationAPIService } from './personalisation-api.service';
import { ProfileConfig } from './profile.config';
import {
  BypassRole,
  DashboardConfig,
  FirmRole,
  ISegmentCharacteristics,
  IUserProfile,
  IUserProfileInfo,
  LoginSource,
  ProfileSummary,
  UserRole,
} from './profile.interface';
import { SegmentService } from './segment.service';
import { ServerCookieService } from './server-cookie.service';
import { SessionService } from './session.service';
import { StorageService } from './storage.service';
import { UserAgentService } from './user-agent.service';

const logger = Logger.getLogger('ProfileService');

@Injectable({
  providedIn: 'root',
})
export class ProfileService implements OnDestroy {
  static readonly roleToSegmentMap = {
    distributor: SegmentId.DISTRIBUTOR,
    FA: SegmentId.FINANCIAL_PROFESSIONALS,
    FP: SegmentId.FINANCIAL_PROFESSIONALS,
    GK: SegmentId.GATEKEEPER,
    INV: SegmentId.INVESTOR,
    // In Profile service for Investor role is SH
    SH: SegmentId.INVESTOR,
    INST: SegmentId.INSTITUTIONAL,
    INT: SegmentId.INTERNAL,
    shariah: SegmentId.SHARIAH,
    // Myfunds role mapping
    INVESTOR: SegmentId.INVESTOR,
    ADVISOR: SegmentId.FINANCIAL_PROFESSIONALS,
  };

  private unsubscribe$: Subject<void> = new Subject<void>();
  private profile: IUserProfile;
  private unValidatedProfileSubject$ = new ReplaySubject<IUserProfile>(1);
  private validatedProfile$: Observable<IUserProfile>;
  private userProfileKeySubject$: ReplaySubject<string> = new ReplaySubject<string>(
    1
  );
  private validated$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1); // changing for dubug purpose - issue with skipUntil

  private profileKey: string;
  private toolsTermsCookie: string;
  private _isAccountsAvailable$: ReplaySubject<string>;
  private healthMessage = '';
  private canadaActors = {
    None: 'None',
    PUBLIC_INVESTOR: 'Signed In Investor',
    ADVISOR: 'Signed In FP - Financial Advisor',
    INTERNAL: 'Internal',
    FTE: 'Internal',
  };
  private accessToken: PersonalisationToken;
  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 - we need to accept more than 7 parameters in the constructor.
    private sessionService: SessionService,
    private segmentService: SegmentService,
    private storageService: StorageService,
    private appStateService: AppStateService,
    private siteConfigService: SiteConfigService,
    private healthCheckService: HealthCheckService,
    private translateService: TranslateService,
    private serverCookieService: ServerCookieService,
    private datadogService: DatadogService,
    private prerenderService: UserAgentService,
    private personalisationApi: PersonalisationAPIService,
    @Inject(LOCATION) readonly locationRef: Location,
    @Inject(WINDOW) readonly windowRef: DataWindow,
    private analyticsService: AnalyticsService
  ) {
    this.validatedProfile$ = combineLatest([
      this.unValidatedProfileSubject$,
      this.validated$,
    ]).pipe(map(([profile, isValidated]): IUserProfile => profile));

    // debug profile changes
    this.validated$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isValidated: boolean): void => {
        logger.debug('Is Validated', isValidated);
      });
    this.getUserProfile$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userProfile: IUserProfile): void => {
        logger.debug('Validated Profile Change', userProfile);
      });
    this.getUnvalidatedUserProfile$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userProfile: IUserProfile): void => {
        logger.debug('Un-validated Profile Change', userProfile);
      });
    this.getProfileChanges$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((key: string): void => {
        logger.debug('Profile Key Change', key);
      });

    this.personalisationApi
      .getPersonalData$()
      .pipe(take(1))
      .subscribe((personalData: PersonalisationPersonalData) => {
        // only setprofile if user is identified and not loggedin
        if (!personalData.isLoggedIn && (personalData.clientType)) {
          this.profile.isIdentified = true;
          this.profile.firm = FirmConfig.getFirmConfigWithParentFirm(personalData.parentFirmGlobalId);
          this.profile.firm.firmId = personalData.firmGlobalId;
          this.profile.profileInfo.firm = this.profile.firm.firmRole || personalData.companyName;
          this.profile.profileInfo.webExperience = personalData.clientType;
          // UDS-1003 - Adding additional profile data required for content personalisation.
          this.profile.profileInfo.userId = personalData?.identifiers?.globalId;
          this.profile.profileInfo.firstName = personalData?.firstName;
          this.profile.profileInfo.lastName = personalData?.lastName;
          this.profile.profileInfo.email = personalData?.email;
          this.setProfile(this.profile, this.profile.loginSource, true);
        }
      });

    this._isAccountsAvailable$ = new ReplaySubject(1);
    this._isAccountsAvailable$.next(this.healthMessage); // assume available until proven otherwise
    this.initiateValidateSession(7000);

    // listen for segment changes
    this.segmentService
      .getCurrentSegmentId$()
      .subscribe((segment: SegmentId) => {
        if (
          this.profile &&
          this.getSegmentRole(this.profile.profileInfo.role) !== segment
        ) {
          // UDS-1155 - Do not get default profile during the segment change for identyfied users
          if (this.profile?.isIdentified) {
            const userRoleKey = Object.keys(UserRole).find((key) => UserRole[key as keyof typeof UserRole] === segment as string);
            this.profile.defaultForSegment = segment;
            this.profile.loginSource = LoginSource.SEGMENT_SWITCH;
            this.profile.bypassRoles = [UserRole[userRoleKey]];
            this.profile.profileInfo.role = userRoleKey;
            this.setProfile(this.profile, LoginSource.SEGMENT_SWITCH);
          } else {
          this.setProfile(
            ProfileConfig.getDefaultConfig(segment),
            LoginSource.SEGMENT_SWITCH
          );
        }
        }
      });

    // Syncing cookies during page load due to issue with loading configuration in storage service constructor.
    this.storageService.syncCookieOnStartup();

    // this is to sync data stored in localStorage to global variable required for external scripts (Medallia)
    this.storageService.syncLocalStorageToGlobalVar();
    // Set profile after syncing cookies
    this.setInitialProfile(this.locationRef.search).catch((error) => logger.debug('Initial profile error:', error));

    // Listening for Canada accounts session
    if (this.appStateService.getAuthenticationType() === 'canada') {
      // Set validation time to 5 min.
      const canadaValidationTime = 5 * 60 * 1000;
      timer(
          canadaValidationTime,
          canadaValidationTime
        ).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
          logger.debug('Checking Canada profile', Date.now());
          this.checkCanadaProfile().catch((error) => {
            logger.debug('Setting profile for Canada - error:', error);
          });
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Returns SegmentId
   * @param role - role from profileInfo
   */
  public getSegmentRole(role: string): SegmentId {
    // role in profile could ne lower or upper case
    if (role) {
      return (
        ProfileService.roleToSegmentMap[role.toUpperCase()] ||
        ProfileService.roleToSegmentMap[role.toLowerCase()]
      );
    }
  }

  /**
   * set the initial profile based on:
   * 1. bypass
   * 2. debug
   * 3. storage
   * 3. default
   * in that priority order.
   */
  private async setInitialProfile(queryString: string): Promise<void> {
    // first check bypass
    const bypassRole =
      (await this.getBypassRole(queryString)) ||
      this.storageService.getBypassCookie();
    if (bypassRole) {
      let config = ProfileConfig.getConfigWithBypass(
        bypassRole as BypassRole | UserRole
      );
      if (!config) {
        config = ProfileConfig.getConfigWithFirm(bypassRole as FirmRole);
        if (config) {
          config.profileInfo.firm = bypassRole; // FIXME probably need to look up proper firm setting
        }
      }
      if (config) {
        this.setProfile(config, LoginSource.BYPASS);
        // WDE-2511 bypass links for ft site
        this.serverCookieService.set(LoginSource.BYPASS, {
          cookieValue: bypassRole,
        });
      } else {
        logger.error('Invalid bypass role:', bypassRole);
      }
    }
    // get firm token and setup the profile
    const firmToken =
      (await this.getFirmTokenFromURL(queryString)) ||
      (await this.storageService.getFirmToken());
    if (firmToken) {
      const config = ProfileConfig.getConfigWithFirm(firmToken);
      if (config) {
        config.profileInfo.firm = firmToken;
        if (this.siteConfigService.isSiteInternational()) {
          config.firm.firmRole = firmToken;
        } else {
          this.personalisationApi.setPersonalisationDataForFirm(config.firm);
        }
      }

      if (config) {
        this.setProfile(config, LoginSource.BYPASS);
      } else {
        logger.error('Invalid firm token :', firmToken);
      }
    }

    this.checkCanadaProfile().catch((error) => {
      logger.debug('Setting profile for Canada - error:', error);
    });
    if (this.profile) {
      logger.debug('profile set', this.profile);
      return;
    }

    // no bypass
    // try debug
    const name = await this.storageService.retrieve<string>(
      'testProfileId',
      true
    );
    const profileConfig: ProfileConfig = ProfileConfig.getConfigWithName(name);
    // NB: some configs only exist in debug component, so won't be found
    if (profileConfig) {
      this.setProfile(profileConfig, LoginSource.DEBUG);
      logger.debug('profile set from debug', this.profile);
      return;
    }

    // no debug
    // try profile from storage
    const profile: IUserProfile = await this.getProfileFromStorage();
    // try to get firm_partner stored in session cookie.
    const firmPartner: string = await this.storageService.retrieve(
      FIRM_PARTNER_COOKIE,
      true
    );
    if (profile) {
      // firm_partners aplies to US site only.
      if (
        firmPartner &&
        this.appStateService.getChannel() === 'en-us' &&
        profile.profileInfo.firm !== firmPartner
      ) {
        profile.profileInfo.firm = firmPartner;
      }
      this.setProfile(profile, LoginSource.STORAGE, !!firmPartner);
      logger.debug('profile set from storage', this.profile);
      return;
    }

    // no profile from storage
    // WDE-2305: try to find profile from partner site
    // e.g. if primerica profile found, we can use that to set role
    let partnerProfile: IUserProfile;
    let partner: string;
    let i = 0;
    // loop through partner sites until profile found or none left
    do {
      partnerProfile = await this.getProfileFromStorage(
        PROFILE_PARTNER_CHANNELS[i]
      );
      partner = Object.values(FirmRole).find((firmRole: string) => {
        return (
          firmRole.includes(PROFILE_PARTNER_CHANNELS[i]) &&
          firmRole.includes(this.appStateService.getChannel())
        );
      });
      logger.debug(
        `profile for "${PROFILE_PARTNER_CHANNELS[i]}" is:`,
        partnerProfile
      );
      i++;
    } while (!partnerProfile && i < PROFILE_PARTNER_CHANNELS.length);
    if (partnerProfile) {
      // Store firm_partner session cookie to transfer to US channel if it is already set.
      this.setFirmPartnerSession(partnerProfile, !!firmPartner);
      this.setProfile(partnerProfile, LoginSource.STORAGE, true);
      logger.debug('profile set from partner site', this.profile);
      return;
    }

    // no storage profile found
    // set profile based on stored segment
    const storageSegmentId: SegmentId = await this.storageService.retrieveSegment();
    let defaultPartnerProfile: IUserProfile;
    if (storageSegmentId) {
      defaultPartnerProfile = this.addPartnerToProfile(
        storageSegmentId,
        partner
      );
      this.setFirmPartnerSession(defaultPartnerProfile, !!firmPartner);
      this.setProfile(defaultPartnerProfile, LoginSource.NEW);
      logger.debug('profile set from stored segment', this.profile);
      return;
    }

    // no stored segment found
    // set profile based on default site segment
    const siteSegmentId = this.segmentService.getCurrentSegmentId(true);
    defaultPartnerProfile = this.addPartnerToProfile(siteSegmentId, partner);
    this.setFirmPartnerSession(defaultPartnerProfile, !!firmPartner);
    this.setProfile(defaultPartnerProfile, LoginSource.NEW);
    logger.debug('profile set from default site segment', this.profile);
    return;
  }

  /**
   * Adding Partner Firm to profile info
   * @param segmentId - Profile SegmentId to add partner
   * @param partner - string
   */
  private addPartnerToProfile(
    segmentId: SegmentId,
    partner: string
  ): IUserProfile {
    const defaultProfile: IUserProfile = ProfileConfig.getDefaultConfig(
      segmentId
    );
    if (partner) {
      defaultProfile.profileInfo.firm = partner;
    }
    return defaultProfile;
  }

  /**
   * Store session cookie for firm partner
   * @param partnerProfile - IUserProfile
   * @param firmPartnerCookieSet - boolean
   */
  private setFirmPartnerSession(
    partnerProfile: IUserProfile,
    firmPartnerCookieSet: boolean
  ): void {
    if (partnerProfile?.profileInfo?.firm && !firmPartnerCookieSet) {
      this.storageService.store(
        FIRM_PARTNER_COOKIE,
        partnerProfile.profileInfo.firm,
        true
      );
    }
  }

  /**
   * Setting Canada user profile and segment base on shared cookie from accounts.
   */
  private async checkCanadaProfile(): Promise<void> {
    if (this.appStateService.getAuthenticationType() === 'canada') {
      // Accounts cookie functionality was disabled. userType cookie is still used for Literature, but it is set internally in core app.
      // Application will not check usertype.dyn service for bypass users.
      if (!this.isBypass()) {
        this.siteConfigService
          .getIsPopulated$()
          .pipe(
            filter((flag: boolean) => flag),
            take(1),
            switchMap(() => {
              if (this.siteConfigService.hideLoginForm()) {
                // use Idp Service
                return this.sessionService.canadaValidateSession$().pipe(
                  takeUntil(this.unsubscribe$),
                  map((response: any) => response.body.userType)
                );
              }
              return this.serverCookieService
                .getCookieValue$(USER_TYPE_COOKIE)
                .pipe(takeUntil(this.unsubscribe$));
            })
          )
          .subscribe(
            async (returnedUserType) => {
              await this.setCanadaProfile(returnedUserType);
            },
            (error) => {
              logger.debug('User type validation error:', error);
              // Set Canada Profile to 'None' when canadaValidateSession$ get error.
              this.setCanadaProfile('None');
            }
          );
      } else {
        // For Bypass users userType cookie needs to be set from bypass profile name.
        const bypassUserType = this.getCanadaUserType();
        this.setUsrTypeCookie(bypassUserType);
        this.setUserTypeProfile(bypassUserType);
      }
    }
  }

  private async setCanadaProfile(userType: string): Promise<void> {
    if (userType) {
      this.setUsrTypeCookie(userType);
      // Base on response from usertype.dyn service (Idp Service) if "userType": "None" set logged-in session to false.
      if (userType === 'None' && this.profile.isLoggedIn) {
        const channel = this.appStateService.getChannel();
        this.storageService.store(`isLoggedIn_${channel}`, false, true);
        this.profile.isLoggedIn = false;
        // Due to time constrain profile needs to be re-set
        this.setProfile(this.profile, this.profile.loginSource);
      }
      this.setUserTypeProfile(userType);
    } else {
      // IF no userType cookie need to reset isLoggedIn cookie
      const isLoggedIn: boolean = await this.storageService.isLoggedIn();
      if (isLoggedIn) {
        await this.resetCanadaLogin();
      }
    }
  }

  /**
   * Resetting session cookie and profile for Canada.
   * It is required when user will sign out from accounts domain and only userType cookie will be removed.
   */
  private async resetCanadaLogin(): Promise<void> {
    for (const channel of ['en-ca', 'fr-ca']) {
      this.storageService.remove(`isLoggedIn_${channel}`, true);
    }
    this.getProfileFromStorage()
      .then((profile: IUserProfile) => {
        logger.debug(profile);
        if (profile.profileInfo.role === 'INT') {
          this.setProfile(
            ProfileConfig.FINANCIAL_PROFESSIONAL,
            LoginSource.NEW
          );
        } else {
          this.setProfile(profile, profile.loginSource);
        }
      })
      .catch((error) => {
        logger.debug('Profile error:', error);
      });
  }

  /**
   * Set Profile and segment base on user type.
   * @param userType - User type string
   */
  private setUserTypeProfile(userType: string): void {
    logger.debug(userType);
    this.setProfile(
      ProfileConfig.getConfigWithName(this.getCanadaUser(userType)),
      LoginSource.OAUTH
    );
    this.segmentService.setSegment(this.profile?.defaultForSegment, true, true);
  }

  /**
   * Get Canada User by userType
   * @param userType - User type string
   */
  private getCanadaUser(userType: string): string {
    return this.canadaActors[userType];
  }

  /**
   * Get Canada User Type base on profile name
   * Required for bypassed users
   */
  private getCanadaUserType(): string {
    return Object.keys(this.canadaActors).find(
      (key: string) => this.canadaActors[key] === this.profile?.name
    );
  }

  /**
   * Set Usr Type Cookie
   * @param userType - userType cookie
   */
  private setUsrTypeCookie(userType: string): void {
    this.storageService.store(
      USER_TYPE_COOKIE,
      userType,
      false,
      USER_TYPE_COOKIE,
      true
    );
  }

  private setProfile(
    profile: IUserProfile,
    loginSource: LoginSource,
    updateLocalStorage = true
  ) {
    logger.debug('setProfile()', profile, loginSource, updateLocalStorage);
    if (profile) {
      if (profile.profileInfo) {
        this.syncProfileDataWithAnalyticsStorage(
          profile.profileInfo,
          this.siteConfigService?.product?.site?.country
        );
      }
      this.profile = profile;
      this.profile.loginSource = profile.loginSource || loginSource;
      this.unValidatedProfileSubject$.next(profile);
      if (updateLocalStorage) {
        this.storageService.storeProfileSummary(this.getProfileSummary());
        this.updateFirmToken();
      }

      this.checkSegmentCompatible();

      const profileKey = this.getSegmentationCharacteristicsString();
      if (this.profileKey !== profileKey) {
        this.profileKey = profileKey;
        this.segmentService.setSegmentCharacteristicsString(this.profileKey);
        this.userProfileKeySubject$.next(this.profileKey);
        this.datadogService?.updateUser({
          loggedIn: this.isLoggedIn(),
          segment: this.segmentService.getCurrentSegmentId(true),
          source: this.profile.loginSource,
          auth0ID: this.profile.profileInfo.userId,
        });
      }

      if (profile.profileInfo?.firm && this.isBypass()) {
        logger.debug('set dashboard redirect for ', profile.profileInfo.firm);
        const firmDashboardUrl = this.getPartnerDashboardUrl(
          profile.profileInfo.firm
        );
        if (
          firmDashboardUrl &&
          !this.locationRef.pathname.includes(firmDashboardUrl)
        ) {
          // TODO: Just simply redirect. Convert to navigation service.
          this.locationRef.href =
            firmDashboardUrl + '?bypass=' + profile.profileInfo.firm;
        }
      }
      this.sendHealthCheck();
    }
  }

  public getPartnerDashboardUrl(firm: string): string {
    return ProfileConfig.DASHBOARD_REDIRECTS.find(
      (dashboardConfig: DashboardConfig) => {
        return dashboardConfig?.firm === firm;
      }
    )?.dashBoardUrl;
  }

  private checkSegmentCompatible() {
    const segmentId: SegmentId = this.segmentService.getCurrentSegmentId(true);
    const profileSegmentId: SegmentId = this.getSegmentRole(
      this.profile.profileInfo.role
    );
    logger.debug(
      'checkSegmentCompatible()',
      segmentId,
      profileSegmentId,
      this.profile.profileInfo.role
    );
    if (segmentId !== profileSegmentId) {
      // assumption: Profile ALWAYS takes precedence over segment
      // could be wrong but need to start somewhere
      this.segmentService.setSegment(profileSegmentId, true, this.isBypass());
    } else if (
      this.isBypass() ||
      this.isRole() ||
      this.storageService.getBypassCookie()
    ) {
      // Set segment storage if bypassing user.
      this.segmentService.setSegment(profileSegmentId, true, true);
    }
  }

  private getProfileFromStorage(channel?: Channel): Promise<IUserProfile> {
    return this.storageService
      .retrieveProfileSummary(channel)
      ?.then((profile: ProfileSummary) => {
        if (profile) {
          const profileSummary: IUserProfile = {
            name: 'Retrieved from Storage',
            isLoggedIn: profile.isLoggedIn,
            isIdentified: profile.isIdentified,
            loginSource: profile.source || LoginSource.STORAGE,
            profileInfo: {
              role: profile.role,
              webExperience: profile.webExperience,
              firm: profile.firm,
              additionalRoles: profile.additionalRoles,
            },
            firm: FirmConfig.getFirmConfigWithParentFirm(
              profile.firmId || (profile.firm as GlobalId)
            ),
          };
          // Adding accountsAccess and dashboardUrl when they exists in profile.
          if (profile.accountsAccess && profile.dashboardUrl) {
            profileSummary.profileInfo.accountsAccess = profile.accountsAccess;
            profileSummary.profileInfo.dashboardUrl = profile.dashboardUrl;
          }
          // Adding dummy data for bypassed users.
          if (this.checkProfileSource(profile)) {
            // TODO: Check if any other options required for Literature
            profileSummary.profileInfo.userId = 'bypassuser';
            profileSummary.profileInfo.displayName = 'bypass user';
            profileSummary.profileInfo.businessKey = '1234';
            profileSummary.profileInfo.dealerNo = '01234';
          }
          return profileSummary;
        }
        return null;
      });
  }

  private checkProfileSource(profileSummary: ProfileSummary): boolean {
    return (
      profileSummary.source === LoginSource.BYPASS && profileSummary.isLoggedIn
    );
  }

  /**
   * returns an Observable that can be subscribed to for user profile changes.
   * Will return earlier with best guess of profile before validateSession returns.
   */
  public getUnvalidatedUserProfile$(): Observable<IUserProfile> {
    return this.unValidatedProfileSubject$.asObservable();
  }

  /**
   * returns an Observable that can be subscribed to for user profile changes
   * only returns after validateSession has returned
   */
  public getUserProfile$(): Observable<IUserProfile> {
    return this.validatedProfile$;
  }

  /**
   * Returns the current value of the user profile
   * WARNING: Try and use observable instead as value can change.
   */
  public getUserProfile(): IUserProfile {
    return this.profile;
  }

  /**
   * returns an Observable that can be subscribed to for login status changes
   */
  public isLoggedIn$(): Observable<boolean> {
    return this.getUnvalidatedUserProfile$().pipe(
      map((userProfile: IUserProfile): boolean => !!userProfile.isLoggedIn)
    );
  }

  /**
   * Returns true if user is currently logged in
   * WARNING: Try and use observable instead as value can change.
   */
  public isLoggedIn(): boolean {
    return this.profile?.isLoggedIn;
  }

  /**
   * Segmentation characteristics for Bloomreach to target content.
   */
  private getSegmentationCharacteristicsString(): string {
    const characteristics: ISegmentCharacteristics = {
      isLoggedIn: this.profile.isLoggedIn ? 'true' : 'false',
      dashboardAccess:
        this.profile.profileInfo?.dashboardUrl &&
        this.profile.profileInfo?.dashboardUrl !== ''
          ? 'true'
          : 'false',
      // isIdentified: this.profile.isIdentified ? 'true' : 'false',
      segment: this.segmentService.getCurrentSegmentId(true),
      subsegment: this.profile.profileInfo.webExperience,
      loginSource: this.profile.loginSource,
    };
    if (this.profile.profileInfo.firm) {
      characteristics.firm = this.profile.profileInfo.firm;
    }
    if (this.profile.firm?.parentFirmId) {
      characteristics.firmId = this.profile.firm.parentFirmId;
    }
    return JSON.stringify(characteristics);
  }

  private getProfileSummary(): ProfileSummary {
    const summary: ProfileSummary = {
      isLoggedIn: this.profile.isLoggedIn,
      source: this.profile.loginSource,
      isIdentified: this.profile.isIdentified,
      role: this.profile.profileInfo.role,
      webExperience: this.profile.profileInfo.webExperience,
      firm: this.profile.profileInfo.firm,
      accountsAccess: this.profile.profileInfo.accountsAccess,
      dashboardUrl: this.profile.profileInfo.dashboardUrl,
      firmId: this.profile.firm?.parentFirmId,
    };
    return summary;
  }
  /**
   * Handle response from validateSession to update the profile.
   * This can cause a segment switch if it doesnt match the current segment
   */
  private async setProfileFromAccounts(profileInfo: IUserProfileInfo) {
    logger.debug('Entering setProfileFromAccounts', profileInfo);
    let isLoggedIn: boolean;

    // Additional check for MFA is completed or not
    const isMfaComplete = this.storageService.getMFAStatus();
    const deviceRecognizeStatus = this.storageService.getDeviceRecognizeStatus();

    if (isMfaComplete || deviceRecognizeStatus) {
      if (isMfaComplete === 'Y' || deviceRecognizeStatus === 'Y') {
        isLoggedIn = this.isUserSignedIn(profileInfo);
      } else {
        isLoggedIn = false;
      }
    } else {
      isLoggedIn = this.isUserSignedIn(profileInfo);
    }

    if (isLoggedIn) {
      if (profileInfo.pingToken) {
        // TODO remove once we stop going through profile services.
        this.serverCookieService.setOauthToken(profileInfo.pingToken);
        this.accessToken = profileInfo.pingToken as PersonalisationToken;
      }
      this.personalisationApi.invalidateIdentity(); // if you are properly logged in then you are no longer an "identified user";
      if (
        // if US FP then call personalisationApi
        profileInfo.businessKey &&
        this.getSegmentRole(profileInfo.role) ===
          SegmentId.FINANCIAL_PROFESSIONALS
      ) {
        await this.callPersonalisationApi({
          isLoggedIn: true,
          name: 'Logged In From Auth0',
          profileInfo,
        });
      } else {
        this.setProfile(
          {
            isLoggedIn: true,
            name: 'Logged In From Accounts',
            profileInfo,
          },
          LoginSource.OAUTH
        );
      }
    } else if (this.profile?.loginSource === LoginSource.OAUTH) {
      // logged out from accounts
      // keep role and web experience but lose the rest.
      this.setProfile(
        {
          isLoggedIn: false,
          name: 'Logged Out From Accounts',
          profileInfo: {
            role: this.profile.profileInfo.role,
            webExperience: this.profile.profileInfo.webExperience,
            businessKey: '',
          },
          firm: this.profile.firm, // keep firm after logout?
        },
        LoginSource.OAUTH
      );
    } else {
      // need to force new emit as now is validated
      this.unValidatedProfileSubject$.next(this.profile);
    }
    // NGC-10438 moved this from start of method to end, as we shouldn't emit validated profile until we've set the profileInfo
    this.validated$.next(true);
  }

  /**
   * Validating bypass profile for logged-in users
   * @param storageProfile - profile retrieved from storage. If client uses bypass it should be stored on init.
   * @param profileData - profile retrieved form accounts API.
   */
  private validateBypassProfile(
    storageProfile: ProfileSummary, // null if not found
    profileData: IUserProfileInfo
  ): void {
    logger.debug('Entering validateBypassProfile', profileData);
    if (
      storageProfile?.source === LoginSource.BYPASS &&
      this.isUserSignedIn(profileData)
    ) {
      // Need to check if Bypassed user is in storage and compare it with really logged in user
      if (storageProfile.role !== profileData.role) {
        // Set required for login to null to use bypass profile.
        profileData.userId = null;
        profileData.dealerNo = null;
      }
    }
  }

  /**
   * Check if user is signed-in
   * @param profileInfo - User Profile Info
   */
  private isUserSignedIn(profileInfo: IUserProfileInfo): boolean {
    return profileInfo?.userId && profileInfo.userSysNo !== null;
  }

  /**
   * Set profile through debug mode
   */
  public setProfileFromDebug(profile: ProfileConfig): void {
    this.setProfile(profile, LoginSource.DEBUG);
  }

  /**
   * used to manually trigger a profile update
   * This is used by gateway banner to trigger page refresh when the profile doesn't change from default
   * i.e. choosing investor
   */
  public triggerProfileUpdate(segmentId): void {
    this.userProfileKeySubject$.next(this.profileKey);
    this.segmentService.setSegment(segmentId, true);
  }

  /**
   * This can return before the user profile has been validated.
   */
  public getProfileChanges$(): Observable<string> {
    return this.userProfileKeySubject$.asObservable();
  }

  /**
   * Returns firm token for not logged in users
   * @param queryString - URL query string.
   */
  private async getFirmTokenFromURL(queryString: string) {
    const params = new URLSearchParams(queryString);
    const token = params.get(FIRM_TOKEN) as FirmRole;
    if (token) {
      this.storageService.setFirmTokenInStorage(token);
      // remove firm token from url
      params.delete(FIRM_TOKEN);
      const newParams =
        params.toString().length > 0
          ? '?' + params
          : '' + this.windowRef.location.hash;
      this.windowRef.history.replaceState(
        null,
        '',
        `${this.windowRef.location.pathname}${newParams}`
      );
    }
    return token;
  }

  /**
   * Returns bypass role for not logged in users
   * @param queryString - URL query string.
   */
  private async getBypassRole(
    queryString: string
  ): Promise<BypassRole | UserRole | FirmRole> {
    const isLoggedInStore = await this.storageService.isLoggedIn();
    if (isLoggedInStore && queryString.includes('role')) {
      logger.debug('User is logged-in. Can not change the role');
      return null;
    }
    const params = new URLSearchParams(queryString.substring(1));
    let ret: BypassRole | UserRole | FirmRole;
    ret = params.get(BYPASS) as BypassRole;
    if (!ret) {
      ret = params.get(ROLE) as UserRole;
    }
    if (!ret && this.prerenderService.isPrerender()) {
      ret = params.get(CRAWLERROLE) as UserRole;
    }
    return ret;
  }
  /**
   * Check for internal user.
   * @param userGroup String
   * @returns boolean
   */
  public checkIfUserIsInternal(userGroup): boolean {
    return userGroup === 'FPEMPLOYEEGROUP' || userGroup === 'FP_INTERNAL_USER';
  }

  /**
   *
   * @param zipCode String
   */
  get5DigitZip(zip: string): string {
    return zip && zip.substring(0, 5);
  }

  /**
   * Check if profile is bypassed
   * @param dontCheckUrlParam - false in don't need to check param in URL
   */
  public isBypass(dontCheckUrlParam?: boolean): boolean {
    const bypassParam =
      this.locationRef.search.includes(BYPASS) || dontCheckUrlParam;
    return this.profile?.loginSource === LoginSource.BYPASS && bypassParam;
  }

  /**
   * Check if role is bypassed
   */
  private isRole(): boolean {
    return (
      this.profile.loginSource === LoginSource.BYPASS &&
      this.locationRef.search.includes(ROLE)
    );
  }

  /**
   * Set Tools Terms Cookie name
   * @param dialogConfig - Dialog service config
   */
  private setToolsTermsCookie$(dialogConfig: DialogConfig): void {
    this.getUserProfile$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile: IUserProfile) => {
        this.toolsTermsCookie =
          (profile.profileInfo?.userSysNo || '') +
          dialogConfig.eventName +
          '_termsaccepted';
      });
  }

  /**
   * Get Tools Terms Cookie name
   * @param dialogConfig - Dialog service config
   */
  public getToolsTermsCookie(dialogConfig: DialogConfig): string {
    this.setToolsTermsCookie$(dialogConfig);
    return this.toolsTermsCookie;
  }

  /**
   * Whenever profile data changes,update analytics data to localstorage.
   * If user has been logged out then do not remove the data,persist it as it
   * @param profile IUserProfileInfo
   * @returns void
   */
  public syncProfileDataWithAnalyticsStorage(
    profile: IUserProfileInfo,
    siteCountry: string
  ): void {
    const { businessKey, userSysNo } = profile;
    // prettier-ignore
    switch (siteCountry) { // NOSONAR - typescript:S1301 - Might be extended with other countries
      case 'US':
        if (businessKey || userSysNo) {
          this.storageService.storeAnalyticsData({
            expressNo: businessKey || '',
            userSysNo: userSysNo || '',
          });
        }
        break;
      default:
    }
  }

  /**
   * Checking authentication. Might be extended with other countries.
   */
  private checkAuthentication(): boolean {
    return (
      this.appStateService.hasAuthentication() &&
      this.appStateService.getAuthenticationType() !== 'canada'
    );
  }

  /**
   * Initiate Validate Session
   * @param requestTimeout - ms number for timeout.
   */
  public initiateValidateSession(requestTimeout: number) {
    if (this.checkAuthentication()) {
      this.sessionService
        .validateSession$(requestTimeout)
        .pipe(first())
        .subscribe(
          (profileData) => {
            this.serverCookieService.clearOauthToken();
            this.storageService
              .retrieveProfileSummary()
              .then((storageProfile: ProfileSummary) => {
                if (storageProfile?.isLoggedIn) {
                  // this event should only triggered if user is logged in and get the details from cookie/session storage
                  this.trackEvent();
                }
                this.validateBypassProfile(storageProfile, profileData);
                this.setProfileFromAccounts(profileData);
                if (profileData.hasOwnProperty('userId')) {
                  this.storageService.logLoggedInStatusInDataDog(profileData);
                }
              });
          },
          (e) => {
            if (e.status !== 401) {
              // 401 is not logged in status for myfunds
              logger.error(
                'validateSession error',
                e,
                requestTimeout,
                this.translateService.isLoaded()
              );
              if (!this.healthMessage) {
                // TODO: this sometimes runs before translate service has loaded labels
                // will need an async method on translate service to get label
                this.healthMessage = this.translateService.instant(
                  'signin.profile-unavailable'
                );
                this._isAccountsAvailable$.next(this.healthMessage);
              }
              // FIXME - Code below causes issues with literature filters multiplying
              // setTimeout(
              //   () => this.initiateValidateSession(requestTimeout + 5000),
              //   30000
              // ); // retry in 30 seconds with an increased timeout
            } else {
              // user is logged out on MyFunds so need to ensure we are logged out on marketing
              if (
                this.profile.loginSource === LoginSource.OAUTH &&
                this.profile.isLoggedIn
              ) {
                this.profile.isLoggedIn = false;
                this.setProfile(this.profile, LoginSource.STORAGE, true);
                this.unValidatedProfileSubject$.next(this.profile);
              }
            }
            // even though profile load might have failed, set validated$ to true so we can use default profile
            this.validated$.next(true);
          }
        );
    } else {
      this.validated$.next(true);
    }
  }

  private sendHealthCheck() {
    if (this.appStateService?.getAuthenticationType() === 'myfunds') {
      const actor =
        this.segmentService.getCurrentSegmentId() === SegmentId.INVESTOR
          ? 'investor'
          : 'advisor';
      this.healthCheckService
        .sendHealthCheck$(
          this.appStateService.getEnvConfig().myfunds.apiBase,
          this.appStateService.getEnvConfig().myfunds.apiKey,
          this.appStateService.getMyFundsLocale(),
          actor
        )
        .pipe(first())
        .subscribe((message) => {
          if (message) {
            this.healthMessage =
              message === 'unplanned'
                ? this.translateService.instant('signin.profile-unavailable')
                : message;
            this._isAccountsAvailable$.next(this.healthMessage);
          }
        });
    }
  }

  public isAccountsAvailable$(): Observable<string> {
    return this._isAccountsAvailable$;
  }

  private async callPersonalisationApi(
    profile: IUserProfile
  ): Promise<boolean> {
    const token = await this.sessionService.getPersonalisationApiAccessToken();
    this.personalisationApi.setOauthToken(token);
    const profileInfo = profile.profileInfo;
    return new Promise((resolve, reject) => {
      this.personalisationApi.setLoggedInHeaders({
        expressNo: profileInfo.businessKey, // the express number
        globalId: profileInfo.globalId,
        firmGlobalId: profileInfo.firmGlobalId,
      });
      this.personalisationApi
        .callPersonalisationApiForLoggedInUser$(token as PersonalisationToken)
        .subscribe(
          (data: PersonalisationPersonalData): void => {
            logger.debug(
              'logged in user loaded personalisation api data successfully',
              data
            );
            profileInfo.webExperience = data.clientType;
            if (profile.loginSource !== LoginSource.OAUTH) {
              // can remove if after auth0
              profileInfo.firm = data?.companyName;
            }
            this.setProfile(
              {
                isLoggedIn: true,
                name: 'Logged In From Accounts',
                profileInfo,
                // set profile from accounts with firmid
                firm: FirmConfig.getFirmConfigWithParentFirm(
                  data.parentFirmGlobalId
                ),
              },
              LoginSource.OAUTH,
              true
            );
            resolve(true);
          },
          (error: any): void => {
            // User is logged in but not in FA 360
            logger.warn(
              'logged in user error loading personalisation api data',
              error
            );
            // still set accounts from profile but without firmId
            this.setProfile(
              {
                isLoggedIn: true,
                name: 'Logged In From Accounts',
                profileInfo,
                firm: {},
              },
              LoginSource.OAUTH
            );
            resolve(true);
          }
        );
    });
  }

  /**
   * Remove the firm token from the profile info and local storage
   */
  public forgetMe(): void {
    this.storageService.remove(FIRM_TOKEN);
    this.storageService.removeIdentyToken();
    this.profile.profileInfo.firm = '';
    this.profile.firm = {};
    this.setProfile(this.profile, LoginSource.NEW);
  }

  private updateFirmToken() {
    if (this.profile.firm?.firmRole) {
      this.storageService.setFirmTokenInStorage(this.profile.firm.firmRole);
    } else {
      this.storageService.remove(FIRM_TOKEN);
    }
  }

  private trackEvent(): void {
    const method = 'auth0';
    // UA to remove after migration to GA4
    const trackObject = {
      event: 'login',
      name: 'success silent auth',
      method,
      page_location: this.windowRef.location.href,
    };

    const trackObjectGA4 = {
      event: 'login',
      detailed_event: 'User Signed In',
      event_data: {
        name: 'success silent auth',
        method,
      },
    };
    // UA to remove after migration to GA4
    this.analyticsService.trackEvent(trackObject);

    // GA4 call
    this.analyticsService.trackEvent(trackObjectGA4);
  }
}
