import { BMAssociation } from '@products/models/benchmark-association';
import { BMAssocDTO } from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class BMAssocsMapper extends Mapper<BMAssociation[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(bmAssocsDTO: BMAssocDTO[]): BMAssociation[] {
    const bmAssocs: BMAssociation[] = [];

    bmAssocsDTO.forEach((bmAssocDTO: BMAssocDTO) => {
      const bmAssoc: BMAssociation = this.createMapper(BMAssocMapper).toDomain(
        bmAssocDTO
      );

      if (bmAssoc) {
        bmAssocs.push(bmAssoc);
      }
    });

    return bmAssocs;
  }
}

export class BMAssocMapper extends Mapper<BMAssociation> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(bmAssocDTO: BMAssocDTO): BMAssociation {
    return {
      benchmarkName: bmAssocDTO.bmname,
    };
  }
}
