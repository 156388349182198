<div
  *ngIf="document && content"
  [ngClass]="{ 'has-edit-button': isEditMode }"
  edsPadding
  [edsPaddingBottom]="padding"
  [edsPaddingTop]="padding"
  [edsSpacing]="marginBottom ? marginBottom : defaultMarginBottom"
>
  <ng-container>
    <ng-container [brManageContentButton]="document"></ng-container>
  </ng-container>

  <div edsGrid>
    <div edsRow>
      <eds-legal [hasSection]="true" edsCol>
        <div section [innerHTML]="content.content?.value"></div>
      </eds-legal>
    </div>
  </div>
</div>

<!-- no document -->
<div *ngIf="(!document || !content) && isEditMode" class="no-document">
  <div edsGrid class="no-document__component">
    <div class="no-document__overlay">
      Click to configure <strong>Important Legal</strong>
    </div>
  </div>
</div>
