import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import { ResponsiveService } from '@frk/eds-components';
import { TitleTheme } from '@frk/eds-components';
import { Observable } from 'rxjs';
import { Component as BrComponent } from '@bloomreach/spa-sdk/';

/*Layout Enum */
enum Layout {
  Layout1 = 'layout-1',
  Layout3 = 'layout-3',
  Layout4 = 'layout-4',
  Layout5 = 'layout-5',
  Layout6 = 'layout-6',
}

/*Layout Enum */
enum VerticalAlignment {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

interface TwoColContentBlock {
  contentType?: string;
  items?: any[];
  itemsTwo?: any[];
  layout?: Layout;
  verticalAlignment?: VerticalAlignment;
  mobileGridOrder?: string;
  tabletGridOrder?: string;
  desktopGridOrder?: string;
}
@Component({
  selector: 'ft-two-col-block',
  templateUrl: './two-col-block.component.html',
})
export class TwoColBlockComponent implements OnInit {
  @Input() component?: BrComponent;
  /**
   * Bloomreach page object
   */
  @Input() page: Page;

  /**
   * Content block content
   */
  @Input() contentBlock: TwoColContentBlock;

  /**
   * Spacing between items
   */
  @Input() spacing = 'none';

  @Input() titleColorTheme: TitleTheme = 'primary';

  /**
   * Subgrid layout options
   */
  layout: Layout = Layout.Layout1;

  public isMobile$: Observable<boolean>;
  public isHandheld$: Observable<boolean>;
  leftItemOrderDesktop = 'auto';
  leftItemOrderMobile = 'auto';
  leftItemOrderTablet = 'auto';
  rightItemOrderDesktop = 'auto';
  rightItemOrderMobile = 'auto';
  rightItemOrderTablet = 'auto';

  /**
   * Subgrid alignment
   */
  verticalAlignment: VerticalAlignment = VerticalAlignment.Start;

  /**
   * Constructor
   * @param responsiveService responsive service
   */
  constructor(private responsiveService: ResponsiveService) {
    this.isMobile$ = this.responsiveService.isMobile$();
    this.isHandheld$ = this.responsiveService.isHandheld$();
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    if (this.contentBlock.layout) {
      this.layout = this.contentBlock.layout;
    }
    if (this.contentBlock.verticalAlignment) {
      this.verticalAlignment = this.contentBlock.verticalAlignment;
    }
    if (this.contentBlock.mobileGridOrder) {
      this.leftItemOrderMobile = '2';
      this.rightItemOrderMobile = '1';
    }
    if (this.contentBlock.tabletGridOrder) {
      this.leftItemOrderTablet = '2';
      this.rightItemOrderTablet = '1';
    }
    if (this.contentBlock.desktopGridOrder) {
      this.leftItemOrderDesktop = '2';
      this.rightItemOrderDesktop = '1';
    }
  }
}
