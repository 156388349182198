import { DC, FA, FP, GK, INST, INT, INV, RIA, SH } from '@utils/app.constants';
import {
  BypassRole,
  DashboardConfig,
  FirmRole,
  IUserProfile,
  IUserProfileInfo,
  IFirmProfile,
  LoginSource,
  UserRole,
} from './profile.interface';
import cloneDeep from 'lodash/cloneDeep';
import { GlobalId, SegmentId } from '@types';
import { FirmConfig } from './firm.config';

export class ProfileConfig implements IUserProfile {
  // Germany only. "Vertriebspartner" translates as both "Sales Partner" and "Distribution Partner"
  static readonly DISTRIBUTOR = new ProfileConfig({
    name: 'Distributor',
    isLoggedIn: false,
    defaultForSegment: SegmentId.DISTRIBUTOR,
    bypassRoles: [UserRole.DISTRIBUTOR],
    profileInfo: {
      role: 'distributor',
      webExperience: 'distributor',
      businessKey: '',
    },
  });

  static readonly FINANCIAL_PROFESSIONAL = new ProfileConfig({
    name: 'Financial Professional',
    isLoggedIn: false,
    defaultForSegment: SegmentId.FINANCIAL_PROFESSIONALS,
    bypassRoles: [UserRole.FP, UserRole.FA],
    firmRoles: [
      FirmRole.EJ,
      FirmRole.PBD,
      FirmRole.PM,
      FirmRole.UNI,
      FirmRole.ATTRAX,
    ],
    profileInfo: {
      role: FP,
      webExperience: FA,
      businessKey: '',
    },
  });

  static readonly FP_DC = new ProfileConfig({
    name: 'Financial Professional - DC',
    isLoggedIn: false,
    bypassRoles: [UserRole.DC],
    firmRoles: [],
    profileInfo: {
      role: FP,
      webExperience: DC,
      businessKey: '',
    },
  });

  static readonly FP_RIA = new ProfileConfig({
    name: 'Financial Professional - RIA',
    isLoggedIn: false,
    bypassRoles: [UserRole.RIA],
    firmRoles: [],
    profileInfo: {
      role: FP,
      webExperience: RIA,
      businessKey: '',
    },
  });

  static readonly GATEKEEPER = new ProfileConfig({
    name: 'Gatekeeper',
    isLoggedIn: false,
    defaultForSegment: SegmentId.GATEKEEPER,
    bypassRoles: [UserRole.GK, BypassRole.GK],
    profileInfo: {
      role: GK,
      webExperience: GK,
      businessKey: '',
    },
  });

  static readonly INSTITUTIONAL = new ProfileConfig({
    name: 'Institutional',
    isLoggedIn: false,
    defaultForSegment: SegmentId.INSTITUTIONAL,
    bypassRoles: [UserRole.INST],
    profileInfo: {
      role: INST,
      webExperience: INST,
      businessKey: '',
    },
  });

  static readonly INVESTOR = new ProfileConfig({
    name: 'Investor',
    isLoggedIn: false,
    defaultForSegment: SegmentId.INVESTOR,
    bypassRoles: [UserRole.INV],
    profileInfo: {
      role: INV,
      webExperience: 'investor',
      businessKey: '',
    },
  });

  static readonly SHAREHOLDER = new ProfileConfig({
    name: 'Shareholder',
    isLoggedIn: false,
    bypassRoles: [UserRole.SH],
    profileInfo: {
      role: SH,
      webExperience: SH,
      businessKey: '',
    },
  });

  // Malaysia only
  static readonly SHARIAH = new ProfileConfig({
    name: 'Shariah',
    isLoggedIn: false,
    defaultForSegment: SegmentId.SHARIAH,
    profileInfo: {
      role: 'shariah',
      webExperience: 'shariah',
      businessKey: '',
    },
  });

  // Internal user for Canada
  static readonly INTERNAL = new ProfileConfig({
    name: 'Internal',
    isLoggedIn: true,
    defaultForSegment: SegmentId.INTERNAL,
    bypassRoles: [UserRole.INT],
    profileInfo: {
      userId: 'internalsuser',
      displayName: 'internal user',
      role: INT,
      webExperience: INT,
      businessKey: '',
      dealerNo: '',
    },
  });

  static readonly SIGNED_IN_INVESTOR = new ProfileConfig({
    name: 'Signed In Investor',
    isLoggedIn: true,
    bypassRoles: [BypassRole.INV],
    firmRoles: [],
    profileInfo: {
      userId: 'bypassuser',
      displayName: 'bypass user',
      role: INV,
      webExperience: 'investor',
      businessKey: 'INV1234',
      dealerNo: '',
    },
  });

  static readonly SIGNED_IN_SHAREHOLDER = new ProfileConfig({
    name: 'Signed In Shareholder',
    isLoggedIn: true,
    bypassRoles: [BypassRole.SH],
    profileInfo: {
      userId: 'bypassuser',
      displayName: 'bypass user',
      role: SH,
      webExperience: SH,
      businessKey: 'SH1234',
      dealerNo: '',
    },
  });

  static readonly SIGNED_IN_FP = new ProfileConfig({
    name: 'Signed In FP - Financial Advisor',
    isLoggedIn: true,
    bypassRoles: [BypassRole.FA, BypassRole.FP],
    firmRoles: [FirmRole.RJ, FirmRole.ML, FirmRole.LPL, FirmRole.MS],
    profileInfo: {
      userId: 'bypassuser',
      displayName: 'bypass user',
      role: FP,
      webExperience: FA,
      businessKey: 'FA1234',
      dealerNo: '01234',
    },
  });

  static readonly SIGNED_IN_RIA = new ProfileConfig({
    name: 'Signed In FP - RIA',
    isLoggedIn: true,
    bypassRoles: [BypassRole.RIA],
    profileInfo: {
      userId: 'bypassuser',
      displayName: 'bypass user',
      role: FP,
      webExperience: RIA,
      businessKey: 'RIA123',
      dealerNo: '01234',
    },
  });

  static readonly SIGNED_IN_DC = new ProfileConfig({
    name: 'Signed In FP - DC Specialist',
    isLoggedIn: true,
    bypassRoles: [BypassRole.DC],
    profileInfo: {
      userId: 'bypassuser',
      displayName: 'bypass user',
      role: FP,
      webExperience: DC,
      businessKey: 'DC1234',
      dealerNo: '01234',
    },
  });

  static readonly SIGNED_IN_GATEKEEPER = new ProfileConfig({
    name: 'Signed In FP - GateKeeper',
    isLoggedIn: true,
    bypassRoles: [BypassRole.GK],
    profileInfo: {
      userId: 'bypassuser',
      displayName: 'bypass user',
      role: FP,
      webExperience: GK,
      businessKey: 'GK1234',
      dealerNo: '',
    },
  });

  // TODO: This needs to be refactored into Firm config and configured redirects
  static readonly DASHBOARD_REDIRECTS: DashboardConfig[] = [
    {
      firm: FirmRole.EJ,
      dashBoardUrl: '/partners/edward-jones',
    },
    {
      firm: FirmRole.RJ,
      dashBoardUrl: '/partners/rj529',
    },
    {
      firm: FirmRole.ML,
      dashBoardUrl: '/partners/ml/ml',
    },
    {
      firm: FirmRole.LPL,
      dashBoardUrl: '/campaigns/lpl-financial',
    },
    {
      firm: FirmRole.MS,
      dashBoardUrl: '/campaigns/model-portfolio-services-for-morgan-stanley',
    },
  ];

  static readonly allConfigs: ProfileConfig[] = [
    ProfileConfig.DISTRIBUTOR,
    ProfileConfig.FINANCIAL_PROFESSIONAL,
    ProfileConfig.FP_DC,
    ProfileConfig.FP_RIA,
    ProfileConfig.GATEKEEPER,
    ProfileConfig.INSTITUTIONAL,
    ProfileConfig.INTERNAL,
    ProfileConfig.INVESTOR,
    ProfileConfig.SHAREHOLDER,
    ProfileConfig.SHARIAH,
    ProfileConfig.SIGNED_IN_INVESTOR,
    ProfileConfig.SIGNED_IN_FP,
    ProfileConfig.SIGNED_IN_SHAREHOLDER,
    ProfileConfig.SIGNED_IN_DC,
    ProfileConfig.SIGNED_IN_GATEKEEPER,
    ProfileConfig.SIGNED_IN_RIA,
  ];

  name: string;
  isLoggedIn: boolean;
  isidentified?: boolean;
  segment?: SegmentId;
  profileInfo: IUserProfileInfo;
  loginSource?: LoginSource;
  bypassRoles?: (BypassRole | UserRole)[];
  firmRoles?: FirmRole[];
  defaultForSegment?: SegmentId;
  firm: IFirmProfile;

  private constructor(data: Partial<IUserProfile>) {
    Object.assign(this, data);
    this.profileInfo.firm = '';
  }

  static getConfigWithBypass(role: BypassRole | UserRole): ProfileConfig {
    return cloneDeep(
      ProfileConfig.allConfigs.find((config) =>
        config.bypassRoles?.includes(role)
      )
    );
  }

  static getConfigWithFirm(firm: FirmRole): ProfileConfig {
    const ret: ProfileConfig = cloneDeep(
      ProfileConfig.allConfigs.find((config) =>
        config.firmRoles?.includes(firm)
      )
    );
    if (!ret) {
      return;
    }
    ret.firm =
      FirmConfig.allConfigs.find(
        (firmConfig) => firmConfig.firmRole === firm
      ) || {};
    return ret;
  }

  static getConfigWithName(name: string): ProfileConfig {
    return cloneDeep(
      ProfileConfig.allConfigs.find((config) => config.name === name)
    );
  }

  static getDefaultConfig(segment: SegmentId): ProfileConfig {
    return cloneDeep(
      ProfileConfig.allConfigs.find(
        (config) => config.defaultForSegment === segment
      )
    );
  }
}
