import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ViewChild,
  OnInit,
} from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk/';
import { NavigationService } from '@frk/eds-components';
import {
  GatewayModalComponent,
  TermsAccepted,
  CustomTermsAccepted,
} from '@marketing/gateway-modal/gateway-modal.component';
import { PageContainerService } from '@pages/services/page-container.service';
import { AppStateService } from '@services/app-state.service';
import { SegmentService } from '@services/segment.service';
import { Logger } from '@utils/logger';
import { of, Observable, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  GatewayModalService,
  ProfileService,
  StorageService,
  ViewModeService,
} from '@services';

const logger = Logger.getLogger('GatewayComponent');

@Component({
  selector: 'ft-gateway',
  templateUrl: './gateway.component.html',
})
export class GatewayComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  previewMessage = '';

  /**
   * used to remove subscriptions when component is destroyed
   */
  private unsubscribe$: Subject<void> = new Subject<void>();

  // Child modal
  @ViewChild('gatewayModal', { static: false })
  gatewayModal: GatewayModalComponent;

  isEditMode = false;
  hasCustomModal = false;
  customTermsPath: string;
  private retrieveCustomTermsAgreed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private segmentService: SegmentService,
    private profileService: ProfileService,
    private storageService: StorageService,
    private appStateService: AppStateService,
    private navService: NavigationService,
    private pageContainerService: PageContainerService,
    private viewModeService: ViewModeService,
    private gatewayModalService: GatewayModalService
  ) {
    this.isEditMode = this.viewModeService.isEditMode();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    Object.assign(this, this.component.getModels()?.gateway);

    // we check if gateway component has property 'document' and if this is not empty string
    const customTermsDocumentPath = this.component?.getModels()?.gateway
      ?.document;
    this.customTermsPath = this.gatewayModalService.getRelativeTermsPath(
      customTermsDocumentPath
    );

    const hasCustomModal = this.customTermsPath && this.customTermsPath !== '';

    if (this.appStateService.hasAuthentication()) {
      // Checking if user has valid profile.
      this.profileService
        .getUserProfile$()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((profileData) => {
          logger.debug('Profile Data', profileData);
          // Forcing to show T&C for bypassed users when agreement is not in cookie.
          if (this.profileService.isBypass()) {
            this.storageService
              .retrieveTermsAgreed(this.segmentService.getCurrentSegment().id)
              .then((agreed: boolean) => {
                logger.debug('Bypassed:', this.segmentService);
                if (!agreed) {
                  this.segmentService.forceTerms();
                  this.gatewayModal.show(
                    this.segmentService.getCurrentSegment()
                  );
                }
              });
          }
        });
    }

    // listen for new pages and open Terms modal if needed
    this.pageContainerService.page$
      .pipe(
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.segmentService.termsNeedAccepted$())
      )
      .subscribe(([config, needAccepted]): void => {
        logger.debug('page$ fired', config, needAccepted);
        if (needAccepted && !hasCustomModal) {
          this.gatewayModal.show(this.segmentService.getCurrentSegment());
        }
      });

    // listen for new pages and open Custom Terms modal if needed
    this.retrieveCustomTermsAgreed$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customTermsAgreed): void => {
        logger.debug('custom Terms needed:', customTermsAgreed);
        if (hasCustomModal && !customTermsAgreed) {
          this.gatewayModal.showCustom();
        }
      });

    // listen for terms accepted false, to close modal
    this.segmentService
      .termsNeedAccepted$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((needAccepted: boolean): void => {
        logger.debug('termsNeedAccepted$()', needAccepted);
        if (!needAccepted) {
          this.gatewayModal?.close();
        }
      });

    // after creating listeners we check customTerms cookie to trigger popup
    this.storageService
      .retrieveCustomTermsAgreed(this.customTermsPath)
      .then((agreed: boolean) => {
        this.retrieveCustomTermsAgreed$.next(agreed);
      });
  }

  /**
   * On component destroyed
   * Removes any remaining subscriptions
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Listens for the terms accepted callback from child modal
   * @param value passed from modal
   */
  public onTermsDecision(value: TermsAccepted): void {
    if (value.accepted) {
      this.segmentService.acceptTerms();
    } else {
      // reject terms
      this.segmentService.declineTerms();
      // navigate to homepage
      this.navService.navigateByUrl(
        this.appStateService.getHomePageUrl(),
        '_self'
      );
    }
  }

  public onCustomTermsDecision(value: CustomTermsAccepted): void {
    if (value.accepted) {
      this.acceptCustomTerms(value.customTermsPath);
    } else {
      // reject terms
      this.declineCustomTerms(value.customTermsPath);
      // navigate to homepage
      this.navService.navigateByUrl(
        this.appStateService.getHomePageUrl(),
        '_self'
      );
    }
  }

  public acceptCustomTerms(customTermsPath: string) {
    this.storageService.storeCustomTermsAgreed(customTermsPath, true);
  }

  public declineCustomTerms(customTermsPath: string) {
    this.storageService.storeCustomTermsAgreed(customTermsPath, false);
  }
}
