import { RowNode } from '@ag-grid-community/core';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import { fromLocaleNumber } from './il8n/number-il8n';
import { Logger } from './logger';

dayjs.extend(localeData);

const logger = Logger.getLogger('sort-utils');

/**
 * Sorting, Dropdown, and filtering table data
 */

// compare function for use in Array.sort()
export type CompareFn<T> = (a: T, b: T) => number;

//  compare function for use in ag grid
export type AgGridComparator = (
  valueA: any,
  valueB: any,
  nodeA?: RowNode,
  nodeB?: RowNode,
  isInverted?: boolean
) => number;

type DirectionInt = 1 | -1;

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface CompareOptions<T> {
  dataKey: keyof T;
  direction: SortDirection;
  isNumeric?: boolean;
  isDate?: boolean;
  // TODO: format and dateFormat should probably be combined into a single property
  format?: string;
  dateFormat?: string;
  secondaryCompare?: CompareFn<T>;
}

/**
 * @see createCompare for alphabetical string, date or numerical sorting
 */
export function createSortByListCompare<T>(
  orderedListOfThings: T[]
): CompareFn<T> {
  return (thingA: T, thingB: T) => {
    const indexVehicleA: number = orderedListOfThings.indexOf(thingA);
    const indexVehicleB: number = orderedListOfThings.indexOf(thingB);
    if (indexVehicleA !== -1 && indexVehicleB !== -1) {
      return indexVehicleA > indexVehicleB ? 1 : -1;
    }
    if (indexVehicleA !== -1) {
      return -1;
    }
    if (indexVehicleB !== -1) {
      return 1;
    }
    return thingA > thingB ? 1 : -1;
  };
}

/**
 * WARNING: numerical sorting !== string sorting !!!
 * creates a sorting function, for use in [].sort(xxx)
 * @returns function for using in .sort
 * @see createSortByListCompare for matching array to arbitrary sort order from a list
 */
export function createCompare<T>(options: CompareOptions<T>): CompareFn<T> {
  const directionInt: DirectionInt = options.direction === 'DESC' ? -1 : 1;
  if (options.isNumeric) {
    return _createNumericCompare(
      options.dataKey,
      directionInt,
      options.secondaryCompare
    );
  }
  if (options.isDate) {
    if (options.format === 'YYYY-MM-DD') {
      logger.warn(
        'Date Sorting YYYY-MM-DD Format, x1000 faster to do string sorting, doing string compare'
      );
      return _createStringCompare(
        options.dataKey,
        directionInt,
        options.secondaryCompare
      );
    }
    // Default date formatting to locale e.g. DD/MM/YYYY
    if (!options.dateFormat) {
      options.dateFormat = dayjs.localeData().longDateFormat('L');
    }
    return _createDateCompare(
      options.dataKey,
      directionInt,
      options.dateFormat,
      options.secondaryCompare
    );
  }
  return _createStringCompare(
    options.dataKey,
    directionInt,
    options.secondaryCompare
  );
}

function _createNumericCompare<T>(
  dataKey: keyof T,
  directionInt: DirectionInt,
  secondaryCompare: CompareFn<T>
): CompareFn<T> {
  return (a, b) => {
    const aParsed = get(a, dataKey, '');
    const bParsed = get(b, dataKey, '');
    const result: number = numericValueCompare(
      aParsed,
      bParsed,
      null,
      null,
      directionInt === -1
    );

    if (result === 0 && secondaryCompare) {
      return secondaryCompare(a, b);
    }

    return result;
  };
}

export const numericValueCompare: AgGridComparator = (
  a: string | number,
  b: string | number,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean
): number => {
  const aParsed = fromLocaleNumber(a);
  const bParsed = fromLocaleNumber(b);
  if ((isNull(aParsed) && isNull(bParsed)) || aParsed === bParsed) {
    return 0;
  }
  if (isNull(aParsed)) {
    // non-numbers always last
    return 1;
  }
  if (isNull(bParsed)) {
    // non-numbers always last
    return -1;
  }
  if (isInverted) {
    return bParsed - aParsed;
  }
  return aParsed - bParsed;
};

function _createStringCompare<T>(
  dataKey: keyof T,
  directionInt: DirectionInt,
  secondaryCompare: CompareFn<T>
): CompareFn<T> {
  const dashes = ['\u2014', '&#8212;', '-', '—', ''];
  return (a: T, b: T): number => {
    const aVal = (get(a, dataKey) || '').toString();
    const bVal = (get(b, dataKey) || '').toString();
    // depending on direction, make sure dashes or empty always last / bottom
    if (dashes.includes(aVal)) {
      // dashes or empty always last
      return 1;
    }
    if (dashes.includes(bVal)) {
      // dashes or empty always last
      return -1;
    }
    // string compare
    const result = aVal.localeCompare(bVal) * directionInt; // NB: by default, localeCompare() is case-insensitive
    if (result === 0 && secondaryCompare) {
      return secondaryCompare(a, b);
    }
    return result;
  };
}

/**
 * @param directionInt else 'ASC'
 * @param format dayjs date format
 */
function _createDateCompare<T>(
  dataKey: keyof T,
  directionInt: DirectionInt,
  format: string,
  secondaryCompare?: CompareFn<T>
): CompareFn<T> {
  return (a: T, b: T): number => {
    const aParsed = dayjs(get(a, dataKey), format).valueOf();
    const bParsed = dayjs(get(b, dataKey), format).valueOf();
    if ((isNaN(aParsed) && isNaN(bParsed)) || aParsed === bParsed) {
      return secondaryCompare ? secondaryCompare(a, b) : 0;
    }
    if (isNaN(aParsed)) {
      // non-numbers (non-dates) always last
      return 1;
    }
    if (isNaN(bParsed)) {
      // non-numbers (non-dates) always last
      return -1;
    }
    return (aParsed - bParsed) * directionInt;
  };
}

export const floatComparator: AgGridComparator = (
  value1: string,
  value2: string,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted
): number => {
  const aParsed = fromLocaleNumber(value1);
  const bParsed = fromLocaleNumber(value2);
  if ((isNull(aParsed) && isNull(bParsed)) || aParsed === bParsed) {
    return 0;
  }
  if (isNull(aParsed)) {
    // non-numbers always last
    return isInverted ? -1 : 1;
  }
  if (isNull(bParsed)) {
    // non-numbers always last
    return isInverted ? 1 : -1;
  }
  return aParsed - bParsed;
};

export function createDateComparator(format: string): AgGridComparator {
  return (
    value1: string,
    value2: string,
    nodeA: RowNode,
    nodeB: RowNode,
    isInverted: boolean
  ): number => {
    const aParsed = dayjs(value1, format).valueOf();
    const bParsed = dayjs(value2, format).valueOf();
    if ((isNaN(aParsed) && isNaN(bParsed)) || aParsed === bParsed) {
      return 0;
    }
    if (isNaN(aParsed)) {
      // non-numbers always last
      return isInverted ? -1 : 1;
    }
    if (isNaN(bParsed)) {
      // non-numbers always last
      return isInverted ? 1 : -1;
    }
    return aParsed - bParsed;
  };
}

export const stringCompare: AgGridComparator = (
  value1: string,
  value2: string
): number => {
  const dashes = ['\u2014', '&#8212;', '-', '—', ''];
  // depending on direction, make sure dashes or empty always last / bottom
  if (dashes.includes(value1)) {
    // dashes or empty always last
    return 1;
  }
  if (dashes.includes(value2)) {
    // dashes or empty always last
    return -1;
  }

  return value1.localeCompare(value2);
};

// Handled literature related  sorting
export const literatureSort = (
  valueA: string,
  valueB: string,
  nodeA?: any,
  nodeB?: any,
  isInverted?: boolean
): number => {
  // For handling mobile literature data
  const dataA = nodeA?.data || nodeA;
  const dataB = nodeB?.data || nodeB;
  // Sort by ticker on the basis of matchesOnTicker property.
  if (dataA?.matchesOnTicker > dataB?.matchesOnTicker) {
    return -1;
  }

  if (dataA?.matchesOnTicker < dataB?.matchesOnTicker) {
    return 1;
  }

  // string compare: if equal, sort alphabetically instead.
  const compareValue = stringCompare(valueA, valueB, nodeA, nodeB);

  // Added secondary level sorting on publication date(frkReferenceDate) for same document name.
  if (!compareValue) {
    const publicationDate1 = new Date(
      dataA?.literatureData?.frkReferenceDate
    ).getTime();
    const publicationDate2 = new Date(
      dataB?.literatureData?.frkReferenceDate
    ).getTime();
    return publicationDate2 - publicationDate1;
  } else {
    return compareValue;
  }
};

/* trying to sort product name based on age*/
export function getNameForSorting(name: string): string {
  /**
   * Replacing Newborn with Age 0 as it is considered as recent
   */
  name = name.indexOf('Newborn') > -1 ? name.replace('Newborn', 'Age 0') : name;
  const regex = /(.*?)(^(.*?)?Based\s+(\d|[a-zA-Z])+|Age\s+\d+|Age)/m;
  const match = regex.exec(name);

  if (match && match[0]) {
    return match[0].trim();
  } else {
    return name; // Return full name if no match
  }
}
