import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { DynamicTableColDef } from '@frk/eds-components';
import { CouldFieldData, Courses } from '@marketing/events/events.type';

interface TopicsColDef {
  item: string;
  val?: string;
}

@Component({
  selector: 'ft-digital-catalog-card',
  templateUrl: './digital-catalog-card.component.html',
  styleUrls: ['./digital-catalog-card.component.scss'],
})
export class DigitalCatlogCardComponent {
  @Input() course?: Courses;
  @Input() hideFilterValues = false;
  @Input() isCategoryLink: boolean;
  @Output() filterUpdate = new EventEmitter<CouldFieldData>();

  public colDefs: DynamicTableColDef<TopicsColDef>[] = [
    {
      field: 'item',
      headerName: '',
    },
    {
      field: 'val',
      headerName: '',
    },
  ];

  public updateFilter(event: Event, type: string, field?: string): void {
    event.preventDefault();
    this.filterUpdate.emit({
      type,
      field,
    });
  }
}
