<main>
  <section edsGrid edsSpacing="lg">
    <div class="container container--wide">
      <div edsSpacing="md" edsRow edsFlexbox justifyContent="space-between">
        <div edsCol edsSubGridGap="md" edsSubGridTabletGap="md">
          <eds-enhanced-filter
            [filters]="filters"
            [chipsFilter]="chipsFilters"
            [resetText]="'common.reset-filter' | translate"
            (dropdownSelected)="onSelectFilter($event)"
            (resetTriggered)="resetFilter($event)"
            (chipRemovedTriggered)="chipRemoved($event)"
          ></eds-enhanced-filter>

          <div edsPadding [edsPaddingTop]="'md'" edsSpacing="lg">
            <div *ngIf="visibleEvents.length" id="eventScrollPosition">
              <ng-container
                [ngTemplateOutlet]="eventCard"
                [ngTemplateOutletContext]="{ eventDetails: visibleEvents }"
              ></ng-container>
            </div>

            <!--Pagination-->
            <div
              edsFlexbox
              alignItems="center"
              justifyContent="space-between"
              edsSpacing="lg"
              *ngIf="!hidePagination && filteredEvents.length > pageSize.value"
            >
              <div edsSpacing="none">
                <eds-pagination
                  [prevPageText]="'products.previous' | translate"
                  [nextPageText]="'products.next' | translate"
                  [pages]="totalPages"
                  [pageTitle]="pageTitle"
                  [activePage]="currentPageNumber$ | async"
                  (pageChanged)="onPageChange($event)"
                  [ariaLabelGoToPage]="'common.ariaLabelGoToPage' | translate"
                  [ariaLabelPageNav]="'common.navigationLandmark' | translate"
                ></eds-pagination>
              </div>

              <eds-dropdown
                [attr.aria-label]="'products.dropdown' | translate"
                [dropdownId]="'ItemsPerPageEventsDropdown'"
                [label]="'common.itemsPerPageLbl' | translate"
                [items]="itemsPerPageOption"
                [preselectedValue]="pageSize"
                (dropdownChange)="onDropdownChange($event)"
                edsPadding
                edsPaddingLeft="none"
              ></eds-dropdown>
            </div>
            <!-- TODO Component for no event-->
            <div *ngIf="filteredEvents.length === 0">
              {{ "common.no-event" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<!--
  Event Card Templete
-->
<ng-template #eventCard let-eventDetails="eventDetails">
  <div edsRow edsSpacing="md">
    <div edsCol>
      <div
        edsSubGrid="layout-2"
        edsSubGridTabletGap="sm"
        edsSubGridTablet="layout-1"
        edsSubGridGap="sm"
        edsSubGridRowGap="sm"
      >
        <div *ngFor="let event of eventDetails">
          <ft-event-info [event]="event"></ft-event-info>
        </div>
      </div>
    </div>
  </div>
</ng-template>
