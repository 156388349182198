import { Injectable, OnDestroy } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import {
  DropdownItem,
  NavigationService,
  RoleSelector,
} from '@frk/eds-components';
import { TranslateService } from '@shared/translate/translate.service';
import { Segment, SegmentId, SiteParams } from '@types';
import { Logger } from '@utils/logger';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppStateService } from './app-state.service';
import { GlobalConfigService } from './global-config-service';
import { UserAgentService } from './user-agent.service';
import { ProfileService } from './profile.service';
import { Selector } from './role-selector.enum';
import { SegmentService } from './segment.service';
import { SignInService } from './sign-in.service';
import { StorageService } from './storage.service';

const logger = Logger.getLogger('RoleSelectorService');

/**
 * A service that provides data and process events for role selector components
 */
@Injectable({
  providedIn: 'root',
})
export class RoleSelectorService implements OnDestroy {
  // this is used by routing guard to pause leaving a page until segment is set (or other conditions apply)
  private isDeactivatingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private unsubscribe$: Subject<void> = new Subject<void>();
  private params: SiteParams;
  private currentSegmentId: SegmentId;

  public modalRoleSelectorRequested$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private appStateService: AppStateService,
    private segmentService: SegmentService,
    private storageService: StorageService,
    private profileService: ProfileService,
    private navService: NavigationService,
    private signInService: SignInService,
    private prerenderService: UserAgentService,
    private translateService: TranslateService,
    private pageConfigService: GlobalConfigService
  ) {
    this.pageConfigService
      .getGlobalConfigSubject$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        // the reason why we read from getSiteParams and not from getGlobalConfigSubject$ is type incompatibility
        // SiteParams, Global Site COnfig and thye in EDS are not in sync.
        // it requires refactoring in the future
        // this line should resolve 'config' and read config.siteConfiguration.siteParams
        this.params = this.pageConfigService.getSiteParams();
      });

    this.segmentService
      ?.getCurrentSegmentId$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((segmentId: SegmentId) => {
        this.currentSegmentId = segmentId;
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getCanDeactivate(
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    logger.debug(
      'getCanDeactivate()',
      currentState,
      nextState,
      this.prerenderService.isPrerender(),
      !this.storageService.isStorageEnabled(),
      this.appStateService.isHomePage(),
      !this.appStateService.getGatewayUrl()
    );
    // check if this is just a fragment change
    // NB: this needs to run before setting isDeactivatingSubject$
    const oldBaseUrl: string = currentState.url.replace(/#(.)*/gm, '');
    const newBaseUrl: string = nextState.url.replace(/#(.)*/gm, '');
    logger.debug(
      '#fragment change?',
      oldBaseUrl,
      newBaseUrl,
      oldBaseUrl === newBaseUrl
    );
    if (oldBaseUrl === newBaseUrl) {
      // base section of url is the same i.e. just a #fragment change
      // so route is ok to complete
      return true;
    }

    // NB: although we never turn this back off, it doesn't seem to be an issue for now
    this.isDeactivatingSubject$.next(true);

    // initial quick check to see if route is ok to complete immediately
    if (
      this.prerenderService.isPrerender() ||
      !this.storageService.isStorageEnabled() ||
      this.appStateService.isHomePage() ||
      !this.appStateService.getGatewayUrl()
    ) {
      return true;
    }

    // otherwise, route can complete once segment has been set
    return this.segmentService.isSegmentSet$().pipe(
      // this filters out false values, which would cancel the route completely instead of just delaying
      filter((isSet: boolean): boolean => isSet)
    );
  }

  /**
   * base observable method
   * indicates if the Role Selector banner/modal/cards component should display
   */
  private showRoleSelector$(): Observable<boolean> {
    /* only run code if
      is NOT pre-render
      AND segment is not set
      AND local storage is available in the browser
      AND gateway-url is configured for site
    */
    return this.segmentService
      .isSegmentSet$()
      .pipe(
        map(
          (isSet: boolean): boolean =>
            !this.prerenderService.isPrerender() &&
            this.storageService.isStorageEnabled() &&
            this.appStateService.getGatewayUrl() &&
            !isSet
        )
      );
  }

  /**
   * indicates if the Role Selector banner component should display
   * NB: this adds additional logic to showRoleSelector$()
   */
  public showRoleSelectorBanner$(): Observable<boolean> {
    /* only run code if
      is NOT pre-render
      AND local storage is available in the browser
      AND gateway-url is configured for site
      AND user is on homepage
      AND user is on gateway page
    */
    return this.showRoleSelector$().pipe(
      map(
        (showRole: boolean): boolean =>
          // if home page than show role selector banner
          this.appStateService.isHomePage() &&
          this.appStateService.getIsGateway()
      )
    );
  }

  /**
   * indicates if the Role Selector Modal component should display before next route
   * NB: this adds additional logic to showRoleSelector$()
   */

  public showRoleSelectorModal$(): Observable<boolean> {
    /* only return true if
      is NOT prerender
      AND segment is not set
      AND local storage is available in the browser
      AND gatewayurl is configured for site (i.e. gateway is enabled)
      AND we're NOT on homepage
    */
    return combineLatest([
      this.showRoleSelector$(),
      this.isDeactivatingSubject$,
      this.modalRoleSelectorRequested$,
    ]).pipe(
      map(
        ([showSelector, isDeactivating, modalRoleSelectorRequested]: [
          boolean,
          boolean,
          boolean
        ]): boolean =>
          // if not home page than show role selector popup
          (showSelector &&
            isDeactivating &&
            !this.appStateService.isHomePage()) ||
          modalRoleSelectorRequested
      )
    );
  }

  /**
   * indicates if the Role Selector cards component should display
   * Note: currently this is just an alias to showRoleSelector$() base method, as logic is the same
   */
  public showRoleSelectorCards$(): Observable<boolean> {
    /* only run code if
      is NOT prerender
      AND segment is not set
      AND local storage is available in the browser
      AND gatewayurl is configured for site
    */
    return this.showRoleSelector$();
  }

  /**
   * this returns an observable with the data for the eds role selector component
   * As an observable, this won't fire until the segments are set, so we avoid possible race conditions
   */
  public getRoleSelectorOptions$(): Observable<RoleSelector> {
    return this.segmentService.getSegments$().pipe(
      map(
        (segments: Segment[]): RoleSelector => {
          const roleOptions: DropdownItem[] = segments
            .map(
              (segment: Segment): DropdownItem => {
                if (!segment.isHiddenInBanner) {
                  return {
                    value: segment.id,
                    label: segment.label,
                    subLabel: segment.subLabel,
                  };
                }
              }
            )
            .filter(Boolean);
          return {
            label: this.translateService.instant(
              `signin.roleSelectorBanner-selectRole`
            ),
            name: 'roleSelector',
            items: roleOptions,
            preselectedValue: roleOptions[0],
            multi: false,
            required: false,
          };
        }
      )
    );
  }

  /**
   * optional banner background image
   */
  public getBannerImage(): string {
    if (this.params.personalizeBannerImage) {
      return this.params.personalizeBannerImage;
    }
    return '';
  }

  /**
   * transparency settings
   */
  public getTransparencyRatio(): '40' | '60' | '50' {
    if (this.params.personalizeBannerTransparencyRatio) {
      return this.params.personalizeBannerTransparencyRatio;
    }
    return '50';
  }

  /**
   * transparency setting
   */
  public isBgTransparent(): boolean {
    if (this.params.personalizeBannerTransparency) {
      return this.params.personalizeBannerTransparency;
    }
    return false;
  }

  /**
   * Return banner title if it's set in site config, otherwise it uses translated label
   */
  public getBannerTitle(): string {
    if (
      this.params.personalizeBannerTitleOverrideLoggedIn &&
      this.profileService.isLoggedIn()
    ) {
      return this.params.personalizeBannerTitleOverrideLoggedIn;
    }

    if (this.params.personalizeBannerTitleOverride) {
      return this.params.personalizeBannerTitleOverride;
    }

    const titleLabelLoggedIn: string = this.translateService.instant(
      `signin.roleSelectorBanner-personaliseTitleLoggedIn`
    );

    if (
      !this.isLabelPlaceholder(titleLabelLoggedIn) &&
      this.profileService.isLoggedIn()
    ) {
      return titleLabelLoggedIn;
    }

    const titleLabel: string = this.translateService.instant(
      `signin.roleSelectorBanner-personaliseTitle`
    );

    // To not break existing functionality we need to hide label placeholder for Title
    if (!this.isLabelPlaceholder(titleLabel)) {
      return titleLabel;
    }

    return null;
  }

  /**
   * Return banner preTitle if it's set in site config, otherwise it uses translated label
   */
  public getBannerPreTitle(): string {
    if (
      this.params.personalizeBannerPreTitleOverrideLoggedIn &&
      this.profileService.isLoggedIn()
    ) {
      return this.params.personalizeBannerPreTitleOverrideLoggedIn;
    }

    if (this.params.personalizeBannerPreTitleOverride) {
      return this.params.personalizeBannerPreTitleOverride;
    }

    const preTitleLabelLoggedIn: string = this.translateService.instant(
      `signin.roleSelectorBanner-personaliseYourExperienceLoggedIn`
    );

    if (
      !this.isLabelPlaceholder(preTitleLabelLoggedIn) &&
      this.profileService.isLoggedIn()
    ) {
      return preTitleLabelLoggedIn;
    }

    const preTitleLabel: string = this.translateService.instant(
      `signin.roleSelectorBanner-personaliseYourExperience`
    );
    // To not break existing functionality we need to hide label placeholder for Title
    if (!this.isLabelPlaceholder(preTitleLabel)) {
      return preTitleLabel;
    }
    return null;
  }

  /**
   * Return banner preTitle if it's set in site config, otherwise it uses translated label
   */
  public getBannerSummary(): string {
    if (this.params.summaryLoggedIn && this.profileService.isLoggedIn()) {
      return this.params.summaryLoggedIn;
    }

    if (this.params.summary) {
      return this.params.summary;
    }

    const summaryLabelLoggedIn: string = this.translateService.instant(
      `signin.roleSelectorBanner-summaryLoggedIn`
    );

    if (
      !this.isLabelPlaceholder(summaryLabelLoggedIn) &&
      this.profileService.isLoggedIn()
    ) {
      return summaryLabelLoggedIn;
    }

    const summaryLabel: string = this.translateService.instant(
      `signin.roleSelectorBanner-summary`
    );
    // To not break existing functionality we need to hide label placeholder for Title
    if (!this.isLabelPlaceholder(summaryLabel)) {
      return summaryLabel;
    }
    return null;
  }

  /**
   * returns true if returned value is not label placeholder
   * @param label label string
   */
  private isLabelPlaceholder(label: string): boolean {
    return label.match(/\[.*\]/g) !== null;
  }

  /**
   * sends request to open role selector modal immediately
   */
  public openRoleSelectorModal() {
    this.modalRoleSelectorRequested$.next(true);
  }

  /**
   * sends request top close role selector modal immediately
   */
  public closeRoleSelectorModal() {
    this.modalRoleSelectorRequested$.next(false);
  }

  // sets user preferences
  public confirmSelection(segmentId: SegmentId, selector?: Selector): void {
    logger.debug('confirmSelection', segmentId);
    const segment: Segment = this.segmentService.getSegment(segmentId);

    // disable modal opening request after role selected
    // to consider if we need to all this always when selection confirmed or only if value is true
    this.onModalClose();

    // if new segment is the same as current segment, then return
    if (segmentId === this.currentSegmentId) {
      logger.debug('selected the same segment from banner', segmentId);
      this.profileService.triggerProfileUpdate(segmentId);
      return;
    }

    if (segment.externalLink) {
      logger.debug('redirect to segment external link', segment.externalLink);
      this.navService.navigateByUrl(segment.externalLink, '_self');
      return;
    }

    // WDE-444 removing condition. We need to logout always when changing profile
    if (
      this.profileService.isLoggedIn()
      // && this.profileService.getUserProfile().loginSource === LoginSource.OAUTH
    ) {
      // this also changes segment, so we don't need to call setSegment for logged user
      this.signInService.signOut(segment);
      return;
    }

    this.segmentService.setSegment(segmentId, true);
  }

  // prevents modal opening after closed
  public onModalClose(): void {
    this.modalRoleSelectorRequested$.next(false);
  }
}
