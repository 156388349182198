import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SignInComponentFieldsInfo } from '@frk/eds-components';
import { Logger } from '@utils/logger';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { AppStateService } from './app-state.service';
import { StorageService } from './storage.service';
import { initialize, Page } from '@bloomreach/spa-sdk/';

const logger = Logger.getLogger('GatewayModalService');

@Injectable({
  providedIn: 'root',
})
export class GatewayModalService {
  private signInSuccess: boolean;
  // MD5 hashed credentials Array
  private signInHashArray = [
    '21dfc99a6d90af4afbc0e738217f46db', // de-de channel
    '7dbaa5e7faafed1a2706e23b8d650dbc', // de-at channel
    '9987d4bf66351870086a88d531710f9f', // nl-be channel
    '07b630a1d9daecc68069c23e3f0bf7fe', // fr-be channel
    '55490c13675f97c17f87d5b76bdf9d9b', // en-os channel advisor
    '6088636797d7f86b3626fbc20c3455a2', // es-os channel advisor
    '596503017eb4a45e0f14f23b8ddf891c', // pt-os channel advisor
    '5381a31ca3e27e7151228d4c91beadaf', // en-os channel sh
    'adf91401ecfbdf0aa9a38e8633244923', // es-os channel sh
    '57298b28c5bf0df255c4e3d0c251ff4e', // pt-os channel sh
    '9786eb1271f27b5612a92f065bc6e62a', // pl-pl channel
  ];

  private showSignInGatewayModalSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  private scrollElement: HTMLCollectionOf<HTMLBodyElement>;

  constructor(
    private http: HttpClient,
    private appState: AppStateService,
    private storageService: StorageService
  ) {}

  /**
   * Get Sign In Success
   */
  public getSignInSuccess(): boolean {
    return this.signInSuccess;
  }

  /**
   * Submit Gateway modal form
   * @param submitObject - Analytics Login object
   */
  public onSubmit(signInForm: FormGroup): void {
    const submittedHashKey = this.storageService.getHashKey(signInForm);
    logger.debug('Login hashed key', submittedHashKey);
    this.setSignInSuccess(submittedHashKey);
  }

  /**
   * Set SignIn Gateway Modal Subject
   */
  public getSignInGatewayModal$(): Observable<boolean> {
    return this.showSignInGatewayModalSubject$.asObservable();
  }

  /**
   * Hide Sign In Gateway Modal
   */
  public hideSignInGatewayModal(): void {
    this.storageService.store(this.getSignInStoreName(), true);
    this.enableScrollForBodyElement();
    this.showSignInGatewayModalSubject$.next(false);
  }

  /**
   * Check if we have local storage cookie for sign in modal.
   */
  public checkSignInGatewayModal(): void {
    if (this.getSignInGatewayModal$()) {
      this.storageService.retrieve(this.getSignInStoreName()).then((value) => {
        if (value) {
          this.showSignInGatewayModalSubject$.next(false);
          this.enableScrollForBodyElement();
          return;
        }
        this.showSignInGatewayModalSubject$.next(true);
      });
    }
  }

  /**
   * Set parameters which shouldn't be display to null.
   * @param signInComponentContent - signIn content to clean
   */
  public cleanGatewaySignIn(signInComponentContent: SignInComponentFieldsInfo) {
    signInComponentContent.ctaText = null;
    signInComponentContent.orText = null;
    signInComponentContent.forgotIdLink = null;
    signInComponentContent.forgotPasswordLink = null;
    signInComponentContent.bodyContent = null;
    signInComponentContent.subTitle = null;
    return signInComponentContent;
  }

  /**
   * Set body element for service
   * @param element - HTMLBodyElement
   */
  public setScrollBodyElement(
    element: HTMLCollectionOf<HTMLBodyElement>
  ): void {
    this.scrollElement = element;
  }

  /**
   * Enable scrolling
   */
  private enableScrollForBodyElement(): void {
    this.scrollElement[0].style.overflow = 'auto';
  }

  /**
   * Get local storage cookie name for Gateway sign-in
   */
  private getSignInStoreName(): string {
    return 'gateway_signin_' + this.appState.getChannel();
  }

  /**
   * Set Sign In Success
   * @param submittedHashKey - hash key from submitted values
   */
  private setSignInSuccess(submittedHashKey: string | Int32Array): void {
    this.signInSuccess = !!this.signInHashArray.find((hashKey: string) => {
      return hashKey === submittedHashKey;
    });
  }

  /**
   * Get Gateway modal content from Brxm
   * @param segmentId - Segment ID
   */
  public getBrConfiguration$(modalPathInConfig: string): Observable<Page> {
    return from(
      initialize({
        ...this.appState.getBrConfiguration(),
        path: modalPathInConfig,
      })
    );
  }

  /**
   *
   * @param customTermsPath retrieves relative path from T&C document path
   * @returns relative string
   */
  public getRelativeTermsPath(customTermsPath: string): string {
    const index = customTermsPath.indexOf('/modals/');
    return index !== -1 ? customTermsPath.substring(index) : '';
  }
}
