import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableSelectionComponent } from './table-selection.component';
import {
  DynamicModule,
  ComponentMapping,
} from '@services/module-loader.config';
import {
  FlexboxModule,
  IconModule,
  LinkModule,
  GridModule,
  InfoTableSelectionModule,
  PaddingModule,
  PanelModule,
  SpacingModule,
} from '@frk/eds-components';
import { TableSelectionCardComponent } from './table-selection-card/table-selection-card.component';

@NgModule({
  declarations: [TableSelectionComponent, TableSelectionCardComponent],
  imports: [
    FlexboxModule,
    CommonModule,
    IconModule,
    LinkModule,
    PaddingModule,
    SpacingModule,
    PanelModule,
    GridModule,
    InfoTableSelectionModule,
  ],

  exports: [TableSelectionComponent],
  entryComponents: [TableSelectionComponent],
})

/**
 * Table Module
 */
export class TableSelectionModule implements DynamicModule {
  getComponentMapping(): ComponentMapping {
    return {
      'Table Selection': TableSelectionComponent,
    };
  }
}
