import { TranslateService } from '@shared/translate/translate.service';
import { Injectable } from '@angular/core';
import { Logger } from '@utils/logger';
import { ResourceBundleModels } from '@types';
import { DebugService } from '@services/debug.service';
import { SiteConfigService } from '@services/site-config.service';
import { AccountsLabels } from '@accounts/accounts-alerts/accounts-alerts.interface';
import { EnvConfig, EnvConfigService } from '@services/env-config.service';

const logger = Logger.getLogger('LabelLoader');

export interface TranslateLabels {
  products?: any;
  pds?: any;
  literature?: any;
  signin?: any;
  common?: any;
  accountsNotification?: AccountsLabels;
  languages?: any;
  dynamicContent?: any;
  subscriptions?: any;
  customForm?: any;
  strategy?: any;
}

@Injectable({
  providedIn: 'root',
})
export class LabelLoader {
  static showKeys = false;
  private readonly envConfig: EnvConfig;

  constructor(
    private translateService: TranslateService,
    private envConfigService: EnvConfigService,
    private debugService: DebugService,
    private siteConfig: SiteConfigService
  ) {
    this.debugService
      .isShowLabelKeys$()
      ?.subscribe((flag) => (LabelLoader.showKeys = flag));

    // loading config
    if (this.envConfigService) {
      this.envConfig = this.envConfigService.getEnvConfig();
    }
    // TODO: Get profile information to cover PROFILE tokens in labels.
  }

  /**
   * To set labels for translation
   * Extract the resourcebundles for labels from models Object
   * and pass it to setTranslation method of NGX-Translate with namespaces
   * @param models ResourceBundleModels Object
   */
  setLabelTranslation(models: ResourceBundleModels) {
    // check to see if they are already loaded.
    if (!this.translateService.isLoaded()) {
      const productLabels = models['ft.core.products'];
      const literatureLabels = models['ft.core.literatures'];
      const signinLabels = models['ft.core.signin'];
      const commonLabels = models['ft.core.common'];
      const accountsNotificationLabels = models['ft.core.accountsNotification'];
      const languageLabels = models['ft.core.languages'];
      const dynamicContentLabels = models['ft.core.dynamic-content'];
      const subscriptionsLabels = models['ft.core.subscriptions'];
      const strategyLabels = models['brandywine.core.strategy'];

      const translateLabels: TranslateLabels = {
        products: productLabels,
        literature: literatureLabels,
        signin: signinLabels,
        common: commonLabels,
        accountsNotification: accountsNotificationLabels,
        languages: languageLabels,
        dynamicContent: dynamicContentLabels,
        subscriptions: subscriptionsLabels,
        strategy: strategyLabels,
      };
      this.addLabels(translateLabels);
    }
  }

  loadPdsTranslations(pdsLabels: Record<string, string>) {
    this.addLabels({ pds: pdsLabels }, false);
    logger.debug('PDS Labels loaded');
  }

  public loadCustomFormTranslations(
    customFormLabels: Record<string, string>
  ): void {
    this.addLabels({ customForm: customFormLabels }, false);
  }

  loadLabelOverrides() {
    this.translateService.setFundOverrides(
      this.siteConfig.product?.general?.labelOverrideList
    );
  }

  public appendComponentTranslations(
    componentLabels: Record<string, string>,
    resourceBundleName: string
  ): void {
    const labelsObject = {
      [resourceBundleName]: componentLabels,
    };
    this.addLabels(labelsObject, false);
  }

  private addLabels(labels: TranslateLabels, ignoreOverrides = true) {
    // if showKeys then let MissingLabelHandler kick in.
    if (!LabelLoader.showKeys) {
      this.translateService.setTranslation(
        labels,
        ignoreOverrides,
        this.envConfig
      );
    }
  }
}
