<div class="debug" edsPadding *ngIf="isDebugWidgetEnabled">
  <h4>Testing</h4>
  <button
    (click)="toggleExpanded()"
    edsButton="icon-only"
    size="normal"
    edsSpacing="md"
  >
    <eds-icon
      [type]="isExpanded ? 'carrot-up' : 'carrot-down'"
      size="small"
    ></eds-icon>
    <span edsPadding edsPaddingLeft="sm">
      {{ isExpanded ? "Collapse Testing" : "Expand Testing" }}
    </span>
  </button>
  <ng-container *ngIf="isExpanded">
    <!-- Toggle -->
    <div edsSpacing="md">
      <eds-toggle-v2
        [toggleButtons]="toggleButtons"
        (toggleActive)="onFilterToggle($event)"
      >
      </eds-toggle-v2>
    </div>
    <div class="grid debug__container">
      <div class="row">
        <!-- Profile -->
        <ng-container *ngIf="activeToggle === 'profile'">
          <div class="col" *ngIf="testProfiles?.length">
            <h5>Set User Profile</h5>
            <select
              class="debug__select select-option"
              [(ngModel)]="testProfileId"
              #profileSelector
              (change)="changeProfile(profileSelector.value)"
            >
              <option
                class="option"
                *ngFor="let profile of testProfiles"
                [value]="profile.name"
              >
                {{ profile.name }}
              </option>
            </select>
            <br />
            <button
              (click)="forgetMe()"
              title="clear profile and become anonymous"
            >
              Forget Me
            </button>
          </div>
          <div class="col">
            <h5>Profile</h5>

            <eds-info-item
              [info]="{
                label: 'Profile role',
                val: (profile$ | async)?.profileInfo?.role
              }"
            ></eds-info-item>
            <h6>Profile Key:</h6>
            <pre>{{ profileKey$ | async }}</pre>
          </div>
        </ng-container>
        <!-- Debug -->
        <ng-container *ngIf="activeToggle === 'debug'">
          <div class="col">
            <button
              (click)="clear()"
              title='add"?debug=web-platform" to url again to see again after'
            >
              Clear Settings and Hide
            </button>
            <br />

            <label>
              <input
                type="checkbox"
                [checked]="debugCaveats$ | async"
                (click)="toggleCaveatDebug()"
              />
              Debug Caveats </label
            ><br />

            <label>
              <input
                type="checkbox"
                [checked]="showDummyCaveats$ | async"
                (click)="toggleShowDummyCaveats()"
              />
              Show Dummy Caveats </label
            ><br />

            <label>
              <input
                type="checkbox"
                [checked]="debugPortfolio$ | async"
                (click)="togglePortfolioDebug()"
              />
              Debug Portfolio Components</label
            ><br />

            <label>
              <input
                type="checkbox"
                [checked]="showTooltipKeys$ | async"
                (click)="toggleShowTooltipKeys()"
                [disabled]="showTooltipKeysDisabled"
              />
              Show Tooltip Keys </label
            ><br />

            <label>
              <input
                type="checkbox"
                [checked]="showLabelKeys$ | async"
                (click)="toggleShowLabelKeys()"
                [disabled]="showLabelKeysDisabled"
              />
              Show Label Keys </label
            ><br />
            <label>
              <input
                type="checkbox"
                [checked]="showInsightsData$ | async"
                (click)="toggleShowInsightsData()"
                [disabled]="showInsightsDataDisabled"
              />
              Show Insights Tags</label
            ><br />
            <label>
              <input
                type="checkbox"
                [checked]="showInsightsReport$ | async"
                (click)="toggleShowInsightsReport()"
                [disabled]="showInsightsReportDisable"
              />
              Show Insights Report</label
            ><br />

            <label>
              <input
                type="checkbox"
                [checked]="debugExpandAll$ | async"
                (click)="toggleExpandAll()"
              />
              Expand All Components </label
            ><br />

            <button (click)="showLabels()">Show Loaded Labels</button>

            <br />
            <pre *ngIf="this.labels" class="debug__labels">{{
              this.labels | json
            }}</pre>
          </div>
        </ng-container>
        <!-- Misc -->
        <ng-container *ngIf="activeToggle === 'misc'">
          <div class="col">
            <h5>Page Loading</h5>
            <div edsSpacing="md">
              <eds-info-item
                [info]="{
                  label: 'Page Ready',
                  val: (isPageReady$ | async)
                }"
              ></eds-info-item>
              <!-- TODO: async component monitoring isn't fully implemented yet -->
              <eds-info-item
                [info]="{
                  label: 'Async Components Loaded',
                  val: (asyncComponentsReady$ | async)
                }"
              ></eds-info-item>
              <eds-info-item
                [info]="{
                  label: 'Images Loaded',
                  val: (imagesReady$ | async)
                }"
              ></eds-info-item>
            </div>
            <div>
              <table class="table">
                <thead class="table__thead">
                  <tr class="table__tr">
                    <th class="table__th">Component</th>
                    <th class="table__th">Type</th>
                    <th class="table__th">Status</th>
                    <th class="table__th">Section</th>
                    <th class="table__th">Duration (ms)</th>
                  </tr>
                </thead>
                <tbody class="table__body">
                  <tr
                    class="table__tr"
                    *ngFor="let cmp of pageComponentsStatus$ | async"
                  >
                    <td class="table__td">{{ cmp.name }}</td>
                    <td class="table__td">
                      {{ cmp.isAsync ? "async" : "sync" }}
                    </td>
                    <td class="table__td">{{ cmp.status }}</td>
                    <td class="table__td">{{ cmp.sectionId }}</td>
                    <td class="table__td">{{ cmp.duration }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
