import {
  GridModule,
  ButtonModule,
  PullQuoteAvatarModule,
} from '@frk/eds-components';

import { BrSdkModule } from '@bloomreach/ng-sdk';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlockquoteAvatarBlockComponent } from './blockquote-avatar-block.component';

@NgModule({
  declarations: [BlockquoteAvatarBlockComponent],
  imports: [
    BrSdkModule,
    ButtonModule,
    CommonModule,
    PullQuoteAvatarModule,
    GridModule,
  ],
  exports: [BlockquoteAvatarBlockComponent],
  entryComponents: [BlockquoteAvatarBlockComponent],
})

/**
 * Avatar Block Module
 */
export class BlockQuoteAvatarBlockModule {}
