import { Injectable } from '@angular/core';
import { AppStateService } from '@services/app-state.service';
import { StorageService } from '@services/storage.service';
import { CookieResponse, DocumentList, StorageItems } from '@types';
import { Logger } from '@utils/logger';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { LiteratureApiService } from './api-services/literature-api.service';

const logger = Logger.getLogger('LiteratureDocumentCartService');
@Injectable({ providedIn: 'root' })
export class LiteratureDocumentCartService {
  readonly storageKey = 'docs';
  subject: Subject<StorageItems[]> = new Subject<StorageItems[]>();
  cartCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  deletedDocSubject: Subject<string[]> = new Subject<string[]>();
  constructor(
    private appStateService: AppStateService,
    private storageService: StorageService,
    private literatureApiService: LiteratureApiService
  ) {
    this.init();
  }

  addToStorage(document: StorageItems[]) {
    this.storageService
      .retrieve<DocumentList[]>(this.storageKey)
      .then((docs: DocumentList[]) => {
        let data = document;
        if (docs) {
          data = [...docs, ...data];
        }
        data = this.handleDuplicateCartItems(data);
        this.storageService.store<StorageItems[]>(this.storageKey, data);
        this.cartCountSubject.next(data.length);
        this.setCartCookie(data?.map((item) => item.docId));
      });
  }
  removeFromStorage(docIds: string[]) {
    this.storageService
      .retrieve<DocumentList[]>(this.storageKey)
      .then((docs: DocumentList[]) => {
        if (docs) {
          docIds.forEach((docId: string) => {
            const index = docs.findIndex((doc) => doc.docId === docId);
            if (index > -1) {
              docs.splice(index, 1);
            }
          });
          this.storageService.store<StorageItems[]>(this.storageKey, docs);
          docs = this.handleDuplicateCartItems(docs);
          this.deletedDocSubject.next(docIds);
          this.cartCountSubject.next(docs.length);
          this.setCartCookie(docs?.map((item) => item.docId));
        }
      });
  }
  updateStorageItemQuantity(items: Map<string, number>) {
    this.storageService
      .retrieve<DocumentList[]>(this.storageKey)
      .then((docs: DocumentList[]) => {
        if (docs) {
          for (const item of docs) {
            const quantity = items.get(item.docId);
            item.quantity = quantity > 0 ? quantity : 0;
          }
          this.storageService.store<StorageItems[]>(this.storageKey, docs);
        }
      });
  }

  getFromStorage(): Promise<StorageItems[]> {
    return Promise.resolve(
      this.storageService
        .retrieve<StorageItems[]>(this.storageKey)
        .then((docs: StorageItems[]) => {
          if (docs) {
            return docs;
          }
        })
    );
  }
  emptyStorage() {
    this.storageService.remove(this.storageKey);
    this.cartCountSubject.next(0);
    this.setCartCookie([]);
  }
  init() {
    this.storageService
      .retrieve<StorageItems[]>(this.storageKey)
      .then((docs: DocumentList[]) => {
        if (docs) {
          docs = this.handleDuplicateCartItems(docs);
          this.cartCountSubject.next(docs.length);
        }
      });
  }

  /**
   * call the api to set cookie to overcome local-storage limitation over sub-domain request migration
   * @param docIds - set of cart items need to update in cookie
   */
  setCartCookie(docIds: string[]) {
    const url = this.appStateService.getLiteratureBaseApiUrl() + '/cart';
    this.literatureApiService.setCartCookie(url, { items: docIds }).subscribe(
      (response) => logger.info(response),
      (error) => logger.info(error)
    );
  }

  getCartCookie(): Observable<CookieResponse> {
    const baseUrl = this.appStateService.getLiteratureBaseApiUrl();
    const url = baseUrl + '/cart';
    return this.literatureApiService.getCartCookie(url);
  }
  /**
   * removes duplicate items added to cart
   * @param docs DocumentList[] - contains items added to cart
   * @returns DocumentList[] - unique items
   */
  handleDuplicateCartItems(docs: DocumentList[]): DocumentList[] {
    docs = docs.reduce((accumalator, current) => {
      if (!accumalator.some((item) => item.docId === current.docId)) {
        accumalator.push(current);
      }
      return accumalator;
    }, []);
    return docs;
  }
}
