import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
@Component({
  selector: 'ft-watchlist-cell-renderer',
  templateUrl: './watchlist-cell-renderer.component.html',
  styleUrls: ['./watchlist-cell-renderer.component.scss'],
})
export class WatchlistCellRendererComponent
  implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  unsubscribeDocuments() {}
}
