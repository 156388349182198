import { GridModule, ButtonModule, PullQuoteModule } from '@frk/eds-components';

import { BrSdkModule } from '@bloomreach/ng-sdk';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlockquoteBlockComponent } from './blockquote-block.component';

@NgModule({
  declarations: [BlockquoteBlockComponent],
  imports: [
    BrSdkModule,
    ButtonModule,
    CommonModule,
    PullQuoteModule,
    GridModule,
  ],
  exports: [BlockquoteBlockComponent],
  entryComponents: [BlockquoteBlockComponent],
})

/**
 * Avatar Block Module
 */
export class BlockQuoteBlockModule {}
