import dayjs from 'dayjs';
import frConfig from 'dayjs/locale/fr';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

dayjs.locale('fr-fr', { ...frConfig });
export const customFrFrConfig = dayjs.updateLocale('fr-fr', {
  ...frConfig,
  months: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split(
    '_'
  ),
  monthsShort: 'Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split(
    '_'
  ),
  formats: {
    ...frConfig.formats,
    L: 'DD.MM.YYYY',
  },
});
