<div edsGrid [ngClass]="{ 'has-edit-button': isEditMode }">
  <!-- New create/edit button -->
  <ng-container
    *ngIf="isEditMode"
    [brManageContentButton]="document"
    documentTemplateQuery="featured-content-card-document"
    folderTemplateQuery="featured-content-card-folder"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>
  <div [edsSpacing]="marginBottom ? marginBottom : 'xl'">
    <div class="container container--wide">
      <!-- Pre Title -->
      <div edsRow *ngIf="title">
        <div edsCol>
          <eds-section-title
            theme="basic"
            [preTitle]="preTitle"
            [title]="title"
          ></eds-section-title>
        </div>
      </div>

      <!-- 3 in a row layout -->

      <div edsRow>
        <div
          edsCol
          edsSubGrid="layout-2"
          edsSubGridTablet="layout-1"
          edsSubGridGap="md"
          edsSubGridTabletGap="md"
        >
          <div *ngFor="let card of cards">
            <eds-card-article
              [hasImage]="true"
              [imgSrc]="card.imgSrc"
              [altText]="'Image Details'"
              [title]="card.title"
              [date]="card.preTitle"
              [description]="card.content"
              [isCompact]="false"
              [isNewWindow]="card.isNewWindow"
              [href]="card.href"
              [signInRequired]="
                card.signInRequired | ftIsSignInRequired | async
              "
              [externalLink]="card.externalLink"
            >
            </eds-card-article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
