import merge from 'lodash/merge';
import { LoggerOutput } from './logging/logger-output';

export const enum LogLevel {
  DEBUG,
  INFO,
  WARN,
  ERROR,
  EVENT,
  NONE,
}

export interface LoggingConfiguration {
  defaultLevel?: LogLevel;
  defaultOutputs?: LoggerOutput[];
  loggerLevels?: {};
  loggerOutputs?: {};
}

export class Logger {
  // implements LoggerOutput {
  private static configuration: LoggingConfiguration = {
    defaultLevel: LogLevel.DEBUG,
    defaultOutputs: [console],
    loggerLevels: {},
    loggerOutputs: {},
  };
  private static loggers = new Map();

  name: string;
  level: number;
  outputs: LoggerOutput[];

  constructor(name: string) {
    this.name = name;
    this.configure();
  }

  public static setConfiguration(config: LoggingConfiguration) {
    Logger.configuration = config;
    Logger.loggers.forEach((logger) => logger.configure());
  }

  public static getConfiguration(): LoggingConfiguration {
    return Logger.configuration;
  }

  /**
   * add new configurations to be merged with existing configurations
   */
  public static addConfiguration(config: LoggingConfiguration) {
    Logger.configuration = merge(Logger.configuration, config);
    Logger.loggers.forEach((logger) => logger.configure());
  }

  public static getLogger(name: string): Logger {
    const logger = new Logger(name);
    Logger.loggers.set(name, logger);
    return logger;
  }

  public debug(...data: any): void {
    this.logMessage(LogLevel.DEBUG, data);
  }

  public willLogDebug(): boolean {
    return this.level === LogLevel.DEBUG;
  }

  public info(...data: any): void {
    this.logMessage(LogLevel.INFO, data);
  }

  public willLogInfo(): boolean {
    return this.level <= LogLevel.INFO;
  }

  public warn(...data: any): void {
    this.logMessage(LogLevel.WARN, data);
  }

  public willLogWarn(): boolean {
    return this.level <= LogLevel.WARN;
  }

  public error(...data: any): void {
    this.logMessage(LogLevel.ERROR, data);
  }

  public willLogError() {
    return this.level <= LogLevel.ERROR;
  }
  // added for appinsights
  public exception(...data: any): void {
    this.logMessage(LogLevel.ERROR, data);
  }
  public event(...data: any): void {
    this.logMessage(LogLevel.EVENT, data);
  }

  private logMessage(level: LogLevel, data) {
    const params = data;
    if (typeof params[0] === 'string') {
      params[0] = this.name + ': ' + String(params[0]);
    } else {
      params.unshift(this.name);
    }

    if (this.level <= level) {
      switch (level) {
        case LogLevel.DEBUG:
          this.outputs.forEach((output) => output.debug(...params));
          break;
        case LogLevel.INFO:
          this.outputs.forEach((output) => output.info(...params));
          break;
        case LogLevel.WARN:
          this.outputs.forEach((output) => output.warn(...params));
          break;
        case LogLevel.ERROR:
          this.outputs.forEach((output) => output.error(...params));
          break;
        case LogLevel.EVENT:
          this.outputs.forEach((output) => {
            // check because console.event() doesn't exist
            if (output.event) {
              output.event(...params);
            }
          });
          break;
        default: // dont log anything
      }
    }
  }

  private configure() {
    this.level = Logger.configuration.loggerLevels[this.name];
    if (this.level === undefined) {
      this.level = Logger.configuration.defaultLevel;
    }
    this.outputs = Logger.configuration.loggerOutputs[this.name];
    if (this.outputs === undefined) {
      this.outputs = Logger.configuration.defaultOutputs;
    }
  }
}
