import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ButtonModule,
  FlexboxModule,
  PaddingModule,
  SimpleModalModule,
  SpacingModule,
  TitleModule,
} from '@frk/eds-components';
import { TranslateModule } from '@shared/translate/translate.module';
import { ConfirmationModalComponent } from './confirmation-modal.component';

@NgModule({
  declarations: [ConfirmationModalComponent],
  imports: [
    CommonModule,
    TitleModule,
    ButtonModule,
    TranslateModule,
    FlexboxModule,
    PaddingModule,
    SimpleModalModule,
    SpacingModule,
  ],
  exports: [ConfirmationModalComponent],
})
export class ConfirmationModalModule {}
