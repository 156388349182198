<!-- Lead content (Breadcrumbs, Banners) -->
<div>
  <ng-container brComponent="lead-content"></ng-container>
</div>

<!-- Main body content -->
<main>
  <div edsGrid class="equal-cols__no-content-col">
    <div class="container--wide">
      <!-- Split content -->
      <div edsRow>
        <div edsCol [columnNumbers]="{ s: 4, m: 4, l: 5 }">
          <ng-container brComponent="left-content"></ng-container>
        </div>
        <div edsCol [columnNumbers]="{ s: 4, m: 4, l: 4 }" class="offset-l1">
          <ng-container brComponent="right-content"></ng-container>
        </div>
      </div>
      <!-- /Split content -->
    </div>
  </div>
  <!-- Full width content -->
  <ng-container brComponent="main-content"></ng-container>
  <!-- / Full width content -->
</main>

<ng-container brComponent="lower-content"></ng-container>

<!-- narrow with lower content container definition.  -->
<div edsGrid>
  <div class="container--wide">
    <div edsRow>
      <div edsCol>
        <ng-container brComponent="lower-content-narrow-width"></ng-container>
      </div>
    </div>
  </div>
</div>
<!-- narrow with lower content container definition.  -->
