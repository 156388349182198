import { Component, Input, OnInit } from '@angular/core';
import { TabContents } from '../tabs.interface';
import { Page } from '@bloomreach/spa-sdk/';
import { CardsService } from '@services/cards.service';
import { CardContent } from '@types';

@Component({
  selector: 'ft-tabs-media-cards-section',
  templateUrl: './tabs-media-cards-section.component.html',
})
export class TabsMediaCardsSectionComponent implements OnInit {
  /**
   * The Bloomreach page
   */
  @Input() page!: Page;
  /**
   * Page Section content
   */
  @Input() content: TabContents;

  /**
   * Cards Array
   */
  cards: Array<CardContent>;

  /**
   * Number of Columns
   */
  columns = 3;

  /**
   * margin bottom
   */
  spacingBottom: string;

  constructor(private cardsService: CardsService) {}

  ngOnInit() {
    if (this.content?.cardCompound) {
      if (
        this.content.cardCompound.length === 2 ||
        this.content.cardCompound.length === 4
      ) {
        this.columns = 2;
      }

      if (this.content.cardCompound.length >= 5) {
        this.columns = 3;
      }
      this.spacingBottom = this.content?.spacingBottom;
      this.cards = this.cardsService.createCardGroups(
        this.cardsService.formatCards(this.content.cardCompound),
        this.columns
      );
      this.cards = this.cardsService.formatCardURL(
        this.cards.flat(),
        this.page
      );
    }
  }
}
