import { NAV, NAVChangeDirection } from '@models';
import { CurrencyCode, NAVDTO } from '@types';
import { fromLocaleNumber } from '@utils/il8n/number-il8n';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class NavMapper extends Mapper<NAV> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(navDto: NAVDTO): NAV {
    return {
      redemptionPrice: navDto.redemptionprice,
      currencyCode: navDto.currcode as CurrencyCode,
      navAsOfDate: navDto.navdate,
      navChangeValue: navDto.navchngvalue,
      navChangePercentage: navDto.navchngpct,
      moneyFundAsOfDate: navDto.moneyfundasofdate,
      navValue: navDto.navvalue,
      marketToMarketNav: navDto.mtmnav,
      marketToMarketNavChg: navDto.navmtmdiffpct,
      popValue: navDto.offerpricevalue,
      navYearToDateReturn: navDto.ytdtotretatnav,
      marketPriceValue: navDto.mktpricevalue,
      marketPriceChangeValue: navDto.mktchngval,
      marketPriceChangePercentage: navDto.mktchngpct,
      marketPriceAsOfDate: navDto.mktpricedt,
      marketPriceYearToDateReturn: navDto.ytdtotretatmktprice,
      dailyVolume: navDto.dailyvolume,
      distributionRateAtNAV: navDto.distribrateatnav,
      distributionRateAtPOP: navDto.distribrateatpop,
      highestNavValueIn52Weeks: navDto.navhigh52week,
      highestNavValueDateIn52Weeks: navDto.navhigh52weekdate,
      lowestNavValueIn52Weeks: navDto.navlow52week,
      lowestNavValueDateIn52Weeks: navDto.navlow52weekdate,
      highestMarketPriceValueIn52Weeks: navDto.mktpricehigh52week,
      highestMarketPriceValueDateIn52Weeks: navDto.mktpricehigh52weekdate,
      lowestMarketPriceValueIn52Weeks: navDto.mktpricelow52week,
      lowestMarketPriceValueDateIn52Weeks: navDto.mktpricelow52weekdate,
      highestNavValueDateAsYearToDate: navDto.highnavdateytd,
      lowestNavValueDateAsYearToDate: navDto.lownavdateytd,
      highestNavValueAsYearToDate: navDto.highnavvalueytd,
      lowestNavValueAsYearToDate: navDto.lownavvalueytd,
      highestNavValue1YearBack: navDto.curryr1highnav,
      highestNavValue2YearBack: navDto.curryr2highnav,
      highestNavValue3YearBack: navDto.curryr3highnav,
      highestNavValue4YearBack: navDto.curryr4highnav,
      highestNavValue5YearBack: navDto.curryr5highnav,
      highestNavValue6YearBack: navDto.curryr6highnav,
      highestNavValue7YearBack: navDto.curryr7highnav,
      highestNavValue8YearBack: navDto.curryr8highnav,
      highestNavValue9YearBack: navDto.curryr9highnav,
      lowestNavValue1YearBack: navDto.curryr1lownav,
      lowestNavValue2YearBack: navDto.curryr2lownav,
      lowestNavValue3YearBack: navDto.curryr3lownav,
      lowestNavValue4YearBack: navDto.curryr4lownav,
      lowestNavValue5YearBack: navDto.curryr5lownav,
      lowestNavValue6YearBack: navDto.curryr6lownav,
      lowestNavValue7YearBack: navDto.curryr7lownav,
      lowestNavValue8YearBack: navDto.curryr8lownav,
      lowestNavValue9YearBack: navDto.curryr9lownav,
      highestNavValueDate1YearBack: navDto.curryr1highdate,
      lowestNavValueDate1YearBack: navDto.curryr1lowdate,
      highestNavValueDate2YearBack: navDto.curryr2highdate,
      lowestNavValueDate2YearBack: navDto.curryr2lowdate,
      highestNavValueDate3YearBack: navDto.curryr3highdate,
      lowestNavValueDate3YearBack: navDto.curryr3lowdate,
      highestNavValueDate4YearBack: navDto.curryr4highdate,
      lowestNavValueDate4YearBack: navDto.curryr4lowdate,
      highestNavValueDate5YearBack: navDto.curryr5highdate,
      lowestNavValueDate5YearBack: navDto.curryr5lowdate,
      highestNavValueDate6YearBack: navDto.curryr6highdate,
      lowestNavValueDate6YearBack: navDto.curryr6lowdate,
      highestNavValueDate7YearBack: navDto.curryr7highdate,
      lowestNavValueDate7YearBack: navDto.curryr7lowdate,
      highestNavValueDate8YearBack: navDto.curryr8highdate,
      lowestNavValueDate8YearBack: navDto.curryr8lowdate,
      highestNavValueDate9YearBack: navDto.curryr9highdate,
      lowestNavValueDate9YearBack: navDto.curryr9lowdate,
      moneyFundNav: navDto.moneyfundnav,
      moneyFundNavChangeValue: navDto.moneyfundnavchngvalue,
      moneyFundMarketNav: navDto.moneyfundmktnav,
      masterFundNav: navDto.masterfundnav,
      dailyLiquidAssetRatio: navDto.dailyliquidassetratio,
      weeklyLiquidAssetRatio: navDto.weeklyliquidassetratio,
      intradayNavDate: navDto.intradaynavdt,
      intradayNav: navDto.intradaynav,
      medianBidAskSpread: navDto.medianbidaskspread,
      netShareHolderValue: navDto.netshareholdervalue,
      bidAskSpread: navDto.bidaskspread,
      premiumDiscountAvg: navDto.premiumdiscountavg,
      premiumDiscountPct: navDto.premiumdiscountpct,
      changeDirection: navDto.navchngvalue
        ? this.getValueDirection(navDto.navchngvalue)
        : (navDto.navchngvalue as NAVChangeDirection),
      effectiveDate: navDto.effdate,
      overlap: navDto.overlap,
    };
  }

  getValueDirection(changeDirectionValue: string): NAVChangeDirection {
    const changeDirVal = fromLocaleNumber(changeDirectionValue);
    let changeDirection: NAVChangeDirection;
    if (changeDirVal > 0) {
      changeDirection = 'gain';
    } else if (changeDirVal < 0) {
      changeDirection = 'loss';
    } else if (changeDirVal === 0) {
      changeDirection = 'static';
    }
    return changeDirection;
  }
}
