import { Mapper, MapperParams } from './type.mapper';
import { MapperFactory } from './mapper-factory';
import { PortfolioChar } from '@products/models/portfolio';
import { PortfolioCharDTO, StatisticId } from '@types';

export class PortfolioCharMapper extends Mapper<PortfolioChar> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(portfolioCharDTO: PortfolioCharDTO): PortfolioChar {
    const portfolioChar = {
      asOfDate: portfolioCharDTO.asofdate,
      elementValue: portfolioCharDTO.elemvaluelocal,
      elementName: portfolioCharDTO.elemname as StatisticId,
      elemtTranslatedname: portfolioCharDTO.elemtransname,
    };
    return portfolioChar;
  }
}
