import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ButtonModule,
  FlexboxModule,
  IconModule,
  ImageModule,
  LinkItemModule,
  PaddingModule,
  SimpleModalModule,
  SpacingModule,
  TitleModule,
} from '@frk/eds-components';
import { TranslateModule } from '@shared/translate/translate.module';
import { CartDialogComponent } from './cart-dialog.component';

@NgModule({
  declarations: [CartDialogComponent],
  imports: [
    CommonModule,
    TitleModule,
    LinkItemModule,
    ImageModule,
    IconModule,
    ButtonModule,
    TranslateModule,
    FlexboxModule,
    PaddingModule,
    SimpleModalModule,
    SpacingModule,
  ],
  exports: [CartDialogComponent],
})
export class CartModalModule {}
