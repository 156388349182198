import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Configuration, PageModel } from '@bloomreach/spa-sdk';
import { ComponentTheme } from '@frk/eds-components';
import { WINDOW } from '@ng-web-apis/common';
import {
  Brand,
  Channel,
  FavoritesConfig,
  GlobalId,
  OneFranklinId,
  ProductType,
  SegmentId,
} from '@types';
import { Logger } from '@utils/logger';
import { DataDogLoggerOutput } from '@utils/logging/datadog-logger-output';
import { map } from 'rxjs/operators';
import { DatadogService } from './datadog.service';
import { EnvConfigService } from './env-config.service';
import { FirmRole, LoginSource } from './profile.interface';

const logger = Logger.getLogger('AppStateService');

declare var appInitChannelConfig; // appInitChannelConfig is defined in index.html

export interface ApiUrls {
  apiBaseUrl?: string;
  spaBaseUrl?: string;
  baseUrl?: string;
  origin: string;
  apiVersion?: string;
  endpoint: string;
}

export interface LiteratureApimFlows {
  useLiteratureAPIMFlow?: boolean;
  useOrderDetailAPIMFlow?: boolean;
  useOrderHistoryAPIMFlow?: boolean;
  useRecentOrderHistoryAPIMFlow?: boolean;
  useLiteratureDownloadZipAPIMFlow?: boolean;
  useLiteratureEmailDocsAPIMFlow?: boolean;
  useSubmitOrderAPIMFlow?: boolean;
  useLiteratureDataAPIMFlow?: boolean;
  useFavoriteFundAPIMFlow?: boolean;
  useGermanCalculatorAPIMFlow?: boolean;
}

export enum LiteratureFlow {
  RECENT_ORDER_HISTORY_LIST = 'RECENT_ORDER_HISTORY_LIST',
  ORDER_HISTORY_DETAIL = 'ORDER_HISTORY_DETAIL',
  ORDER_HISTORY_LIST = 'ORDER_HISTORY_LIST',
  LITERATURE_DOWNLOAD_ZIP = 'LITERATURE_DOWNLOAD_ZIP',
  LITERATURE_EMAIL_DOCS = 'LITERATURE_EMAIL_DOCS',
  SUBMIT_ORDER = 'SUBMIT_ORDER',
  LITERATURE_DATA = 'LITERATURE_DATA',
}

export interface Redirect {
  from: string;
  to: string;
  conditions?: {
    segments?: SegmentId[];
    isLoggedIn?: boolean;
    isIdentified?: boolean;
    source?: LoginSource[];
    firm?: FirmRole[];
    firmId?: GlobalId[];
    hasFirmToken?: boolean;
  };
}

export enum AuthenticationType {
  ONE_FRANKLIN_ID_US = 'oneFranklinIdUs',
  ONE_FRANKLIN_ID_EMEA = 'oneFranklinIdEmea',
  PING_US = 'us',
  MYFUNDS = 'myfunds',
  CANADA = 'canada',
}

export interface Auth0Options {
  clientId: string;
  domain: string;
  personalisationApi?: string;
}
/**
 * TODO refactor later
 * Temporary solution to cater for application state until we decide what we really want to do
 */
@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private channel: Channel = 'ft-core' as Channel;
  private apiUrls: ApiUrls;
  // TODO: this appears to be unused. Can it be deleted?
  private preview: boolean;
  private sharedCookieDomain: string;
  private isGateway: boolean;
  private debugPageModel = false;
  private cmsBaseUrl: string; // FIXME remove this.
  private spaBaseUrl: string;
  private pdsEnv: string;

  constructor(
    private envConfig: EnvConfigService,
    @Inject(DOCUMENT) private documentRef: Document,
    @Inject(WINDOW) readonly windowRef: Window,
    private httpClient: HttpClient,
    private datadogService: DatadogService
  ) {
    const params = new URLSearchParams(this.windowRef.location.search);

    this.debugPageModel = params.get('debug') === 'pagemodel';
    this.pdsEnv =
      params.get('pds') || envConfig.getEnvConfig()?.defaultPds || 'prod';
    // appInitConfig is a global variable set in the channel specific index html during the build process
    this.channel = appInitChannelConfig
      ? appInitChannelConfig.channelId
      : this.channel;
    this.sharedCookieDomain = appInitChannelConfig?.sharedCookieDomain;
  }

  // TODO: temp hacky way to work out if on home page
  public isHomePage(): boolean {
    const spaBaseUrl: string = this.getSpaBaseUrl();
    const path: string = this.windowRef.location.pathname.replace(
      spaBaseUrl,
      ''
    );
    return ['/', ''].includes(path);
  }

  /**
   * returns the Brand theme for this site e.g. 'clearbridge' or 'cpreif'
   * Defaults to Frk brand theme if not set
   */
  public getBrand(): Brand {
    return appInitChannelConfig.brand || Brand.FRK_SKY;
  }

  /**
   * returns the Brand theme for this site e.g. 'clearbridge' or 'cpreif'
   * Defaults to Frk brand theme if not set
   */
  public getFundHeaderTheme(): ComponentTheme {
    return appInitChannelConfig.fundHeaderTheme || ComponentTheme.BASIC;
  }

  /**
   * if set, enables personalisation banner on site
   * The url points to the gateway homepage content
   */
  public getGatewayUrl(): string {
    logger.debug('getGatewayUrl()', appInitChannelConfig);
    return appInitChannelConfig.gatewayUrl || null;
  }

  /**
   * setter isGateway value
   * @param isGateway - boolean param is user on gateway
   */
  public setIsGateway(isGateway: boolean): void {
    this.isGateway = isGateway;
  }

  /**
   * getter isGateway value
   */
  public getIsGateway(): boolean {
    return this.isGateway;
  }

  /**
   * returns the default segment for this site
   */
  // TODO: this appears to be unused. Can it be deleted?
  private getDefaultSegmentId(): SegmentId {
    logger.debug('getDefaultSegmentId()', appInitChannelConfig);
    return appInitChannelConfig.defaultSegment || null;
  }

  /**
   * returns GTM container identifier
   * @returns returns string GTM container identifier
   */
  public getGtmId(): string {
    return appInitChannelConfig.gtmId || null;
  }

  /**
   * returns true if site has sign-in i.e. US initially
   * if false, we don't need to bother making accounts calls etc
   */
  public hasAuthentication(): boolean {
    logger.debug('hasAuthentication()', appInitChannelConfig);
    return Boolean(appInitChannelConfig?.authentication);
  }

  public getAuthenticationType(): string {
    return appInitChannelConfig.authentication;
  }

  /**
   * Returns if authentication type is on the list.
   */
  public isSimplyLoginMethod(): boolean {
    const simpleSignInMethodList = ['myfunds', 'canada'];
    return simpleSignInMethodList.includes(this.getAuthenticationType());
  }

  public isOktaLogin(): boolean {
    const oktaLogins = ['internalFranklinId'];
    return oktaLogins.includes(this.getAuthenticationType());
  }

  public isAuth0Login(): boolean {
    const authType = this.getAuthenticationType();
    return (
      authType === OneFranklinId.APAC ||
      authType === OneFranklinId.EMEA ||
      authType === OneFranklinId.US
    );
  }

  public getAuth0Config(): Auth0Options {
    return this.getEnvConfig()[this.getAuthenticationType()];
  }

  /**
   * TODO: rename as getRelativeHomeURL()
   * Rewrites absolute home page url to relative
   * @param url - string to check
   */
  public relativeHomeURL(url: string): string {
    // NGC-3177 - according to tracking requirement we need replace absolute URL to the domain to relative URL to / in logo link
    const absoluteDomainRegexp: RegExp = /^(http|https):\/\/([a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9.]))+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]+(?:[:0-9]+)?(\/)?([a-z0-9_-]+)?(\/)?$/g;
    if (absoluteDomainRegexp.test(url)) {
      const relativeUrl: string = url.replace(absoluteDomainRegexp, '$3$4$5');
      return relativeUrl ? relativeUrl : '/';
    }
    return url;
  }

  public getChannel(): Channel {
    return this.channel;
  }

  // FIXME remove this method
  public getBaseResourceApiUrl(): string {
    return `${this.cmsBaseUrl}/resourceapi/`;
  }

  /**
   * Get the homepage url for this channel
   */
  public getHomePageUrl(): string {
    return this.cmsBaseUrl || '';
  }

  // FIXME remove this method
  public getCmsBaseUrl(): string {
    return this.cmsBaseUrl;
  }

  // needed for getting access token
  public getPersonalisationApi(): string {
    return this.getAuth0Config().personalisationApi || '';
  }

  public initializeLogging() {
    this.datadogService.initDataDog(
      this.envConfig,
      this,
      this.windowRef.location
    );
    if (this.envConfig.getEnvConfig().datadog.logsEnabled) {
      Logger.addConfiguration({
        defaultOutputs: Logger.getConfiguration().defaultOutputs.concat([
          new DataDogLoggerOutput(this.datadogService),
        ]),
      });
    }
  }

  public getCookieApiUrl(): string {
    return this.envConfig.getEnvConfig().cookieApiUrl;
  }

  public getStockQuoteList(): string {
    return this.envConfig.getEnvConfig().stockData;
  }

  getPdsApiUrl(): string {
    return this.pdsEnv === 'prod'
      ? this.windowRef.location.origin +
          this.envConfig.getEnvConfig()[`prodPdsApiUrl`] // for prod we go through akamai to avoid occasional CORS issues
      : this.envConfig.getEnvConfig()[`devPdsApiUrl`];
  }

  public getLiteratureApiUrl(): string {
    return this.envConfig.getEnvConfig().literatureApiUrl;
  }

  public getLiteratureDownloadFinraUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadFinraUrl;
  }

  public getLiteratureDownloadUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadUrl;
  }

  public getLiteratureBaseApiUrl(): string {
    return this.envConfig.getEnvConfig().literatureBaseApiUrl;
  }

  public getLiteratureOrderHistoryListUrl(): string {
    return this.envConfig.getEnvConfig().literatureOrderHistoryList;
  }

  public getRecentOrderHistoryListUrl(): string {
    return this.envConfig.getEnvConfig().literatureRecentOrderHistoryList;
  }

  public getSearchAPIUrl(): string {
    return this.envConfig.getEnvConfig().searchUrl;
  }

  public getPreSearchAPIUrl(): string {
    return this.envConfig.getEnvConfig().preSearchUrl;
  }

  public getSearchStatusAPIUrl(): string {
    return this.envConfig.getEnvConfig().searchStatusUrl;
  }

  public getFundObjectiveAPIUrl(): string {
    return this.envConfig.getEnvConfig().fundObjective;
  }

  public getInsightsAPIUrl(): string {
    return this.envConfig.getEnvConfig().insightsUrl;
  }

  public getAutoCompleteAPIUrl(): string {
    return this.envConfig.getEnvConfig().autoCompleteUrl;
  }

  public getEnvironment(): string {
    return this.envConfig.getEnvConfig().environment;
  }

  public getSearchEnvironment(): string {
    return this.envConfig.getEnvConfig().searchEnvironment || '';
  }

  public getCommentaryContentUrl(): string {
    return this.envConfig.getEnvConfig().commentaryContentUrl;
  }

  public getFundCommentaryKey(): string {
    return this.envConfig.getEnvConfig().fundCommentaryKey;
  }

  public getOrderHistoryAPIMUrl(): string {
    return this.envConfig.getEnvConfig().orderHistoryAPIMUrl;
  }

  public getOrderHistoryDetailAPIMUrl(): string {
    return this.envConfig.getEnvConfig().orderHistoryDetailAPIMUrl;
  }

  public getSubmitOrderAPIMUrl(): string {
    return this.envConfig.getEnvConfig().submitOrderAPIMUrl;
  }

  public getRecentOrderAPIMUrl(): string {
    return this.envConfig.getEnvConfig().recentOrderAPIMUrl;
  }

  public getLiteratureAuthAPIAPIMKey(): string {
    return this.envConfig.getEnvConfig().literatureAuthAPIAPIMKey;
  }

  public getMarketingAuthAPIMKey(): string {
    return this.envConfig.getEnvConfig().marketingAuthAPIMKey;
  }

  public getLiteratureAPIMKey(): string {
    return this.envConfig.getEnvConfig().literatureAPIMKey;
  }

  public getLiteratureBaseAPIMUrl(): string {
    return this.envConfig.getEnvConfig().LiteratureBaseAPIMUrl;
  }

  public getLiteratureEmailDocsAPIMUrl(): string {
    return this.envConfig.getEnvConfig().LiteratureEmailDocsAPIMUrl;
  }
  public getLiteratureCodeGenerationUrl(): string {
    return this.envConfig.getEnvConfig().literatureCodeGenerationApi;
  }
  public getLiteratureCodesApiUrl(): string {
    return this.envConfig.getEnvConfig().literatureCodesApi;
  }
  // TODO: this appears to be unused. Can it be deleted?
  private getLiteratureDownloadZipAPIMUrl(): string {
    return this.envConfig.getEnvConfig().LiteratureDownloadZipAPIMUrl;
  }

  public getUseAPIM(orderFlow?: LiteratureFlow): boolean {
    let literatureApimFlow: LiteratureApimFlows = {};
    literatureApimFlow = this.envConfig.getEnvConfig().apimFlags;
    switch (orderFlow) {
      case LiteratureFlow.ORDER_HISTORY_DETAIL:
        return (
          literatureApimFlow?.useLiteratureAPIMFlow &&
          literatureApimFlow?.useOrderDetailAPIMFlow
        );
      case LiteratureFlow.ORDER_HISTORY_LIST:
        return (
          literatureApimFlow?.useLiteratureAPIMFlow &&
          literatureApimFlow?.useOrderHistoryAPIMFlow
        );
      case LiteratureFlow.RECENT_ORDER_HISTORY_LIST:
        return (
          literatureApimFlow?.useLiteratureAPIMFlow &&
          literatureApimFlow?.useRecentOrderHistoryAPIMFlow
        );
      case LiteratureFlow.LITERATURE_DOWNLOAD_ZIP:
        return (
          literatureApimFlow?.useLiteratureAPIMFlow &&
          literatureApimFlow?.useLiteratureDownloadZipAPIMFlow
        );
      case LiteratureFlow.LITERATURE_EMAIL_DOCS:
        return (
          literatureApimFlow?.useLiteratureAPIMFlow &&
          literatureApimFlow?.useLiteratureEmailDocsAPIMFlow
        );
      case LiteratureFlow.SUBMIT_ORDER:
        return (
          literatureApimFlow?.useLiteratureAPIMFlow &&
          literatureApimFlow?.useSubmitOrderAPIMFlow
        );
      case LiteratureFlow.LITERATURE_DATA:
        return (
          literatureApimFlow?.useLiteratureAPIMFlow &&
          literatureApimFlow?.useLiteratureDataAPIMFlow
        );
      default:
        return false;
    }
  }

  public getGermanCalculatorOnetimeInvestmentApiUrl(): string {
    return this.envConfig.getEnvConfig()
      .germanCalculatorOnetimeInvestmentApiUrl;
  }

  public getGermanCalculatorSavingPlanApiUrl(): string {
    return this.envConfig.getEnvConfig().germanCalculatorSavingPlanApiUrl;
  }

  public getGermanCalculatorWithdrawlPlanApiUrl(): string {
    return this.envConfig.getEnvConfig().germanCalculatorWithdrawlPlanApiUrl;
  }

  public getGermanCalulatorPensionApiUrl(): string {
    return this.envConfig.getEnvConfig().germanCalculatorPensionApiUrl;
  }

  public getGermanCalulatorPensionEstimatorApiUrl(): string {
    return this.envConfig.getEnvConfig().germanCalculatorPensionEstimatorApiUrl;
  }

  public getGermanCalulatorShortfallClosureApiUrl(): string {
    return this.envConfig.getEnvConfig().shortfallClosureApiUrl;
  }

  public getCalcFtApiKey(): string {
    return this.envConfig.getEnvConfig().calcFtApiKey;
  }

  public getOcpApimTrace(): boolean {
    return this.envConfig.getEnvConfig().ocpApimTrace;
  }

  /**
   * Conditionally allowing the APIM flow to be enabled
   * Check for urls.If this is enabled then it should map to APIM Urls
   * Make corresponsing changes in config files
   * @returns boolean
   */
  public isGermanCalculatorAPIMFlowEnabled(): boolean {
    return (
      this.envConfig.getEnvConfig().apimFlags.useGermanCalculatorAPIMFlow ||
      false
    );
  }

  /**
   * method to get the Accounts API
   */
  public getAccountsApiUrl(): string {
    switch (this.getChannel()) {
      case 'en-us':
        return this.envConfig.getEnvConfig().accountsApiUrl;
      case 'en-ca':
      case 'fr-ca':
        return this.envConfig
          .getEnvConfig()
          .canadaAccountsApiUrl.replace(
            '[LOCALE]',
            this.getLocaleFromChannel()
          );
      case 'de-de': // fall through
      case 'de-at': // fall through
      case 'cs-cz':
        return this.envConfig.getEnvConfig().myfunds.apiUrl;
      default:
        logger.error(`${this.getChannel()} does not have accounts`);
        return null;
    }
  }

  public getCanadaValidateSessionUrl(): string {
    return this.envConfig.getEnvConfig().canadaAccountsValidateSessionUrl;
  }

  /**
   * Returns Sign-out URL for Canada
   */
  public getCanadaSignOutApiURL(): string {
    return this.envConfig
      .getEnvConfig()
      .canadaSignOutApiURL.replace('[LOCALE]', this.getLocaleFromChannel());
  }

  private getLocaleFromChannel(): string {
    switch (this.getChannel()) {
      case 'fr-ca':
        return 'fr';
        break;
      case 'en-ca':
        return 'en';
        break;
      default:
        return 'en';
    }
  }

  public getMyFundsLocale(): string {
    return appInitChannelConfig.myFundsLocale;
  }

  /**
   * method to get the MyFunds Domain
   */
  public getMyFundsDomain(): string {
    return this.envConfig.getEnvConfig().myfundsDomain;
  }

  public getMyFundsIdpUrl() {
    const [lang, country] = this.getMyFundsLocale().split('-');
    return this.envConfig
      .getEnvConfig()
      .myfunds?.idpUrl?.replace('{LANG}', lang)
      .replace('{COUNTRY}', country.toLowerCase());
  }

  public getLiteratureImageUrl(): string {
    return this.envConfig.getEnvConfig().literatureImageUrl;
  }

  public getLiteratureDownloadBaseUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadBaseUrl;
  }

  // this is only for lit-preview page, to avoid right-prospectus redirects
  public getLiteratureDownloadPreviewUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadPreviewUrl;
  }

  public getDamUrlParam() {
    return this.envConfig.getEnvConfig().damUrlParam;
  }

  public getLiteratureDownloadZipUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadZipUrl;
  }

  public getLiteratureDownloadAutoNumberingUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadAutoNumberingUrl;
  }

  public useLiteratureOverrideListFor(): string[] {
    return this.envConfig.getEnvConfig().useLiteratureOverrideListFor;
  }

  /**
   * Load Api urls from App Config Service
   */
  public getApiUrls(): ApiUrls {
    if (!this.apiUrls) {
      const envConfig = this.envConfig.getEnvConfig();
      if (envConfig) {
        const cmsPort =
          envConfig.apiPort !== undefined &&
          envConfig.apiPort !== 80 &&
          envConfig.apiPort !== 443
            ? ':' + envConfig.apiPort
            : '';
        const origin = `${envConfig.apiScheme}://${envConfig.apiUrl}${cmsPort}`;

        // allows us to pull base urls from channel or environment config if they have been set
        let spaBaseUrl =
          (appInitChannelConfig ? appInitChannelConfig.spabaseurl : false) ||
          '';

        let cmsBaseUrl =
          (appInitChannelConfig ? appInitChannelConfig.cmsbaseurl : false) ||
          `${this.documentRef.location.origin}${spaBaseUrl}`;

        if (envConfig.singleHost) {
          spaBaseUrl = cmsBaseUrl = `/site/${this.getChannel()}`;
        }

        this.apiUrls = {
          origin,
          endpoint: cmsBaseUrl + '/resourceapi',
        };
        this.cmsBaseUrl = cmsBaseUrl;
        this.spaBaseUrl = spaBaseUrl;
      }
    }
    return this.apiUrls;
  }

  public getBrConfiguration(): Configuration {
    return {
      ...this.getApiUrls(),
      httpClient: (
        ...[{ data: body, headers, method, url }]: Parameters<
          Configuration['httpClient']
        >
      ) => {
        return this.httpClient
          .request<PageModel>(method, url, {
            body,
            headers: headers as Record<string, string | string[]>,
            responseType: 'json',
          })
          .pipe(map((data) => ({ data })))
          .toPromise();
      },
    };
  }

  public getEnvConfig() {
    return this.envConfig.getEnvConfig();
  }

  /**
   * method to get the Accounts Domain
   */
  public getAccountsDomain(): string {
    return this.envConfig.getEnvConfig().accountsDomain;
  }

  /**
   * method to get the Profile Domain
   */
  public getProfileDomain(): string {
    return this.envConfig.getEnvConfig().profileDomain;
  }

  /**
   * method to get the Shared Cookie Domain
   */
  public getCookieDomain(): string {
    return this.sharedCookieDomain;
  }

  /**
   * returns country local for spa, for example /en-lu
   */
  public getSpaBaseUrl(): string {
    return this.spaBaseUrl;
  }

  public getWidenUrl(): string {
    return this.envConfig.getEnvConfig().widenUrl;
  }

  public getWidenProxyUrl(): string {
    return this.envConfig.getEnvConfig().widenProxyUrl;
  }

  public getFavoritesConfig(): FavoritesConfig {
    const hasFavorites = appInitChannelConfig?.hasFavorites || false;
    const useLocalStorageFavoriteFunds = this.envConfig.getEnvConfig()
      ?.useLocalStorageFavoriteFunds;
    return {
      hasFavorites,
      useLocalStorage: useLocalStorageFavoriteFunds,
    };
  }

  public loadStyle(styleUrl: string, id: string) {
    const head = this.documentRef.getElementsByTagName('head')[0];

    const styleLink = this.documentRef.getElementById(id) as HTMLLinkElement;
    if (styleLink) {
      styleLink.href = styleUrl;
    } else {
      const style = this.documentRef.createElement('link');
      style.id = id;
      style.rel = 'stylesheet';
      style.href = `${styleUrl}`;

      head.appendChild(style);
    }
  }

  public isDebugPageModel(): boolean {
    return this.debugPageModel;
  }

  public getBuildVersion(): string {
    return appInitChannelConfig?.buildVersion || '1.0';
  }

  public getCustomFormEmailUrl(): string {
    return this.envConfig.getEnvConfig().customFormEmailUrl;
  }

  public getGoogleCaptchSiteKey(): string {
    return this.envConfig.getEnvConfig().googleCaptchSitekey;
  }

  public getStrategySpotlightUrl(): string {
    return this.envConfig.getEnvConfig().strategySpotlightUrl;
  }

  public getMostViewedFundsMuleApi(): string {
    return this.envConfig.getEnvConfig().mostViewedFundsMuleApiUrl;
  }
  public getMostViewedFundsClientId(): string {
    return this.envConfig.getEnvConfig().mostViewedClientId;
  }
  public getRelatedArticleUrl(): string {
    return this.envConfig.getEnvConfig().relatedArticleUrl;
  }
  public latestBlogsApiUrl(): string {
    return this.envConfig.getEnvConfig().latestBlogsApiUrl;
  }

  // get list of MVP product types supported by US Servicing
  // TODO: currently use channel json, but in future could use BR Site Config for instant editing
  public getUSServicingProducts(): ProductType[] {
    return appInitChannelConfig.usServicingProducts || [];
  }

  // get the firms from channel json
  public getFirmSpecificFunds(): GlobalId[] {
    return appInitChannelConfig.firmSpecificFunds || [];
  }

  /**
   * method to get the APIM origin
   */
  public getBaseApimUrl(): string {
    return this.envConfig.getEnvConfig().baseApimUrl;
  }

  public getRedirects(): Redirect[] {
    return appInitChannelConfig.redirects;
  }

  public getHtmlLang(): string {
    return appInitChannelConfig.htmlLang || 'en';
  }

  public getBiographiesUrl(): string {
    return this.envConfig.getEnvConfig().biographiesUrl;
  }

  public getBiographiesUrlKey(): string {
    return this.envConfig.getEnvConfig().biographiesUrlKey;
  }

  public getFetchBiosFromElasticFlag(): boolean {
    return this.envConfig.getEnvConfig().fetchBiosFromElasticFlag;
  }

  public getBrBiosDocPath(): string {
    return this.envConfig.getEnvConfig().brBiosDocPath;
  }
}
