import { Component, OnInit } from '@angular/core';
import { FloatingButtonMenu, ResponsiveService } from '@frk/eds-components';
import { AppStateService, ViewModeService } from '@services';
import { LinkService } from '@services/link.service';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { TranslateService } from '@shared/translate/translate.service';
import { LinkCollection } from '@types';
import { Observable } from 'rxjs';

interface LinkListItem {
  description: string;
  link: {
    linkCollection: LinkCollection[];
  };
  image: {
    widenDocument: string;
  };
}

interface NavLink {
  title: string;
  href: string;
  isNewWindow?: boolean;
  externalLink?: string;
  description?: string;
  signInRequired?: boolean;
}
@Component({
  selector: 'ft-floating-action-button',
  templateUrl: './floating-action-button.component.html',
  styleUrls: ['./floating-action-button.component.scss'],
})
export class FloatingActionButtonComponent
  extends AbstractBaseComponent
  implements OnInit {
  public links: NavLink[];
  public isHandheld$: Observable<boolean>;
  public menuItems: Array<FloatingButtonMenu>;

  constructor(
    private ctaLinkService: LinkService,
    protected responsiveService: ResponsiveService,
    private appStateService: AppStateService,
    private viewModeService: ViewModeService,
    private translateService: TranslateService
  ) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
    this.isHandheld$ = this.responsiveService.isHandheld$();
  }

  ngOnInit(): void {
    this.buildFloatingActionButtonLinks();
    this.menuItems = [
      {
        linksLabel: this.translateService.instant('common.fba-quicklinks'),
        menuId: 'quickLinks',
        items: this.links,
      },
    ];
  }

  private buildFloatingActionButtonLinks(): void {
    const { linkListItem } = this.document.getData();
    if (linkListItem) {
      this.links = this.getLinkItems(linkListItem);
    }
  }

  /**
   * Format Quick Links
   */
  private getLinkItems(linkListItems: LinkListItem[]): NavLink[] {
    const links = linkListItems?.map((item: LinkListItem) => {
      const link: LinkCollection = item?.link?.linkCollection[0];
      const linkItem = {
        title: link.displayText,
        href: this.ctaLinkService.getCTALink(
          this.page,
          link,
          this.appStateService?.getHomePageUrl()
        ),
        isNewWindow: link?.target === '_blank' ? true : false,
        externalLink: this.getExternalLink(
          link?.external,
          link?.externalSuffix
        ),
        description: item.description,
        signInRequired: link.signInLink,
      };
      return linkItem;
    });
    return links;
  }

  /**
   * Set external link class
   * @param external - is link external true|false
   * @param externalSuffix - suffix to add to external link
   */
  private getExternalLink(external: boolean, externalSuffix: string): string {
    if (external) {
      const externalLink = 'external-link';
      if (externalSuffix) {
        // Adding external link suffix
        return externalLink + '--' + externalSuffix;
      }
      return externalLink;
    }
    return '';
  }
}
