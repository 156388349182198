import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from '@utils/logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const logger = Logger.getLogger('HealthCheckService');

@Injectable({
  providedIn: 'root',
})
export class HealthCheckService {
  httpOptions: any;

  constructor(private http: HttpClient) {}

  sendHealthCheck$(
    baseUrl: string,
    apiKey: string,
    language: string,
    actor: string
  ): Observable<string> {
    return this.http
      .get(
        `${baseUrl}?appId=iasde&template=planned-outage&language=${language}&actor=${actor}&isRealTime=true`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'X-FT-API-KEY': apiKey,
            Pragma: 'no-cache',
            'If-Modified-Since': '0',
          }),
          withCredentials: true,
          observe: 'response' as 'response',
        }
      )
      .pipe(
        catchError((error) => {
          logger.error('healthcheck call failed', error);
          return of('');
        }),
        map((response: any) => {
          if (response?.body) {
            const planned = response.body.result.plannedOutage.find(
              (target) => target.showMessage === 'show'
            );
            if (planned) {
              return planned.message;
            }
            const unplanned = response.body.result.unPlannedOutage.find(
              (target) => target.targetStatus !== 'UP'
            );
            if (unplanned) {
              return 'unplanned';
            }
          }
          return '';
        })
      );
  }
}
