<ng-container
  *ngIf="
    contentBlock.sectionType === 'media_cards' ||
    contentBlock.sectionType === 'media_cards_list'
  "
>
  <ft-tabs-media-cards-section
    [content]="contentBlock"
    [page]="page"
  ></ft-tabs-media-cards-section>
</ng-container>
<ng-container *ngIf="contentBlock.sectionType === 'media_card_index'">
  <ft-tabs-media-cards-index
    [content]="contentBlock"
    [page]="page"
  ></ft-tabs-media-cards-index>
</ng-container>
<ng-container
  *ngIf="
    contentBlock.sectionType === 'thumbnail_cards' ||
    contentBlock.sectionType === 'thumbnail_cards_compact'
  "
>
  <ft-tabs-thumbnails-section
    [content]="contentBlock"
    [page]="page"
  ></ft-tabs-thumbnails-section>
</ng-container>
