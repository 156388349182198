import {
  FlexboxModule,
  ButtonModule,
  PaddingModule,
  SpacingModule,
  TitleModule,
  GridModule,
  IconModule,
  TooltipModule,
} from '@frk/eds-components';

import { BrSdkModule } from '@bloomreach/ng-sdk';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsBlockComponent } from './buttons-block.component';
import { TranslateModule } from '@shared/translate/translate.module';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
  declarations: [ButtonsBlockComponent],
  imports: [
    BrSdkModule,
    CommonModule,
    FlexboxModule,
    ButtonModule,
    PaddingModule,
    SpacingModule,
    TitleModule,
    GridModule,
    TranslateModule,
    IconModule,
    TooltipModule,
    PipesModule,
  ],
  exports: [ButtonsBlockComponent],
  entryComponents: [ButtonsBlockComponent],
})

/**
 * Buttons Block Module
 */
export class ButtonsBlockModule {}
