import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicTableColDef } from '@frk/eds-components';
import { Courses } from '@marketing/events/events.type';

interface ApprovalColDef {
  item: string;
  val?: string;
}

@Component({
  selector: 'ft-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent {
  @Input() course?: Courses;
  @Output() filterUpdate = new EventEmitter<string>();

  public colDefs: DynamicTableColDef<ApprovalColDef>[] = [
    {
      field: 'item',
      headerName: '',
    },
    {
      field: 'val',
      headerName: '',
    },
  ];

  public updateFilter(event: Event, category: string): void {
    event.preventDefault();
    this.filterUpdate.emit(category);
  }
}
