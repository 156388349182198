<eds-simple-modal
  #modal
  [modalId]="'termsModal1'"
  size="medium"
  [showClose]="false"
>
  <div modal-body innerHTML="{{ brLabels?.pcsToolModal1Body }}"></div>

  <div modal-footer class="text-align-right">
    <button
      id="btnTermsDecline"
      class="ft__btn"
      edsButton="secondary"
      size="normal"
      (click)="declineTerms1()"
    >
      <span class="ft__btn__txt">{{ brLabels?.pcsToolCancel }}</span>
    </button>
    <button
      id="btnTermsAccept"
      class="ft__btn"
      edsButton="primary"
      size="normal"
      [noMargin]="true"
      (click)="acceptTerms1()"
    >
      <span class="ft__btn__txt">{{ brLabels?.pcsToolYes }}</span>
    </button>
  </div>
</eds-simple-modal>

<div edsGrid>
  <div class="container">
    <div *ngIf="!pcsConfig" edsRow>
      <div edsCol [columnNumbers]="{ s: 6, m: 6, l: 6 }">
        <div [innerHTML]="content"></div>
      </div>

      <div edsCol [columnNumbers]="{ s: 2, m: 2, l: 2 }">
        <ng-container *ngFor="let pcsConfigElement of pcsConfigArray">
          <button
            *ngIf="isLoggedIn && isFirmAllowed && !isBypassUser"
            edsButton="primary"
            data-analytics-no-event="true"
            (click)="showInitialTerms(pcsConfigElement)"
            edsSpacing="xs"
          >
            {{ pcsConfigElement?.buttonLabel || brLabels?.pcsToolLaunchBtn }}
          </button>
        </ng-container>
        <div
          *ngIf="isLoggedIn && (!isFirmAllowed || isBypassUser)"
          [innerHTML]="brLabels?.pcsNotAllowedForFirm | safeHtml"
        ></div>
      </div>
    </div>
    <div *ngIf="pcsConfig" edsRow>
      <div edsCol>
        <ng-container *ngFor="let pcsConfigElement of pcsConfigArray">
          <button
            *ngIf="isLoggedIn"
            edsButton="secondary"
            data-analytics-no-event="true"
            (click)="showInitialTerms(pcsConfigElement)"
          >
            {{ pcsConfigElement?.buttonLabel || brLabels?.pcsToolLaunchBtn }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
