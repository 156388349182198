import { AccountsComponentMapping } from '@accounts/accounts.module';
import { Compiler, Injectable, Injector, NgModuleRef } from '@angular/core';
import {
  Component,
  ContainerItem,
  isContainerItem,
  Page,
} from '@bloomreach/spa-sdk/';
import { CaveatService } from '@products/caveats/services/caveat.service';
import { PdsLabelLoaderService } from '@products/services/pds-label-loader.service';
import { SharedComponentMapping } from '@shared/shared.module';
import { Logger } from '@utils/logger';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { AppStateService } from './app-state.service';
import {
  ComponentMapping,
  Dependency,
  DynamicModule,
  ModuleLoaderConfig,
} from './module-loader.config';

const logger = Logger.getLogger('ModuleLoaderService');

@Injectable()
export class ModuleLoaderService {
  /**
   * Bloomreach component mappings
   */
  private mapping: ComponentMapping = {
    ...AccountsComponentMapping,
    ...SharedComponentMapping,
  };
  private mapping$: Subject<ComponentMapping> = new Subject<ComponentMapping>();

  private moduleQueue$: BehaviorSubject<
    ModuleLoaderConfig[]
  > = new BehaviorSubject<ModuleLoaderConfig[]>([]);
  private dependencyQueue$: BehaviorSubject<Dependency[]> = new BehaviorSubject<
    Dependency[]
  >([]);

  private isDebugPageModel: boolean;
  private loadedDependencies: Dependency[] = []; // keep track so only loaded once

  constructor(
    private compiler: Compiler,
    private injector: Injector,
    private pdsLabelLoaderService: PdsLabelLoaderService,
    private appState: AppStateService
  ) {
    this.isDebugPageModel = this.appState.isDebugPageModel();
    this.mapping$.next({
      ...AccountsComponentMapping,
      ...SharedComponentMapping,
    });
    // listen for queue changes
    this.moduleQueue$.subscribe(this.loadNextModule);
    this.dependencyQueue$.subscribe(this.loadDependencies);
  }

  public getMapping$(page: Page): Observable<ComponentMapping> {
    const components = this.getComponentsPresent(page);
    logger.debug('getMapping$(): components present:', components);

    // 1. get required modules
    const modules: ModuleLoaderConfig[] = ModuleLoaderConfig.getRequiredModules(
      components,
      this.isDebugPageModel
    );
    logger.debug('getMapping$(): required modules:', modules);
    if (modules.length > 0) {
      // more modules needed, so update loading queue
      if (this.isDebugPageModel) {
        this.mapping = {};
      }
      const newModulesQueue: ModuleLoaderConfig[] = this.moduleQueue$
        .getValue()
        .concat(modules);

      this.moduleQueue$.next(newModulesQueue);
    }

    // 2. get required dependencies
    const dependencies: Dependency[] = this.getRequiredDependencies(modules);
    logger.debug('getMapping$(): required dependencies:', dependencies);
    if (dependencies.length > 0) {
      // more dependencies needed, so update loading queue
      const newDepsQueue: Dependency[] = this.dependencyQueue$
        .getValue()
        .concat(dependencies);

      this.dependencyQueue$.next(newDepsQueue);
    }

    // 3. check queues status
    this.checkQueues();

    // 4. return mapping$, so calling code knows when required modules + deps have loaded
    return this.mapping$;
  }

  private checkQueues(): void {
    // if both queues are empty, make mapping$ re-emit
    logger.debug(
      'checkQueues(): moduleQueue$, dependencyQueue$',
      this.moduleQueue$.getValue(),
      this.dependencyQueue$.getValue()
    );
    if (
      this.moduleQueue$.getValue().length === 0 &&
      this.dependencyQueue$.getValue().length === 0
    ) {
      logger.debug(
        'checkQueues(): this.mapping$.next(this.mapping) called',
        Object.keys(this.mapping)
      );
      setTimeout(() => {
        // call on next tick, so calling code gets a chance to subscribe to mapping$ first
        this.mapping$.next(this.mapping);
      });
    }
  }

  // NB: used only by custom-layout component
  public getCurrentMapping(): ComponentMapping {
    return this.mapping;
  }

  private loadNextModule = async (
    queue: ModuleLoaderConfig[]
  ): Promise<void> => {
    if (queue.length > 0) {
      const nextModule: ModuleLoaderConfig = queue[0];
      const modulePromise: Promise<any> = nextModule.moduleLoader();
      logger.debug('loadNextModule()', modulePromise);
      await this.loadModule(modulePromise); // updates this.mapping after module is loaded
      // UDS-1326 get latest copy of queue, as it's possible queue was updated while we awaited loadModule
      const remainingModules: ModuleLoaderConfig[] = this.moduleQueue$
        .getValue()
        .filter((mod: ModuleLoaderConfig): boolean => mod !== nextModule);
      // update queue state
      this.moduleQueue$.next(remainingModules);
      // check if queues are empty now
      this.checkQueues();
    }
  };

  /**
   * Based on https://stackoverflow.com/questions/60971689/how-to-dynamically-lazy-load-module-without-router-angular-9
   */
  private async loadModule(
    modulePromise: Promise<any> // TODO: what is `any` type?
  ): Promise<ComponentMapping> {
    return modulePromise
      .then((moduleObj) => moduleObj[Object.keys(moduleObj)[0]])
      .then((module) => this.compiler.compileModuleAsync(module))
      .then((moduleFactory) => moduleFactory.create(this.injector))
      .then((moduleRef: NgModuleRef<unknown>) => {
        const dynamicModule = moduleRef.instance as DynamicModule;
        const newMapping = dynamicModule.getComponentMapping();
        Object.assign(this.mapping, newMapping);
        logger.debug('module loaded', newMapping, this.mapping);
        return this.mapping;
      })
      .catch((e) => {
        logger.error('Failed to load module', modulePromise, e);
      })
      .then(() => this.mapping);
  }

  private getComponentsPresent(page: Page): string[] {
    const componentNames = [page.getComponent().getParameters().layout];
    const components: any[] = [...page.getComponent().getChildren()];
    this.loadComponentsPresent(components, componentNames);
    return componentNames.filter((i) => i !== undefined);
  }

  private loadComponentsPresent(
    components: Component[],
    componentNames: string[]
  ): void {
    components.forEach((item) => {
      if (isContainerItem(item)) {
        const label = (item as ContainerItem).getLabel();
        componentNames.push(label);
      }

      const children = item.getChildren();
      if (children?.length) {
        this.loadComponentsPresent(children, componentNames);
      }
    });
  }

  // TODO move to new page-container.service
  public initializeFootnotes(page: Page, caveatService: CaveatService): void {
    caveatService.initialiseFootnotesByComponent(
      page,
      Object.keys(this.mapping)
    );
  }

  /**
   * Load set of module dependencies.
   * Note these should not be other modules as dependencies are loaded in parallel to the modules
   * but modules need to loaded sequentially.
   */
  private getRequiredDependencies(modules: ModuleLoaderConfig[]): Dependency[] {
    return modules.reduce(
      (deps: Dependency[], module: ModuleLoaderConfig): Dependency[] => {
        if (module.dependencies?.length > 0) {
          const depsToAdd: Dependency[] = module.dependencies.filter(
            (dep: Dependency): boolean =>
              !deps.includes(dep) &&
              !this.loadedDependencies.includes(dep) &&
              !this.dependencyQueue$.getValue().includes(dep)
          );
          deps = deps.concat(depsToAdd);
        }
        return deps;
      },
      []
    );
  }
  private loadDependencies = (deps: Dependency[]): void => {
    const dependenciesToLoad: Observable<boolean>[] = deps.map(
      (dep: Dependency): Observable<boolean> => {
        switch (dep) {
          case Dependency.PDS_LABELS:
            return this.pdsLabelLoaderService.loadPdsLabels$();
          default:
            logger.error('Unknown dependency', dep);
            break;
        }
      }
    );
    logger.debug('loadDependencies()', deps, dependenciesToLoad);
    forkJoin(dependenciesToLoad).subscribe((): void => {
      // update loaded deps list
      this.loadedDependencies = this.loadedDependencies.concat(deps);
      // work out remaining deps to be loaded
      const newDepQueue: Dependency[] = this.dependencyQueue$
        .getValue()
        .filter((dep: Dependency): boolean => !deps.includes(dep));
      // update deps queue
      this.dependencyQueue$.next(newDepQueue);
      logger.debug(
        'forkJoin(dependenciesToLoad)',
        this.loadedDependencies,
        newDepQueue
      );
      // check if queues are now empty
      this.checkQueues();
    });
  };
}
