import { SummaryDetail } from '@models';
import { SiteConfigService } from '@services/site-config.service';
import { SummaryDetailDTO } from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

const getSuppressed = (
  taxonomy: SummaryDetail,
  siteConfig: SiteConfigService
): string[] => {
  // if (siteConfig.)
  return [];
};

export class SummaryDetailsMapper extends Mapper<SummaryDetail[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    summaryDetailsDto: SummaryDetailDTO[],
    isLoggedIn: boolean = false
  ): SummaryDetail[] {
    const summaryDetails: SummaryDetail[] = [];
    const suppressionList = this.mapperParams.config.getSuppressedFundInfoElements(
      this.mapperParams.taxonomy
    );
    const numberOfBenchmarks = this.mapperParams.config.getBenchmarksToShowOnOverview(
      this.mapperParams.fundId
    );

    summaryDetailsDto.forEach((summaryDetailDto: SummaryDetailDTO) => {
      const summaryDetail: SummaryDetail = this.createMapper(
        SummaryDetailMapper
      ).toDomain(summaryDetailDto);

      if (
        summaryDetail &&
        this.notIncludedInSupressionList(
          suppressionList,
          summaryDetail,
          isLoggedIn
        ) &&
        summaryDetail.benchmarkOrder <= numberOfBenchmarks
      ) {
        summaryDetails.push(summaryDetail);
      }
    });

    return summaryDetails;
  }

  /**
   * method verifies if element is present on config supression list
   * @param suppressionList list of supressed elements
   * @param summaryDetail element name
   * @param isLoggedIn is logged in flag
   * @returns boolean
   */
  notIncludedInSupressionList(
    suppressionList: string[],
    summaryDetail: SummaryDetail,
    isLoggedIn: boolean
  ): boolean {
    return !(
      suppressionList.includes(summaryDetail.elementNameStd) ||
      (suppressionList.includes('PUBLIC_' + summaryDetail.elementNameStd) &&
        !isLoggedIn)
    );
  }
}

export class SummaryDetailMapper extends Mapper<SummaryDetail> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(summaryDetailDto: SummaryDetailDTO): SummaryDetail {
    let elementNameStd = summaryDetailDto.elemnamestd;
    let elementName = summaryDetailDto.elemname;
    if (elementNameStd === 'bm_name') {
      if (summaryDetailDto.subsortorder === '1') {
        elementNameStd = 'PRIMARY_BENCHMARK';
        elementName = this.mapperParams.translateService.instant(
          'products.primary-benchmark'
        );
      } else {
        elementNameStd = 'ADDITIONAL_BENCHMARK';
        elementName = this.mapperParams.translateService.instant(
          'products.additional-benchmark'
        );
      }
    }
    return {
      fundId: summaryDetailDto.fundid,
      shareClassCode: summaryDetailDto.shclcode,
      countryCode: summaryDetailDto.cntrycode,
      languageCode: summaryDetailDto.langcode,
      sectionStd: summaryDetailDto.sectionstd,
      section: summaryDetailDto.section,
      elementNameStd,
      elementName,
      elementValueStd: summaryDetailDto.elemvaluestd,
      elementValue: summaryDetailDto.elemvalue,
      preelemValueStd: summaryDetailDto.preelemvaluestd,
      preelemValue: summaryDetailDto.preelemvalue,
      sortOrder: summaryDetailDto.sortorder,
      benchmarkOrder: summaryDetailDto.subsortorder
        ? Number(summaryDetailDto.subsortorder)
        : 0,
      dateDriven: summaryDetailDto.datedriven,
      asOfDate: summaryDetailDto.asofdate,
      asOfDateStd: summaryDetailDto.asofdatestd,
    };
  }
}
