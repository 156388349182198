<ng-container
  [brManageMenuButton]="menuModel"
  *ngIf="isEditMode"
></ng-container>
<div *ngIf="isLoggedIn" edsGrid edsSpacing="xxl">
  <div edsgrid>
    <div class="container--wide">
      <div
        edsSubGrid="layout-0"
        edsSubGridGap="md"
        edsSubGridRowGap="md"
        edsSpacing="md"
      >
        <div
          *ngIf="showFavFundCard"
          [class.my-dashboard--grey]="background === 'grey'"
        >
          <div class="debug" style="display: none">
            <textarea>
          {{ favFundsCard | json }}
        </textarea
            >
          </div>

          <eds-card-dashboard
            [card]="favFundsCard"
            [fullHeight]="true"
            (menuItemSelectedWithIndexOutput)="selectedIndex($event)"
            (btnSubscribe)="openPPSSPage($event)"
          >
            <eds-card-dashboard-favorite-funds
              [tableProperties]="favFundsTableProperties"
              [favoriteFundData]="favFunds"
              (btnRemoveFund)="removeFavFunds($event)"
              [rightAlignLink]="true"
              [enableScrolling]="true"
            ></eds-card-dashboard-favorite-funds>
          </eds-card-dashboard>
        </div>
      </div>
      <div edsSubGrid="layout-1" edsSubGridGap="md" edsSubGridRowGap="md">
        <!-- My Favourite Funds -->

        <div
          *ngIf="accountAccess"
          [class.my-dashboard--grey]="background === 'grey'"
        >
          <eds-card-dashboard [card]="dashboardAccCard" [fullHeight]="true">
            <eds-card-dashboard-accounts
              [dashboardAccountLinks]="accLinksInfo"
              [dashboardAccountData]="accData"
            ></eds-card-dashboard-accounts>
          </eds-card-dashboard>
        </div>
        <!-- My Literature -->
        <div
          *ngIf="showLiteratureCard"
          [class.my-dashboard--grey]="background === 'grey'"
        >
          <!-- Reuse Shared Cart Modal -->
          <ft-literature-dialog
            [dialogId]="ModalId['Cart_Modal']"
            [modalIdEnum]="modalId"
            (dialog)="closeDialog(modalId)"
            (deleteCartDoc)="deleteFromCart($event)"
            [gridData]="dialogData"
          ></ft-literature-dialog>

          <eds-card-dashboard
            [card]="literatureCard"
            [fullHeight]="true"
            (btnSubscribe)="openHistoryPage($event)"
          >
            <eds-card-dashboard-literature
              [literatureTableProperties]="literatureTableProperties"
              [toggleButtons]="toggleButtons"
              [documentData]="literatureDocumentData"
              (btnAddToCart)="addToCart($event)"
              (btnDownloadDocument)="downloadDocument($event)"
              [enableScrolling]="true"
            ></eds-card-dashboard-literature>
          </eds-card-dashboard>
        </div>

        <!-- My Saved Hypotheticals -->
        <div
          *ngIf="
            showSavedHypotheticals ||
            (parameters.showSavedHypotheticals && isEditMode)
          "
          [class.my-dashboard--grey]="background === 'grey'"
        >
          <eds-card-dashboard
            [card]="savedHypotheticalsCard"
            [fullHeight]="true"
            [isNewWindow]="true"
            [externalLink]="true"
            externalSuffix="leaving-tools"
          >
            <eds-card-dashboard-saved-hypotheticals
              [hypotheticalTableProperties]="hypotheticalTableProperties"
              [hypotheticalsData]="visibleHypotheticalsData"
              [isNewWindow]="true"
              [externalLink]="true"
              externalSuffix="leaving-tools"
              [enableScrolling]="true"
            >
            </eds-card-dashboard-saved-hypotheticals>
          </eds-card-dashboard>
        </div>
        <!-- My Sales Team Contacts -->
        <div
          *ngIf="showSalesTeamCard || (parameters.salesTeams && isEditMode)"
          [class.my-dashboard--grey]="background === 'grey'"
        >
          <ft-sales-team [parentLabels]="labels"></ft-sales-team>
        </div>

        <!-- Custom Cards -->
        <eds-card-dashboard
          *ngFor="let card of cards"
          [card]="card"
          [fullHeight]="true"
        >
          <eds-media-card
            [isImg]="false"
            [isIcon]="true"
            [isStacked]="true"
            [imgSrc]="card.imageSrc"
            [bodyContent]="card.content"
            [linkText]="card.link?.linkCollection[0]?.displayText"
            [url]="card.link?.linkCollection[0]?.url"
            [isNewWindow]="card.link?.linkCollection[0]?.target === '_blank'"
            [externalLink]="card.link?.linkCollection[0]?.external"
          >
          </eds-media-card>
        </eds-card-dashboard>
        <!-- Custom Cards -->

        <!-- Interactive widgets -->
        <ft-interactive-widgets
          [myDashboardComponent]="component"
          [myDashboardPage]="page"
        ></ft-interactive-widgets>
        <!-- Interactive widgets -->
      </div>
    </div>
  </div>
</div>
