import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  DropdownData,
  NavigationService,
  SearchServiceToken,
} from '@frk/eds-components';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';

import { FtSearchService } from '@search/services/ftsearch.service';
import { ConfigService } from '@search/services/config.service';
import { TranslateService } from '@shared/translate/translate.service';
import { SiteConfigService } from '@services/site-config.service';
import { FullResultsFundsService } from '@search/search-results/full-results/sections/full-results-funds/full-results-funds.service';
import { FundId, FundIdentifier, ShareClassCode } from '@types';
import {
  FtSearchItem,
  FtSearchOptions,
  FtSearchParams,
  FtSearchResponse,
  FundItem,
} from '@search/interfaces/search.interface';
import { HttpParams } from '@angular/common/http';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  ContactInfoList,
  ContactInfoListDocument,
  ProductContactInfoContent,
  ProductContactInfoData,
  SelectedProductContactInfoContent,
} from './product-contact-info.type';

@Component({
  selector: 'ft-product-contact-info',
  templateUrl: './product-contact-info.component.html',
  styleUrls: ['./product-contact-info.component.scss'],
})
export class ProductContactInfoComponent
  extends AbstractBaseComponent
  implements OnInit, OnDestroy {
  public options: FtSearchOptions = {
    page: 0,
    term: '',
    dataSource: 'dev',
    config: {
      ftSearchUrl: '',
      ftInsightsUrl: '',
      ftAutoCompleteUrl: '',
      httpParams: new HttpParams(),
      httpOptions: {},
    },
    counters: {
      funds: 100,
      literature: 0,
      pages: 1,
    },
  };

  public searchItems: ProductContactInfoData[] = [];
  private emptyResult: DropdownData[];
  private errorResult: DropdownData[];
  private notFound = false;
  private error = false;
  private debouncer$: Subject<FtSearchParams> = new Subject<FtSearchParams>();

  public selectedProduct: SelectedProductContactInfoContent;

  // All Content Blocks to filter Out with
  public contentBlocksMaps: Map<string, ProductContactInfoContent> = new Map<
    string,
    ProductContactInfoContent
  >();

  public componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private configService: ConfigService,
    private cd: ChangeDetectorRef,
    protected translateService: TranslateService,
    protected siteConfigService: SiteConfigService,
    protected navigationService: NavigationService,
    protected fundResultsService: FullResultsFundsService,
    @Inject(SearchServiceToken) private searchService: FtSearchService
  ) {
    super();

    this.emptyResult = [
      {
        label: this.translateService.instant(
          'common.product-contact-info-no-results'
        ),
        value: '',
      },
    ];
    this.errorResult = [
      {
        label: this.translateService.instant(
          'common.product-contact-info-search-error'
        ),
        value: '',
      },
    ];

    this.searchService.onPageSearchOutput$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        ({ results, error }) => {
          // error handling without unsubscribing from observable
          if (error) {
            this.handleError();
            return;
          }

          if (!Array.isArray(results)) {
            const fundResults: FtSearchResponse = (results as unknown) as FtSearchResponse;
            this.searchItems = this.getFundsFromResult(fundResults);
          }

          if (Array.isArray(results)) {
            const fundResults: FtSearchResponse = results.find(
              (resultItem) => resultItem.name === 'funds'
            ) as FtSearchResponse;

            this.searchItems = this.getFundsFromResult(fundResults);
          }

          this.notFound = false;
          this.error = false;
          this.cd.markForCheck();
        },
        () => {
          this.handleError();
        }
      );

    this.debouncer$
      .pipe(takeUntil(this.componentDestroyed$), debounceTime(500))
      .subscribe((searchParams) => this.searchService.findAFund(searchParams));
  }

  ngOnInit(): void {
    this.parseBRContent();

    this.configService
      .getConfig('')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((config) => {
        this.options.config = config;
      });
  }

  public parseBRContent(): void {
    const { contactInfoList } = this.getContentData();
    if (
      contactInfoList &&
      Array.isArray(contactInfoList) &&
      contactInfoList?.length
    ) {
      // If contact info List
      const contentBlocks = this.mapContent(contactInfoList);
      const contentBlocksMap = new Map<string, ProductContactInfoContent>();
      contentBlocks.forEach((block) => {
        contentBlocksMap.set(block?.fundUmbrella, block);
      });
      this.contentBlocksMaps = contentBlocksMap;
    } else {
      this.contentBlocksMaps = new Map();
    }
  }

  public mapContent(
    contactInfoList: ContactInfoListDocument[]
  ): ProductContactInfoContent[] {
    return contactInfoList?.map((infoItem: ContactInfoListDocument) => {
      const { fundUmbrella, contentBlocks } = infoItem;
      return {
        fundUmbrella,
        contentBlocks: contentBlocks?.length ? contentBlocks[0] : null,
      };
    });
  }

  /**
   * Get Content from BR
   */
  public getContentData(): ContactInfoList {
    return this.component.getModels()?.productContactModel;
  }

  public findAFund(term: string): void {
    if (!term?.trim()) {
      this.clearSelectedFund();
    }
    this.options.term = term;
    this.options.page = 0;
    this.options.collection = 'funds';

    const searchParams: FtSearchParams = {
      options: this.options,
      filters: '',
    };

    this.debouncer$.next(searchParams);
  }

  private clearSelectedFund(): void {
    this.selectedProduct = null;
  }

  public selectFund(selectedFund: ProductContactInfoData): void {
    if (this.contentBlocksMaps.has(selectedFund?.fundUmbrella)) {
      this.selectedProduct = {
        ...this.contentBlocksMaps?.get(selectedFund?.fundUmbrella),
        fundTitle: selectedFund.label,
        fundLink: selectedFund.fundLink,
      };
    } else {
      this.selectedProduct = {
        infoNotFound: true,
      };
    }
  }

  /**
   * handle error in case of response issue
   */
  private handleError(): void {
    this.searchItems = this.errorResult;
    this.searchService.loading$.next(false);
    this.notFound = false;
    this.error = true;
    this.cd.markForCheck();
  }

  /**
   * reads fund nodes and transforms it into dropdown title/fund Umbrella
   * @param result search result
   */
  private getFundsFromResult(
    result: FtSearchResponse
  ): ProductContactInfoData[] {
    const responseItems: FtSearchItem[] = result.response.hits.hits;

    const fundResults = responseItems.map((item: FtSearchItem) => {
      const fundDataSource = item._source;
      const fundObj = {
        fundId: fundDataSource.fundid as FundId,
        // TODO fix this after splitting exact match search type from full results
        shareClass: (fundDataSource.shclcode as unknown) as ShareClassCode,
        fundName: fundDataSource.title,
        identifier: fundDataSource.searchIdentifier as FundIdentifier,
      };

      const fundLink: string = this.fundResultsService.getProductPageLink(
        fundObj
      );
      const fundName: string = this.fundResultsService.getFundName(
        item,
        fundDataSource
      );

      const fundItem: FundItem = {
        fundName,
        fundFamily: fundDataSource.assetclass,
        fundLink,
        fundTitle: fundDataSource.title,
        fundShareClassName: fundDataSource.shclname,
        fundUmbrella: fundDataSource?.fundumbrlla,
      };

      return fundItem;
    });

    // Group by fund name and sort the group by share class name logic added below
    const groupSortResults = fundResults.reduce(
      (results: any, fundItem: FundItem) => {
        results[fundItem.fundTitle] = [
          ...(results[fundItem.fundTitle] || []),
          fundItem,
        ];
        results[fundItem.fundTitle].sort((a: FundItem, b: FundItem) => {
          return a.fundShareClassName
            .toLocaleLowerCase()
            .localeCompare(b.fundShareClassName.toLocaleLowerCase());
        });
        return results;
      },
      Object.create(null)
    );

    const finalFundResults = Object.keys(groupSortResults).reduce(
      (groupValues: FundItem[], groupName: string) => {
        return groupValues.concat(groupSortResults[groupName]);
      },
      []
    );

    const mappedFunds = finalFundResults.map((item) => {
      const fundNameText = item.fundName.replace(/(<([^>]+)>)/gi, '-');
      // TODO : Verify if this can be diretly mapped to BR
      const fundUmbrella = item.fundUmbrella
        ?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ' ') // Remove special charaters
        ?.trim()
        ?.replace(/\s/g, '-') // Replace all spaces with nothing
        ?.replace(/-+/g, '-')
        ?.toLowerCase(); // BR returning FTF (OEIC) as ftf-oeic
      return {
        label: fundNameText,
        value: fundUmbrella,
        fundLink: item.fundLink,
        fundUmbrella,
      };
    });

    return mappedFunds.length > 0 ? mappedFunds : this.emptyResult;
  }

  ngOnDestroy(): void {
    // unsubscribe from Observables
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
