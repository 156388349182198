import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouteHistoryService implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private history: string[] = [];
  private history$: Subject<string[]>;
  private historyMaxLength = 5;

  constructor(private router: Router) {
    this.history$ = new Subject();
    this.setRouteHistory();
  }

  /**
   * Set Route History array.
   * It is checking routing redirects and storing in history array.
   */
  public setRouteHistory(): void {
    // Set current page URL as first entry, when history is empty.
    // This prevent to miss opened page when page will be reloaded.
    if (this.history.length === 0) {
      this.history.push(this.router.url);
    }
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        // We are storeing only few history records defined in historyMaxLength.
        if (this.history.length > this.historyMaxLength) {
          this.history.splice(0, 1);
        }
        this.history = [...this.history, urlAfterRedirects];

        // Publish the updated history as well.
        this.history$.next(this.history);
      });
  }

  /**
   * Get Route History array
   * @returns - string array history
   */
  public getRouteHistory(): string[] {
    return this.history;
  }

  /**
   * Get route history array as observable.
   * @returns - observable of string array history
   */
  public getRouteHistory$(): Observable<string[]> {
    return this.history$.asObservable();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
