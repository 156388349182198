import { Component, Input, OnInit } from '@angular/core';
import { BrPageComponent } from '@bloomreach/ng-sdk';
import { Page } from '@bloomreach/spa-sdk';
import { ViewModeService } from '@services/view-mode.service';

@Component({
  selector: 'ft-one-col-accordion',
  templateUrl: './one-col-accordion.component.html',
  styleUrls: ['./one-col-accordion.component.scss'],
})
export class OneColAccordionComponent implements OnInit {
  @Input() numberOfAccordionItems;
  @Input() page!: Page;

  configuration: BrPageComponent['configuration'];
  accordionItems;
  accordionLabels: Array<string> = [];
  isEditMode = false;

  constructor(private viewModeService: ViewModeService) {
    this.isEditMode = this.viewModeService.isEditMode();
    this.accordionItems = Array(this.numberOfAccordionItems).fill(
      this.numberOfAccordionItems
    );
  }
  ngOnInit(): void {
    this.accordionItems = this.page?.getComponent('accordion-item-1')
      ? Array(this.numberOfAccordionItems).fill(this.numberOfAccordionItems)
      : [];

    this.generateAccordionLabel();
  }

  generateAccordionLabel(): void {
    this.accordionItems.forEach((element, index) => {
      const accordionArea = this.page.getComponent(
        `accordion-item-${index + 1}`
      );
      const label = accordionArea?.getComponent('accordion-label')?.getModels()
        ?.AccordionLabel?.label;
      this.accordionLabels.push(label);
    });
  }
}
