import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';
import { AppStateService } from '@services';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(
    private sanitized: DomSanitizer,
    private appStateService: AppStateService
  ) {}
  transform(value) {
    const internalLinkRegexp = /<a\s+(?:[^>]*?\s+)?href="([^"]*)".*data-type="internal"/g;
    if (internalLinkRegexp.test(value)) {
      // Parsing HTML only if it contains 'internal' links (PageModel 1.0 issue)
      value = this.setHtmlLinks(value);
    }
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  /**
   * Fixing links in ContentBlocks HTML entries (PageModel 1.0 removes channel from internal links)
   * @param value - PageModel 1.0 page.model.page object
   */
  private setHtmlLinks(value: string): string {
    const htmlValue: Document = new DOMParser().parseFromString(
      value,
      'text/html'
    );
    const link: NodeListOf<HTMLAnchorElement> = htmlValue.querySelectorAll('a');
    link.forEach((anchorElement: HTMLAnchorElement) => {
      anchorElement.href = this.setHtmlHrefAttribute(
        anchorElement,
        this.appStateService.getSpaBaseUrl()
      );
    });
    return htmlValue.body.innerHTML;
  }

  /**
   * Set Html Href Attribute
   * @param anchorElement - HTMLAnchorElement
   * @param baseUrl - string
   */
  private setHtmlHrefAttribute(
    anchorElement: HTMLAnchorElement,
    baseUrl: string
  ): string {
    const hrefAttribute = anchorElement.getAttribute('href');
    if (
      anchorElement.dataset.type === 'internal' &&
      !hrefAttribute.startsWith(baseUrl)
    ) {
      return baseUrl + hrefAttribute;
    }
    return hrefAttribute;
  }
}
