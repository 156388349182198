import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ViewModeService } from '@services/view-mode.service';

import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Logger } from '@utils/logger';

/**
 * Logger
 */
const logger = Logger.getLogger('RichTextComponent');

/**
 * Interface for the RichText Content
 */
interface RichTextContent {
  content: RichTextValue;
}

interface RichTextValue {
  value: string;
}

/**
 * FT Rich Text component
 *
 * This component is used at the top of a template and has it's own associated document type.
 *
 * Selector: `ft-rich-text`
 *
 * Exported as: `RichTextComponent`
 */
@Component({
  selector: 'ft-rich-text',
  templateUrl: './rich-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextComponent extends AbstractBaseComponent implements OnInit {
  /**
   * Url of Image ref
   */
  imageUrl: string;

  /**
   * Constructor
   */
  constructor(private viewModeService: ViewModeService) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  /**
   * Angular init hook
   */
  ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * Get content from document
   */
  get content() {
    return this.document?.getData<RichTextContent>();
  }
}
