// these are the site channel ids as defined in Bloomreach
// to be updated as more sites added
export enum Channel {
  // Asia Pacific Sites
  APAC_INSTITUTIONAL = 'apac-institutional',
  AUSTRALIA = 'en-au',
  CHINA = 'zh-cn',
  HONG_KONG_ENGLISH = 'en-hk',
  HONG_KONG_CHINESE = 'zh-hk',
  INDIA = 'en-in',
  MALAYSIA = 'en-my',
  SINGAPORE = 'en-sg',
  SOUTH_KOREA = 'en-kr',
  TGGF = 'tggf',
  // Middle East & African Sites
  AFRICA = 'en-xa',
  GEM_MIDDLE_EAST = 'gem',
  SOUTH_AFRICA = 'en-za',
  // European Sites
  AUSTRIA = 'de-at',
  BELGIUM_FRENCH = 'fr-be',
  BELGIUM_DUTCH = 'nl-be',
  BRANDYWINE_GLOBAL = 'brandywine-global',
  CREDIT_DU_NORD = 'fr-fr', // is this duplicate of France site?
  CYPRUS = 'en-cy',
  CZECH = 'cs-cz',
  EMEA_INSTITUTIONAL = 'emea-institutional',
  EDINBURGH_PARTNERS = 'edinburgh-partners',
  ESTONIA = 'et-ee',
  FRANCE = 'fr-fr',
  GERMANY = 'de-de',
  GREECE = 'el-gr',
  HUNGARY = 'hu-hu',
  IRELAND = 'en-ie',
  ITALY = 'it-it',
  LATVIA = 'lv-lv',
  LITHUANIA = 'lt-lt',
  LUXEMBOURG = 'en-lu',
  MALTA = 'en-mt',
  MARTIN_CURRIE_GPT = 'martin-currie-gpt',
  NETHERLANDS = 'nl-nl',
  NORDICS = 'nordics',
  POLAND = 'pl-pl',
  PORTUGAL = 'pt-pt',
  ROMANIA = 'ro-ro',
  SLOVAKIA = 'sk-sk',
  SLOVENIA = 'sl-sl',
  SPAIN = 'es-es',
  SWITZERLAND = 'en-ch',
  TEMIT = 'temit',
  TURKEY = 'en-tr',
  UK = 'en-gb',
  // South American Sites
  BRAZIL = 'pt-br',
  OFFSHORE_ENGLISH = 'en-os',
  OFFSHORE_SPANISH = 'es-os',
  OFFSHORE_PORTUGUESE = 'pt-os',
  OFFSHORE_MERRILL_LYNCH = 'en-os-ml',
  // North American Sites
  CPREIF = 'cpreif',
  CANADA_ENGLISH = 'en-ca',
  CANADA_FRENCH = 'fr-ca',
  CLEARBRIDGE = 'clearbridge',
  CLEARBRIDGE_PRODUCTS = 'clearbridge-products',
  DARBY = 'darbyoverseas',
  FIDUCIARY_TRUST_CANADA_ENGLISH = 'fiduciarytrust-en-ca',
  FIDUCIARY_TRUST_CANADA_FRENCH = 'fiduciarytrust-fr-ca',
  FIDUCIARY_TRUST = 'fiduciarytrust',
  FRANKLIN_INDICES = 'franklin-indices',
  MEXICO = 'es-mx',
  NJ_BEST = 'njbest',
  PRIMERICA = 'primerica',
  US_INSTITUTIONAL = 'us-institutional',
  US_RETAIL = 'en-us',
  // beyondbullsandbears
  BEYOND_BULLS_AND_BEARS_CHINESE = 'beyondbullsandbears-zh',
  BEYOND_BULLS_AND_BEARS_ENGLISH = 'beyondbullsandbears-en',
  BEYOND_BULLS_AND_BEARS_FRENCH = 'beyondbullsandbears-fr',
  BEYOND_BULLS_AND_BEARS_GERMAN = 'beyondbullsandbears-de',
  BEYOND_BULLS_AND_BEARS_ITALIEN = 'beyondbullsandbears-it',
  BEYOND_BULLS_AND_BEARS_POLISH = 'beyondbullsandbears-pl',
  BEYOND_BULLS_AND_BEARS_SPANISH = 'beyondbullsandbears-es',
  // System
  CLEARBRIDGE_USER_GUIDE = 'clearbridge-user-guide',
  DXP_CENTRAL = 'dxp-central',
  GWP = 'gwp',
  SANDBOX = 'en',
  SUBSCRIPTION_PREFERENCE_CENTER = 'subscription-preference-center',
  LIST_PREVIEW = 'list-preview',
  // International Sites
  FT_ACADEMY = 'ft-academy',
  FT_CAREERS = 'ft-careers',
  FT_GLOBAL = 'ft-global',
  FT_RESOURCES = 'ft-resources',
  FTI_DOCUMENTS = 'fti-docs',
  FTI_DOCS_FRENCH = 'ftidocs-fr-be',
  FTI_DOCS_DUTCH = 'ftidocs-nl-be',
  FLORIDA = 'florida',
  K2_ADVISORS = 'k2-advisors',
  // Fiduciary Trust
  FIDUCIARY_TRUST2 = 'en-us-ftci', // what is difference between this and 'fiduciarytrust' site?
  // My Funds
  MYFUNDS_GLOBAL = 'myfunds-en',
  MYFUNDS_HONG_KONG = 'myfunds-en-hk',
  MYFUNDS_SINGAPORE = 'myfunds-en-sg',
  MYFUNDS_UK = 'myfunds-en-gb',
  // Western Asset
  WESTERN_ASSET = 'westernasset',
  WESTERN_ASSET_MCC = 'westernassetmcc',
}

// WDE-2305
// if a profile already exists in this list, it can be used to
// set user's profile on US site
// e.g. if user is already a FP on Primerica, we default them to FP on US Retail
export const PROFILE_PARTNER_CHANNELS = [Channel.PRIMERICA];
