import { Component, Input, OnInit } from '@angular/core';
import { BrPageComponent } from '@bloomreach/ng-sdk';
import { Page } from '@bloomreach/spa-sdk';
import { ViewModeService } from '@services/view-mode.service';

@Component({
  selector: 'ft-one-col-tabbed',
  templateUrl: './one-col-tabbed.component.html',
  styleUrls: ['./one-col-tabbed.component.scss'],
})
export class OneColTabbedComponent implements OnInit {
  @Input() numberOfTabs;
  @Input() page!: Page;
  @Input() mapping;

  @Input() isWide: boolean;

  isLowerContentWide: boolean;
  isTitleContentWide: boolean;

  configuration: BrPageComponent['configuration'];
  tabs;
  public tabIndex: number[] = [];

  isEditMode = false;

  constructor(private viewModeService: ViewModeService) {
    this.isEditMode = this.viewModeService.isEditMode();
    this.tabs = Array(this.numberOfTabs).fill(this.numberOfTabs);
  }

  ngOnInit(): void {
    this.tabs = [];
    if (this.page?.getComponent('tab-area-1')) {
      for (let i = 1; i <= this.numberOfTabs; i++) {
        const tabObj = this.page
          .getComponent('tab-area-' + i)
          ?.getComponent('tab-label')
          ?.getModels()?.TabLabel;
        if (tabObj?.label || this.isEditMode) {
          this.tabs.push(tabObj);
          this.tabIndex.push(i);
        }
      }
    }

    this.isTitleContentWide =
      'true' ===
      this.page?.getComponent('title-content')?.getParameters()?.wideContainer;
    this.isLowerContentWide =
      'true' ===
      this.page?.getComponent('lower-content')?.getParameters()?.wideContainer;
  }
}
