import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonModule,
  GridModule,
  SimpleModalModule,
  SignInModule as EdsSignInModule,
  FlyoutMenuModule,
  FlexboxModule,
  LinkItemModule,
} from '@frk/eds-components';
import { SignInModalComponent } from '@shared/sign-in-modal/sign-in-modal.component';
import { TranslateModule } from '@shared/translate/translate.module';

@NgModule({
  declarations: [SignInModalComponent],
  imports: [
    CommonModule,
    ButtonModule,
    EdsSignInModule,
    GridModule,
    SimpleModalModule,
    TranslateModule,
    FlyoutMenuModule,
    FlexboxModule,
    LinkItemModule,
  ],
  exports: [SignInModalComponent],
})
export class SignInModalModule {}
