<button
  edsButton="secondary"
  (click)="unsubscribeDocuments()"
  edsPadding
  edsPaddingTop="xs"
  edsPaddingBottom="xs"
  size="compact"
  type="button"
>
  {{ "subscriptions.remove-from-watchlist" | translate }}
</button>
