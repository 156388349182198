import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AppStateService,
  RedirectService,
  SessionService,
  StorageService,
} from '@services';
import { Logger } from '@utils/logger';
import { AlertService } from '@accounts/services/alert.service';
import { DOCUMENT } from '@angular/common';
import { Auth0Client, RedirectLoginResult, User } from '@auth0/auth0-spa-js';
import { WINDOW } from '@ng-web-apis/common';
import { AnalyticsService } from '@frk/eds-components';

const logger = Logger.getLogger('Auth0LoginCallbackComponent');

@Component({
  selector: 'ft-auth0-login-callback',
  templateUrl: './auth0-login-callback.component.html',
  styleUrls: ['./auth0-login-callback.component.scss'],
})
export class Auth0LoginCallbackComponent implements OnInit {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private appStateService: AppStateService,
    private alertService: AlertService,
    private redirectService: RedirectService,
    private sessionService: SessionService,
    @Inject(DOCUMENT) private documentRef: Document,
    private analyticsService: AnalyticsService,
    @Inject(WINDOW) readonly windowRef: Window
  ) {}

  ngOnInit(): void {
    const params = new URLSearchParams(this.documentRef.location.search);
    if (params.get('error')) {
      const desc = params.get('error_description');
      switch (desc) {
        case 'email not verified': // user has decided to come back to ft.com without clicking link in email
          this.alertService.setAccountsAlert({
            verifyEmail: true,
          });
          logger.event('Auth0 - email not verified');
          break;
        case 'email resent': // user asked to have email resent so send them back to profile page where message will appear
          this.sessionService.auth0Authorize({
            ui_locales: this.appStateService.getHtmlLang(),
            resent: true,
          });
          logger.event('Auth0 - email resent');
          return; // Redirecting to profile so stop processing here
        case 'user not found in FA360':
          // need to logout as the user is still logged into auth0
          this.sessionService.logout(undefined, true);
          logger.event('Auth0 - user not found in FA360');
          // TODO need to set another alert to inform user
          // that their login is no longer recognized and they should re-register with a different email address
          break;
        default:
          //  TODO  how to handle from user perspective?
          // 'email resend failed': // the call to initiate another verification email failed
          // 'user is blocked'
          logger.event('Auth0 - something went wrong - ', desc);
          logger.error(desc); // log to DD
          break;
      }
      this.redirectToPage();
    } else if (params.get('iss')) {
      this.sessionService.auth0Authorize({
        ui_locales: this.appStateService.getHtmlLang(),
      });
    } else if (params.get('code') && params.get('state')) {
      this.sessionService.getAuth0Client()?.then((auth0: Auth0Client) => {
        auth0
          .handleRedirectCallback()
          .then((loginResult: RedirectLoginResult<any>) => {
            this.redirectToPage(true);
            auth0.getUser().then((profile: User | undefined) => {
              if (profile) {
                logger.event(`Auth0 Profile loaded for - ${profile.sub}`);
              }
            });
          });
      });
      this.trackEvent();
    } else {
      this.redirectToPage();
    }
  }

  private redirectToPage(isSuccessfulSignIn = false) {
    this.storageService.getAuth0CallbackRoute().then((callbackRoute) => {
      const route = callbackRoute || this.appStateService.getHomePageUrl();
      if (route?.startsWith('http')) {
        // needs a full page refresh e.g. for muniladder
        this.documentRef.location.href = route;
        return;
      }
      this.redirectService.getRedirect(route).then((redirectedRoute) => {
        if (redirectedRoute) {
          this.router.navigate([redirectedRoute]);
        } else {
          const splitRoute = route?.split('#');
          if (splitRoute?.length === 2) {
            this.router.navigate([splitRoute[0]], { fragment: splitRoute[1] });
          } else {
            this.router.navigate([route]);
          }
        }

        if (isSuccessfulSignIn) {
          // Log Successful Login event in dataDog
          this.storageService.isFreshLogin$.next(true);
          logger.event('Successful Login', route || redirectedRoute);
        }
      });
    });
  }

  private trackEvent(): void {
    // UA to remove after migration to GA4
    const trackObject = {
      event: 'login',
      name: 'logged in',
      method: 'auth0',
      page_location: this.windowRef.location.href,
    };

    const trackObjectGA4 = {
      event: 'login',
      detailed_event: 'User Signed In',
      event_data: {
        name: 'logged in',
        method: 'auth0',
      },
    };
    // UA to remove after migration to GA4
    this.analyticsService.trackEvent(trackObject);

    // GA4 call
    this.analyticsService.trackEvent(trackObjectGA4);
  }
}
