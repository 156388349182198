import { Component, Input, OnInit } from '@angular/core';

import { BrPageComponent } from '@bloomreach/ng-sdk';
import { Page } from '@bloomreach/spa-sdk';
import { ViewModeService } from '@services/view-mode.service';

@Component({
  selector: 'ft-one-col',
  templateUrl: './one-col.component.html',
  styleUrls: ['./one-col.component.scss'],
})
export class OneColComponent implements OnInit {
  /**
   * Bloomreach page object
   */
  @Input() page!: Page;

  /**
   * The content width
   * Standard A - 8 columns
   * Standard B - 10 columns
   * Standard C - 12 columns
   */
  @Input() contentWidth: 8 | 10 | 12;

  /**
   * The class name to be used for the main content
   * container
   */
  containerClass: string;

  isEditMode = false;

  /**
   * Component configuration
   */
  configuration: BrPageComponent['configuration'];

  constructor(private viewModeService: ViewModeService) {
    this.isEditMode = this.viewModeService.isEditMode();
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    this.contentWidth = 12; // All layouts are now full width with components controlling their container
  }
}
