import { Injectable, Inject } from '@angular/core';
import {
  SEARCH_RESULTS,
  FtSearchResponseItem,
  ItemHeader,
  FtSearchItem,
} from '@search/interfaces/search.interface';
import { FullResultsCommonService } from '../../full-results-common.service';
import { ConfigService } from '@search/services/config.service';
import { TranslateService } from '@shared/translate/translate.service';
@Injectable({
  providedIn: 'root',
})
export class FullResultsGeneralService {
  constructor(
    private configService: ConfigService,
    private translateService: TranslateService,
    @Inject(SEARCH_RESULTS) private commonDataService: FullResultsCommonService
  ) {}

  getGeneralHeader(displayedPage: number): ItemHeader {
    // TODO this should be dynamic, recognize which page is displayed.
    const counter = (displayedPage + 1) * this.commonDataService.resultsPerPage;
    const pagesKey = 'pages';
    const totalRecords = this.commonDataService.totalItems[pagesKey];
    const displayedRecords = Math.min(counter, totalRecords);
    const rightTitleText =
      this.translateService.instant('common.showing') +
      ' ' +
      displayedRecords +
      ' ' +
      this.translateService.instant('common.of') +
      ' ' +
      totalRecords;

    const itemHeader = {
      leftTitle: this.translateService.instant('common.search.general'),
      rightTitle: displayedRecords > 0 ? rightTitleText : '',
      viewMoreBtnText:
        totalRecords > displayedRecords
          ? this.translateService.instant('common.view-more-results')
          : '',
    };
    return itemHeader;
  }

  /**
   * TODO define input type
   */
  getGeneralItems(): object[] {
    const responseItems = this.commonDataService.results.filter((item) => {
      return item.name === 'pages';
    })[0].response.hits.hits;

    const generalItems = responseItems.map((item) => {
      return {
        title: this.getGeneralTitle(item),
        body: this.getGeneralBody(item),
        docTypeSrc: this.configService.getIconPath(item._source.pageTypeCustom),
        titleLink: this.configService.getLocalizedPath(
          item._source.relativePath
        ),
      };
    });

    return generalItems;
  }

  getGeneralBody(item: FtSearchItem): string {
    const formattedDate = item._source.referenceDate_formatted || '';
    const contentType = this.translateService.instant(
      'common.page-type.' + item._source.pageTypeCustom
    );
    const descriptionText = this.getGeneralContent(item);

    return `<span class="text-meta-tag">${formattedDate} | ${contentType}</span><br/>${descriptionText}`;
  }
  /**
   * returns plain or highlighted title
   * @param item search item
   */
  getGeneralTitle(item: FtSearchItem): string {
    return item.highlight?.title
      ? item.highlight?.title[0]
      : item._source.title;
  }

  /**
   * reads highlighted content for general
   * if _source.description is available, we display it
   * otherwise we cehck if highlight content is available
   * if both are not preent we return empty string
   * @param item search item
   */
  getGeneralContent(item: FtSearchItem): string {
    if (item._source?.description) {
      return item._source.description;
    }

    return item.highlight?.content ? item.highlight?.content[0] : '';
  }

  /**
   *
   * // TODO - this must be moved to service extracting data
   * // total may be set as component attribute
   */
  getTotal(response: FtSearchResponseItem, apiVer: string): number {
    return Number(
      apiVer === 'v2' ? response.hits.total.value : response.hits.total
    );
  }
}
