<div class="column1" edsTrapFocus>
  <eds-search-panel
    panelId="funds"
    [leftTitle]="fundHeader.leftTitle"
    [rightTitle]="fundHeader.rightTitle"
    [viewMoreBtnText]="fundHeader.viewMoreBtnText"
    (viewMoreTrigger)="onViewMore($event)"
  >
    <ng-container *ngFor="let fund of fundItems">
      <eds-thumbnail-search
        class="full-results__search-thumbnail"
        [hasBorders]="false"
        [hasShadows]="false"
        [headingLevel]="2"
        [layoutCompact]="true"
        [layoutCompactWide]="true"
        [fundFamily]="fund.fundFamily"
        [fundName]="fund.fundName"
        [shareClasses]="fund.shareClasses"
        [titleLink]="fund.fundLink"
        (shareclassClicked)="goToFund($event)"
        (onLinkClick)="onLinkClicked($event, fund, 'Investment Options')"
        titleTheme="search"
      >
      </eds-thumbnail-search>
    </ng-container>
    <ng-container *ngIf="!fundItems.length">
      <eds-thumbnail-search
        class="full-results__search-thumbnail"
        [hasBorders]="false"
        [hasShadows]="false"
        [headingLevel]="2"
        [layoutCompact]="true"
        [layoutCompactWide]="true"
        fundFamily=""
        [fundName]="noResultsFilters"
        shareClasses=""
        titleLink=""
      >
      </eds-thumbnail-search>
    </ng-container>
  </eds-search-panel>
</div>
