import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { ToggleState } from '../interfaces/search.interface';
import { AnalyticsService, ResponsiveService } from '@frk/eds-components';

const ANALYTICS_SEARCH_VIEW_MORE = 'search_view_more';

@Component({
  selector: 'ft-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit {
  @Input() results = [];
  @Input() aggregations = {};
  @Input() exactMatch = {
    data: {
      hits: [],
    },
    type: '',
  };
  @Input() originalQueryText: string;
  @Input() updatedQueryText: string;
  @Input() activeToggle: ToggleState = 'exact';
  @Input() displayedPage: number;
  @Input() searchTerm: string;

  @Output() filtered = new EventEmitter<number>();

  activeTab = 'all';
  foundMessage = '';

  /**
   * angular device detection
   */
  isHandheld$: Observable<boolean>;

  constructor(
    private analyticsService: AnalyticsService,
    private responsiveService: ResponsiveService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    this.isHandheld$ = this.responsiveService.isHandheld$();
  }

  /**
   *
   */
  onViewMore($event: { page: number; tabName: string }) {
    // we perform analytics tracking before changing activeTab
    this.analyticsService.trackEvent({
      event: ANALYTICS_SEARCH_VIEW_MORE,
      detailed_event: 'Site Search View More',
      event_data: {
        search_category: this.activeTab,
        search_column: this.getAnalyticsLabel($event.tabName),
        search_term: this.updatedQueryText.trim(),
      },
      search_category: this.activeTab,
      search_column: this.getAnalyticsLabel($event.tabName),
      search_term: this.updatedQueryText.trim(),
    });

    this.activeTab = $event.tabName;
    this.filtered.emit($event.page + 1);
  }

  /**
   * tranlates tab name to required analytics label
   * @param tabName string describing tab
   */
  getAnalyticsLabel(tabName: string) {
    return tabName === 'pages' ? 'general' : tabName;
  }

  /**
   *
   */
  onFiltered() {
    this.filtered.emit(0);
  }

  /**
   *
   */
  isEmptyObject(obj: {}) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }
}
