import dayjs from 'dayjs';
import enGbConfig from 'dayjs/locale/en-gb';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

dayjs.locale('en-za', { ...enGbConfig });
export const customEnZaConfig = dayjs.updateLocale('en-za', {
  ...enGbConfig,
  formats: {
    ...enGbConfig.formats,
    L: 'DD.MM.YYYY',
  },
});
