import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Page } from '@bloomreach/spa-sdk';
import {
  AdditionalLegacyLink,
  LanguageSelector,
  SignInComponentFieldsInfo,
  SignInEvent,
} from '@frk/eds-components';
import {
  AppStateService,
  GatewayModalService,
  GlobalConfigService,
  LanguageSelectorService,
  RouteHistoryService,
  SegmentService,
  SignInService,
  StorageService,
} from '@services';
import {
  AnalyticsLogin,
  ModalIntroTxt,
  SignInInterface,
  StaticPassAccessConfig,
} from '@shared/sign-in/sign-in.interface';
import { TranslateService } from '@shared/translate/translate.service';
import { Logger } from '@utils/logger';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LinkService } from '@services/link.service';

const logger = Logger.getLogger('SignInModalComponent');

@Component({
  selector: 'ft-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss'],
})
export class SignInModalComponent implements OnInit, OnDestroy {
  @Input() page: Page;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private isGatewaySignIn = false;
  private staticPasswordConfig: StaticPassAccessConfig;
  public isVisible: boolean;
  public signInForm: FormGroup;
  public isSubmitButtonDisabled = false;
  public signInComponentContent: SignInComponentFieldsInfo;
  public useSmallText: true;
  public isLegacy: boolean;
  public showClose = true;
  public languageOptions: LanguageSelector;
  public signIn: SignInInterface;
  public downloadLocation: string;
  public isStaticPassSignIn = false;
  @ViewChild('signinModal') signinModal: ElementRef;
  public isAuthLoginForm: boolean;
  public authSignInModalTitle$: Observable<string>;

  // prettier-ignore
  constructor( // NOSONAR - disable rule - Maximum allowed Constructor parameters 7. sonarlint(typescript:S107)
    private appStateService: AppStateService,
    private formBuilder: FormBuilder,
    private gatewayModalService: GatewayModalService,
    private globalConfigService: GlobalConfigService,
    private segmentService: SegmentService,
    private signInService: SignInService,
    private translateService: TranslateService,
    private languageSelectorService: LanguageSelectorService,
    private router: Router,
    private routeHistory: RouteHistoryService,
    private linkService: LinkService,
    private storageService: StorageService,
    @Inject(DOCUMENT) readonly documentRef: Document
  ) {
    this.signInForm = this.signInService.signInFormInit(this.formBuilder);
    this.isLegacy = this.getLegacy();
    this.isAuthLoginForm = this.appStateService.isAuth0Login();
  }

  ngOnInit(): void {
    this.linkService
      .getCurrentLinkData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((linkData) => {
        this.downloadLocation = linkData;
      });
    this.isGatewaySignIn = this.segmentService.checkSegmentRestrict(
      this.page,
      true
    );
    this.signIn = this.getSignIn();
    this.signInComponentContent = this.signInService.getSignInComponentFieldsInfo(
      this.signIn
    );
    this.setModalCustomizationFields();

    this.signInService
      .getFastTrackRegistration$(this.signIn)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((signIn: SignInInterface) => {
        this.signInComponentContent = this.signInService.getSignInComponentFieldsInfo(
          signIn
        );
        this.setModalCustomizationFields();
      });
    this.signInService
      .getSignInCustomIntroTxtModal$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customMsg: ModalIntroTxt) => {
        if (customMsg.showMsg) {
          this.signInComponentContent.introText = this.translateService.instant(
            customMsg.customLabel
          );
        }
      });
    this.signInService
      .getSignInCustomContentModal$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customTextLabel: string) => {
        // When links don't have parameter [data-signin-custom-content-text] customTextLabel can be empty.
        if (customTextLabel) {
          // Do not set custom content for fast track registration.
          if (!this.signInComponentContent.fastTrackRegistrationButtonLabel) {
            this.signInComponentContent.bodyContent = this.replaceRefToken(
              this.translateService.instant(customTextLabel)
            );
          }
          // Getting default subTitle label when "Custom Body" is not set.
          // For "Custom Body" sub-title will be not displayed.
          if (customTextLabel === 'signin.bodyContent') {
            this.isLegacy = this.getLegacy();
          } else {
            this.isLegacy = false;
          }
        }
      });

    this.signInService
      .getSignInModal$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isVisible: boolean) => {
        this.showSignInModal(isVisible);
      });

    if (this.isGatewaySignIn) {
      this.languageOptions = this.languageSelectorService.getLanguageOptions(
        this.page
      );
      this.signInComponentContent = this.gatewayModalService.cleanGatewaySignIn(
        this.signInComponentContent
      );
      this.showClose = !this.appStateService.isHomePage();
      this.signInComponentContent.bodyContent = this.replaceRefToken(
        this.translateService.instant('signin.gatewaySignInBody')
      );
      this.gatewayModalService
        .getSignInGatewayModal$()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((isVisible: boolean) => {
          this.showSignInModal(isVisible);
        });
      this.gatewayModalService.setScrollBodyElement(
        this.documentRef.getElementsByTagName('body')
      );
      this.gatewayModalService.checkSignInGatewayModal();
    }
    // Listen for Static Password config change.
    this.signInService
      .getStaticPassAccessConfig$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((staticPassConfig: StaticPassAccessConfig) => {
        this.staticPassSignIn(staticPassConfig);
      });
    this.authSignInModalTitle$ = this.signInService.getAuth0SignInModalTitle$();
  }

  private setModalCustomizationFields(): void {
    // Set ctaText to null to not display sign in button for EDS sign-in component.
    this.signInComponentContent.ctaText = null;
    // Set introText from label
    this.signInComponentContent.introText = this.translateService.instant(
      'signin.introText'
    );
  }

  private showSignInModal(isVisible: boolean): void {
    this.isVisible = isVisible;
    if (this.isVisible && !this.isAuthLoginForm) {
      setTimeout(() => {
        this.signinModal.nativeElement.getElementsByTagName('input')[0].focus();
      });
    }
  }

  /**
   * Set sign-in parameters required for component
   */
  private getSignIn(): SignInInterface {
    return {
      legacy: this.isLegacy,
      signOut: false,
      skin: 'basic',
      showLegacyContent: this.isLegacy,
    };
  }
  /**
   * Set LM legacy for component
   */
  private getLegacy(): boolean {
    // Check Site configuration first
    if (
      this.globalConfigService.config.siteConfiguration?.site
        ?.authenticationLegacy
    ) {
      return this.globalConfigService.config.siteConfiguration.site
        .authenticationLegacy;
    }
    return false;
  }

  public onSubmit(signIn: SignInEvent) {
    logger.debug('SignInEvent', signIn);
    const signInFormValid = this.signInService.isSignInFormValid(
      signIn,
      this.signInForm,
      this.signInComponentContent
    );
    this.signInForm = signInFormValid.signInForm;
    this.signInComponentContent = signInFormValid.signInComponentContent;
    this.isSubmitButtonDisabled = signInFormValid.canSubmit;
    if (signInFormValid.canSubmit) {
      const submitObject: AnalyticsLogin = {
        signInForm: this.signInForm,
        analyticsKey: 'Modal',
      };
      this.signInService.onSubmit(submitObject, this.downloadLocation);
    }
  }

  public onSubmitExt(): void {
    const signInInput: HTMLCollectionOf<HTMLInputElement> = this.getSignInModalSubElement(
      'input'
    );
    const signIn: SignInEvent = {
      userName: signInInput.namedItem('userName').value,
      password: signInInput.namedItem('password').value,
      rememberMe: false,
    };
    if (
      this.isGatewaySignIn ||
      this.staticPasswordConfig?.staticPassAccessUsers?.length > 0
    ) {
      this.signInForm.value.userId = signIn.userName;
      this.signInForm.value.password = signIn.password;
      // Set error label when sign-in fail.
      this.signInComponentContent.errorText = this.translateService.instant(
        'signin.modalSignInErrorLabel'
      );
    }
    if (this.isGatewaySignIn) {
      this.gatewayModalService.onSubmit(this.signInForm);
      if (this.gatewayModalService.getSignInSuccess()) {
        this.gatewayModalService.hideSignInGatewayModal();
      }
      return;
    }
    if (this.staticPasswordConfig?.staticPassAccessUsers?.length > 0) {
      this.staticPassSignInCheck(this.signInForm);
    }
    this.onSubmit(signIn);
  }

  /**
   * Close modal window
   */
  public closeModal(): void {
    this.signInService.hideSignInModal();

    // Remove download location entry from local storage if user chose to terminate the signin process
    this.storageService.remove('current_download_location');
    if (
      this.isGatewaySignIn ||
      this.staticPasswordConfig?.staticPassAccessUsers?.length > 0
    ) {
      this.storageService.remove(
        this.storageService.staticPassCookieName(
          this.staticPasswordConfig?.cookieNameSuffix
        )
      );
      const navigateTo: string = this.checkHistoryNav();
      // Redirecting "simple login" to homepage when modal close and there are no routing history.
      this.router.navigateByUrl(navigateTo).then((redirect: boolean) => {
        if (!redirect) {
          logger.debug('Redirect to homepage failed');
        }
      });
    }
  }

  /**
   * redirect to auth0 signup page
   */
  public fastTrackRegistration() {
    this.closeModal();
    this.signInService.submitAuth0('signup');
  }

  /**
   * Returns string url for redirect after modal close
   * @returns - string redirect to
   */
  private checkHistoryNav(): string {
    const history: string[] = this.routeHistory.getRouteHistory();
    // Going back to last but one record in history which is previously visited page.
    if (history.length > 1) {
      return history[history.length - 2];
    }
    return this.appStateService.relativeHomeURL(
      this.appStateService.getHomePageUrl()
    );
  }

  // WDE-533 - Listen for key event in sign in form.
  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'Tab') {
      // Get Inputs and buttons for form
      const signInForm: NodeListOf<HTMLElement> = this.getSignInModalNodeList(
        'input, button'
      );
      const activeFormElement: Element = this.documentRef.activeElement;
      const firstFormElement: HTMLElement = signInForm[1];
      const lastFormElement: HTMLElement = signInForm[signInForm.length - 1];
      if (event.shiftKey) {
        // Covers Shift+Tab
        if (activeFormElement === firstFormElement) {
          lastFormElement.focus();
          event.preventDefault();
        }
      } else if (activeFormElement === lastFormElement) {
        firstFormElement.focus();
        event.preventDefault();
      }
    }
  }

  /**
   * Getting SignIn Modal HTML element
   * @returns HTMLElement - SignIn modal element
   */
  private getSignInModalElement(): HTMLElement {
    return this.documentRef.getElementById('signInModal');
  }

  /**
   * Getting HTMLCollection of elements from modal
   * @param element string - element to get
   * @returns HTMLCollectionOf<any> - elements collection
   */
  private getSignInModalSubElement(element: string): HTMLCollectionOf<any> {
    const signInModal: HTMLElement = this.getSignInModalElement();
    return signInModal.getElementsByTagName(element);
  }

  /**
   * Getting Node list of elements
   * @param elements string - list of elements separated with coma
   * @returns NodeListOf<HTMLElement> - elements list
   */
  private getSignInModalNodeList(elements: string): NodeListOf<HTMLElement> {
    const signInModal: HTMLElement = this.getSignInModalElement();
    return signInModal.querySelectorAll<HTMLElement>(elements);
  }

  public replaceRefToken(label: string): string {
    return label?.replace(
      '$REFURL',
      this.signInService.getRedirectURL('', true)
    );
  }

  /**
   * Trigger legacy button click
   * @param linkEvent - AdditionalLegacyLink
   */
  public legacyBtnclick(linkEvent: AdditionalLegacyLink): void {
    this.signInService.openLegacyLink(linkEvent);
  }

  /**
   * Static Password SignIn display
   * @param staticPassConfig - Static Pass Access Config
   */
  private staticPassSignIn(staticPassConfig: StaticPassAccessConfig): void {
    if (staticPassConfig) {
      this.signIn.overrideHideLoginForm = true;
      this.signInComponentContent = this.signInService.getSignInComponentFieldsInfo(
        this.signIn
      );
      this.signInComponentContent = this.gatewayModalService.cleanGatewaySignIn(
        this.signInComponentContent
      );
      this.signInComponentContent.additionalInformationIcon = null;
      this.signInComponentContent.additionalInformationText = null;
      this.signInComponentContent.bodyContent =
        staticPassConfig?.modalBodyContent ||
        this.translateService.instant('signin.bodyContent');
      this.staticPasswordConfig = staticPassConfig;
      this.isStaticPassSignIn = true;
      this.showSignInModal(true);
    }
  }

  /**
   * Static Password SignIn Check
   * @param signInForm - Form Group
   */
  private staticPassSignInCheck(signInForm: FormGroup): void {
    const hashedKey = this.storageService.getHashKey(signInForm, false);
    const isPassCorrect = this.signInService.isStaticPasswordCorrect(
      this.staticPasswordConfig?.staticPassAccessUsers,
      hashedKey
    );
    if (isPassCorrect) {
      this.storageService.store(
        this.storageService.staticPassCookieName(
          this.staticPasswordConfig?.cookieNameSuffix
        ),
        hashedKey
      );
      this.showSignInModal(false);
    }
  }

  public goToAuth0(hint: string): void {
    this.signInService.submitAuth0(hint);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.signInService.hideSignInModal();
    this.signInService.hideCustomIntroTxtInModal();
  }
}
