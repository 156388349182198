<div class="column-3" edsTrapFocus>
  <eds-search-panel
    panelId="pages"
    [leftTitle]="generalHeader.leftTitle"
    [rightTitle]="generalHeader.rightTitle"
    [viewMoreBtnText]="generalHeader.viewMoreBtnText"
    (viewMoreTrigger)="onViewMore($event)"
  >
    <ng-container *ngFor="let item of generalItems">
      <eds-thumbnail
        class="full-results__search-thumbnail"
        [hasBorders]="false"
        [hasShadows]="false"
        [headingLevel]="2"
        [layoutSearch]="true"
        [docTypeSrc]="item.docTypeSrc"
        [title]="item.title"
        [body]="item.body"
        [titleLink]="item.titleLink"
        [hasSearchIcon]="true"
        [noTitleColor]="true"
        titleTheme="search"
        (linkClick)="onLinkClicked($event, item)"
      >
      </eds-thumbnail>
    </ng-container>
    <ng-container *ngIf="!generalItems.length">
      <eds-thumbnail-search
        class="full-results__search-thumbnail"
        [hasBorders]="false"
        [hasShadows]="false"
        [headingLevel]="2"
        [layoutCompact]="true"
        [layoutCompactWide]="true"
        fundFamily=""
        [fundName]="noResultsFilters"
        shareClasses=""
        titleLink=""
      >
      </eds-thumbnail-search>
    </ng-container>
  </eds-search-panel>
</div>
