<ng-container *ngIf="!isDebugMode">
  <section edsSpacing="xxl" edsGrid *ngIf="!isDebugMode">
    <div class="container container--wide">
      <!-- Articles view -->
      <div class="row">
        <div
          edsCol
          [ngStyle]="{ position: 'relative' }"
          [columnNumbers]="
            sideBarDocumentRef
              ? { s: '4', m: '6', l: '5', push: '-l2' }
              : { s: '4', m: '6', l: '6' }
          "
        >
          <div
            id="insightScrollPosition"
            edsFlexbox
            flexWrap="wrap-reverse"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <ng-container *ngIf="totalRecords">
                <p edsSpacing="none">
                  {{ translationLabel?.showing }}
                  <strong>{{ visibleRecords }}</strong>
                  {{ translationLabel?.of }}
                  <strong>{{ totalRecords }}</strong>
                </p>
              </ng-container>
            </div>
          </div>
          <div edsSpacing="lg"></div>
          <div *ngIf="!results?.length">
            <div>
              <p class="large bold">{{ translationLabel?.noRecordFound }}</p>
            </div>
          </div>
          <ng-container *ngIf="results?.length">
            <div *ngFor="let result of results">
              <div edsSpacing="lg">
                <ng-container
                  *ngTemplateOutlet="
                    articleCard;
                    context: {
                      result: result,
                      isCompact: true
                    }
                  "
                ></ng-container>
              </div>
            </div>
          </ng-container>
          <!-- Pagination -->
          <div edsSpacing="lg"></div>
          <div edsRow *ngIf="totalRecords > options?.counters?.pages">
            <eds-pagination
              edsCol
              [edsSpacing]="'lg'"
              [prevPageText]="translationLabel?.previous"
              [nextPageText]="translationLabel?.next"
              [ariaLabelGoToPage]="translationLabel?.ariaLabelGoToPage"
              [pages]="totalPages"
              [pageTitle]="pageTitle"
              (pageChanged)="goToPage($event)"
            ></eds-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<ng-template let-result="result" let-isCompact="isCompact" #articleCard>
  <eds-card-article
    [hasImage]="result._source?.imgSrc ? true : false"
    [imgSrc]="result._source?.imgSrc"
    [title]="result._source?.title"
    [description]="result._source?.description"
    [date]="result._source?.referenceDate"
    [href]="result._source?.relativePath"
    [isCompact]="isCompact"
    [logoLink]="result._source?.SIM?.logoSrc"
    [altLogoText]="result._source?.SIM?.logoAltText | titlecase"
    [logoTheme]="result._source?.SIM?.logoTheme"
    [logoBackgroundColor]="result._source?.SIM?.logoBackgroundColor"
    [subTitle]="
      result._source?.isArchived ? this.translationLabel?.archived : ''
    "
    [trackDownloads]="result._source.externalLinkData?.downloadtrackingflag"
    [trackImpression]="result._source.externalLinkData?.impressiontrackingflag"
    [isNewWindow]="result._source.externalLinkData?.isNewWindowflag"
    [externalLink]="result._source.externalLinkData?.externallinkflag"
    [externalLinkSuffix]="result._source.externalLinkData?.leavingSiteModalKey"
  >
  </eds-card-article>
</ng-template>
