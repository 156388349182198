import { Pipe, PipeTransform } from '@angular/core';
import { FundId } from '@types';
import { TranslateService } from './translate.service';

@Pipe({
  name: 'translate',
  pure: true,
})
export class TranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(key: string | string[], fundId?: FundId, ...args: any[]): string {
    if (Array.isArray(key)) {
      // if array of keys, join using a space
      return key
        .map((k) => this.transform(k, fundId, ...args) as string)
        .join(' ');
    }
    return this.translateService.instant(key, fundId, ...args);
  }
}
