import { Injectable } from '@angular/core';
import {
  ProductDetailConfiguration,
  RiskEfficiencyTableData,
  RiskStatisticsAnalysisState,
} from '@types';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import { RISK__EFFICIENCY_ANALYSIS_INITIAL_STATE } from './fund-performance.config';
import { FundOverviewService } from '@products/services/fund-overview.service';
import riskEfficiencyAnalysisQuery from '@graphql/fund-performance/risk-efficiency-analysis.graphql';
import { Logger } from '@utils/logger';
import { Product } from '@models';
import { RiskStatistics } from '@products/models/risk-statistics';
import { TranslateService } from '@shared/translate/translate.service';

const logger = Logger.getLogger('EfficiencyAnalysisService');

enum EfficiencyAnalysisRows {
  BULL_MARKET = 'bull-market',
  LOW_MARKET = 'low-market',
  TOTAL = 'total',
  AVERAGE_EXCESS = 'average-excess',
}

@Injectable({
  providedIn: 'root',
})
export class EfficiencyAnalysisService {
  riskEfficiencyAnalysis$: Subject<RiskStatisticsAnalysisState>;
  private state: RiskStatisticsAnalysisState;
  private fundName: string;

  constructor(
    private overviewService: FundOverviewService,
    private translateService: TranslateService
  ) {
    this.state = cloneDeep(RISK__EFFICIENCY_ANALYSIS_INITIAL_STATE);
    this.riskEfficiencyAnalysis$ = new Subject();
  }

  /**
   * Call register method of fund overview service.
   */
  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    this.overviewService
      .register(riskEfficiencyAnalysisQuery, productDetailConfiguration)
      .subscribe(
        (product) => {
          logger.debug(product);
          this.state = this.mapState(product);
          this.riskEfficiencyAnalysis$.next(this.state);
        },
        (error) => {
          logger.error(
            `Error in getting Efficency Analysis data for fund id ${productDetailConfiguration.fundId}
             and share class ${productDetailConfiguration.shareClassCode}`,
            error
          );
          this.state.isError = true;
          this.riskEfficiencyAnalysis$.next(this.state);
        }
      );
  }

  mapState(product: Product): RiskStatisticsAnalysisState {
    this.fundName = product.fundName;
    const shareClassData = product.shareClasses[0];
    if (
      shareClassData.riskStatistics &&
      this.checkIfSingleValueExist(shareClassData.riskStatistics)
    ) {
      const tableData = Object?.values(EfficiencyAnalysisRows).map((item) => {
        return this.buildRowForEfficiencyAnalysis(
          item,
          shareClassData.riskStatistics
        );
      });
      return {
        shareClassName: shareClassData.shareClassName,
        efficiencyAnalysis: tableData,
        asOfDate: shareClassData.riskStatistics.asOfDate,
        isError: false,
      };
    } else {
      return {
        shareClassName: shareClassData.shareClassName,
        efficiencyAnalysis: [],
        asOfDate: shareClassData.riskStatistics?.asOfDate,
        isError: true,
      };
    }
  }

  /**
   * Check if values exist for the provided keys
   * @param statDict RiskStatistics
   * @returns boolean
   */
  private checkIfSingleValueExist(statDict: RiskStatistics): boolean {
    const keysUsedInEfficinecyAnalysisTable = [
      'averageExcessMarketAboveTheBenchmark2yr',
      'averageExcessMarketBelowTheBenchmark2yr',
      'bearMarketAboveTheBenchmark2yr',
      'bearMarketAverageAccess2yr',
      'bearMarketBelowTheBenchmark2yr',
      'bearMarketHitRate2yr',
      'bearMarketTotal2yr',
      'bullMarketAboveTheBenchmark2yr',
      'bullMarketAverageAccess2yr',
      'bullMarketBelowTheBenchmark2yr',
      'bullMarketHitRate2yr',
      'bullMarketTotal2yr',
      'totalMarketAboveTheBenchmark2yr',
      'totalMarketAverageAccess2yr',
      'totalMarketBelowTheBenchmark2yr',
      'totalMarketHitRate2yr',
      'totalMarketOfTotal2yr',
    ];
    for (const key of keysUsedInEfficinecyAnalysisTable) {
      if (statDict[key]) {
        return true;
      }
    }
    return false;
  }

  private buildRowForEfficiencyAnalysis(
    rowName: string,
    data: RiskStatistics
  ): RiskEfficiencyTableData {
    switch (rowName) {
      case EfficiencyAnalysisRows.BULL_MARKET:
        return {
          statistic: rowName,
          statisticLabel: `products.efficiency-analysis-${rowName}`,
          aboveTheBenchmark: data.bullMarketAboveTheBenchmark2yr,
          belowTheBenchmark: data.bullMarketBelowTheBenchmark2yr,
          averageExcess: data.bullMarketAverageAccess2yr,
          hitRate: data.bullMarketHitRate2yr,
          total: data.bullMarketTotal2yr,
          footnote: `products.efficiency-analysis-footnote-${rowName}`,
          tooltip: this.translateService.tooltip(
            `products.efficiency-analysis-tooltip-${rowName}`
          ),
        };

      case EfficiencyAnalysisRows.LOW_MARKET:
        return {
          statistic: rowName,
          statisticLabel: `products.efficiency-analysis-${rowName}`,
          aboveTheBenchmark: data.bearMarketAboveTheBenchmark2yr,
          belowTheBenchmark: data.bearMarketBelowTheBenchmark2yr,
          averageExcess: data.bearMarketAverageAccess2yr,
          hitRate: data.bearMarketHitRate2yr,
          total: data.bearMarketTotal2yr,
          footnote: `products.efficiency-analysis-footnote-${rowName}`,
          tooltip: this.translateService.tooltip(
            `products.efficiency-analysis-tooltip-${rowName}`
          ),
        };

      case EfficiencyAnalysisRows.TOTAL:
        return {
          statistic: rowName,
          statisticLabel: `products.efficiency-analysis-${rowName}`,
          aboveTheBenchmark: data.totalMarketAboveTheBenchmark2yr,
          belowTheBenchmark: data.totalMarketBelowTheBenchmark2yr,
          averageExcess: data.totalMarketOfTotal2yr,
          hitRate: data.totalMarketHitRate2yr,
          total: data.totalMarketOfTotal2yr,
          footnote: `products.efficiency-analysis-footnote-${rowName}`,
          tooltip: this.translateService.tooltip(
            `products.efficiency-analysis-tooltip-${rowName}`
          ),
        };

      case EfficiencyAnalysisRows.AVERAGE_EXCESS:
        return {
          statistic: rowName,
          statisticLabel: `products.efficiency-analysis-${rowName}`,
          aboveTheBenchmark: data.averageExcessMarketAboveTheBenchmark2yr,
          belowTheBenchmark: data.averageExcessMarketBelowTheBenchmark2yr,
          averageExcess: null,
          hitRate: null,
          total: null,
          footnote: `products.efficiency-analysis-footnote-${rowName}`,
          tooltip: this.translateService.tooltip(
            `products.efficiency-analysis-tooltip-${rowName}`
          ),
        };
    }
  }
}
