import {
  Component,
  OnDestroy,
  ChangeDetectorRef,
  Input,
  OnInit,
  Inject,
} from '@angular/core';
import { SiteAlertService } from '@accounts/services/site-alert.service';
import {
  NotificationDetails,
  NotificationMessage,
} from '@accounts/accounts-alerts/accounts-alerts.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Logger } from '@utils/logger';
import { Page } from '@bloomreach/spa-sdk';
import { WINDOW } from '@ng-web-apis/common';
import { AnalyticsService } from '@frk/eds-components';
import { GlobalConfigService, ProfileService } from '@services';
import { CommonConfig } from '@types';

const logger = Logger.getLogger('SiteAlertComponent');
@Component({
  selector: 'ft-site-alert',
  templateUrl: './site-alert.component.html',
})
export class SiteAlertComponent implements OnDestroy, OnInit {
  /**
   * The Bloomreach page
   */
  @Input() page!: Page;

  /**
   * The Notification details
   */
  siteAlerts: NotificationMessage[];
  notificationDetails: NotificationDetails;
  isLoggedIn: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private siteGeneral: CommonConfig;
  public notificationsEnabled: boolean;

  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    private cd: ChangeDetectorRef,
    public siteAlertService: SiteAlertService,
    private analyticsService: AnalyticsService,
    private pageConfigService: GlobalConfigService,
    private profileService: ProfileService
  ) {
    this.siteGeneral = this.pageConfigService.getSiteGeneral();
    this.notificationsEnabled = this.siteGeneral?.notificationsEnabled || false;
  }

  ngOnInit(): void {
    if (!this.notificationsEnabled) {
      return;
    }

    this.setNotificationContext();
    this.siteAlertService
      .getSiteAlerts$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.siteAlerts = data;
        this.cd.detectChanges();
      });
    this.profileService
      .isLoggedIn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
  }
  getTarget(type) {
    return type?.target || '';
  }
  ////////////////////
  // Public methods //
  ////////////////////
  public processClick(
    buttonType: string,
    siteAlert: NotificationMessage
  ): void {
    if (buttonType === 'primary') {
      this.trackEvent(
        siteAlert?.notificationDetails?.primaryBtnData?.displayText,
        this.getTarget(
          siteAlert?.notificationDetails?.notificationButton?.primary
        )
      );
    } else if (buttonType === 'secondary') {
      this.trackEvent(
        siteAlert?.notificationDetails?.secondaryBtnData?.displayText,
        this.getTarget(
          siteAlert?.notificationDetails?.notificationButton?.secondary
        )
      );
    } else {
      this.trackEvent(
        siteAlert?.notificationDetails?.tertiaryBtnData?.displayText,
        this.getTarget(
          siteAlert?.notificationDetails?.notificationButton?.tertiary
        )
      );
    }
  }

  public closeSiteAlert(notificationDetails: NotificationDetails): void {
    this.siteAlertService.removeSiteAlert(notificationDetails);
  }

  /////////////////////
  // Private methods //
  /////////////////////
  private trackEvent(displayTxt: string, linkUrl: string): void {
    this.analyticsService.trackEvent({
      event: 'linkOrButtonClick',
      detailed_event: 'Link or Button Click',
      event_data: {
        category: '',
        link_id: '',
        link_text: displayTxt,
        link_type: this.analyticsService.getLinkType(linkUrl),
        link_url: linkUrl,
      },
      link_text: displayTxt,
      link_url: linkUrl,
    });
  }

  /**
   * Set site name and notification page type
   */
  private setNotificationContext(): void {
    // Get site query scope
    const scope = this.page?.getComponent('notifications')?.getModels()?.scope;
    const siteName = this.getSiteNameFromScope(scope);
    logger.debug('Site name from scope', siteName);
    if (siteName) {
      this.siteAlertService.setSiteName(siteName);
    }

    // Get Notification Page type from page data
    const pageData = this.page?.getComponent()?.getModels()?.pageData;
    let notificationPageType = pageData?.notificationPageType;
    // workaround for product pages - which are not xpages
    if (!notificationPageType && pageData?.pageType === 'product') {
      notificationPageType = 'product';
    }
    this.siteAlertService.setNotificationPageType(notificationPageType);
  }

  /**
   * Returns site name required for notifications param.
   * @param scope - string site name scope path
   */
  private getSiteNameFromScope(scope: string): string | null {
    if (scope) {
      const scopeArray: string[] = scope.split('/');
      return scopeArray.length > 1
        ? scopeArray?.slice(1).join('/')
        : scopeArray?.pop();
    }
    return null;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
