import { Component, Input, OnInit } from '@angular/core';
import {
  InfoTableItem,
  RowData,
  TableHeader,
} from '../table-selection.interface';

@Component({
  selector: 'ft-table-selection-card',
  templateUrl: './table-selection-card.component.html',
})
export class TableSelectionCardComponent implements OnInit {
  /**
   * table rows data
   */
  @Input() rowData: Array<RowData>;

  /**
   * table header data
   */
  @Input() headerData: Array<TableHeader>;

  /**
   * card data will be set in info item
   */
  infoItems: InfoTableItem[][] = [];

  ngOnInit(): void {
    this.setCardData();
  }
  /**
   * creating the card data from table Data
   */
  private setCardData() {
    for (const row of this.rowData[0]?.tableStyleRow) {
      const data = [];
      for (const i in row) {
        if (row[i]) {
          data.push({
            label: this.headerData[i].title,
            val: row[i].addColumn,
            linkText: row[i].linkCompound?.linkCollection[0]?.displayText,
            iconType: row[i].icon,
            externalLinkClass: row[i]?.linkCompound?.externalLinkClass,
            href: row[i].linkCompound?.linkCollection[0]?.url,
            target: row[i].linkCompound?.linkCollection[0]?.target,
          });
        }
      }
      this.infoItems.push(data);
    }
  }
}
