import { Component, Input, OnInit } from '@angular/core';

import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { WidenService } from '@services/widen.service';
import { ResponsiveService } from '@frk/eds-components';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ImageBlockContent {
  title?: string;
  summary?: string;
  source?: string;
  widenAsset?: string;
  altText?: string;
  imageSrc?: string;
  imageProperties: ImageProperties;
  mobileImage?: MobileImage;
}

interface ImageProperties {
  imageSize?: string;
  horizontalAlignment?: 'left' | 'center' | 'right';
  verticalAlignment?: 'start' | 'center' | 'end';
}

interface MobileImage {
  name: string;
  displayName: string;
  widenDocument: string;
  contentType: string;
}

@Component({
  selector: 'ft-image-block',
  templateUrl: './image-block.component.html',
  styleUrls: ['./image-block.component.scss'],
})
export class ImageBlockComponent
  extends AbstractBaseComponent
  implements OnInit {
  /**
   * Content to render
   */
  @Input()
  content: ImageBlockContent;

  /**
   * Ng Styles
   */
  imageStyle: {};

  /**
   * Flexbox vertical alignment
   */

  alignItems: string;

  /**
   * Flexbox horizontal aligment
   */
  justifyContent: string;
  private isMobile$: Observable<boolean>;
  private unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private widenService: WidenService,
    private responsiveService: ResponsiveService
  ) {
    super();
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    this.isMobile$ = this.responsiveService.isMobile$();
    // Widen processing
    this.isMobile$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isMobile: boolean) => {
        const mobileImage = this.content?.mobileImage?.widenDocument;
        let imageContent = this.content?.widenAsset;
        if (isMobile && mobileImage) {
          // Mobile image if exists
          imageContent = mobileImage;
        }
        if (imageContent) {
          this.content.imageSrc = this.widenService.getWidenImageVariantUrl(
            JSON.parse(imageContent).url,
            'original',
            'webp'
          );
        }
      });
    // Read image properties
    if (this.content.imageProperties) {
      // Flex properties
      this.alignItems = this.setAlignItems(this.content.imageProperties);
      this.justifyContent = this.setJustifyContent(
        this.content.imageProperties
      );
      // Image Style
      this.imageStyle = {
        height: 'auto',
        width: this.content.imageProperties.imageSize,
      };
    }
  }

  /**
   * Set Flexbox alignment
   * @param imageProperties cms managed properties
   */
  setAlignItems(imageProperties: ImageProperties): string {
    let alignment = 'center';
    switch (imageProperties.verticalAlignment) {
      case 'start':
        alignment = 'flex-start';
        break;
      case 'end':
        alignment = 'flex-end';
    }
    return alignment;
  }

  /**
   * Set Flexbox justify content
   * @param imageProperties cms managed properties
   */
  setJustifyContent(imageProperties: ImageProperties): string {
    let alignment = 'center';
    switch (imageProperties.horizontalAlignment) {
      case 'left':
        alignment = 'flex-start';
        break;
      case 'right':
        alignment = 'flex-end';
    }
    return alignment;
  }
}
