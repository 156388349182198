import { Component, Input, OnInit } from '@angular/core';
import { WidenService } from '@services';
interface ImgVideoAsset {
  assetSrc?: string;
  assetType?: string;
  assetAltText?: string;
  isVideoAsset?: boolean;
  tarnscriptionTracks?: TarnscriptionTrack[];
}

interface TarnscriptionTrack {
  trackLabel: string;
  trackLanguage: string;
  trackSrc: string;
}

@Component({
  selector: 'ft-widen-img-video',
  templateUrl: './widen-img-video.component.html',
  styleUrls: ['./widen-img-video.component.scss'],
})
export class WidenImgVideoComponent implements OnInit {
  @Input() widenAssetJson: string;
  @Input() assetAltText: string;
  @Input() tarnscriptionTracks: TarnscriptionTrack[];
  public imgVideoAsset: ImgVideoAsset;

  constructor(private widenService: WidenService) {
    // Constructor placeholder
  }

  ngOnInit(): void {
    if (this.widenAssetJson) {
      this.imgVideoAsset = {
        assetSrc: this.widenService.getWidenAssetUrl(this.widenAssetJson),
        assetType: this.widenService.getAssetFileExtension(this.widenAssetJson),
        assetAltText: this.assetAltText || '',
        isVideoAsset: this.widenService.isVideoAsset(this.widenAssetJson),
      };
      if (this.tarnscriptionTracks && this.tarnscriptionTracks.length > 0) {
        this.imgVideoAsset.tarnscriptionTracks = this.tarnscriptionTracks;
      }
    }
  }
}
