// marketing.module.ts
// Angular Modules

import {
  BreadcrumbsModule,
  ButtonModule,
  CardModule,
  DialogModule,
  DynamicCardModule,
  EnhancedFilterModule,
  FlexboxModule,
  FooterModule,
  GridModule,
  IconModule,
  ImageModule,
  LegalModule,
  LinkItemModule,
  MarketoButtonModule,
  MarketoFormModule,
  PaddingModule,
  PanelModule,
  ProgressBarModule,
  SectionHeaderModule,
  SectionTitleModule,
  SpacingModule,
  StaggeredBoxesModule,
  TabsStickyModule,
  TextInputModule,
  TitleModule,
  VideoModule,
  DropdownModule,
  RadioModule,
  TextAreaModule,
  MediaCardModule,
} from '@frk/eds-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import { CodeComponent } from './code/code.component';
import { CommonModule } from '@angular/common';
import { CustomFormComponent } from './custom-form/custom-form.component';
import { GatewayComponent } from './gateway/gateway.component';
import { GatewayModalService } from '@services/gateway-modal.service';
import { NgModule } from '@angular/core';
import { PageMetadataComponent } from './page-metadata/page-metadata.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { VideoComponent } from './video/video.component';
import { RichTextComponent } from './rich-text/rich-text.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { StaggeredBoxesComponent } from './staggered-boxes/staggered-boxes.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { RoleSelectorBannerComponent } from './role-selector-banner/role-selector-banner.component';
import { RecaptchaModule } from 'ng-recaptcha';
// Separate component modules and mappings
import {
  ComponentMapping,
  DynamicModule,
} from '@services/module-loader.config';
import { MyliteratureService } from '@literature/literature-dashboard/services/myliterature.service';
import { CartModalModule } from '@shared/cart-modal/cart-modal.module';
import { ImportantLegalComponent } from '@shared/important-legal/important-legal.component';
import { RoleSelectorModalComponent } from './role-selector-modal/role-selector-modal.component';
import { LiteratureDialogModule } from '@literature/literature-dialog/literature-dialog.module';
import { FeaturedContentCardsComponent } from './featured-content-cards/featured-content-cards.component';
import { VideoTextBlockComponent } from './video-text-block/video-text-block.component';
import { SalesTeamComponent } from './sales-team/sales-team.component';
import { OrgSchemaHomepageComponent } from './org-schema-homepage/org-schema-homepage.component';
import { PressReleasesComponent } from './press-releases/press-releases.component';
import { SubscriptionsManageComponent } from './subscriptions-manage/subscriptions-manage.component';
import { SubscriptionsManageModule } from '@marketing/subscriptions-manage/subscriptions-manage.module';
import { TableSelectionModule } from './table-selection/table-selection.module';
import { TabsModule } from './tabs/tabs.module';
import { DocViewerModule } from '@marketing/doc-viewer/doc-viewer.module';
import { DocViewerModalModule } from '@marketing/doc-viewer-modal/doc-viewer-modal.module';
import { InteractiveWidgetsComponent } from './interactive-widgets/interactive-widgets.component';
import { PcsToolModule } from '../interactive-content/pcs-tool/pcs-tool.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EventsModule } from '@marketing/events/events.module';
import { ProductContactInfoComponent } from './product-contact-info/product-contact-info.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { FloatingActionButtonComponent } from './floating-action-button/floating-action-button.component';
import { FloatingActionButtonModule } from './floating-action-button/floating-action-button.module';
import { EventEntryComponent } from './events/event-entry/event-entry.component';
import { WidenImgVideoModule } from '@shared/widen-img-video/widen-img-video.module';
import { WebinarComponent } from './webinar/webinar.component';
import { WebinarsModule } from './webinar/webinar.module';

@NgModule({
  imports: [
    BreadcrumbsModule,
    BrSdkModule,
    ButtonModule,
    CardModule,
    CartModalModule,
    CommonModule,
    DialogModule,
    DynamicCardModule,
    EnhancedFilterModule,
    FlexboxModule,
    FooterModule,
    FormsModule,
    GridModule,
    IconModule,
    ImageModule,
    LegalModule,
    LinkItemModule,
    LiteratureDialogModule,
    MarketoButtonModule,
    MarketoFormModule,
    MediaCardModule,
    PaddingModule,
    PanelModule,
    ProgressBarModule,
    SectionHeaderModule,
    ReactiveFormsModule,
    RouterModule,
    SectionTitleModule,
    SharedModule,
    SpacingModule,
    StaggeredBoxesModule,
    TabsStickyModule,
    TextInputModule,
    TitleModule,
    VideoModule,
    DropdownModule,
    RadioModule,
    TextAreaModule,
    SubscriptionsManageModule,
    TableSelectionModule,
    TabsModule,
    DocViewerModule,
    DocViewerModalModule,
    RecaptchaModule,
    PcsToolModule,
    NgxSpinnerModule,
    EventsModule,
    FloatingActionButtonModule,
    WidenImgVideoModule,
    WebinarsModule,
  ],
  declarations: [
    CustomFormComponent,
    GatewayComponent,
    // TODO: To uncomment. Temporarily moved to Shared Modules for segmentation workaround
    // GatewayModalComponent,
    DashboardComponent,
    MyDashboardComponent,
    PageMetadataComponent,
    PageTitleComponent,
    VideoComponent,
    RichTextComponent,
    RoleSelectorBannerComponent,
    RoleSelectorModalComponent,
    StaggeredBoxesComponent,
    CodeComponent,
    FeaturedContentCardsComponent,
    VideoTextBlockComponent,
    SalesTeamComponent,
    OrgSchemaHomepageComponent,
    PressReleasesComponent,
    SubscriptionsManageComponent,
    InteractiveWidgetsComponent,
    EventEntryComponent,
  ],
  entryComponents: [],
  exports: [
    PageTitleComponent,
    RoleSelectorBannerComponent,
    RoleSelectorModalComponent,
  ],
  providers: [GatewayModalService, MyliteratureService],
})
export class MarketingModule implements DynamicModule {
  getComponentMapping(): ComponentMapping {
    return {
      Code: CodeComponent,
      'Custom Form': CustomFormComponent,
      'Featured Content Cards': FeaturedContentCardsComponent,
      Gateway: GatewayComponent,
      'Important Legal': ImportantLegalComponent,
      Dashboard: DashboardComponent,
      'My Dashboard': MyDashboardComponent,
      'Page Metadata': PageMetadataComponent,
      'Page Title': PageTitleComponent,
      'Rich Text': RichTextComponent,
      'Segment Banner': AbstractBaseComponent,
      'Staggered Content': StaggeredBoxesComponent,
      'Tab Label': AbstractBaseComponent,
      'Accordion Label': AbstractBaseComponent,
      Video: VideoComponent,
      'Video Content Blocks': VideoTextBlockComponent,
      'Sales Contact Widget': SalesTeamComponent,
      'Org-Schema Homepage': OrgSchemaHomepageComponent,
      'Press Releases': PressReleasesComponent,
      'Subscriptions Manage': SubscriptionsManageComponent,
      widget: InteractiveWidgetsComponent,
      'event-info': EventEntryComponent,
      'Product Contact Info': ProductContactInfoComponent,
      'Quick Links': QuickLinksComponent,
      'Floating Action Button': FloatingActionButtonComponent,
      Webinar: WebinarComponent,
    };
  }
}
