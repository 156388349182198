<div class="empty-search" edsGrid edsPadding edsPaddingAll="sm">
  <eds-panel [theme]="'white'">
    <div edsPadding edsPaddingLeft="sm" edsPaddingRight="sm" edsPaddingTop="md">
      <div
        [edsSubGrid]="
          quickLinks && quickLinks.length > 4 ? 'layout-3' : 'layout-1'
        "
        edsSubGridTablet="layout-1"
      >
        <div edsSpacing="md" *ngIf="quickLinks && quickLinks.length">
          <eds-title
            edsSpacing="xs"
            [level]="3"
            theme="basic"
            size="h3"
            [title]="'common.search.content-heading' | translate"
          >
          </eds-title>
          <div
            [edsSubGrid]="
              quickLinks && quickLinks.length > 4 ? 'layout-1' : 'layout-0'
            "
          >
            <ul class="list--unstyled list--unordered">
              <ng-container
                *ngFor="let preSearchItem of quickLinks; let i = index"
              >
                <li *ngIf="i < 4">
                  <h4 class="light" edsSpacing="xs">
                    <a
                      href="{{ preSearchItem.url }}"
                      data-analytics-quick-link="true"
                      data-analytics-no-event="true"
                      >{{ preSearchItem.title }}</a
                    >
                  </h4>
                </li>
              </ng-container>
            </ul>
            <ul
              class="list--unstyled list--unordered"
              *ngIf="quickLinks.length > 4"
            >
              <ng-container
                *ngFor="let preSearchItem of quickLinks; let i = index"
              >
                <li *ngIf="i >= 4">
                  <h4 class="light" edsSpacing="xs">
                    <a
                      href="{{ preSearchItem.url }}"
                      data-analytics-quick-link="true"
                      data-analytics-no-event="true"
                      >{{ preSearchItem.title }}</a
                    >
                  </h4>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>

        <div
          edsSpacing="md"
          *ngIf="recentSearchItems && recentSearchItems.length && !clearRecent"
        >
          <div edsFlexbox alignItems="center">
            <eds-title
              edsSpacing="xs"
              [level]="3"
              theme="basic"
              size="h3"
              edsPadding
              edsPaddingRight="xs"
              [title]="'common.search.recent-search' | translate"
            >
            </eds-title>
            <p edsSpacing="none">
              <button
                edsSpacing="xs"
                edsButton="icon-only"
                (click)="clearRecentSearch()"
              >
                {{ "common.search.clear" | translate }}
              </button>
            </p>
          </div>
          <ul class="list--unstyled list--unordered">
            <li *ngFor="let value of recentSearchItems">
              <button
                (click)="searchItem(value, $event)"
                edsButton="icon-only"
                size="normal"
              >
                <p class="large">
                  {{ value }}
                </p>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </eds-panel>
</div>
