import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EmailDocsRequestBody,
  FundComentaryContent,
  LiteratureRequestBodyProfile,
  MailRequestBody,
  MailResponseObject,
  DownloadRequest,
  OrderHistoryList,
  OrderHistoryDetail,
  LiteratureData,
  LiteratureHistoryData,
} from '../../types';
import { LiteratureAPIMAPIService } from './apim-literature-api.service';

@Injectable({
  providedIn: 'root',
})
export class LiteratureApiService {
  constructor(
    private httpClient: HttpClient,
    private literatureAPIMAPIService: LiteratureAPIMAPIService
  ) {}

  // myliterature.service.ts
  public getRecentOrderHistoryList(
    url: string,
    profile: LiteratureRequestBodyProfile
  ): Observable<OrderHistoryDetail> {
    return this.httpClient.post<OrderHistoryDetail>(url, profile);
  }

  // myliterature.service.ts
  public getRecentOrderHistoryListAPIMFlow(
    url: string,
    profile: LiteratureRequestBodyProfile
  ): Observable<OrderHistoryDetail> {
    return this.literatureAPIMAPIService
      .postWithToken('auth', url, null)
      .pipe(map((response) => response?.result));
  }

  // literature-orderhistory-detail.service
  public getOrderHistoryDetail(
    url: string,
    profileInfo: LiteratureRequestBodyProfile
  ): Observable<OrderHistoryDetail> {
    return this.httpClient.post<OrderHistoryDetail>(url, profileInfo);
  }

  // literature-orderhistory-detail.service
  public getOrderHistoryDetailAPIMFlow(
    url: string,
    profileInfo: LiteratureRequestBodyProfile
  ): Observable<OrderHistoryDetail> {
    return this.literatureAPIMAPIService
      .postWithToken('auth', url, null)
      .pipe(map((response) => response?.result));
  }

  // order-history-list.service.ts
  public getOrderHistoryList(
    url: string,
    profile: LiteratureRequestBodyProfile
  ): Observable<OrderHistoryList> {
    return this.httpClient.post<OrderHistoryList>(url, profile);
  }

  // order-history-list.service.ts
  public getOrderHistoryListAPIMFlow(
    url: string,
    profile: LiteratureRequestBodyProfile
  ): Observable<OrderHistoryList> {
    return this.literatureAPIMAPIService
      .postWithToken('auth', url, {
        userId: profile.userId,
        role: profile.role,
        webExperiance: profile.webExperience,
        businessKey: profile.businessKey,
      })
      .pipe(map((response) => response?.result));
  }

  // download service
  public download$(
    url: string,
    reqBody: DownloadRequest,
    customHeaders: any
  ): Observable<any> {
    return this.httpClient.post(url, reqBody, customHeaders);
  }

  // literature-document-cart.service.ts
  public setCartCookie(url: string, reqBody: any): Observable<any> {
    return this.httpClient.post(url, reqBody);
  }

  // literature-document-cart.service.ts
  public getCartCookie(url: string): Observable<any> {
    return this.httpClient.get(url);
  }

  // literature-data.service.ts
  public getLiteratureData(
    url: string,
    customHeaders: any,
    postBody?: any // if true, do POST instead of GET request
  ): Observable<LiteratureData> {
    if (postBody) {
      return this.httpClient.post<LiteratureData>(url, postBody, {
        headers: customHeaders,
      });
    }
    return this.httpClient.get<LiteratureData>(url, { headers: customHeaders });
  }

  // literature-data.service.ts
  public getLiteratureDataAPIMFlow(
    url: string,
    customHeaders: any
  ): Observable<LiteratureData> {
    return this.literatureAPIMAPIService.getWithToken('nonAuth', url, {
      headers: customHeaders,
    });
  }

  public postEmailDocuments(
    url: string,
    requestBody: EmailDocsRequestBody
  ): Observable<any> {
    return this.httpClient.post(url, requestBody);
  }

  public postEmailDocumentsAPIMFlow(
    url: string,
    requestBody: EmailDocsRequestBody
  ): Observable<any> {
    return this.literatureAPIMAPIService.postWithToken(
      'nonAuth',
      url,
      requestBody
    );
  }

  public mailDocument(
    url: string,
    mailRequestBody: MailRequestBody
  ): Observable<MailResponseObject> {
    return this.httpClient.post(url, mailRequestBody);
  }

  public mailDocumentAPIMFlow(
    url: string,
    mailRequestBody: MailRequestBody
  ): Observable<MailResponseObject> {
    return this.literatureAPIMAPIService
      .postWithToken('auth', url, mailRequestBody)
      .pipe(map((response) => response?.result));
  }

  public postEmailDocumentsAndObserveEvents(
    url: string,
    requestBody: EmailDocsRequestBody,
    customHeaders: any
  ): Observable<any> {
    return this.httpClient.post(url, requestBody, customHeaders);
  }

  public getLiteratureHistory(
    url: string,
    profile: LiteratureRequestBodyProfile
  ): Observable<LiteratureHistoryData> {
    return this.httpClient.post<LiteratureHistoryData>(url, profile);
  }

  public deleteLiteratureDocument(
    url: string,
    profile: LiteratureRequestBodyProfile
  ): Observable<any> {
    return this.httpClient.post(url, profile);
  }

  public getFundCommentaryContent(
    url: string,
    customHeaders: any
  ): Observable<FundComentaryContent> {
    return this.httpClient.get<FundComentaryContent>(url, {
      headers: customHeaders,
    });
  }
}
