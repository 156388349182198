<section
  class="video__bg"
  [class.video__bg--has-gradient]="theme === 'gradient'"
>
  <div edsGrid>
    <div [edsSpacing]="marginBottom === 'auto' ? 'xl' : marginBottom">
      <div class="container">
        <div edsRow>
          <div edsCol>
            <eds-video
              [edsSpacing]="theme === 'gradient' ? 'xl' : 'lg'"
              edsPadding
              [edsPaddingTop]="theme === 'gradient' ? 'xl' : ''"
              [embedScript]="videoEmbedCode"
              [hasVideoShadow]="hasVideoShadow"
              [isMultiPlayer]="isMultiPlayer"
              [multipleVideoTranscriptDetails]="multipleVideoTranscriptDetails"
              [transcriptTitle]="transcriptTitle"
            >
            </eds-video>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
