<ng-container *ngIf="page">
  <ng-container *ngIf="brConfig?.fundId; else previewBlock">
    <div>
      <eds-section-header
        [level]="3"
        theme="basic"
        size="h3"
        [asOfLabel]="'products.as-of' | translate"
        [asOfDate]="customFundPerformanceData?.performanceDates?.asOfDate"
        [analyticsName]="heading"
        [tooltip]="tooltips['products.average-annual-total-returns-section']"
        edsSpacing="sm"
      >
        {{ heading }}
        <eds-footnote
          placement="Annualized:AnnualizedPerformance"
        ></eds-footnote>
      </eds-section-header>
      <div *ngIf="isError">
        <div>
          <span>{{ "products.no-performance-data" | translate }}</span>
        </div>
      </div>
      <div *ngIf="!isError">
        <div
          edsSpacing="md"
          edsFlexbox
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <div *ngIf="periodEndToggle?.length > 1">
            <eds-toggle
              [toggleButtons]="periodEndToggle"
              (toggleActive)="onPeriodEndUpdate($event)"
              [ariaControls]="'annualizedData'"
            ></eds-toggle>
          </div>
          <div
            edsFlexbox
            alignItems="center"
            justifyContent="flex-end"
            *ngIf="fundPerformanceConfig?.showHistorical"
          >
            <eds-month-picker
              [label]="'products.calendar-from' | translate"
              [placeholder]="datePickerFormat"
              [opens]="'left'"
              [min]="
                fundPerformanceData?.performanceDates?.inceptionDateMonthStd
              "
              [max]="fundPerformanceData?.performanceDates?.asOfDateMonthStd"
              [format]="datePickerFormat"
              [value]="selectedDateStd"
              (selected)="onHistoricalDateSelection($event)"
              [errorMsgObj]="errorMsgObj"
              [calendarHeading]="'common.month-calendar-heading' | translate"
              [closeLabel]="'common.month-close' | translate"
              [prevButtonLabel]="'common.month-previous-button' | translate"
              [nextButtonLabel]="'common.month-next-button' | translate"
              [toggleButtonLabel]="'common.month-toggle-button' | translate"
              [toggleButtonChangeLabel]="
                'common.month-change-button' | translate
              "
              [selectedDateLabel]="'common.month-selected-date' | translate"
              (openPanel)="onOpenMonthPicker($event)"
            ></eds-month-picker>
          </div>
        </div>

        <ng-container *ngIf="performanceStatusCaveat">
          <eds-proximal
            edsSpacing="lg"
            edsPadding
            edsPaddingBottom="sm"
            [placement]="performanceStatusCaveat"
          ></eds-proximal>
          <br />
        </ng-container>
        <ng-container *ngIf="customFundPerformanceData?.tableData?.length">
          <eds-proximal
            placement="PerformanceAnnualizedProximalTop"
          ></eds-proximal>
        </ng-container>
        <ng-container
          *ngIf="
            customFundPerformanceData?.graphData?.length > 0 &&
            !fundPerformanceConfig.hideChart
          "
        >
          <eds-proximal
            placement="PerformanceAnnualizedChartProximalTop"
          ></eds-proximal>
          <ft-fund-performance-column-chart
            [seriesData]="customFundPerformanceData.graphData"
            [xAxisCategories]="xAxisCategories"
          >
          </ft-fund-performance-column-chart>
          <eds-proximal
            placement="PerformanceAnnualizedChartProximalBottom"
          ></eds-proximal>
        </ng-container>
        <!-- No graph data error message -->
        <ng-container *ngIf="fundPerformanceConfig?.hideChart">
          <p>{{ "products.no-chart-data-found" | translate }}</p>
        </ng-container>

        <br />
        <ft-fund-performance-annualized-table
          [fundPerformanceData]="customFundPerformanceData?.tableData"
          [fundPerformanceDates]="customFundPerformanceData?.performanceDates"
          [fundPerformanceConfig]="fundPerformanceConfig"
          [tableLabel]="customFundPerformanceData?.tableLabel"
          [tableColumns]="customFundPerformanceData?.tableColumns"
          [showInception]="customFundPerformanceData?.showInception"
          [fundId]="brConfig.fundId"
          [containerId]="'annualizedData'"
          *ngIf="customFundPerformanceData?.tableData?.length > 0"
        >
        </ft-fund-performance-annualized-table>
        <div
          *ngIf="customFundPerformanceData?.tableData.length === 0"
          aria-live="polite"
        >
          <div>
            <h6>{{ "products.no-performance-hist-data" | translate }}</h6>
            <br />
          </div>
        </div>
        <div *ngIf="fundPerformanceConfig?.showMifid">
          <eds-title size="h6" theme="basic" [isLight]="true">
            {{ "products.perf-inception-mifid" | translate: brConfig.fundId }}
            {{ fundPerformanceData.performanceDates.inceptionDate }}
            {{
              "products.perf-inception-mifid-end" | translate: brConfig.fundId
            }}
          </eds-title>
        </div>
      </div>
      <eds-expand-collapse
        [collapsedButton]="
          'products.view-all-class-perf' | translate: brConfig.fundId
        "
        [expandedButton]="
          'products.view-all-class-perf' | translate: brConfig.fundId
        "
        top="true"
        paddingNone="true"
        borderNone="true"
        edsSpacing="md"
        *ngIf="fundPerformanceData?.allShareClassesTableData?.tableData?.length"
      >
        <ft-fund-performance-annualized-table
          [fundPerformanceData]="
            fundPerformanceData?.allShareClassesTableData?.tableData
          "
          [fundPerformanceDates]="fundPerformanceData?.performanceDates"
          [fundPerformanceConfig]="fundPerformanceConfig"
          [tableColumns]="
            customFundPerformanceData?.allShareClassesTableData?.tableColumns
          "
          [showInception]="
            customFundPerformanceData?.allShareClassesTableData?.showInception
          "
          [tableLabel]="fundPerformanceData?.tableLabel"
          [fundId]="brConfig.fundId"
          [isAllShareClasses]="true"
        >
        </ft-fund-performance-annualized-table>
      </eds-expand-collapse>

      <ng-container *ngIf="customFundPerformanceData?.tableData?.length">
        <eds-proximal
          placement="PerformanceAnnualizedProximalBottom"
        ></eds-proximal>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #previewBlock>
  <h5>Fund Performance Component - Annualized</h5>
</ng-template>
