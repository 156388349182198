import { Mapper, MapperParams } from './type.mapper';
import { MapperFactory } from './mapper-factory';
import { Ratings } from '@models';
import { RatingsDTO } from '@types';
import { parseStdNumber } from './mapper.utils';

export class RatingsMapper extends Mapper<Ratings> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(ratingsDto: RatingsDTO): Ratings {
    return {
      morningStarCategory: ratingsDto.mscatg,
      morningStarRatingOverall: parseStdNumber(ratingsDto.msrtngoverall),
      morningStarRating3yr: parseStdNumber(ratingsDto.msrtng3yr),
      morningStarRating5yr: parseStdNumber(ratingsDto.msrtng5yr),
      morningStarRating10yr: parseStdNumber(ratingsDto.msrtng10yr),
      monthEndNoOfFunds3yr: ratingsDto.mnthendnooffnds3yr,
      monthEndNoOfFunds5yr: ratingsDto.mnthendnooffnds5yr,
      monthEndNoOfFunds10yr: ratingsDto.mnthendnooffnds10yr,
      asOfDate: ratingsDto.asofdate,
      morningStar3yrHybrid: ratingsDto.ms3yrhybrid === 'true',
      morningStar5yrHybrid: ratingsDto.ms5yrhybrid === 'true',
      morningStar10yrHybrid: ratingsDto.ms10yrhybrid === 'true',
      morningStarOverallHybrid: ratingsDto.msoverallhybrid === 'true',
      morningStarEqStylebox: ratingsDto.mseqstylbox,
      morningStarFiStylebox: ratingsDto.msfistylbox,
      morningStarStyleboxType: ratingsDto.msstylboxtyp,
      morningStarStyleboxHist: ratingsDto.msstyleboxhist,
      morningStarPortfolioDate: ratingsDto.msportfoliodate,
      analizyRatingEffectiveDate: ratingsDto.rtganalizyeffdt,
      analizyRatingCategory: ratingsDto.rtnganalizycategory,
      analizyRating36Months: ratingsDto.rtganalizyqnt36mo,
      analizyRating12Months: ratingsDto.rtganalizyqnt12mo,
    };
  }
}
