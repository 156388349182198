import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ButtonModule,
  IconModule,
  SpacingModule,
  GridModule,
  SafeHtmlModule,
} from '@frk/eds-components';
import { SignInBarComponent } from './sign-in-bar.component';
import { TranslateModule } from '../translate/translate.module';

@NgModule({
  declarations: [SignInBarComponent],
  exports: [SignInBarComponent],
  entryComponents: [SignInBarComponent],
  imports: [
    CommonModule,
    IconModule,
    ButtonModule,
    SpacingModule,
    GridModule,
    TranslateModule,
    SafeHtmlModule,
  ],
})
export class SignInBarModule {}
