<div class="search-results">
  <!-- full results component -->
  <ft-full-results
    *ngIf="activeToggle !== 'exact'"
    [updatedQueryText]="updatedQueryText"
    [results]="results"
    [activeTab]="activeTab"
    [aggregations]="aggregations"
    [displayedPage]="displayedPage"
    (viewMoreClicked)="onViewMore($event)"
    (filtered)="onFiltered()"
    [searchTerm]="searchTerm"
    edsTrapFocus
  >
  </ft-full-results>

  <!-- exact match  component -->
  <div class="box" *ngIf="activeToggle === 'exact'">
    <ft-exact-match [exactMatch]="exactMatch"></ft-exact-match>
  </div>
</div>
