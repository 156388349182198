import { Mapper, MapperParams } from './type.mapper';
import { PerformanceDetail, Performance, PerformanceStatus } from '@models';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import {
  CurrencyCode,
  PerformanceCurrentDTO,
  PerformanceDTO,
  PerformanceSnapshotDTO,
} from '@types';

const mapPerformanceStatus = (stat: string): PerformanceStatus => {
  switch (stat) {
    case 'FINAL':
      return PerformanceStatus.FINAL;
    case 'PRELIM':
      return PerformanceStatus.PRELIM;
    default:
      return null;
  }
};

export class PerformanceMapper extends Mapper<Performance> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(performanceDto: PerformanceDTO): Performance {
    return {
      monthEnd: performanceDto.month_end?.map((perf) =>
        this.createMapper(PerformanceDetailMapper).toDomain(perf)
      ),
      quarterEnd: performanceDto.quarter_end?.map((perf) =>
        this.createMapper(PerformanceDetailMapper).toDomain(perf)
      ),
      historical: performanceDto.snapshot?.map((perf) =>
        this.createMapper(HistoricalPerformanceDetailMapper).toDomain(perf)
      ),
    };
  }
}

export class PerformanceDetailMapper extends Mapper<PerformanceDetail> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(performanceDetailDto: PerformanceCurrentDTO): PerformanceDetail {
    return {
      currencyCode: performanceDetailDto.currcode as CurrencyCode,
      performanceAsOfDate: performanceDetailDto.asofdate,
      performanceAsOfDateStd: performanceDetailDto.asofdatestd,
      calcType: performanceDetailDto.calcname,
      calcTypeStd: performanceDetailDto.calcnamestd,
      avgAnnual1YearReturn: performanceDetailDto.avgannret1yr,
      avgAnnual2YearReturn: performanceDetailDto.avgannret2yr,
      avgAnnual3YearReturn: performanceDetailDto.avgannret3yr,
      avgAnnual4YearReturn: performanceDetailDto.avgannret4yr,
      avgAnnual5YearReturn: performanceDetailDto.avgannret5yr,
      avgAnnual7YearReturn: performanceDetailDto.avgannret7yr,
      avgAnnual10YearReturn: performanceDetailDto.avgannret10yr,
      avgAnnual15YearReturn: performanceDetailDto.avgannret15yr,
      avgAnnual20YearReturn: performanceDetailDto.avgannret20yr,
      avgAnnual25YearReturn: performanceDetailDto.avgannret25yr,
      avgAnnual35YearReturn: performanceDetailDto.avgannret35yr,
      avgAnnualYearToDateReturn: performanceDetailDto.avgannretytd,
      avgAnnualSinceInceptionReturn: performanceDetailDto.avgannretactinc,
      cummulativeReturn1Month: performanceDetailDto.cummret1mth,
      cummulativeReturn3Month: performanceDetailDto.cummret3mth,
      cummulativeReturn6Month: performanceDetailDto.cummret6mth,
      cummulativeReturn1Year: performanceDetailDto.cummret1yr,
      cummulativeReturn2Year: performanceDetailDto.cummret2yr,
      cummulativeReturn3Year: performanceDetailDto.cummret3yr,
      cummulativeReturn5Year: performanceDetailDto.cummret5yr,
      cummulativeReturn10Year: performanceDetailDto.cummret10yr,
      cummulativeReturn15Year: performanceDetailDto.cummret15yr,
      cummulativeReturnYearToDate: performanceDetailDto.cummretytd,
      cummulativeReturnSinceInception: performanceDetailDto.cummretactinc,
      calendarYearReturn1Year: performanceDetailDto.calendaryrret1yr,
      calendarYearReturn2Year: performanceDetailDto.calendaryrret2yr,
      calendarYearReturn3Year: performanceDetailDto.calendaryrret3yr,
      calendarYearReturn4Year: performanceDetailDto.calendaryrret4yr,
      calendarYearReturn5Year: performanceDetailDto.calendaryrret5yr,
      calendarYearReturn6Year: performanceDetailDto.calendaryrret6yr,
      calendarYearReturn7Year: performanceDetailDto.calendaryrret7yr,
      calendarYearReturn8Year: performanceDetailDto.calendaryrret8yr,
      calendarYearReturn9Year: performanceDetailDto.calendaryrret9yr,
      calendarYearReturn10Year: performanceDetailDto.calendaryrret10yr,
      discreteAnnualRollingYear1: performanceDetailDto.discrannualrollyear1,
      discreteAnnualRollingYear2: performanceDetailDto.discrannualrollyear2,
      discreteAnnualRollingYear3: performanceDetailDto.discrannualrollyear3,
      discreteAnnualRollingYear4: performanceDetailDto.discrannualrollyear4,
      discreteAnnualRollingYear5: performanceDetailDto.discrannualrollyear5,
      discreteAnnualRollingYear6: performanceDetailDto.discrannualrollyear6,
      discreteAnnualRollingYear7: performanceDetailDto.discrannualrollyear7,
      discreteAnnualRollingYear8: performanceDetailDto.discrannualrollyear8,
      discreteAnnualRollingYear9: performanceDetailDto.discrannualrollyear9,
      discreteAnnualRollingYear10: performanceDetailDto.discrannualrollyear10,
      performanceStatus: mapPerformanceStatus(performanceDetailDto.perfstatus), // this should also replace empty string with null
      performanceYear1YearBack: performanceDetailDto.performance1yr,
      performanceYear2YearBack: performanceDetailDto.performance2yr,
      performanceYear3YearBack: performanceDetailDto.performance3yr,
      performanceYear4YearBack: performanceDetailDto.performance4yr,
      performanceYear5YearBack: performanceDetailDto.performance5yr,
      performanceYear6YearBack: performanceDetailDto.performance6yr,
      performanceYear7YearBack: performanceDetailDto.performance7yr,
      performanceYear8YearBack: performanceDetailDto.performance8yr,
      performanceYear9YearBack: performanceDetailDto.performance9yr,
      performanceYear10YearBack: performanceDetailDto.performance10yr,
    };
  }
}

export class HistoricalPerformanceDetailMapper extends Mapper<PerformanceDetail> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(performanceDetailDto: PerformanceSnapshotDTO): PerformanceDetail {
    return {
      currencyCode: performanceDetailDto.currcode as CurrencyCode,
      performanceAsOfDate: performanceDetailDto.asofdate,
      performanceAsOfDateStd: performanceDetailDto.asofdatestd,
      calcType: performanceDetailDto.calcname,
      calcTypeStd: performanceDetailDto.calcnamestd,
      avgAnnual1YearReturn: performanceDetailDto.avgannret1yr,
      avgAnnual2YearReturn: performanceDetailDto.avgannret2yr,
      avgAnnual3YearReturn: performanceDetailDto.avgannret3yr,
      avgAnnual4YearReturn: performanceDetailDto.avgannret4yr,
      avgAnnual5YearReturn: performanceDetailDto.avgannret5yr,
      avgAnnual7YearReturn: performanceDetailDto.avgannret7yr,
      avgAnnual10YearReturn: performanceDetailDto.avgannret10yr,
      avgAnnual15YearReturn: performanceDetailDto.avgannret15yr,
      avgAnnual20YearReturn: performanceDetailDto.avgannret20yr,
      avgAnnual25YearReturn: performanceDetailDto.avgannret25yr,
      avgAnnual35YearReturn: performanceDetailDto.avgannret35yr,
      avgAnnualYearToDateReturn: performanceDetailDto.avgannretytd,
      avgAnnualSinceInceptionReturn: performanceDetailDto.avgannretactinc,
      cummulativeReturn1Month: performanceDetailDto.cummret1mth,
      cummulativeReturn3Month: performanceDetailDto.cummret3mth,
      cummulativeReturn6Month: performanceDetailDto.cummret6mth,
      cummulativeReturn1Year: performanceDetailDto.cummret1yr,
      cummulativeReturn2Year: performanceDetailDto.cummret2yr,
      cummulativeReturn3Year: performanceDetailDto.cummret3yr,
      cummulativeReturn5Year: performanceDetailDto.cummret5yr,
      cummulativeReturn10Year: performanceDetailDto.cummret10yr,
      cummulativeReturn15Year: performanceDetailDto.cummret15yr,
      cummulativeReturnYearToDate: performanceDetailDto.cummretytd,
      cummulativeReturnSinceInception: performanceDetailDto.cummretactinc,
      calendarYearReturn1Year: performanceDetailDto.calendaryrret1yr,
      calendarYearReturn2Year: performanceDetailDto.calendaryrret2yr,
      calendarYearReturn3Year: performanceDetailDto.calendaryrret3yr,
      calendarYearReturn4Year: performanceDetailDto.calendaryrret4yr,
      calendarYearReturn5Year: performanceDetailDto.calendaryrret5yr,
      calendarYearReturn6Year: performanceDetailDto.calendaryrret6yr,
      calendarYearReturn7Year: performanceDetailDto.calendaryrret7yr,
      calendarYearReturn8Year: performanceDetailDto.calendaryrret8yr,
      calendarYearReturn9Year: performanceDetailDto.calendaryrret9yr,
      calendarYearReturn10Year: performanceDetailDto.calendaryrret10yr,
      discreteAnnualRollingYear1: performanceDetailDto.discrannualrollyear1,
      discreteAnnualRollingYear2: performanceDetailDto.discrannualrollyear2,
      discreteAnnualRollingYear3: performanceDetailDto.discrannualrollyear3,
      discreteAnnualRollingYear4: performanceDetailDto.discrannualrollyear4,
      discreteAnnualRollingYear5: performanceDetailDto.discrannualrollyear5,
      discreteAnnualRollingYear6: performanceDetailDto.discrannualrollyear6,
      discreteAnnualRollingYear7: performanceDetailDto.discrannualrollyear7,
      discreteAnnualRollingYear8: performanceDetailDto.discrannualrollyear8,
      discreteAnnualRollingYear9: performanceDetailDto.discrannualrollyear9,
      discreteAnnualRollingYear10: performanceDetailDto.discrannualrollyear10,
    };
  }
}
