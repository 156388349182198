import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('DividerComponent');
export interface DividerContent {
  marginBottom: string;
  dividerColour: string;
  containerClass?: 'container' | 'container-wide' | 'none';
}
@Component({
  selector: 'ft-divider',
  templateUrl: './divider.component.html',
})
export class DividerComponent extends AbstractBaseComponent implements OnInit {
  @Input() margin: string;
  public dividerTheme: 'primary' | 'basic' | 'dark' | 'white' = 'basic';
  @Input() containerClass?: 'container' | 'container-wide' | 'none';
  @Input() content: DividerContent;

  ngOnInit() {
    const paramaters = this.component?.getParameters();
    const compTheme =
      paramaters?.theme?.toLowerCase() || this.content?.dividerColour;
    this.dividerTheme = compTheme;
    switch (compTheme) {
      case 'default':
        this.dividerTheme = 'basic';
        break;
      case 'black':
        this.dividerTheme = 'dark';
        break;
    }
    this.margin = paramaters?.marginProperty || this.content?.marginBottom;
    this.containerClass =
      paramaters?.containerClass || this.content?.containerClass;
  }
}
