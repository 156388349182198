import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';

import { Logger } from '@utils/logger';
import { SubscriptionsParams } from '@marketing/subscriptions-manage/subscription-manage.interface';
import { DOCUMENT } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SubscriptionProductsService } from './services/subscription-products.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewModeService } from '@services/view-mode.service';
import { ProfileService } from '@services';

/**
 * Logger
 */
const logger = Logger.getLogger('SubscriptionsManageComponent');

@Component({
  selector: 'ft-subscriptions-manage',
  templateUrl: './subscriptions-manage.component.html',
  styleUrls: ['./subscriptions-manage.component.scss'],
})
export class SubscriptionsManageComponent
  extends AbstractBaseComponent
  implements OnInit, OnDestroy {
  public subscriptionsParams: SubscriptionsParams;
  private unSubscribe$: Subject<void> = new Subject<void>();
  public isLoading = true;
  public isLoggedIn: boolean;

  constructor(
    @Inject(DOCUMENT) readonly docRef: Document,
    private subscriptionsProductsService: SubscriptionProductsService,
    private changeDetector: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private viewModeService: ViewModeService,
    private profileService: ProfileService
  ) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  ngOnInit(): void {
    this.subscriptionsParams = this.component.getParameters();
    this.spinner
      .show('userDataLoadingSpinner')
      .then(() => logger.debug('User Data Spinner set'));
    this.profileService
      .isLoggedIn$()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((isLoggedIn) => {
        this.isLoading = false;
        this.isLoggedIn = isLoggedIn;
        if (this.isLoggedIn) {
          this.subscriptionsProductsService.setProductsUserData$(
            this.subscriptionsParams.apiUrl
          );
        }
      });
    // Handling user data loading error
    this.subscriptionsProductsService
      .getUserDaraError$()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((isError: boolean) => {
        if (isError) {
          this.isLoading = this.isLoggedIn = false;
          this.changeDetector.detectChanges();
        }
      });

    logger.debug('Component initialised');
  }

  ngOnDestroy(): void {
    this.docRef.body.style.zoom = null;
    this.docRef.body.style['-moz-transform'] = null;
    this.docRef.body.style['-moz-transform-origin'] = null;
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
