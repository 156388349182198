import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil, scan, filter, share, map } from 'rxjs/operators';
import { SegmentId, SiteAlertContent } from '@types';
import { Logger } from '@utils/logger';

// TODO copied from page container - refactoring needed
import get from 'lodash/get';

import {
  NotificationDetails,
  NotificationMessage,
  NotificationTypes,
} from '@accounts/accounts-alerts/accounts-alerts.interface';

import { ProfileService, StorageService, WidenService } from '@services';
import { SegmentService } from '@services/segment.service';

import { removePTags } from '@utils/text/string-utils';
import { HttpClient } from '@angular/common/http';
import { LinkCollections } from '@pages/page-container/types';
import { NotificationLinkOptions } from '@frk/eds-components/lib/models/notification.model';

export interface StorageAlert {
  id: string;
  expires: number;
}

interface LinkNotificationFields {
  ctaText?: string;
  actionTarget?: string;
  isExternal?: boolean;
  isNewWindow?: boolean;
  signInLink?: boolean;
  target?: string;
}

const storageSettings = {
  always: 365 * 24 * 60 * 60 * 1000, // 365 days
  'once-per-hour': 1 * 60 * 60 * 1000, // 24 hours from now
  'once-per-day': 24 * 60 * 60 * 1000, // 1 hours from now
};

const logger = Logger.getLogger('SiteAlertService');

const localStorageKey = 'closed_alerts';

@Injectable({
  providedIn: 'root',
})
export class SiteAlertService implements OnDestroy {
  private siteAlertInput$: Subject<NotificationMessage> = new Subject<NotificationMessage>();
  private siteAlertsSubject$: BehaviorSubject<
    NotificationDetails[]
  > = new BehaviorSubject<NotificationDetails[]>([]);

  private closedAlertsId: string[];
  private notificationPageType: string;
  private siteName: string;
  private currentSegment: string;
  private firmId: string;

  private unsubscribe$: Subject<void> = new Subject<void>();

  private alerts: SiteAlertContent[] = [];

  constructor(
    private storageService: StorageService,
    private segmentService: SegmentService,
    private profileService: ProfileService,
    private http: HttpClient,
    private widenService: WidenService
  ) {
    // read current segment
    this.segmentService
      .getCurrentSegmentId$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentSegmentId: SegmentId) => {
        this.currentSegment = currentSegmentId;
      });

    // read current firm
    this.profileService
      .getUserProfile$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile) => {
        this.firmId =
          profile?.firm?.parentFirmId ||
          profile?.firm?.firmRole ||
          profile?.profileInfo?.firm;
      });
  }

  public getSiteAlerts$(): Observable<NotificationMessage[]> {
    // TODO analyze this - it uses promise so may be delayed
    // to consider if this is required if there are no alerts
    // however we call alerts from page-container component and resourceapi
    this.updateClosedAlertsFromStorage();
    this.setSiteAlerts();
    // after receiving new alert message (currently from page-container only)
    // this subscription process alerts, filter it and presents as an array.
    // alert message can add new alert or remove alert from the list and update storage
    return this.siteAlertInput$.pipe(
      takeUntil(this.unsubscribe$),
      filter((alertItem) => {
        logger.debug('alertItem', alertItem);
        return this.isAlertValid(alertItem);
      }),
      // scan operator cumulates messages emmited by Observable
      scan((alertAccumulated: NotificationMessage[], value) => {
        logger.debug('scan', value);
        if (value?.messageType === 'add') {
          return [...alertAccumulated, value];
        }

        // remove notification from the list
        if (value?.messageType === 'remove') {
          // update storage with removed id
          this.updateClosedAlertsFromStorage(value);
          return alertAccumulated.filter(
            (message) => message.notificationDetails.id !== value.id
          );
        }
      }, [])
    );
  }

  /**
   * Get Site Alerts Subject
   * @returns observable -  with list of alerts
   */
  public getSiteAlertsSubject$(): Observable<NotificationDetails[]> {
    return this.siteAlertsSubject$.asObservable().pipe(
      map((alerts: NotificationDetails[]) => {
        return alerts.filter((alert) => {
          const alertMessage: NotificationMessage = {
            messageType: 'add',
            notificationDetails: alert,
          };
          return this.isAlertValid(alertMessage);
        });
      })
    );
  }

  /**
   * Returns if alert is valid to be displayed
   * @param alertItem - NotificationMessage
   * @returns - boolean
   */
  private isAlertValid = (alertItem: NotificationMessage): boolean => {
    return (
      this.isNotExpired(alertItem) &&
      this.isPageMatch(alertItem) &&
      this.isSegmentMatch(alertItem) &&
      this.isFirmMatch(alertItem)
    );
  };

  /**
   * This method reads site alerts from rest service and stores it in local variable
   */
  private setSiteAlerts(): void {
    // we want to call this afer observable is returned
    if (this.alerts.length > 0) {
      setTimeout(() => {
        this.pushAlerts(this.alerts);
      }, 0);
    }
    // Read site alerts from rest service
    // we call alerst only in they were not called earlier - so once per session
    if (this.alerts.length === 0) {
      this.getSiteAlertsContent$()
        .pipe(takeUntil(this.unsubscribe$), share())
        .subscribe((alerts: SiteAlertContent[]) => {
          this.alerts = alerts;
          this.pushAlerts(alerts);
        });
    }
  }

  /**
   * Push alerts to subject
   * @param alerts - list of alerts to be pushed to subject
   */
  private pushAlerts(alerts: SiteAlertContent[]) {
    const alertDetails = [];
    alerts.forEach((alert: SiteAlertContent) => {
      const alertDetail: NotificationDetails = this.getAlertDetails(alert);
      alertDetails.push(alertDetail);
      this.setSiteAlert(alertDetail);
    });
    // Push alerts to subject.
    // This subject will be used in other components to display alerts alerts API call will be initialized in site-alert.component.
    // Api call is initialized by component as it requires siteName parameter defined in component.
    // Site-alert.component is always loaded in page-container, so it will be called only once per session.
    this.siteAlertsSubject$.next(alertDetails);
  }

  /**
   * validates if specific notification can be displayed on a page
   * @param alertItem NotificationMessage
   */
  private isPageMatch(alertItem: NotificationMessage) {
    if (alertItem?.notificationDetails?.pageTypes?.includes('all')) {
      return true;
    }
    return alertItem?.notificationDetails?.pageTypes?.includes(
      this.notificationPageType
    );
  }

  /**
   * validates if specific alert expider
   * @param alertItem item to be validated
   */
  private isNotExpired(alertItem: NotificationMessage) {
    return !this.closedAlertsId?.includes(alertItem?.notificationDetails.id);
  }

  /**
   * validates if alert is applicable for segment
   * @param alertItem NotificationMessage
   */
  private isSegmentMatch(alertItem: NotificationMessage) {
    return (
      !alertItem?.notificationDetails.segmentTarget ||
      alertItem?.notificationDetails.segmentTarget?.includes(
        this.currentSegment
      )
    );
  }

  /**
   * Checks if the given notification message matches the firm criteria for display
   * @param alertItem - NotificationMessage The notification message to be checked
   * @returns A boolean indicating if the notification message matches the firm criteria
   */
  private isFirmMatch(alertItem: NotificationMessage): boolean {
    return (
      !alertItem.notificationDetails?.firms ||
      alertItem.notificationDetails.firms?.length === 0 ||
      alertItem.notificationDetails.firms.includes(this.firmId)
    );
  }

  /**
   * this method reads list of supressed alerts, cleanup it(leaves only not expired)
   * then it adds new alert if parameter added and stores it again
   * @param notificationMessage simplified message with id and expiration attributes
   *
   */
  private updateClosedAlertsFromStorage(
    notificationMessage: NotificationMessage = null
  ) {
    this.storageService
      .retrieve(localStorageKey)
      .then((closedAlerts: StorageAlert[]): void => {
        const nowTimestamp = Date.now();
        let filteredClosedAlerts: StorageAlert[] = [];

        // process alerst from storage if exist
        if (closedAlerts?.length > 0) {
          filteredClosedAlerts = closedAlerts.filter(
            (closedAlert) => closedAlert.expires > nowTimestamp
          );
        }

        // append notification id to removed ids if message is 'remove'
        if (notificationMessage?.messageType === 'remove') {
          filteredClosedAlerts.push({
            id: notificationMessage.id,
            expires: nowTimestamp + notificationMessage.expires,
          });
        }

        // storing filtered alerts to remove expired elements
        this.storageService.store(localStorageKey, filteredClosedAlerts);

        this.closedAlertsId = filteredClosedAlerts.map(
          (closedAlert: StorageAlert) => {
            return closedAlert.id;
          }
        );
      });
  }

  /**
   * Get Site Alert Content
   */
  private getSiteAlertsContent$(): Observable<SiteAlertContent[]> {
    return this.http.get<SiteAlertContent[]>(
      `/rest/notifications?site=` + this.siteName
    );
  }

  public setSiteName(siteName: string): void {
    this.siteName = siteName;
  }

  public setNotificationPageType(notificationPageType: string): void {
    this.notificationPageType = notificationPageType;
  }

  /**
   * this method adds new alert to list of alerts
   * @param siteAlertContent site alert message details
   */
  public setSiteAlert(siteAlertContent: NotificationDetails): void {
    const notificationMessage: NotificationMessage = {
      messageType: 'add',
      notificationDetails: siteAlertContent,
    };
    logger.debug('adding:', notificationMessage);
    this.siteAlertInput$.next(notificationMessage);
  }

  /**
   * this method claculate expiration type and triggers storing method
   * @param alertId this method removes alert from list of alerts
   */
  public removeSiteAlert(alert: NotificationDetails): void {
    const notificationMessage: NotificationMessage = {
      messageType: 'remove',
      id: alert.id,
      expires: this.calculateExpiration(alert.expirationType),
      notificationDetails: alert,
    };

    logger.debug('removing:', notificationMessage);
    // TODO add id to local storage with required expiration
    this.siteAlertInput$.next(notificationMessage);
  }

  /**
   * calculates expiration time based on displayoption set for alert
   */
  private calculateExpiration(expirationType: string): number {
    // TODO - calculate and implement other options - this is currently hardcoded
    if (!(expirationType in storageSettings)) {
      expirationType = 'always';
    }
    return storageSettings[expirationType];
  }

  /**
   * helper method to map fields
   * @param siteAlertContent site alert from resourceapi
   */
  public getAlertDetails(
    siteAlertContent: SiteAlertContent
  ): NotificationDetails {
    return {
      id: siteAlertContent.id,
      title: siteAlertContent.title,
      subTitle: siteAlertContent.subTitle,
      notificationType: this.mapNotificationType(siteAlertContent.type),
      notificationLabel: removePTags(this.getMessageContent(siteAlertContent)),
      active: true,
      canClose: this.getCanClose(siteAlertContent),
      firms: siteAlertContent?.firms,
      pageTypes: siteAlertContent.pageTypes, // TODO change after backend attribute name is fixed (cammelcase + multiselection)
      segmentTarget: siteAlertContent.segments,
      expirationType: siteAlertContent.displayOptions, // TODO change after backend attribute name is fixed (cammelcase)
      primaryBtnData: this.getButtonData(siteAlertContent, 'primary'),
      secondaryBtnData: this.getButtonData(siteAlertContent, 'secondary'),
      tertiaryBtnData: this.getButtonData(siteAlertContent, 'tertiary'),
      ctaText: this.getButtonData(siteAlertContent, 'link').displayText,
      notificationButton: {
        primary: this.getNotificationOptions(siteAlertContent, 'primary'),
        secondary: this.getNotificationOptions(siteAlertContent, 'secondary'),
        tertiary: this.getNotificationOptions(siteAlertContent, 'tertiary'),
        ctaLink: this.getNotificationOptions(siteAlertContent, 'link'),
      },
      isHeaderNotification: siteAlertContent?.headerNotification,
    };
  }
  private getNotificationOptions(content: SiteAlertContent, key: string) {
    const buttonData: NotificationLinkOptions = {};
    if (Array.isArray(content?.ButtonCompound)) {
      content?.ButtonCompound?.forEach((btn) => {
        if (btn.button.ButtonStyle === key) {
          const widenAsset =
            btn?.button?.LinkCompound?.linkCollection?.widenAssetCompound
              ?.widenDocument;
          buttonData.target = btn?.button?.LinkCompound?.linkCollection?.url;

          buttonData.isExternal =
            btn?.button?.LinkCompound?.linkCollection?.external;
          buttonData.isNewWindow =
            btn?.button?.LinkCompound?.linkCollection?.target === '_blank';
          if (btn?.button?.LinkCompound?.linkCollection?.signInLink) {
            buttonData.signInRequired = true;
          }

          buttonData.hideLockIcon =
            btn?.button?.LinkCompound?.linkCollection?.hideLockIcon;
          if (widenAsset) {
            buttonData.target = this.widenService.getWidenAssetUrl(widenAsset);
          }
        }
      });
    }
    return buttonData;
  }

  /**
   * Types in Bloomreach are defined with first capital characters, EDS required tyle lowercase
   * @param alertType alert type list
   */
  private mapNotificationType(alertType: NotificationTypes): NotificationTypes {
    const transformedAlertType = alertType.toLowerCase() as NotificationTypes;
    if (Object.values(NotificationTypes).includes(transformedAlertType)) {
      return transformedAlertType;
    }
    return alertType;
  }

  /**
   * helper method for alert buttons
   * @param content site alret content
   * @param key button type
   */
  private getButtonData(
    content: SiteAlertContent,
    key: string
  ): LinkCollections {
    const buttonData: LinkCollections = {};
    let buttonText = '';
    if (Array.isArray(content?.ButtonCompound)) {
      content?.ButtonCompound?.forEach((btn) => {
        if (btn.button.ButtonStyle === key) {
          buttonText = btn?.button?.LinkCompound?.linkCollection?.displayText;
          buttonData.displayText =
            btn?.button?.LinkCompound?.linkCollection?.displayText;
          buttonData.document =
            btn?.button?.LinkCompound?.linkCollection?.document;
        }
      });
    }
    return buttonData;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * helper method for get can close
   * @param siteAlertContent site alert content
   */
  private getMessageContent(siteAlertContent): string {
    return siteAlertContent?.messageLink?.content !== undefined &&
      siteAlertContent?.messageLink?.content !== ''
      ? siteAlertContent?.messageLink?.content
      : siteAlertContent?.message;
  }

  /**
   * helper method for get can close
   * @param siteAlertContent site alert content
   */
  private getCanClose(siteAlertContent): boolean {
    let canClose;
    if (siteAlertContent?.displayOptions === 'cant-dismiss') {
      canClose = false;
    } else {
      if (siteAlertContent?.canClose === undefined) {
        canClose = true;
      } else if (siteAlertContent?.canClose !== undefined) {
        canClose = siteAlertContent?.canClose === true;
      }
    }
    return canClose;
  }
}
