import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ResponsiveService, SpacingValue } from '@frk/eds-components';
import { AppStateService, SignInService } from '@services';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  Subject,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ft-sign-in-bar',
  templateUrl: './sign-in-bar.component.html',
  styleUrls: ['./sign-in-bar.component.scss'],
})
export class SignInBarComponent implements AfterViewInit {
  /**
   * signInBarText
   * text on sign in bar
   */
  @Input() signInBarTextLabel: string;

  /**
   * fullPageWidth
   * Toggles grid wraapper when sign in bar
   * runs across full width of page e.g. below a banner
   */
  @Input() isFullPageWidth = false;

  @Input() spacingBottom: SpacingValue = 'md';

  @ViewChild('signInBarComponent') signInBarComponent: ElementRef;
  private unSubscribe$: Subject<void> = new Subject<void>();
  private smallComponentWidth = 630;
  public isMobile$: Observable<boolean>;
  public isSignInBarSmall$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isSignInBarSmal = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private responsiveService: ResponsiveService,
    private signInService: SignInService,
    private appStateService: AppStateService
  ) {
    // Listen for responsive changes
    this.isMobile$ = this.responsiveService.isMobile$();
    // Listen for window resize
    fromEvent(window, 'resize')
      .pipe(
        map(() => this.signInBarComponent?.nativeElement?.offsetWidth),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((width: number) => {
        this.isSignInBarSmall$.next(width < this.smallComponentWidth);
      });
  }

  ngAfterViewInit(): void {
    if (
      this.signInBarComponent?.nativeElement?.offsetWidth <
      this.smallComponentWidth
    ) {
      this.isSignInBarSmall$.next(true);
    }

    combineLatest([this.isMobile$, this.isSignInBarSmall$])
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(([isMobile, isSignInBarSmal]: [boolean, boolean]): void => {
        this.isSignInBarSmal = isMobile || isSignInBarSmal;
        this.changeDetector.detectChanges();
      });
  }

  /**
   * launchSignIn
   * Launches signin modal
   */
  public launchSignIn() {
    if (!this.appStateService.isAuth0Login()) {
      this.signInService.showSignInModal();
    } else {
      this.signInService.submitAuth0();
    }
  }
}
