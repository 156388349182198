import { Injectable } from '@angular/core';
import {
  ExactMatch,
  ExactMatchDataNode,
  FundThumbnail,
  FundItem,
  FundData,
  FundThumbnailItem,
  QuickLinkItem,
} from '@search/interfaces/search.interface';
import { FundId, FundIdentifier, ShareClassCode } from '@types';
import { TranslateService } from '@shared/translate/translate.service';
import { ConfigService } from '@search/services/config.service';
import { SiteConfigService } from '@services/site-config.service';
import { FileInfo, extractFileInfoFromLink } from '@utils/link-utils';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  generalData: ExactMatchDataNode;
  title: string;
  overview: string;
  fundsViewAllLink: string;
  pagesViewAllurls: string;
  columns: string[];

  fundsThumbnails: Array<FundThumbnail>;
  fundItemsGeneral: Array<FundItem>;
  quickLinks: Array<QuickLinkItem>;

  constructor(
    protected siteConfigService: SiteConfigService,
    protected translateService: TranslateService,
    private configService: ConfigService
  ) {}

  setExactResults(exactMatch: ExactMatch): void {
    this.generalData = exactMatch.data[0];
    this.overview = this.generalData.hits[0].richText;
    this.title = this.generalData.hits[0].title;
    this.fundsViewAllLink = this.generalData.hits[0].fundsViewAllLink;
    this.pagesViewAllurls = this.generalData.hits[0].pagesViewAllurls;
    this.columns = this.generalData.hits[0].columnCombos;

    this.fundItemsGeneral = this.generalData.hits[0].relatedFunds.map(
      (fundItem) => {
        return {
          fundName: fundItem.title,
          fundFamily: fundItem.assetclass,
          fundLink: this.getProductPageLink({
            fundId: fundItem.fundid as FundId,
            shareClass: fundItem.shclcode as ShareClassCode,
            fundName: fundItem.title,
            identifier: fundItem.ticker,
          }),
        };
      }
    );

    this.fundsThumbnails = this.generalData.hits[0].relatedPages.map(
      (pageItem: FundThumbnailItem) => {
        return {
          title: pageItem.title,
          titleLink: pageItem.reference,
          body: this.getGeneralBody(pageItem),
          docTypeSrc: this.configService.getIconPath(pageItem.pageTypeCustom),
        };
      }
    );

    this.quickLinks = this.generalData.hits[0].quickLinks.map(
      (linkItem: QuickLinkItem) => {
        const docPathLocal = linkItem.url ? linkItem.url : '';
        const fileAttributes: FileInfo = extractFileInfoFromLink(docPathLocal);
        const documentIcon = this.configService.knownExtensions.includes(
          fileAttributes.fileExtension
        )
          ? fileAttributes.fileExtension
          : '';

        return {
          title: linkItem.title,
          url: docPathLocal,
          subTitle: linkItem.subTitle,
          docTypeSrc: this.configService.getIconPath(documentIcon),
        };
      }
    );
  }

  getLayoutType() {
    return Math.max(this.columns.length - 1, 0);
  }

  hasColumn(type: string): boolean {
    return this.columns.includes(type);
  }

  getGeneralBody(item: FundThumbnailItem): string {
    const formattedDate = item.referenceDate_formatted || '';
    const contentType = this.translateService.instant(
      'common.page-type.' + item.pageTypeCustom || ''
    );
    const descriptionText = this.getGeneralContent(item);

    return `<span class="text-meta-tag">${formattedDate} | ${contentType}</span><br/>${descriptionText}`;
  }

  getGeneralContent(item: FundThumbnailItem): string {
    return item.description;
  }

  getProductPageLink(fundData: FundData): string {
    const fundLink = this.siteConfigService.getFundLink(
      fundData.fundId as FundId,
      fundData.shareClass as ShareClassCode,
      fundData.fundName,
      fundData.identifier as FundIdentifier
    );
    return fundLink;
  }

  /**
   * returns section title based on selected layout type
   */
  getLatestContentTitle(): string {
    if (
      this.hasColumn('Quicklinks') ||
      this.hasColumn('Insights & Quicklinks')
    ) {
      return this.translateService.instant('common.quick-links');
    }

    return this.translateService.instant('common.latest-content');
  }

  getTitle(): string {
    return this.title;
  }

  getOverview(): string {
    return this.overview;
  }

  getRelatedFunds(): FundThumbnail[] {
    return this.fundsThumbnails;
  }

  getRelatedPages(): FundItem[] {
    return this.fundItemsGeneral;
  }

  getQuickLinks(): QuickLinkItem[] {
    return this.quickLinks;
  }

  getViewMoreFundsLink(): string {
    return this.fundsViewAllLink;
  }

  getViewMoreGeneralLink(): string {
    return this.pagesViewAllurls;
  }
}
