import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import { BrPageComponent } from '@bloomreach/ng-sdk';
import { ViewModeService } from '@services';

@Component({
  selector: 'ft-two-cols-extended',
  templateUrl: './two-cols-extended.component.html',
  styleUrls: ['./two-cols-extended.component.scss'],
})
export class TwoColsExtendedComponent {
  /**
   * Bloomreach page object
   */
  @Input() page!: Page;

  /**
   * isWide
   */
  @Input() isWide = true;

  /**
   * Component configuration
   */
  configuration: BrPageComponent['configuration'];

  isEditMode = false;

  constructor(private viewModeService: ViewModeService) {
    this.isEditMode = this.viewModeService.isEditMode();
  }
}
