import { Identifiers } from '@models';
import {
  ADM,
  AnbimaCode,
  APIR,
  ARSN,
  Bloomberg,
  ConfigurationCountry,
  ConfigurationLocale,
  CUSIP,
  DSC,
  Frontend,
  FundId,
  IdentifiersDTO,
  IntradayNAV,
  IOPV,
  Iress,
  IressNAVCode,
  ISIN,
  LowLoad,
  MEXID,
  MLIdentifier,
  NASDAQ,
  ReutersNumber,
  RFI,
  SEDOL,
  ShareClassCode,
  TAID,
  Ticker,
  VLN,
  WKN,
} from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class IdentifiersMapper extends Mapper<Identifiers> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(identifiersDto: IdentifiersDTO): Identifiers {
    return {
      adm: identifiersDto.adm as ADM,
      anbimaCode: identifiersDto.anbimacode as AnbimaCode,
      apir: identifiersDto.apir as APIR,
      arsn: identifiersDto.arsn as ARSN,
      bloomberg: identifiersDto.bloomberg as Bloomberg,
      countryCode: identifiersDto.cntrycode as ConfigurationCountry,
      cusip: identifiersDto.cusip as CUSIP,
      dsc: identifiersDto.dsc as DSC,
      frontend: identifiersDto.frontend as Frontend,
      fundbmTicker: identifiersDto.fundbmticker as Ticker,
      fundId: identifiersDto.fundid as FundId,
      intradayNav: identifiersDto.intradaynav as IntradayNAV,
      iopv: identifiersDto.iopv as IOPV,
      iress: identifiersDto.iress as Iress,
      iressinavCode: identifiersDto.iressinavcode as IressNAVCode,
      isin: identifiersDto.isin as ISIN,
      languageCode: identifiersDto.langcode as ConfigurationLocale,
      lowload: identifiersDto.lowload as LowLoad,
      mexId: identifiersDto.mexid as MEXID,
      mlidentifier: identifiersDto.mlidentifier as MLIdentifier,
      nasdaqxnms: identifiersDto.nasdaqxnms as NASDAQ,
      reuterSystem: identifiersDto.reuterssym as ReutersNumber,
      rfi: identifiersDto.rfi as RFI,
      shareClassCode: identifiersDto.shclcode as ShareClassCode,
      sedol: identifiersDto.sedol as SEDOL,
      taid: identifiersDto.taid as TAID,
      ticker: identifiersDto.ticker as Ticker,
      vln: identifiersDto.vln as VLN,
      wkn: identifiersDto.wkn as WKN,
    };
  }
}
