<div
  edsGrid
  edsPadding
  [edsPaddingBottom]="padding"
  [edsPaddingTop]="padding"
  [edsSpacing]="marginBottom ? marginBottom : defaultMarginBottom"
>
  <div class="container">
    <!--row -->
    <div edsRow>
      <!-- EDS component -->
      <eds-title
        edsCol
        *ngIf="pageTitle"
        [title]="pageTitle"
        [level]="1"
      ></eds-title>
    </div>
    <!--/row -->
  </div>
</div>
