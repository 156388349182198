import dayjs from 'dayjs';
import frCaConfig from 'dayjs/locale/fr-ca';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

dayjs.locale('fr-ca', { ...frCaConfig });

export const customFrCaConfig = dayjs.updateLocale('fr-ca', {
  ...frCaConfig,
  formats: {
    ...frCaConfig.formats,
    L: 'DD/MM/YYYY',
  },
});
