import { Injectable, OnDestroy } from '@angular/core';
import { EnvConfigService } from './env-config.service';
import { Content, Page } from '@bloomreach/spa-sdk';
import { Link, WidenDocumentJson } from '@types';
import { replaceTokens } from '@utils/text/string-utils';
import { SignInService } from '@services/sign-in.service';
import { ProfileService } from '@services/profile.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Logger } from '@utils/logger';
import { AppStateService } from '@services';
import { TranslateService } from '@shared/translate/translate.service';

/**
 * Logger
 */
const logger = Logger.getLogger('LinkService');

@Injectable({
  providedIn: 'root',
})
// NGC-14350 - Move link operations from abstract-base-component due to issues with environment tokens replacement
export class LinkService implements OnDestroy {
  public isLoggedIn: boolean;

  private unsubscribe$: Subject<void> = new Subject<void>();

  private linkDataState$ = new BehaviorSubject<string>('#');

  constructor(
    private appStateService: AppStateService,
    private envConfigService: EnvConfigService,
    private signInService: SignInService,
    private profileService: ProfileService,
    private translateService: TranslateService
  ) {
    this.profileService
      .isLoggedIn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
  }
  /**
   * Return the calculator state subject as observable.
   */
  getCurrentLinkData$(): Observable<string> {
    return this.linkDataState$.asObservable();
  }

  public setUpdatedLinkData(linkData: string) {
    if (linkData && linkData !== 'undefined' && linkData !== '#') {
      this.linkDataState$.next(linkData);
    }
  }

  /**
   * Parse widen document json and read parameters
   * @param widenDocument - Widen document Json
   */
  public getWidenDocumentParams(widenDocument: string): WidenDocumentJson {
    if (widenDocument) {
      const widenJson = JSON.parse(widenDocument);
      return {
        id: widenJson.id,
        url: widenJson.url,
        external_id: widenJson?.external_id,
        document_title: widenJson?.document_title,
        last_update_date: widenJson?.last_update_date,
        noindex: widenJson?.noindex as boolean,
      };
    }
    return null;
  }

  /**
   * Return absolute link
   * @param linkCompound - Link object
   * @param cmsURL - CMS URL string
   */
  private getCmsUrl(linkCompound: Link, cmsURL: string): string {
    if (linkCompound.url.startsWith('/')) {
      // WDE-1642 : Australia Campaign Page Callout Tracking Issue
      // cmsURL gets us full domain(e.g. www.franklintempleton.com/our-products/...)
      // we just need the spaBaseUrl to be appended and if spaBaseUrl is not
      // available(In case of prod site) we just need to pass the linkCoumpond.url
      // so that the link generated is a redirection/router path and the page
      // doesn't refreshes.
      if (cmsURL === '') {
        return linkCompound.url;
      }
      return `${cmsURL}${linkCompound.url}`;
    }
    return `${cmsURL}/${linkCompound.url}`;
  }

  /**
   * Replace environment token if it is in link
   * @param linkURL - URL string
   */
  private replaceEnvToken(linkURL: string): string {
    if (/\$ENV/.test(linkURL)) {
      return replaceTokens(linkURL, this.envConfigService.getEnvConfig());
    }
    return linkURL;
  }

  /**
   * Read the link compound and return the url
   * @param page - Page
   * @param linkCompound - Link object
   * @param cmsURL - CMS URL string
   */
  public getCTALink(
    page: Page,
    linkCompound: Link,
    cmsURL?: string,
    exposeUrl = false
  ): string {
    if (linkCompound) {
      // Hide URL when link is protected and user is not logged in
      linkCompound.isLoggedIn = this.isLoggedIn;
      if (linkCompound.signInLink && !linkCompound.isLoggedIn && !exposeUrl) {
        return '#';
      }
      // Return document asset
      if (linkCompound.document) {
        return this.processLink(page, linkCompound.document);
      }
      // Return Widen asset
      if (linkCompound.widenAssetCompound) {
        return this.getWidenDocumentParams(
          linkCompound.widenAssetCompound?.widenDocument
        )?.url;
      }

      if (linkCompound.url) {
        // Checking if URL starts with "absolute" path or with $ENV variable.
        const regex = /^http:|^https:|^mailto:|\$ENV/;
        // Return URL defined in resourceapi
        if (regex.test(linkCompound.url)) {
          return this.replaceEnvToken(linkCompound.url);
        }
        // Adding cmsUrl to link if required
        if (cmsURL) {
          return this.getCmsUrl(linkCompound, cmsURL);
        }

        // WDE-1642 : Australia Campaign Page Callout Tracking Issue
        // cmsURL gets us full domain(e.g. www.franklintempleton.com/our-products/...)
        // we just need the spaBaseUrl to be appended and if spaBaseUrl is not
        // available(In case of prod site) we just need to pass the linkCoumpond.url
        // so that the link generated is a redirection/router path and the page
        // doesn't refreshes.
        return this.getCmsUrl(linkCompound, '');
      }
    }
  }

  /**
   * Shared documents such as Articles, Press Releases and Profiles require a
   * little more processing as we refactor our shared content method (WDE-4151)
   * @param page br sdk page object
   * @param document br sdk document
   */
  public processLink(page: Page, document: string) {
    let url: string;
    const content: Content = page.getContent(document);
    const data: any = page.getContent(document).getData();
    const baseUrl: string = this.appStateService.getSpaBaseUrl();

    if (data?.sharedUrl) {
      // Read "articles" translation
      url = this.processSharedLink(data?.sharedUrl);
    } else {
      url = content?.getUrl();
    }
    return url;
  }

  private processSharedLink(sharedUrl: string): string {
    const baseUrl: string = this.appStateService.getSpaBaseUrl();
    sharedUrl = baseUrl + sharedUrl;
    // Replace English with translated paths

    // Articles
    const articlePath = this.translateService.instant('common.article.path');
    if (
      sharedUrl.includes('/articles/') &&
      articlePath !== 'common.article.path'
    ) {
      sharedUrl = sharedUrl.replace('/articles/', '/' + articlePath + '/');
    }

    // Press Releases
    // Profiles

    return sharedUrl;
  }

  /**
   * Open Sign In Modal for link compounds with sign in params
   * @param event - click event
   * @param linkCompound - linkCompound object
   */
  public openSignInLink(event: Event, linkCompound: Link): void {
    event.preventDefault();
    this.signInService.showSignInModal();
    this.signInService.showCustomIntroTxtInModal(
      linkCompound.signInIntroTextLabel
    );
  }

  /**
   * Returns true if linkCompound has sign in options
   * @param linkCompound - linkCompound object
   */
  public isLinkSignIn(linkCompound: Link): boolean {
    return !!(linkCompound?.signInLink && linkCompound?.signInIntroTextLabel);
  }

  public getHref(page: Page, linkCompound: Link, homeURL?: string): any {
    return this.getCTALink(page, linkCompound, homeURL);
  }

  public getDownloadLocationHref(
    page: Page,
    linkCompound: Link,
    homeURL?: string
  ): string {
    return this.getCTALink(page, linkCompound, homeURL, true);
  }

  /**
   * Appends spaBaseURL to the hrefs in the content
   * @param content Content from CMS
   * @param spaBaseUrl spaBaseURL
   * @returns modified hrefs in the content
   */
  public addSpaBaseURLToLinks(content: string, spaBaseUrl: string): string {
    const textContent = content;
    // Extracting all the links with href from the content
    const allLinks = textContent.match(
      /<a[^>]+href=\"(.*?)\"[^>]*>(.*?)<\/a>/g
    );
    const links = [...new Set(allLinks)];

    if (links.length && spaBaseUrl) {
      const pathNames: string[] = [];

      links.forEach((link) => {
        // Extracting href value from the link
        const href = link.match(/href="([^"]*)/)[1];

        if (!href.startsWith(spaBaseUrl)) {
          pathNames.push(href);
        }
      });
      if (pathNames) {
        // Reg exp for the string that starts with only one slash
        const regex = new RegExp(/^\/(?!\/.+)/);

        const spaBasePathNames = pathNames.map((pathName) => {
          if (pathName.startsWith('/') && regex.test(pathName)) {
            return `${spaBaseUrl}${pathName}`;
          }
          return pathName;
        });
        const spaBaseUrlContent = pathNames.reduce(
          (previousContent, pathName, index) => {
            // Reg exp for finding all the similar urls
            const pathNameRegex = new RegExp(pathName, 'g');

            if (!previousContent) {
              previousContent = textContent.replace(
                pathNameRegex,
                spaBasePathNames[index]
              );
              return previousContent;
            } else {
              return previousContent.replace(
                pathNameRegex,
                spaBasePathNames[index]
              );
            }
          },
          ''
        );
        return spaBaseUrlContent;
      }
    }
    return textContent;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
