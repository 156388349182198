import { Component, Input, OnInit } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk/';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { TabsData } from './tabs.interface';
import { CardsService } from '@services/cards.service';
import { ViewModeService } from '@services/view-mode.service';

@Component({
  selector: 'ft-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent extends AbstractBaseComponent implements OnInit {
  /**
   * The Bloomreach component
   */
  @Input() component!: BrComponent;

  /**
   * The Bloomreach page
   */
  @Input() page!: Page;

  /**
   * tabs data from BR
   */
  tabsData: Array<TabsData>;

  /**
   * spacing bottom
   */
  spacingBottom: string;

  /**
   * tabs type e.g. 'horizontal' or 'vertical'
   */
  tabsType: string;

  /**
   * container or container--wide will get from BR
   */
  containerClass: string;

  /**
   * tabs background should be grey or white
   */
  tabTheme: string;

  /**
   * tab header title
   */
  title: string;

  /**
   * tab header pre title
   */
  preTitle: string;

  /**
   * tab header summary
   */
  summary: string;

  constructor(
    private cardsService: CardsService,
    private viewModeService: ViewModeService
  ) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  ngOnInit(): void {
    this.setTabsData();
  }

  /**
   * sets the tabs data
   */
  private setTabsData() {
    const data = this.document?.getData();
    this.tabsData = data?.horizontalVerticalTabItem;
    const params = this.component?.getParameters();
    this.spacingBottom = params?.marginBottom;
    this.tabsType = params?.tabsType;
    this.tabTheme = params?.theme;
    this.containerClass = params?.containerClass;
    this.title = data?.title?.title;
    this.preTitle = data?.title?.preTitle;
    this.summary = data?.summary?.value;
    this.formatDataForMediaCards();
  }

  /**
   * formatting the Card Group Compound data as
   * per the eds media cards and thumbnail cards requirement
   */
  private formatDataForMediaCards() {
    if (this.tabsData) {
      for (const tab of this.tabsData) {
        for (let content of tab.tabContent) {
          content = this.cardsService.formatDataForMediaCards(
            content,
            this.page
          );
        }
      }
    }
  }
}
