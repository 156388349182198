import { AumDto } from '@types';
import { Mapper, MapperParams } from './type.mapper';
import { MapperFactory } from './mapper-factory';
import { Aum } from '@products/models/aum';

export class AumMapper extends Mapper<Aum> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(aumDto: AumDto): Aum {
    return {
      avg12MonthsAum: aumDto.avg12mnthaum,
      totalNetAssets: aumDto.totnetassets,
      asOfDate: aumDto.asofdate,
    };
  }
}
