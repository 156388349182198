<eds-simple-modal
  #modal
  [modalId]="'termsModal'"
  [title]="customTermsPath ? title[customTermsPath] : title[segmentId]"
  [config]="modalConfig"
  size="medium"
  [showClose]="false"
  [scrollToAcceptAnchorBtnLabel]="'common.scrollToAcceptBtnLabel' | translate"
  [hasSideContent]="termsWithSignIn"
  [hasScrollToBottom]="hasScrollToBottom"
>
  <div modal-body>
    <div
      *ngIf="languageOptions"
      edsFlexbox
      justifyContent="flex-end"
      edsSpacing="sm"
      class="gateway-modal__lang-switch"
    >
      <eds-flyout-menu
        [useSmallText]="true"
        [title]="languageOptions.title"
        [items]="languageOptions.items"
        ariaLabelledby="languages"
        [theme]="'basic'"
      ></eds-flyout-menu>
    </div>
    <div
      [innerHTML]="
        customTermsPath ? content[customTermsPath] : content[segmentId]
      "
    ></div>
    <p *ngIf="termsMustRead">
      <strong>
        <eds-checkbox
          name="checkTermsAgreement"
          [labelName]="'common.modalTermsAgreementLabel' | translate"
          [required]="true"
          (checkedChange)="onCheckedChange($event)"
        ></eds-checkbox>
      </strong>
    </p>
  </div>
  <div modal-side-content>
    <div *ngIf="signInError" class="gateway-modal__login-error">
      {{ "signin.modalSignInErrorLabel" | translate }}
    </div>
    <eds-sign-in
      id="signInTermsModal"
      [hasBorders]="false"
      [hasShadows]="false"
      [hasPadding]="false"
      [signInComponentFields]="signInComponentContent"
      [hasMaskedIcon]="true"
      [showMaskedText]="'signin.hide' | translate"
      [hideMaskedText]="'signin.show' | translate"
    ></eds-sign-in>
  </div>

  <div modal-footer class="text-align-right">
    <span class="gateway-modal__scroll-down-button-wrapper">
      <button
        id="btnTermsDecline"
        class="ft__btn"
        edsButton="secondary"
        size="normal"
        (click)="declineTerms()"
        data-analytics-no-event="true"
      >
        <span class="ft__btn__txt">{{ "common.decline" | translate }}</span>
      </button>
      <button
        id="btnTermsAccept"
        class="ft__btn"
        edsButton="primary"
        size="normal"
        [disabled]="!isTermsAccepted"
        [noMargin]="true"
        (click)="acceptTerms()"
        data-analytics-no-event="true"
      >
        <span class="ft__btn__txt">{{ "common.accept" | translate }}</span>
      </button>
    </span>
  </div>
</eds-simple-modal>
