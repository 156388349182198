import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisualHintComponent } from './visual-hint.component';

@NgModule({
  declarations: [VisualHintComponent],
  imports: [CommonModule],
  exports: [VisualHintComponent],
})
export class VisualHintModule {}
