import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CompanyInfo,
  DialogConfig,
  DialogService,
  NavigationService,
} from '@frk/eds-components';
import { GatewayModalComponent } from '@marketing/gateway-modal/gateway-modal.component';
import {
  getBreakpointType,
  isExternalLink,
  openInNewTab,
} from '@marketing/marketing-utils';
import {
  AppStateService,
  GlobalConfigService,
  LabelsService,
  LanguageSelectorService,
  LoginSource,
  PersonalisationAPIService,
  ProfileService,
  SegmentService,
  SignInService,
  SiteConfigService,
  WindowScrollService,
} from '@services';
import { ViewModeService } from '@services/view-mode.service';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import {
  getSocialImg,
  Segment,
  SegmentId,
  SimplyLinkItem,
  SiteParams,
} from '@types';
import { LOGOUT, SEGMENT_SWITCH } from '@utils/app.constants';
import { LabelCollection, Labels } from '@utils/labels';
import { Logger } from '@utils/logger';
import { getMenus } from '@utils/pagemodel-utils';
import find from 'lodash/find';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  FooterModel,
  GlobalItem,
  MobileSegment,
  NavItem,
  Parameters,
  SiteLabels,
  SiteNavigationType,
  SocialMediaItem,
} from './footer.interfaces';

// Interfaces

const logger = Logger.getLogger('FooterComponent');

@Component({
  selector: 'ft-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent
  extends AbstractBaseComponent
  implements OnInit, OnDestroy {
  date: Date = new Date();
  menuItem: any;
  segments: object[];
  currentSegment: string;
  currentSegmentLabel: string;
  homeUrl: string;
  siteLabels: SiteLabels;
  siteName: string;
  siteParams: SiteParams;
  hasSelectHub = true; // TODO: Make this configurable

  // Hide Navigation
  hasNavigation = true;

  // Text Logo
  hasTextLogo = false;

  // Header Style
  footerStyle: 'basic' | 'standard';

  // Nav Items
  footerCustomComponentLabels;
  footerParams: Parameters;
  countryLabel;
  componentLabel;
  socialMediaItems: SocialMediaItem[];
  userRoleItems;
  countryItems;
  langItems;
  siteNavItems;
  globalItems;
  companyInfo;
  languageLabel;
  roleNavData;

  // Child modal
  @ViewChild('gatewayModal', { static: false })
  gatewayModal: GatewayModalComponent;
  /**
   * External link dialog config
   */
  dialogConfig: DialogConfig;
  // Selected Segment and Segment Id
  selectedSegmentId: SegmentId;
  selectedSegment: Segment;

  // DCE-2649 Full site navigation
  siteNavigationType: SiteNavigationType;
  hasfullSiteNavigation = false;

  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private segmentService: SegmentService,
    private pageConfigService: GlobalConfigService,
    private appStateService: AppStateService,
    private labelsService: LabelsService,
    private profileService: ProfileService,
    private signInService: SignInService,
    private navService: NavigationService,
    private dialogService: DialogService,
    private languageSelectorService: LanguageSelectorService,
    private scrollService: WindowScrollService,
    private viewModeService: ViewModeService,
    private personalisationService: PersonalisationAPIService,
    private siteConfigService: SiteConfigService
  ) {
    super();
    this.segments = this.segmentService.getSegments();
    this.currentSegment = this.segmentService.getCurrentSegmentId();
    this.currentSegmentLabel = get(
      find(this.segments, { id: this.currentSegment }),
      'label',
      ''
    );
    this.siteParams = this.pageConfigService.getSiteParams();
    this.homeUrl = this.appStateService.getHomePageUrl();
    this.siteLabels = {
      commonLabels: {},
      countryLabels: {},
    };
    this.isEditMode = this.viewModeService.isEditMode();
  }

  ngOnInit() {
    this.labelsService.loadCountryLabels(this.page);
    this.initLabels();
    this.labelIcon = this.isEditMode ? '[L]' : '';
    this.siteName = get(this.page, 'model.channel.info.props["Site Name"]', '');

    // Read metadata from page model
    const metadataComponent = this.page.getComponent(
      'page-config',
      'pageMetadata'
    );

    this.initFooter();
    this.initDialogConfig();
    this.initSwitchSegmentDialog();
    logger.debug('Footer initialised');
  }

  initFooter() {
    const pageMetadata = this.page.getComponent()?.getModels()?.pageData;

    // Footer style
    this.footerStyle = pageMetadata?.footerStyle;

    // Footer Config
    this.hasNavigation = this.footerStyle !== 'basic';
    this.personalisationService
      .hasFirmSpecificProducts$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isFirmAdvisor: boolean) => {
        // hide navigation for users identified with specific firms
        if (isFirmAdvisor) {
          this.hasNavigation = false;
        }
      });

    // Get footer model
    const footerModel = this.component.getModels<FooterModel>();
    this.footerCustomComponentLabels = footerModel?.Footer;
    this.footerParams = this.getFooterParams();
    // Init Menu Items
    this.menuItem = getMenus(this.page, this.component);

    // Nav Items
    this.globalItems = this.getGlobalItems();
    this.siteNavigationType = this.footerCustomComponentLabels?.siteNavigationStyle?.toLowerCase();
    this.hasfullSiteNavigation =
      !this.siteConfigService.isSiteInternational() &&
      this.siteNavigationType === 'full';
    this.siteNavItems = this.getSiteNavItems();
    this.socialMediaItems = this.getSocialMediaItems();

    this.userRoleItems = this.getUserRoleItems();
    this.countryItems = this.getCountryItems();
    this.langItems = this.getLangItems();

    this.companyInfo = this.getCompanyInfo();
    this.languageLabel = this.getLanguageLabel();
    this.countryLabel = this.getCountryLabel();
    this.roleNavData = this.getRoleNavData();
    this.hasTextLogo = !!this.footerParams?.textLogo;
  }

  parseBoolean(value) {
    return value === 'true';
  }

  /**
   * Get Labels
   */
  initLabels(): SiteLabels {
    this.siteLabels.commonLabels = this.labelsService.initLabels('common');
    this.siteLabels.countryLabels = this.labelsService.initLabels('countries');
    return this.siteLabels;
  }

  /**
   * Get label for language dropdown
   */
  getLanguageLabel() {
    const channelLang = this.languageSelectorService.getChannelLanguageLabel(
      this.page
    );
    return this.labelIcon + channelLang;
  }

  /**
   * Get label for country dropdown
   */
  getCountryLabel() {
    return (
      this.labelIcon + get(this.siteLabels, 'commonLabels.country', 'Country')
    );
  }

  /**
   * Get additional parameters
   */
  getFooterParams(): Parameters {
    const countryDisclaimer: string =
      this.labelIcon +
      get(this.siteLabels, 'commonLabels.footerDisclaimer', '');
    const countryDisclaimerOverride: string =
      this.siteParams?.footerDisclaimerOverride ||
      this.siteLabels?.commonLabels?.footerDisclaimerOverride ||
      '';
    const copyrightFt: string =
      this.footerCustomComponentLabels?.copyright ||
      get(
        this.siteLabels,
        'commonLabels.copyright-ft',
        'Franklin Templeton Investments. All Rights Reserved.'
      );
    const copyright = `${this.labelIcon}${get(
      this.siteLabels,
      'commonLabels.copyright',
      'Copyright ©'
    )} ${this.date.getFullYear()} ${this.labelIcon}${copyrightFt}`;
    return {
      copyright,
      countryDisclaimer: this.getCountryDisclaimer(
        countryDisclaimer,
        countryDisclaimerOverride
      ),
      countryHubLink: get(
        this.siteParams,
        'countryHubLink',
        '//www.franklinresources.com/resources/help-legal/all-sites'
      ),
      textLogo: this.siteParams?.footerMicrositeTextLogo,
      imgSrc: get(
        this.siteParams,
        'footerLogo',
        '/assets/images/brand/ft-global-logo-footer.png'
      ),
      logoAltText: get(
        this.siteParams,
        'logoAltText',
        'Franklin Templeton Investments'
      ),
      socialMediaTitle:
        this.labelIcon +
        get(
          this.siteLabels,
          'commonLabels.socialMediaTitle',
          'Connect with us'
        ),
      userRoleMenuName: get(
        this.siteLabels,
        'commonLabels.userRoleMenuName',
        'User Role Menu'
      ),
      langMenuName: get(
        this.siteLabels,
        'commonLabels.langMenuName',
        'Language Menu'
      ),
      isLeavingSiteForCountryLinkClickDisplayed: this.siteParams
        ?.isLeavingSiteForCountryLinkClickDisplayed,
    };
  }

  /**
   * Get country disclaimer
   * @param countryDisclaimer - Country disclaimer from label
   * @param countryDisclaimerOverride - Country disclaimer which overrides label disclaimer
   */
  private getCountryDisclaimer(
    countryDisclaimer: string,
    countryDisclaimerOverride?: string
  ): string {
    if (this.footerCustomComponentLabels?.footerDisclaimer) {
      return this.footerCustomComponentLabels.footerDisclaimer;
    }
    if (countryDisclaimerOverride !== '') {
      return countryDisclaimerOverride;
    }
    if (countryDisclaimer !== '') {
      return `${countryDisclaimer} ${this.siteName}`;
    }
    return '';
  }
  /**
   * Get User roles for footer
   */
  getUserRoleItems(): SimplyLinkItem[] {
    if (this.currentSegment) {
      return this.convertRoles(this.segments, this.currentSegment);
    }
    return [];
  }

  /**
   * Get Country selector for footer
   */
  getCountryItems(): SimplyLinkItem[] {
    const countryItems = get(this.siteLabels, 'countryLabels', []);
    return this.convertCountries(countryItems);
  }

  /**
   * Get language selector for footer
   */
  getLangItems(): SimplyLinkItem[] {
    return this.languageSelectorService.getConvertedLanguages();
  }

  /**
   * Get Navigational menu for footer
   */
  getSiteNavItems(): NavItem[] {
    const siteNavItems = find(this.menuItem?.siteMenuItems, {
      name: 'Site Navigation Links',
    });
    return this.convertNavItems(get(siteNavItems, 'childMenuItems', []));
  }

  /**
   * Get global Links for footer
   */
  getGlobalItems(): GlobalItem[] {
    const globalItems = find(this.menuItem?.siteMenuItems, {
      name: 'Global Links',
    });
    return this.convertGlobal(get(globalItems, 'childMenuItems', []));
  }

  /**
   * Get Social Media links for footer
   */
  getSocialMediaItems(): SocialMediaItem[] {
    const socialMediaItems = find(this.menuItem?.siteMenuItems, {
      name: 'Social Media Links',
    });
    return this.convertSocial(get(socialMediaItems, 'childMenuItems', []));
  }

  /**
   * Generate Segment selector for Mobile
   */
  getRoleNavData(): MobileSegment[] {
    if (this.userRoleItems.length > 0) {
      return [
        {
          id: 100,
          name: this.currentSegmentLabel,
          link: '#',
          active: false,
          child: this.getSegmentsForMobile(),
        },
      ];
    }
    return [];
  }

  /**
   * Converting roles to required mobile segments
   */
  private getSegmentsForMobile(): MobileSegment[] {
    const segments = [];
    const roles = this.convertRoles(this.segments, this.currentSegment);
    let count = 101;
    roles.forEach((segment) => {
      segments.push({
        id: count++,
        name: segment.link,
        link: segment.href,
        active: false,
        child: [],
      });
    });
    return segments;
  }

  /**
   * Segment change for mobile view
   * @param event - click event
   */
  @HostListener('click', ['$event'])
  onClick(event) {
    // Need to find role mobile selector in clicked object
    const role = event.composedPath().find((el) => {
      return (
        typeof el.className === 'string' &&
        el.className.includes('country-role-mobile')
      );
    });
    const roleHref = event.composedPath().find((el) => {
      return el instanceof HTMLAnchorElement;
    });
    // Changing role only if link contains #
    if (role && roleHref?.href.includes('#')) {
      this.roleChanged(event);
    }
  }

  /**
   *  keyboard functionality for checking and Changing role only if link contains #.
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(event): void {
    if (event.code === 'Enter') {
      const roleHref = event.composedPath().find((el) => {
        return el instanceof HTMLAnchorElement;
      });

      // Changing role only if link contains #
      if (roleHref?.href.includes('#')) {
        this.roleChanged(event);
      }
    }
  }

  /**
   * Converting BR menu item to EDS GlobalItem interface
   * @param globalItems - BR array to convert
   */
  convertGlobal(globalItems: Array<any>): GlobalItem[] {
    if (!globalItems) {
      return [];
    }
    const globalItemsEds = [];
    globalItems.forEach((item) => {
      const globalItem: GlobalItem = {
        linkText: item.name,
        href: get(item, 'links.site.href', ''),
        hrefExternal: isExternalLink(item),
        breakpointType: getBreakpointType(item),
        hook: item.parameters['Angular Hook'] || '',
        newWindow: openInNewTab(item),
        ...(this.hasfullSiteNavigation && {
          linksList: this.convertGlobal(item?.childMenuItems),
        }),
      };
      globalItemsEds.push(globalItem);
    });
    return globalItemsEds;
  }

  /**
   * Converting BR menu item to EDS SocialMediaItem interface
   * @param socialMediaItems - BR array to convert
   */
  convertSocial(socialMediaItems: Array<any>): SocialMediaItem[] {
    if (!socialMediaItems) {
      return [];
    }
    const socialMediaItemsEds = [];
    socialMediaItems.forEach((item) => {
      const socialItem: SocialMediaItem = {
        alt: item.name,
        src: getSocialImg(item.name),
        href: get(item, 'links.site.href', ''),
      };
      socialMediaItemsEds.push(socialItem);
    });
    return socialMediaItemsEds;
  }

  /**
   * Converting BR menu item to EDS NavItem interface
   * @param siteNavItems - BR array to convert
   */
  convertNavItems(siteNavItems: Array<any>): NavItem[] {
    if (!siteNavItems) {
      return [];
    }
    const siteNavItemsEds: NavItem[] = [];
    siteNavItems.forEach((item) => {
      logger.debug(item);
      const navItem: NavItem = {
        siteNavTitle: item.name,
        ariaLabelledby: item.parameters['Aria Labelled By'] || '',
        siteNavTitleHref: this.hasfullSiteNavigation
          ? ''
          : get(item, 'links.site.href', ''),
        hrefExternal: isExternalLink(item),
        breakpointType: getBreakpointType(item),
        newWindow: openInNewTab(item),
        linksList: this.convertGlobal(get(item, 'childMenuItems', [])),
      };
      siteNavItemsEds.push(navItem);
    });
    return siteNavItemsEds;
  }

  /**
   * Converting BR menu item to EDS SimplyLinkItem interface
   * @param countryItems - BR array to convert
   */
  convertCountries(countryItems: object): SimplyLinkItem[] {
    if (!countryItems) {
      return [];
    }

    const simplyItemsEds = Object.entries(countryItems).map(([key, item]) => {
      const simplyItem: SimplyLinkItem = {
        link: item,
        href: key,
      };
      return simplyItem;
    });
    return sortBy(simplyItemsEds, 'link');
  }

  /**
   * User has selected a new role
   * This will set the new segment and store LS/Cookie
   * NB: this doesn't deal with accepting T&Cs anymore - that is handled by GatewayComponent
   */
  roleChanged(event, segmentId?: SegmentId) {
    if (event?.preventDefault) {
      event.preventDefault();
    }

    // TODO: logged in check
    // if user is logged in, show warning and stop
    // In future, logged in status should come directly from profile observable method

    let segment: Segment;
    try {
      // Read segment label from event;
      const segmentLabel = event.target.innerText?.trim();
      segment = this.segmentService.getSegmentByLabel(segmentLabel);
    } catch (e) {
      // if that fails, see if segmentId was passed as param
      if (segmentId) {
        segment = this.segmentService.getSegment(segmentId);
      }
    }
    // if user is logged in, show warning and stop
    if (
      this.profileService.isLoggedIn() &&
      this.profileService.getUserProfile().loginSource === LoginSource.OAUTH
    ) {
      this.selectedSegment = segment;
      this.openSwitchSegmentModal();
    } else {
      this.setNewSegment(segment);
    }
    this.scrollService.scrollToTop();
  }
  // Method for set the segment
  setNewSegment(segment: Segment) {
    // Set new segment
    if (segment) {
      if (segment.externalLink) {
        logger.debug('redirect to segment external link', segment.externalLink);
        this.navService.navigateByUrl(segment.externalLink, '_self');
        return;
      } else {
        // this.switchProfile(segment);
        this.segmentService.setSegment(segment.id, true);
        const accessControl: Array<string> = this.page
          .getComponent()
          ?.getModels()?.pageData?.accessSegments;
        if (accessControl?.length) {
          const access = accessControl?.includes(segment.id);
          if (!access) {
            this.navService.navigateByUrl(
              this.appStateService?.getHomePageUrl(),
              '_self'
            );
            return;
          }
        }
      }
    }
  }
  // save selected segment
  // this ensures cookie + local storage will be set, as they may not have been if you see this form
  private switchProfile(segment: Segment) {
    if (
      this.profileService.isLoggedIn() &&
      this.profileService.getUserProfile().loginSource === LoginSource.OAUTH
    ) {
      this.selectedSegmentId = segment.id;
      this.openSwitchSegmentModal();
    } else {
      this.segmentService.setSegment(segment.id, true);
    }
  }

  /**
   * Converting segment roles to EDS interface
   * @param currentSegment - selected segment
   * @param segments - Segments from site config
   */
  convertRoles(
    segments: Array<object>,
    currentSegment: string
  ): SimplyLinkItem[] {
    if (!segments) {
      return [];
    }
    const segmentsEds = [];
    segments.forEach((segment: Segment) => {
      const segmentId = get(segment, 'id', '');
      const simplyItem: SimplyLinkItem = {
        link: get(segment, 'label', segmentId),
        href: get(segment, 'externalLink', '#'),
      };
      if (segmentId !== currentSegment && !segment.isHiddenInSwitcher) {
        segmentsEds.push(simplyItem);
      }
    });
    return segmentsEds;
  }

  /**
   * Returns 3rd party company info for footer
   */
  getCompanyInfo(): CompanyInfo {
    if (typeof this.siteParams.companyInfo === 'undefined') {
      return;
    }
    if (
      this.siteParams.companyInfo.src &&
      this.siteParams.companyInfo.src !== ''
    ) {
      return {
        info: this.siteParams.companyInfo.info,
        src: this.siteParams.companyInfo.src,
        href: this.siteParams.companyInfo.href,
        alt: this.siteParams.companyInfo.alt,
        isExternal: !!this.siteParams.companyInfo?.isExternal,
      };
    }
  }
  /**
   * Open the Switch segment Model
   */
  openSwitchSegmentModal(): void {
    this.dialogConfig.successUrl = LOGOUT;
    this.dialogService.open(SEGMENT_SWITCH, this.dialogConfig);
  }
  /**
   * Initialise Dialog config
   */
  initDialogConfig() {
    const labels = Labels.getLabels(LabelCollection.common);
    if (labels) {
      this.dialogConfig = {
        title: labels.switchSegmentTitle,
        content: labels.switchSegmentContent,
        cancelBtnLabel: labels.cancel,
        closeBtnLabel: labels.close,
        okBtnLabel: labels.ok,
      };
    }
  }
  /**
   * Init External Dialog
   */
  initSwitchSegmentDialog() {
    // Shared dialog
    if (this.dialogService) {
      // Listen for dialog confirmation
      this.dialogService
        ?.getDialogClosedObs()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((dialog) => {
          if (dialog) {
            if (dialog.dialogId === SEGMENT_SWITCH) {
              if (dialog.config.successUrl) {
                logger.debug('Selected segment id', this.selectedSegmentId);
                this.segmentService.setSegment(this.selectedSegmentId, true);
                this.signInService.signOut(this.selectedSegment);
              }
              // TODO - open role selector modal if
            }
          }
        });
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
