import { Injectable } from '@angular/core';
import riskReturnOverviewQuery from '@graphql/fund-performance/risk-return-overview.graphql';
import riskReturnProfileQuery from '@graphql/fund-performance/risk-return-profile.graphql';
import { Product } from '@models';
import { RiskReturnProfile } from '@products/models/risk-return-profile';
import { FundOverviewService } from '@products/services/fund-overview.service';
import { RiskReturnProfileDataService } from '@products/services/risk-return-profile-data.service';
import { ProductDetailConfiguration } from '@types';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

export interface RiskReturnProfileState {
  profile: RiskReturnProfile;
  subTitle: string;
}

@Injectable({
  providedIn: 'root',
})
export class RiskReturnProfileService {
  state$ = new ReplaySubject<RiskReturnProfileState>(1);

  constructor(
    private fundOverviewService: FundOverviewService,
    private riskReturnProfileDataService: RiskReturnProfileDataService
  ) {}

  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    forkJoin([
      this.fundOverviewService.register(riskReturnOverviewQuery, {
        fundId: productDetailConfiguration.fundId,
        shareClassCode: productDetailConfiguration.shareClassCode,
      }),
      this.riskReturnProfileDataService.register(riskReturnProfileQuery, {
        fundId: productDetailConfiguration.fundId,
      }),
    ])
      .pipe(take(1))
      .subscribe(([product, profile]: [Product, RiskReturnProfile]) => {
        this.state$.next({
          profile,
          subTitle: profile.chartLabel || '',
        });
      });
  }

  getState$(): Observable<RiskReturnProfileState> {
    return this.state$.asObservable();
  }
}
