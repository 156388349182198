export enum CalcTypeStd {
  AACR = 'AACR',
  GROSS = 'GROSS',
  MARKET_PRICE = 'MARKET_PRICE',
  NAV = 'NAV',
  NET = 'NET',
  POP = 'POP',
  PUREGROSS = 'PUREGROSS',
  RANKINGS = 'RANKINGS', // see NGC-13642
  SINGAPORE = 'SINGAPORE', // see NGC-7670
}
