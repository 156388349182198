import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import {
  AppStateService,
  DatadogService,
  RedirectService,
  SegmentService,
  StorageService,
  UserAgentService,
} from '@services';
import { SegmentId } from '@types';
import { Logger } from '@utils/logger';
import { from, Observable } from 'rxjs';
import { ViewModeService } from '@services/view-mode.service';

const logger = Logger.getLogger('resource-api-interceptor');

@Injectable({
  providedIn: 'root',
})
export class ResourceApiHeadersInterceptor implements HttpInterceptor {
  firstLoad = true;
  baseUrlCheck: string;
  isEditMode = false;

  constructor(
    private appStateService: AppStateService,
    private segmentService: SegmentService,
    private storageService: StorageService,
    private prerenderService: UserAgentService,
    private datadogService: DatadogService,
    private redirectService: RedirectService,
    private viewModeService: ViewModeService
  ) {
    this.isEditMode = this.viewModeService.isEditMode();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.getProfile(request, next));
  }

  async getProfile(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf('resourceapi') !== -1) {
      let newUrl = this.checkChannelInUrl(req.url);

      logger.debug('intercept resourceapi request', req.url);

      let params = req.params;
      let headers = req.headers;

      // check if first time loading and add url param
      if (this.firstLoad) {
        this.firstLoad = false; // only want to load once
        logger.debug('add firstLoad url param');
        params = params.append('first-load', 'true');
      }

      const segmentChars: string = this.segmentService.getSegmentCharacteristicsString();
      if (segmentChars) {
        logger.debug(
          'append profile characteristics string header',
          segmentChars
        );
        headers = headers.append('profile', segmentChars);
      }

      if (this.datadogService.isRumEnabled()) {
        const context = datadogRum.getInternalContext();
        if (context) {
          headers = headers.append(
            'ddsid',
            `${context.session_id} ${context.view?.id ? context.view.id : ''}`
          );
        }
      }
      /**
       * work out segment and add as url param
       * TODO: this will be cleverer in future and take into account Profile data
       * for now, it just uses segment from LS/Cookie or site default
       */
      await this.segmentService
        .getCurrentSegmentIdPromise()
        .then((segmentId: SegmentId): void => {
          if (this.segmentService.getCurrentSegmentId() !== segmentId) {
            // When segment is selected in modal selector we are not updating observable to avoid loading resource-api
            // It needs to be updated here. Otherwise wrong content can be loaded.
            segmentId = this.segmentService.getCurrentSegmentId();
            this.segmentService.setSegment(segmentId);
          }
          logger.debug('append segment param', segmentId);
          params = params.append('segment', segmentId);
        });

      /*
       * Some article components require more than the default reference level.
       * Hub Doc > Stategy Tab Doc > Link
       */
      if (req.url.indexOf('resourceapi/articles/hubs') !== -1) {
        params = params.append('_maxreflevel', '3');
      }

      /*
        This code is used to redirect a homepage request to a special gateway homepage
        if
          the request is to homepage
          AND a gatewayUrl is defined in config
          AND the user has no segment LS/Cookie set
          then reroute the request to the gateway url
      */
      const gatewayUrl: string = this.appStateService.getGatewayUrl();
      // Gateway page can contain caveats. Prevent to set isGateway to false if caveats are called.
      if (!/resourceapi\/caveats?/.test(req.url)) {
        this.appStateService.setIsGateway(false);
      }
      logger.debug(
        'resourceapi url:',
        newUrl,
        newUrl.endsWith('resourceapi/'),
        /resourceapi\/?$/.test(req.url),
        this.appStateService.isHomePage(),
        gatewayUrl,
        this.appStateService.getChannel()
      );
      if (
        // check if this request is from prerender.io
        !this.prerenderService.isPrerender() &&
        // if homepage, determine if we need to redirect to 'gateway' homepage
        this.appStateService.isHomePage() &&
        // also check request isn't a different bit of content e.g. T&Cs
        // this regex checks the request ends with 'resourceapi' or 'resourceapi/'
        (/resourceapi\/?$/.test(newUrl) || /resourceapi\/\?/.test(newUrl)) && // this is homepage with request parameter
        // restrict this to sites that have Personalisation Banner (via gatewayUrl) enabled
        gatewayUrl &&
        // only apply if local storage is available in browser
        this.storageService.isStorageEnabled()
      ) {
        const isSegmentSet: boolean = await this.storageService.isSegmentSet();

        // WDE-4854 Check for latest segment from LocalStorage
        this.segmentService.checkSegmentSet();

        logger.debug('storageService.isSegmentSet():', isSegmentSet);
        // check segment after all the other conditions, as it's slower due to async
        if (!isSegmentSet && !this.isEditMode) {
          // LS+cookies not set for segment, so use gateway homepage instead
          // removes role and bypass params if exists
          newUrl = newUrl.replace(/\/\?.*/, '');
          if (!newUrl.endsWith('/') && !gatewayUrl.startsWith('/')) {
            // add '/' to path if needed
            newUrl = newUrl + '/';
          }
          newUrl = newUrl + gatewayUrl;
          this.appStateService.setIsGateway(true);
        }
      }

      // check if we should redirect resourceapi request
      const fromUrl = newUrl.replace(/^.*resourceapi/, '');
      const redirectUrl: string = await this.redirectService.getRedirect(
        fromUrl,
        true
      );
      logger.debug('redirectUrl', redirectUrl);
      if (redirectUrl) {
        const baseUrl = this.appStateService.getSpaBaseUrl();
        newUrl = `${baseUrl}/resourceapi${redirectUrl}`;
      }

      req = req.clone({
        withCredentials: true,
        params,
        headers,
        url: newUrl,
      });
      logger.debug('resourceapi url after replacement:', req.url);
      logger.debug('resourceapi headers:', req.headers);
    }
    return next.handle(req).toPromise();
  }

  // pagemodel 1.0 is inconsistent in adding channel to urls so need to remove
  private checkChannelInUrl(url): string {
    if (this.firstLoad) {
      const baseUrl = this.appStateService.getSpaBaseUrl();
      if (baseUrl) {
        this.baseUrlCheck = `resourceapi${baseUrl}`;
      }
    }

    return this.baseUrlCheck
      ? url.replace(this.baseUrlCheck, 'resourceapi')
      : url;
  }
}
