import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToggleState } from '@search/interfaces/search.interface';

@Component({
  selector: 'ft-exact-toggle',
  templateUrl: './exact-toggle.component.html',
  styleUrls: ['./exact-toggle.component.scss'],
})
export class ExactToggleComponent {
  @Input() showToggle = false;
  @Input() activeToggle: ToggleState = 'full';

  @Output() toggleChanged = new EventEmitter<string>();

  /**
   *
   */
  onToggleChange() {
    this.activeToggle = this.activeToggle === 'full' ? 'exact' : 'full';
    this.toggleChanged.emit(this.activeToggle);
  }
}
