import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessToken, IDToken } from '@okta/okta-auth-js';
import { AppStateService, SessionService, StorageService } from '@services';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('OktaLoginGuardService');

@Component({
  selector: 'ft-okta-login-callback',
  templateUrl: './okta-login-callback.component.html',
})
export class OktaLoginCallbackComponent implements OnInit {
  constructor(
    private router: Router,
    private sessionService: SessionService,
    private storageService: StorageService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    // TODO handle connection error
    // TODO handle wrong username/password error
    const oktaClient = this.sessionService.getOktaClient(
      this.appStateService.getAuthenticationType()
    );
    oktaClient?.token
      .parseFromUrl()
      .then((tokenContainer) => {
        oktaClient.tokenManager.add(
          'idToken',
          tokenContainer.tokens.idToken as IDToken
        );
        oktaClient.tokenManager.add(
          'accessToken',
          tokenContainer.tokens.accessToken as AccessToken
        );
        oktaClient.token
          .getUserInfo(
            tokenContainer.tokens.accessToken,
            tokenContainer.tokens.idToken
          )
          .then((userInfo) => {
            logger.debug(userInfo);
            // need to set profile and navigate to appropriate page.
            userInfo.userId = 'okta';
            userInfo.userSysNo = 'okta';
            this.storageService.saveOktaProfile(userInfo);
            const home = this.appStateService.relativeHomeURL(
              this.appStateService.getHomePageUrl()
            );
            this.storageService
              .getOktaCallbackRoute()
              .then((route: string) => {
                if (route) {
                  if (route.includes('?')) {
                    const routeWithParams = route.split('?');
                    const urlParams = new URLSearchParams(routeWithParams[1]);
                    const queryParams = Object.fromEntries(urlParams);
                    this.router
                      .navigate([routeWithParams[0]], {
                        queryParams,
                      })
                      .then(() => {
                        logger.debug('Routing to URL with params:', route);
                      });
                    return;
                  }
                  this.router.navigate([route]).then(() => {
                    logger.debug('Routing to:', route);
                  });
                } else {
                  this.router.navigate([home]).then(() => {
                    logger.debug('Routing to home:', home);
                  });
                }
              })
              .catch(() => {
                this.router.navigate([home]).then(() => {
                  logger.debug('Routing to home due to error.', home);
                });
              });
          });
      })
      .catch((e) => {
        logger.error(e);
      });
  }
}
