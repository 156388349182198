import { Injectable } from '@angular/core';
import { PerformanceStatus, Product, ShareClass } from '@models';
import { RiskStatsSummary } from '@products/models/risk-stats-summary';
import { FundOverviewService } from '@products/services/fund-overview.service';
import { EMDASH } from '@products/utils/constants/product.constants';
import {
  ConfigurationId,
  ProductDetailConfiguration,
  RiskMeasuresState,
  RMSummaryTableData,
} from '@types';
import { Logger } from '@utils/logger';
import { Subject } from 'rxjs';
import riskStatsSummaryQuery from '@graphql/fund-performance/risk-stats-summary.graphql';
import { RISK_MEASURES_INITIAL_STATE } from './fund-performance.config';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import kebabCase from 'lodash/kebabCase';
import { SiteConfigService } from '@services';

const logger = Logger.getLogger('RiskMeasuresService');
// This value is mapped value,not the actual value returned by PDS
const STATISTICS_ANALYSIS_ELEMENT_NAME_BR = 'elementValueSinceInception';

@Injectable({
  providedIn: 'root',
})
export class RiskMeasuresService {
  private configurationName: ConfigurationId;
  private state: RiskMeasuresState;
  riskMeasureStateSubject: Subject<RiskMeasuresState>;
  private rmSummaries: RiskStatsSummary[];
  private fundName: string;
  private maxNumberOfBenchmarksToShow: number;
  private isSingleShareClass: boolean;
  private productType: string;
  public isSMA: boolean;

  constructor(
    private overviewService: FundOverviewService,
    private siteConfigService: SiteConfigService
  ) {
    this.riskMeasureStateSubject = new Subject();
  }

  /**
   * Call register method of fund overview service.
   */
  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    this.state = cloneDeep(RISK_MEASURES_INITIAL_STATE);
    this.state.hideRiskStatisticsBenchmark = this.siteConfigService.hideRiskStatisticsBenchmark();
    this.overviewService
      .register(riskStatsSummaryQuery, {
        fundId: productDetailConfiguration.fundId,
        shareClassCode: productDetailConfiguration.shareClassCode,
      })
      .subscribe(
        (product) => {
          logger.debug(product);
          if (this.isRiskMeasuresDataAvailable(product)) {
            this.mapState(product);
          } else {
            logger.info(
              `No Risk Measures data found for fund id ${productDetailConfiguration.fundId}
               and share class ${productDetailConfiguration.shareClassCode} `
            );
            this.state.isError = true;
          }
          this.riskMeasureStateSubject.next(this.state);
        },
        (error) => {
          logger.error(
            `Error in getting Risk Measures data for fund id ${productDetailConfiguration.fundId}
             and share class ${productDetailConfiguration.shareClassCode}`,
            error
          );
          this.state.isError = true;
          this.riskMeasureStateSubject.next(this.state);
        }
      );
  }

  setConfigurationName(configurationName: ConfigurationId) {
    this.configurationName = configurationName;
  }

  getConfigurationName(): ConfigurationId {
    return this.configurationName;
  }

  /**
   * Check if risk measures data is present in respone or not.
   * @param product product data
   */
  private isRiskMeasuresDataAvailable(product: Product): boolean {
    if (
      product?.shareClasses?.length > 0 &&
      product.shareClasses[0]?.riskStatsSummary?.length > 0
    ) {
      return true;
    }
    return false;
  }

  /**
   * Map the risk measures response.
   * @param product received product risk measures details
   */
  mapState(product: Product): void {
    this.fundName = product.fundName;
    const shareClassData = product.shareClasses[0];
    this.rmSummaries = shareClassData.riskStatsSummary;
    this.isSingleShareClass = shareClassData.isSingleShareClass;
    this.state.perfStatusCaveat = this.getPerfStatusCaveat(shareClassData);
    this.productType = product.productType;

    this.isSMA = this.productType === 'Managed Accounts';

    this.maxNumberOfBenchmarksToShow = this.siteConfigService.getMaxNumberOfBenchmarksToShow(
      product.fundId
    );

    this.state.config.isFundOlderThan10Yrs =
      dayjs().diff(
        dayjs(shareClassData.inceptionDateStd, 'YYYY-MM-DD'),
        'years'
      ) >= 10
        ? true
        : false;

    this.state.config.show1YrPerformance =
      !this.state.config.isFundOlderThan10Yrs || this.isSMA;
    this.state.config.showSinceInception = this.getSiteInceptionConfig();

    this.state.shareClassName = shareClassData.shareClassName;
    this.state.benchmarkName = this.rmSummaries.find(
      (rmSummary) => rmSummary.benchmarkName
    )?.benchmarkName;
    this.state.asOfDate = this.rmSummaries[0].asOfDate;
    const statisticsData = this.rmSummaries;
    if (!this.siteConfigService.isBrazil()) {
      this.state.stdDev = this.getStdDevTableData(this.rmSummaries);
      this.state.sharpeRatio = this.getSharpeRatioTableData(this.rmSummaries);
    }

    this.state.statistics = this.getStatisticsTableData(statisticsData); // statistics remain always the last, to get extracted.

    this.riskMeasureStateSubject.next(this.state);
  }

  private getSiteInceptionConfig(): boolean {
    if (this.isSMA && !this.state.config.isFundOlderThan10Yrs) {
      return true;
    }

    return !!this.siteConfigService.product.performance?.showSinceInception;
  }
  /**
   * Get performance status caveat name based upon PDS flag.
   * In case no flag, return null.
   * @param currentShareClass currently selected share class
   */
  private getPerfStatusCaveat(currentShareClass: ShareClass): string {
    return currentShareClass?.performance.quarterEnd[0]?.performanceStatus ===
      PerformanceStatus.FINAL
      ? 'PPSSPerformanceFinalTop'
      : currentShareClass?.performance.quarterEnd[0]?.performanceStatus ===
        PerformanceStatus.PRELIM
      ? 'PPSSPerformancePreEliminaryTop'
      : null;
  }

  /**
   * Extract and format standard deviation data as per table display.
   * @param rmSummary Risk Stats Summary
   */
  getStdDevTableData(rmSummaries: RiskStatsSummary[]): RMSummaryTableData[] {
    const stdDevTableData: RMSummaryTableData[] = [];

    const stdDevFundDataIndex: number = rmSummaries.findIndex(
      (rmSummary) => rmSummary.elementNameStd === 'STANDARD_DEVIATION_FUND'
    );

    if (stdDevFundDataIndex > -1) {
      const stdDevFundData: RiskStatsSummary = rmSummaries[stdDevFundDataIndex];
      stdDevTableData.push({
        rmName: this.getRmName(stdDevFundData),
        calcTypeLabel: this.getCalcTypeLabel(stdDevFundData),
        year1Val: stdDevFundData?.elementValueFor1Year || EMDASH,
        year3Val: stdDevFundData?.elementValueFor3Year || EMDASH,
        year5Val: stdDevFundData?.elementValueFor5Year || EMDASH,
        year10Val: stdDevFundData?.elementValueFor10Year || EMDASH,
        valueSinceInception:
          stdDevFundData?.elementValueSinceInception || EMDASH,
      });
      rmSummaries.splice(stdDevFundDataIndex, 1);
    }

    const stdDevFundDataIndexNet: number = rmSummaries.findIndex(
      (rmSummary) => rmSummary.elementNameStd === 'STANDARD_DEVIATION_NET_FUND'
    );

    if (stdDevFundDataIndexNet > -1) {
      const stdDevFundData: RiskStatsSummary =
        rmSummaries[stdDevFundDataIndexNet];
      stdDevTableData.push({
        rmName: this.getRmName(stdDevFundData),
        calcTypeLabel: this.getCalcTypeLabel(stdDevFundData),
        year1Val: stdDevFundData?.elementValueFor1Year || EMDASH,
        year3Val: stdDevFundData?.elementValueFor3Year || EMDASH,
        year5Val: stdDevFundData?.elementValueFor5Year || EMDASH,
        year10Val: stdDevFundData?.elementValueFor10Year || EMDASH,
        valueSinceInception:
          stdDevFundData?.elementValueSinceInception || EMDASH,
      });
      rmSummaries.splice(stdDevFundDataIndexNet, 1);
    }

    const stdDevFundDataIndexGross: number = rmSummaries.findIndex(
      (rmSummary) =>
        rmSummary.elementNameStd === 'STANDARD_DEVIATION_GROSS_FUND'
    );

    if (stdDevFundDataIndexGross > -1) {
      const stdDevFundData: RiskStatsSummary =
        rmSummaries[stdDevFundDataIndexGross];
      stdDevTableData.push({
        rmName: this.getRmName(stdDevFundData),
        calcTypeLabel: this.getCalcTypeLabel(stdDevFundData),
        year1Val: stdDevFundData?.elementValueFor1Year || EMDASH,
        year3Val: stdDevFundData?.elementValueFor3Year || EMDASH,
        year5Val: stdDevFundData?.elementValueFor5Year || EMDASH,
        year10Val: stdDevFundData?.elementValueFor10Year || EMDASH,
        valueSinceInception:
          stdDevFundData?.elementValueSinceInception || EMDASH,
      });
      rmSummaries.splice(stdDevFundDataIndexGross, 1);
    }

    // Extract all standard deviation BM data
    const stdDevBMData = rmSummaries.filter(
      (rmSummary) => rmSummary.elementNameStd === 'STANDARD_DEVIATION_BM'
    );

    const maxNumberOfBenchmarks =
      this.maxNumberOfBenchmarksToShow <= 2
        ? this.maxNumberOfBenchmarksToShow
        : stdDevBMData?.length;
    stdDevBMData.forEach((bmData, index) => {
      if (index < maxNumberOfBenchmarks) {
        stdDevTableData.push({
          rmName: bmData?.benchmarkName ? bmData.benchmarkName : EMDASH,
          year1Val: bmData?.elementValueFor1Year || EMDASH,
          year3Val: bmData?.elementValueFor3Year || EMDASH,
          year5Val: bmData?.elementValueFor5Year || EMDASH,
          year10Val: bmData?.elementValueFor10Year || EMDASH,
          valueSinceInception: bmData?.elementValueSinceInception || EMDASH,
        });
      }
    });

    return stdDevTableData;
  }

  /**
   * Extract and format sharpe ratio data as per table display.
   * @param rmSummary Risk Stats Summary
   */
  getSharpeRatioTableData(
    rmSummaries: RiskStatsSummary[]
  ): RMSummaryTableData[] {
    const sharpeRatioTableData: RMSummaryTableData[] = [];

    const sharpeRatioFundDataIndex: number = rmSummaries.findIndex(
      (rmSummary) => rmSummary.elementNameStd === 'SHARPE_RATIO_FUND'
    );

    if (sharpeRatioFundDataIndex > -1) {
      const sharpeRatioFundData: RiskStatsSummary =
        rmSummaries[sharpeRatioFundDataIndex];

      sharpeRatioTableData.push({
        rmName: this.getRmName(sharpeRatioFundData),
        calcTypeLabel: this.getCalcTypeLabel(sharpeRatioFundData),
        year1Val: sharpeRatioFundData?.elementValueFor1Year || EMDASH,
        year3Val: sharpeRatioFundData?.elementValueFor3Year || EMDASH,
        year5Val: sharpeRatioFundData?.elementValueFor5Year || EMDASH,
        year10Val: sharpeRatioFundData?.elementValueFor10Year || EMDASH,
        valueSinceInception:
          sharpeRatioFundData?.elementValueSinceInception || EMDASH,
      });
      rmSummaries.splice(sharpeRatioFundDataIndex, 1);
    }

    const sharpeRatioFundDataIndexNet: number = rmSummaries.findIndex(
      (rmSummary) => rmSummary.elementNameStd === 'SHARPE_RATIO_NET_FUND'
    );

    if (sharpeRatioFundDataIndexNet > -1) {
      const sharpeRatioFundData: RiskStatsSummary =
        rmSummaries[sharpeRatioFundDataIndexNet];

      sharpeRatioTableData.push({
        rmName: this.getRmName(sharpeRatioFundData),
        calcTypeLabel: this.getCalcTypeLabel(sharpeRatioFundData),
        year1Val: sharpeRatioFundData?.elementValueFor1Year || EMDASH,
        year3Val: sharpeRatioFundData?.elementValueFor3Year || EMDASH,
        year5Val: sharpeRatioFundData?.elementValueFor5Year || EMDASH,
        year10Val: sharpeRatioFundData?.elementValueFor10Year || EMDASH,
        valueSinceInception:
          sharpeRatioFundData?.elementValueSinceInception || EMDASH,
      });

      rmSummaries.splice(sharpeRatioFundDataIndexNet, 1);
    }

    const sharpeRatioFundDataIndexGross: number = rmSummaries.findIndex(
      (rmSummary) => rmSummary.elementNameStd === 'SHARPE_RATIO_GROSS_FUND'
    );

    if (sharpeRatioFundDataIndexGross > -1) {
      const sharpeRatioFundData: RiskStatsSummary =
        rmSummaries[sharpeRatioFundDataIndexGross];

      sharpeRatioTableData.push({
        rmName: this.getRmName(sharpeRatioFundData),
        calcTypeLabel: this.getCalcTypeLabel(sharpeRatioFundData),
        year1Val: sharpeRatioFundData?.elementValueFor1Year || EMDASH,
        year3Val: sharpeRatioFundData?.elementValueFor3Year || EMDASH,
        year5Val: sharpeRatioFundData?.elementValueFor5Year || EMDASH,
        year10Val: sharpeRatioFundData?.elementValueFor10Year || EMDASH,
        valueSinceInception:
          sharpeRatioFundData?.elementValueSinceInception || EMDASH,
      });

      rmSummaries.splice(sharpeRatioFundDataIndexGross, 1);
    }

    // Extract all Sharp Ratio BM data
    const sharpeRatioBMData = rmSummaries.filter(
      (rmSummary) => rmSummary.elementNameStd === 'SHARPE_RATIO_BM'
    );

    const maxNumberOfBenchmarks =
      this.maxNumberOfBenchmarksToShow <= 2
        ? this.maxNumberOfBenchmarksToShow
        : sharpeRatioBMData?.length;

    sharpeRatioBMData.forEach((bmData, index) => {
      if (index < maxNumberOfBenchmarks) {
        sharpeRatioTableData.push({
          rmName: bmData?.benchmarkName || EMDASH,
          year1Val: bmData?.elementValueFor1Year || EMDASH,
          year3Val: bmData?.elementValueFor3Year || EMDASH,
          year5Val: bmData?.elementValueFor5Year || EMDASH,
          year10Val: bmData?.elementValueFor10Year || EMDASH,
          valueSinceInception: bmData?.elementValueSinceInception || EMDASH,
        });
      }
    });

    return sharpeRatioTableData;
  }

  /**
   * returns element name based on shareclass and product type
   * @param elemData element with data
   */
  getRmName(elemData: RiskStatsSummary) {
    if (this.isSMA) {
      return elemData.elementName;
    }

    return this.isSingleShareClass ? this.fundName : this.state.shareClassName; // BUG-FIX: NGC-5940
  }

  /**
   * returns label or empty string (SMA) for calcType
   * @param elemData element data
   * @returns string
   */
  getCalcTypeLabel(elemData: RiskStatsSummary) {
    if (this.isSMA) {
      return '';
    }
    return `products.${this.getProductTypeLabel(
      this.productType
    )}-performance-calctype-${kebabCase(elemData.calculationName)}`;
  }

  /**
   * Extract and format statistics data as per table display.
   * @param rmSummary Risk Stats Summary
   */
  getStatisticsTableData(
    rmSummaries: RiskStatsSummary[]
  ): RMSummaryTableData[] {
    const statisticsData: RMSummaryTableData[] = [];

    // Sort the risk measures based upon display order.
    rmSummaries.sort(
      (current, next) =>
        Number(current.displayOrder) - Number(next.displayOrder)
    );

    rmSummaries.forEach((rmSummary) => {
      const statsData: RMSummaryTableData = {
        rmName: rmSummary.elementName ? rmSummary.elementName : EMDASH,
        footnote: `performance-${rmSummary.elementNameStd}-risk-statistic`,
        tooltip: `products.risk-measures-statistics-${rmSummary.elementNameStd}`,
        year1Val: rmSummary.elementValueFor1Year || EMDASH,
        year3Val: rmSummary.elementValueFor3Year || EMDASH,
        year5Val: rmSummary.elementValueFor5Year || EMDASH,
        year10Val: rmSummary.elementValueFor10Year || EMDASH,
        valueSinceInception: rmSummary?.elementValueSinceInception || EMDASH,
      };
      if (this.siteConfigService.isBrazil()) {
        statsData.configuredElementValue = rmSummary[
          STATISTICS_ANALYSIS_ELEMENT_NAME_BR
        ]
          ? rmSummary[STATISTICS_ANALYSIS_ELEMENT_NAME_BR]
          : EMDASH;
        // This ensures that for Brazil only elements with values are pushed to table.
        if (rmSummary[STATISTICS_ANALYSIS_ELEMENT_NAME_BR]) {
          statisticsData.push(statsData);
        }
      } else {
        statisticsData.push(statsData);
      }
    });

    return statisticsData;
  }

  getProductTypeLabel(productType: string): string {
    for (const type in ConfigurationId) {
      if (ConfigurationId[type] === productType) {
        return kebabCase(type);
      }
    }
    return '';
  }
}
