<div
  #signInBarComponent
  edsSpacing="spacingBottom"
  class="us-servicing-sign-in-bar"
  [class.grid]="!isFullPageWidth"
>
  <div edsGrid>
    <div edsRow class="us-servicing-sign-in-bar__row-margin">
      <!-- Heading -->
      <div edsCol>
        <div
          class="us-servicing-sign-in-bar__inner"
          [class.us-servicing-sign-in-bar__inner--small]="isSignInBarSmal"
        >
          <div
            class="us-servicing-sign-in-bar__content"
            [class.us-servicing-sign-in-bar__content--small]="isSignInBarSmal"
            [class.us-servicing-sign-in-bar__content--normal]="!isSignInBarSmal"
          >
            <eds-icon
              type="profile"
              [size]="isSignInBarSmal ? 'small' : 'normal'"
              class="us-servicing-sign-in-bar__icon"
            ></eds-icon>
            <p
              class="us-servicing-sign-in-bar__text"
              [innerHtml]="
                signInBarTextLabel
                  ? (signInBarTextLabel | safeHtml)
                  : ('signin.signInBarTextLabel' | translate | safeHtml)
              "
            ></p>
          </div>
          <button
            class="us-servicing-sign-in-bar__button"
            edsButton="secondary-light"
            size="compact"
            [noMargin]="true"
            (click)="launchSignIn()"
          >
            {{ "signin.signIn" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
