import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import {
  FlexboxModule,
  GridModule,
  MediaCardModule,
  PaddingModule,
  SpacingModule,
  TabsModule as EdsTabsModule,
  ThumbnailModule,
  TitleModule,
  SectionTitleModule,
} from '@frk/eds-components';
import {
  ComponentMapping,
  DynamicModule,
} from '@services/module-loader.config';
import { OneColBlockModule } from '@marketing/content-blocks/one-col-block/one-col-block.module';
import { TwoColBlockModule } from '@marketing/content-blocks/two-col-block/two-col-block.module';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TranslateModule } from '@shared/translate/translate.module';
import { TabsThumbnailsSectionComponent } from './tabs-thumbnails-section/tabs-thumbnails-section.component';
import { TabsMediaCardsSectionComponent } from './tabs-media-cards-section/tabs-media-cards-section.component';
import { TabsMediaCardsIndexComponent } from './tabs-media-cards-index/tabs-media-cards-index.component';
import { MediaCardGroupComponent } from '@marketing/tabs/media-card-group/media-card-group.component';

@NgModule({
  declarations: [
    TabsComponent,
    TabsThumbnailsSectionComponent,
    TabsMediaCardsSectionComponent,
    TabsMediaCardsIndexComponent,
    MediaCardGroupComponent,
  ],
  imports: [
    CommonModule,
    EdsTabsModule,
    OneColBlockModule,
    TwoColBlockModule,
    MediaCardModule,
    SpacingModule,
    GridModule,
    BrSdkModule,
    FlexboxModule,
    PaddingModule,
    ThumbnailModule,
    TitleModule,
    PipesModule,
    TranslateModule,
    SectionTitleModule,
  ],
  exports: [
    TabsComponent,
    TabsThumbnailsSectionComponent,
    TabsMediaCardsSectionComponent,
    TabsMediaCardsIndexComponent,
  ],
  entryComponents: [TabsComponent],
})
export class TabsModule implements DynamicModule {
  getComponentMapping(): ComponentMapping {
    return {
      'Horizontal Vertical Tabs': TabsComponent,
    };
  }
}
