import { ISOYearMonth } from '@frk/eds-components';
import { CalcTypeStd, CurrencyCode } from '@types';

export interface FundPerformanceAnnualizedState {
  data?: FundPerformanceAnnualizedStateData;
  config?: FundPerformanceAnnualizedStateConfig;
  perfStatusCaveat?: string;
  heading?: string;
  isError?: boolean;
}
export interface FundPerformanceAnnualizedStateData {
  fundName: string;
  monthEnd?: FundPerformanceAnnualizedPeriodEndData;
  quarterEnd?: FundPerformanceAnnualizedPeriodEndData;
  historicalData?: FundPerformanceAnnualizedPeriodEndData;
}
export interface AllShareClassesAnnualizedData {
  tableData: FundPerformanceAnnualizedTableData[];
  tableColumns: string[];
  showInception: boolean;
}

export interface FundPerformanceAnnualizedPeriodEndData {
  graphData: any;
  graphLabels: string[];
  tableData: FundPerformanceAnnualizedTableData[];
  allShareClassesTableData?: AllShareClassesAnnualizedData;
  tableLabel: string;
  performanceDates: FundPerformanceDates;
  tableColumns: string[];
  showInception: boolean;
}

export interface FundPerformanceAnnualizedTableData {
  name: string;
  asOfDate: string;
  currency?: string;
  oneYear: string;
  twoYear: string;
  threeYear: string;
  fourYear: string;
  fiveYear: string;
  sevenYear: string;
  tenYear: string;
  fifteenYear: string;
  twentyYear: string;
  twentyFiveYear: string;
  thirtyFiveYear: string;
  sinceInception: string;
  inceptionDate?: string;
  feeStructure?: string;
  link?: string;
  hideOnChart?: boolean;
  calcTypeLabel?: string;
  pointColor?: string;
  placement?: string;
  isPeerRankingsRow?: boolean;
  calcTypeStd?: CalcTypeStd;
}

export interface FundPerformanceAnnualizedStateConfig {
  showCurrency?: boolean;
  showMifid?: boolean;
  showNavLabel?: boolean;
  periodEnds?: string[];
  periodEnd?: string;
  hideChart?: boolean;
  showHistorical?: boolean;
}

export enum ChartCalculationType {
  TENK_GROWTH = '_10K',
  HUNDRED_REBASE_POINTS = '_100',
}

export interface FundPerformanceCumulativeState {
  data?: FundPerformanceCumulativeStateData;
  config?: FundPerformanceCumulativeStateConfig;
  perfStatusCaveat?: string;
  heading?: string;
  isError?: boolean;
}

export interface FundPerformanceCumulativeStateData {
  fundName: string;
  monthEnd?: FundPerformanceCumulativePeriodEndData;
  quarterEnd?: FundPerformanceCumulativePeriodEndData;
  historicalData?: FundPerformanceCumulativeHistoricalData;
}

export interface FundPerformanceCumulativePeriodEndData {
  graphData?: Record<ChartCalculationType, FundPerformanceCumulativeGraphData>;
  graphNames?: string[];
  tableData: FundPerformanceCumulativeTableData[];
  allShareClassesTableData?: FundPerformanceCumulativeTableData[];
  tableLabel: string;
  performanceDates: FundPerformanceDates;
  performanceDateRange?: PerformanceDateRange;
}

export interface FundPerformanceCumulativeHistoricalData {
  tableData: FundPerformanceCumulativeTableData[];
  tableLabel: string;
  performanceDates: FundPerformanceDates;
}

export interface FundPerformanceCumulativeTableData {
  name: string;
  asOfDate: string;
  currency?: string;
  yearToDate?: string;
  oneMonth?: string;
  threeMonth?: string;
  sixMonth?: string;
  oneYear: string;
  twoYear?: string;
  threeYear: string;
  fiveYear: string;
  tenYear: string;
  fifteenYear: string;
  sinceInception: string;
  inceptionDate?: string;
  feeStructure?: string;
  link?: string;
  hideOnChart?: boolean;
  calcTypeLabel?: string;
  pointColor?: string;
  calcName?: string;
  placement?: string;
  isPeerRankingsRow?: boolean;
}

export interface FundPerformanceCumulativeGraphData {
  oneYear: any;
  threeYear: any;
  fiveYear: any;
  tenYear: any;
  fifteenYear: any;
  sinceInception: any;
  custom?: any;
}

export interface FundPerformanceCumulativeStateConfig {
  shareClassCurrency?: string;
  showCurrency?: boolean;
  showMifid?: boolean;
  showNavLabel?: boolean;
  periodEnds?: string[];
  periodEnd?: string;
  historicalPeriodEnd?: string;
  chartCalculationOptions?: ChartCalculationType[];
  hideChart?: boolean;
  show1M3M6M?: boolean;
  showYtd?: boolean;
  customHistory?: boolean;
  chartCalculation?: string;
  showHistorical?: boolean;
  show6m15yr?: boolean;
}

export interface FundPerformanceDates {
  asOfDate: string;
  asOfDateStd?: string;
  inceptionDate: string;
  inceptionDateStd?: string;
  asOfDateMonthStd?: ISOYearMonth;
  inceptionDateMonthStd?: ISOYearMonth;
}

export interface FundPerformanceDiscreteReturnsState {
  data?: FundPerformanceDiscreteReturnsStateData;
  config?: FundPerformanceDiscreteReturnsStateConfig;
  perfStatusCaveat?: string;
  heading?: string;
  isError?: boolean;
}
export interface FundPerformanceDiscreteReturnsStateData {
  fundName: string;
  monthEnd?: FundPerformanceDiscreteReturnsPeriodEndData;
  quarterEnd?: FundPerformanceDiscreteReturnsPeriodEndData;
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface AllShareClassesDiscreteReturnsData {
  years: DateRange[];
  tableCols: string[];
  tableData: FundPerformanceDiscreteReturnsTableData[];
}
export interface FundPerformanceDiscreteReturnsPeriodEndData {
  graphData: any;
  tableLabel: string;
  tableData: FundPerformanceDiscreteReturnsTableData[];
  allShareClassesTableData?: AllShareClassesDiscreteReturnsData;
  years: DateRange[];
  tableCols: string[];
  performanceDates: FundPerformanceDates;
}

export interface FundPerformanceDiscreteReturnsTableData {
  name: string;
  asOfDate: string;
  currency?: string;
  oneYear: string;
  twoYear: string;
  threeYear: string;
  fourYear: string;
  fiveYear: string;
  sixYear: string;
  sevenYear: string;
  eightYear: string;
  nineYear: string;
  tenYear: string;
  inceptionDate?: string;
  feeStructure?: string;
  link?: string;
  hideOnChart?: boolean;
  calcTypeLabel?: string;
  pointColor?: string;
  placement?: string;
}

export interface FundPerformanceDiscreteReturnsStateConfig {
  showCurrency?: boolean;
  showMifid?: boolean;
  showNavLabel?: boolean;
  periodEnds?: string[];
  periodEnd?: string;
  hideChart?: boolean;
}

export interface FundPerformanceCalendarYearState {
  data?: FundPerformanceCalendarYearStateData;
  config?: FundPerformanceCalendarYearStateConfig;
  perfStatusCaveat?: string;
  heading?: string;
  isError?: boolean;
}
export interface FundPerformanceCalendarYearStateData {
  fundName: string;
  monthEnd?: FundPerformanceCalendarYearPeriodEndData;
  quarterEnd?: FundPerformanceCalendarYearPeriodEndData;
}

export interface AllShareClassesCalendarData {
  years: string[];
  tableCols: string[];
  showYearToDate: boolean;
  tableData: FundPerformanceCalendarYearTableData[];
}

export interface FundPerformanceCalendarYearPeriodEndData {
  graphData: any;
  years: string[];
  tableCols: string[];
  showYearToDate: boolean;
  tableLabel: string;
  tableData: FundPerformanceCalendarYearTableData[];
  allShareClassesTableData?: AllShareClassesCalendarData;
  performanceDates: FundPerformanceDates;
}

export interface FundPerformanceCalendarYearTableData {
  name: string;
  asOfDate: string;
  currency?: string;
  oneYear: string;
  twoYear: string;
  threeYear: string;
  fourYear: string;
  fiveYear: string;
  sixYear: string;
  sevenYear: string;
  eightYear: string;
  nineYear: string;
  tenYear: string;
  yearToDate: string;
  inceptionDate?: string;
  feeStructure?: string;
  link?: string;
  hideOnChart?: boolean;
  calcTypeLabel?: string;
  pointColor?: string;
  placement?: string;
  isPeerRankingsRow?: boolean;
}

export interface FundPerformanceCalendarYearStateConfig {
  showCurrency?: boolean;
  showMifid?: boolean;
  showNavLabel?: boolean;
  periodEnds?: string[];
  periodEnd?: string;
  hideChart?: boolean;
}
export interface RiskMeasuresState {
  shareClassName: string;
  benchmarkName: string;
  asOfDate: string;
  config: {
    isFundOlderThan10Yrs: boolean;
    show1YrPerformance: boolean;
    showSinceInception: boolean;
  };
  stdDev: RMSummaryTableData[];
  sharpeRatio: RMSummaryTableData[];
  statistics: RMSummaryTableData[];
  perfStatusCaveat?: string;
  isError?: boolean;
  hideRiskStatisticsBenchmark?: boolean;
}

export interface RiskStatisticsAnalysisState {
  shareClassName?: string;
  benchmarkName?: string;
  efficiencyAnalysis?: RiskEfficiencyTableData[];
  asOfDate: string;
  isError?: boolean;
}

export interface RMSummaryTableData {
  rmName: string;
  calcTypeLabel?: string;
  year1Val: string;
  year3Val: string;
  year5Val: string;
  year10Val: string;
  valueSinceInception?: string;
  configuredElementValue?: string;
  footnote?: string;
  tooltip?: string;
}

export interface RiskEfficiencyTableData {
  statistic?: string;
  statisticLabel?: string;
  aboveTheBenchmark?: string;
  belowTheBenchmark?: string;
  total?: string;
  hitRate?: string;
  averageExcess?: string;
  footnote?: string;
  tooltip?: string;
}

export interface PerformanceDateRange {
  minDate: string;
  maxDate: string;
}

export interface PerformanceDateRangeSelection {
  fromDate?: string;
  toDate?: string;
}

export interface MonthlyData {
  year?: number;
  returnsPerMonth?: Array<number>;
  ytd?: number;
}

export interface YearOption {
  label: string;
  value: string;
}

export enum MifidAge {
  UNDER_ONE = '-1yr',
  ONE_TO_FIVE = '-2yr',
  OVER_FIVE = '-5yr',
}

export interface FundPerformanceMonthlyTotalReturnsState {
  data?: FundPerformanceMonthlyTotalReturnsStateData;
  selectedTab?: string;
  perfStatusCaveat?: string;
  heading?: string;
  isError?: boolean;
  highChartOptions?: Highcharts.Options;
}

export interface FundPerformanceMonthlyTotalReturnsStateData {
  annualData?: FundPerformanceMonthlyTotalReturnsAnnualData[];
  shareClass?: string;
  currencyCode?: CurrencyCode;
  currentAsOfDate?: string;
  currentMonthReturns?: string;
}

export interface FundPerformanceMonthlyTotalReturnsCardData {
  label?: string;
  val?: string;
  title?: string;
}

export interface FundPerformanceMonthlyTotalReturnsAnnualData {
  year?: number;
  annualReturns?: string;
  monthlyReturns?: FundPerformanceMonthlyTotalReturnsTableData[];
  cards?: FundPerformanceMonthlyTotalReturnsCardData[];
  ytd?: string;
}

export interface FundPerformanceMonthlyTotalReturnsTableData {
  year?: number;
  returns?: string;
  month?: string;
  asOfDateStd?: string;
  cumulativeMonthlyReturnCalculated?: string;
  cumulativeMonthlyReturnCalculatedStd?: string;
}
