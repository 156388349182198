export enum IndexIds {
  FranklinQualityDividendIndex = 'COIPFQDIBDP',
  FranklinFactorsUSIndex = 'COIPFFUSIBDP',
  FranklinBofAWorldIndex = 'COIPBWEIBDP',
  FranklinUSCoreIndex = 'COIPFUSCIBDP',
  FranklinUSCoreBalancedIndex = 'COIPFUSCBIBD',
}
export enum FranklinIndexDownloadType {
  Allocation = 'DailyWeights',
  TopConstituents = 'MonthlyEquityWeights',
  DailyETFWeights = 'DailyETFWeights',
  FactSheet = 'FactSheet',
  DownloadAll = 'DailyLevels',
}
