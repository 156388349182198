import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { PageContainerComponent } from '@pages/page-container/page-container.component';
import { Logger } from '@utils/logger';
import { Observable } from 'rxjs';
import { AsyncContentManagerService } from './async-content-manager.service';
import { RoleSelectorService } from './role-selector.service';

const logger = Logger.getLogger('RoutingGuardService');

@Injectable({
  providedIn: 'root',
})
export class RoutingGuardService
  implements CanActivate, CanDeactivate<PageContainerComponent> {
  // NB: important default is null, as that is what route retruns when no fragment in url
  private currentFragment: string = null;
  private currentPath: string;

  constructor(
    private roleSelectorService: RoleSelectorService,
    private acm: AsyncContentManagerService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    logger.debug('canActivate called', route, state);
    // this guard will determine if navigation event is just a #fragment change
    // as opposed to a full new page
    const newFragment: string = route.fragment;
    const newPath: string = route.url.join('/');

    if (this.currentPath === newPath) {
      // main path unchanged (i.e. on same page)
      if (this.currentFragment !== newFragment) {
        // but fragment has changed
        logger.debug(
          'fragment change from ',
          this.currentFragment,
          'to',
          newFragment
        );
        this.currentFragment = newFragment;
        // return Observable for when route can proceed
        // i.e. route change and scrolling can be delayed until page/section/images have rendered
        return this.acm.getAnchorReady$(this.currentFragment);
      }
    } else {
      // path has changed (i.e. new page)
      this.currentPath = newPath;
      this.currentFragment = newFragment;
    }

    // don't delay route - it can proceed immediately
    // if #fragment scrolling needed, will be triggered from PageContainer
    return true;
  }

  /**
   * This function is used to trigger Personalization Modal when navigating away from pages
   * inspired by this example: https://tobiasljungstrom.net/blog/2019-07-24-angular-route-guard-dialog/
   */
  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    logger.debug(
      'canDeactivate called',
      component,
      currentRoute,
      currentState,
      nextState
    );
    return this.roleSelectorService.getCanDeactivate(currentState, nextState);
  }
}
