import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Logger } from '@utils/logger';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import {
  FundDataServiceParams,
  GraphQLFundDataService,
} from './graphql-fund-data.service';
import { map, takeUntil } from 'rxjs/operators';
import { Product, SummaryDetail } from '@models';
import { MapperParams } from '@products/utils/mappers/type.mapper';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { SummaryDetailsMapper } from '@products/utils/mappers/summary-details.type.mapper';
import { SiteConfigService } from '@services/site-config.service';
import { ProductDTO, SummaryDetailDTO } from '@types';
import { TranslateService } from '@shared/translate/translate.service';
import { ProductMapper } from '@products/utils/mappers/product.type.mapper';
import { ProfileService } from '@services';

const logger = Logger.getLogger('FundSummaryService');

@Injectable({
  providedIn: 'root',
})
export class FundSummaryService extends GraphQLFundDataService {
  private unsubscribe$: Subject<void> = new Subject<void>();
  isLoggedIn: boolean;

  constructor(
    apollo: Apollo,
    siteConfigService: SiteConfigService,
    private translateService: TranslateService,
    private mapperFactory: MapperFactory,
    private profileService: ProfileService
  ) {
    super(apollo, siteConfigService);

    this.profileService
      .isLoggedIn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      });
  }

  public register(
    query: DocumentNode,
    fundDataServiceParams: FundDataServiceParams
  ): Observable<[Product, SummaryDetail[]]> {
    return super.register(query, this.getVariables(fundDataServiceParams)).pipe(
      map((rawSummaryDetails) => {
        logger.debug('Summary details response: ', rawSummaryDetails);

        const overviewDto: ProductDTO = rawSummaryDetails?.data?.Overview
          ? rawSummaryDetails?.data?.Overview
          : null;
        const overviewMapperParams: MapperParams = {
          config: this.siteConfigService,
          translateService: this.translateService,
          includeSoftClosed: true,
        };
        const mappedOverview: Product = overviewDto
          ? this.mapperFactory
              .createMapper(ProductMapper, overviewMapperParams)
              .toDomain(overviewDto)
          : null;

        const summaryDetailsDto: SummaryDetailDTO[] = rawSummaryDetails?.data
          ?.Summary
          ? rawSummaryDetails.data.Summary
          : [];
        const summaryDetailsMapperParams: MapperParams = {
          config: this.siteConfigService,
          fundId: fundDataServiceParams.fundId,
          translateService: this.translateService,
          taxonomy: mappedOverview?.productTaxonomy,
        };
        const mappedSummaryDetails: SummaryDetail[] = this.mapperFactory
          .createMapper(SummaryDetailsMapper, summaryDetailsMapperParams)
          .toDomain(summaryDetailsDto, this.isLoggedIn);

        return [mappedOverview, mappedSummaryDetails];
      })
    );
  }

  /**
   * Return required variables value to graphql query.
   * @param fundDataServiceParams params required for graphql query
   */
  private getVariables(
    fundDataServiceParams: FundDataServiceParams
  ): Record<string, any> {
    return {
      countrycode: this.getCountry(),
      languagecode: this.getLanguage(),
      fundid: fundDataServiceParams.fundId,
      shareclasscode: fundDataServiceParams.shareClassCode,
    };
  }
}
