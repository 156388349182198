<eds-expand-collapse
  [collapsedButton]="'common.advanced-filter-options' | translate"
  [expandedButton]="'common.advanced-filter-options' | translate"
  [top]="true"
  [arrowRight]="true"
>
  <div edsCol edsSubGrid="layout-2" edsSubGridGap="lg">
    <ng-container *ngFor="let filterGroup of filterGroups">
      <ng-container *ngIf="filterGroup.name === filterType">
        <ng-container *ngFor="let filterName of filterGroup.filters">
          <div class="column-1">
            <eds-dropdown
              [name]="'search_' + filterName"
              [items]="filterItems[filterName]"
              [label]="filterLabels[filterName]"
              [titleText]="'common.select-to-filter' | translate"
              [selectedText]="'selected'"
              [required]="false"
              [multi]="true"
              [theme]="'basic'"
              [eventType]="'search_dropdown_select'"
              [showCount]="true"
              (dropdownChange)="onChange(filterName, $event)"
              (onReset)="onReset($event)"
            ></eds-dropdown>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</eds-expand-collapse>
