import { AlertService } from '@accounts/services/alert.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { NavigationService } from '@frk/eds-components';
import { SegmentService } from '@services/segment.service';
import { TranslateService } from '@shared/translate/translate.service';
import { Segment } from '@types';
import {
  EXTERNAL,
  UPGRADE_FORM_FP,
  UPGRADE_FORM_SH,
} from '@utils/app.constants';
import { Logger } from '@utils/logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  AccountNotificationOptions,
  NotificationDetails,
  NotificationTypes,
} from './accounts-alerts.interface';

const logger = Logger.getLogger('AccountsAlertsComponent');

@Component({
  selector: 'ft-accounts-alerts',
  templateUrl: './accounts-alerts.component.html',
})
export class AccountsAlertsComponent implements OnInit, OnDestroy {
  /**
   * The Bloomreach component
   */
  @Input() component!: BrComponent;
  /**
   * The Notification details
   */
  @Input() notificationDetails: NotificationDetails;
  /**
   * The Bloomreach page
   */
  @Input() page!: Page;

  private unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Alerts component parameters
   */
  // TODO: Check boolean options if they need to be configurable.
  hasBorders = true;
  hasRadius = false;
  hasShadow = false;
  hasIcon = true;
  inline = false;
  canClose: boolean;
  title: string;
  subtitle: string;
  linkInput: string;
  body: string;
  notificationType: NotificationTypes;
  primaryBtnText = '';
  secondaryBtnText = '';
  tertiaryBtnText = '';

  notificationButtons: AccountNotificationOptions;
  constructor(
    private translateService: TranslateService,
    public alertService: AlertService,
    // private domSanitizer: DomSanitizer,
    private segmentService: SegmentService,
    private navService: NavigationService
  ) {
    this.alertService
      .getAccountsAlert$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((notificationData) => {
        this.notificationDetails = notificationData;
        logger.debug(
          'getAccountsAlert',
          this.notificationDetails?.notificationLabel
        );
        this.setNotifications();
      });
  }

  ngOnInit(): void {
    this.notificationButtons = {
      UPGRADE_BTN: this.translateService.instant(
        'accountsNotification.upgrade-btn'
      ),
      NOTNOW_BTN: this.translateService.instant(
        'accountsNotification.notnow-btn'
      ),
      NEVER_BTN: this.translateService.instant(
        'accountsNotification.never-btn'
      ),
      OPEN_BTN: this.translateService.instant('accountsNotification.open-btn'),
    };
    this.setNotifications();
  }

  /**
   * Function for set notification details and show or hide the alert component
   */
  setNotifications(): void {
    if (this.notificationDetails && this.notificationDetails.active) {
      logger.debug(
        'this.notificationDetails',
        this.notificationDetails?.notificationLabel
      );
      this.alertService.showAccountsAlert = true;
      this.notificationType = this.notificationDetails.notificationType;
      this.subtitle = this.notificationDetails.subTitle;
      this.linkInput = null;

      this.title = this.translateService.instant(
        `accountsNotification.${this.notificationDetails.title}`
      );
      this.body = this.translateService.instant(
        `accountsNotification.${this.notificationDetails.notificationLabel}`
      );

      this.canClose = true;
      if (this.notificationDetails.notificationLabel === UPGRADE_FORM_FP) {
        this.primaryBtnText = this.notificationButtons?.UPGRADE_BTN;
        this.secondaryBtnText = this.notificationButtons?.NOTNOW_BTN;
        this.tertiaryBtnText = this.notificationButtons?.NEVER_BTN;
      } else if (
        this.notificationDetails.notificationLabel === UPGRADE_FORM_SH
      ) {
        this.secondaryBtnText = this.notificationButtons?.OPEN_BTN;
        this.primaryBtnText = this.notificationButtons?.UPGRADE_BTN;
        this.tertiaryBtnText = this.notificationButtons?.NOTNOW_BTN;
      } else {
        this.secondaryBtnText = '';
        this.primaryBtnText = '';
        this.tertiaryBtnText = '';
      }

      this.subtitle = this.notificationDetails.subTitle;
      this.linkInput = null;
      // if segment selection is external, need to te redirect that link
      if (
        this.notificationDetails?.segment?.id &&
        this.notificationDetails?.segment?.segmentType === EXTERNAL
      ) {
        logger.debug(
          'segment type external or not',
          this.notificationDetails?.segment?.segmentType
        );
        logger.debug('segment id', this.notificationDetails?.segment?.id);
        const segment: Segment = this.segmentService.getSegment(
          this.notificationDetails?.segment?.id
        );
        logger.debug(
          'redirect to segment external link',
          segment?.externalLink
        );
        this.navService.navigateByUrl(segment?.externalLink, '_self');
        return;
      }
    }
  }

  closeAlert(): void {
    this.alertService.showAccountsAlert = false;
    this.alertService.clearAccountsAlert();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
