import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDocViewerModule } from 'ngx-doc-viewer2';
import { DocViewerModalComponent } from './doc-viewer-modal.component';
import {
  ButtonModule,
  SimpleModalModule,
  SpacingModule,
} from '@frk/eds-components';
import {
  ComponentMapping,
  DynamicModule,
} from '@services/module-loader.config';
import { TranslateModule } from '@shared/translate/translate.module';

@NgModule({
  declarations: [DocViewerModalComponent],
  imports: [
    CommonModule,
    NgxDocViewerModule,
    SpacingModule,
    SimpleModalModule,
    ButtonModule,
    TranslateModule,
  ],
  exports: [DocViewerModalComponent],
})
export class DocViewerModalModule implements DynamicModule {
  getComponentMapping(): ComponentMapping {
    return {
      'Doc Viewer Modal': DocViewerModalModule,
    };
  }
}
