export enum ModalId {
  Sticky_Modal = 'stickyModal',
  Download_Modal = 'downloadModal',
  Email_Modal = 'emailModal',
  Download_Progress_Modal = 'downloadProgressModal',
  Success_Modal = 'successModal',
  Recipients_Detail_Modal = 'recipientsDetailModal',
  Success_Email_Modal = 'successEmailModal',
  Cart_Modal = 'cartModal',
  Dashboard_Cart_Modal = 'dashboardCartModal',
  MyLiterature_Cart_Modal = 'myLiteratureCartModal',
  Max_Cart_Limit = 'maxCartLimit',
  Selection_All_Modal = 'selectionAllModal',
  Max_Selection_Limit = 'maxSelectionLimit',
}
