<div class="exact-match__fund-details">
  <div edsFlexbox justifyContent="space-between" edsSpacing="sm">
    <div>
      <eds-title
        size="h4"
        theme="primary"
        [isLight]="true"
        [title]="fundTitle"
        [href]="fundLink"
      >
      </eds-title>
      <!--
        DCE-2219 - Add Fund Alerts to Site Search Results
        Exact match
      -->
      <ng-container
        *ngIf="fundAlert && fundAlert?.ppssMessage && displayFundAlert"
      >
        <eds-notifications
          [title]="fundAlert.ppssMessage"
          notificationType="alert"
          [inline]="true"
          edsTooltip
          tooltipBehavior="fixed"
          tooltipPositionDefault="bottom"
          [tooltipContent]="fundAlert?.ppssTooltip"
        ></eds-notifications>
      </ng-container>
    </div>
  </div>
  <div>
    <div edsCol edsSubGrid="{{ layoutType }}" edsSubGridGap="md">
      <div class="column-1">
        <div edsSpacing="sm">
          <eds-search-panel [leftTitle]="priceAndYtdLabel" titleLink="">
            <div
              edsPadding
              edsPaddingAll="sm"
              edsPaddingBottom="xs"
              *ngIf="navData$ | async as navData"
            >
              <small
                edsPadding
                edsPaddingBottom="xs"
                class="exact-match__small"
              >
                {{ navData.asOfDate ? ("products.as-of" | translate) : "" }}
                {{ navData.asOfDate }}
                <ng-container *ngIf="navData.asOfDate && navData.updatedDaily">
                  ({{ "products.updated-daily" | translate }})</ng-container
                >
              </small>
              <div edsCol edsSubGrid="layout-2" edsSubGridGap="sm">
                <div
                  class="col-1"
                  *ngIf="priceElements && priceElements.includes('NAV_VALUE')"
                >
                  <eds-summary-item
                    [title]="'products.NAV_VALUE' | translate"
                    [val]="navData.nav || EMDASH"
                  >
                  </eds-summary-item>
                </div>
                <div
                  class="col-1"
                  *ngIf="
                    priceElements &&
                    priceElements.includes('YTD_CUM_NET') &&
                    navData.ytdCumNet
                  "
                >
                  <eds-summary-item
                    [title]="
                      'products.Ytd-Total-Returns-at-NET' | translate: fundId
                    "
                    [val]="navData.ytdCumNet"
                  >
                  </eds-summary-item>
                </div>
                <div
                  class="col-2"
                  *ngIf="priceElements && priceElements.includes('NAV_CHG')"
                >
                  <eds-summary-item
                    [title]="'products.NAV_CHG' | translate: fundId"
                    [val]="navData.navChg || EMDASH"
                    [valueDirection]="navData.navChgDirection || EMDASH"
                  >
                  </eds-summary-item>
                </div>
                <div
                  class="col-2"
                  *ngIf="
                    priceElements &&
                    priceElements.includes('YTD_CUM_GROSS') &&
                    navData.ytdCumGross
                  "
                >
                  <eds-summary-item
                    [title]="
                      'products.Ytd-Total-Returns-at-GROSS' | translate: fundId
                    "
                    [val]="navData.ytdCumGross"
                  >
                  </eds-summary-item>
                </div>
                <div
                  class="col-2"
                  *ngIf="
                    priceElements &&
                    priceElements.includes('YTD_CUM_PUREGROSS') &&
                    navData.ytdCumPureGross
                  "
                >
                  <eds-summary-item
                    [title]="
                      'products.Ytd-Total-Returns-at-PURE-GROSS'
                        | translate: fundId
                    "
                    [val]="navData.ytdCumPureGross"
                  >
                  </eds-summary-item>
                </div>
                <div
                  class="col-3"
                  *ngIf="priceElements && priceElements.includes('YTD_TOT_RET')"
                >
                  <eds-summary-item
                    [title]="
                      'products.Ytd-Total-Returns-at-NAV' | translate: fundId
                    "
                    [val]="navData.ytdTotal || EMDASH"
                  >
                  </eds-summary-item>
                </div>
              </div>
            </div>
          </eds-search-panel>
        </div>
        <div edsSpacing="sm">
          <eds-search-panel
            [leftTitle]="'products.fund-description' | translate: fundId"
            titleLink=""
            *ngIf="fundDescription$ | async as fundDescription"
          >
            <div edsPadding edsPaddingAll="sm">
              <p edsSpacing="none" [innerHtml]="fundDescription"></p>
            </div>
          </eds-search-panel>
        </div>
        <eds-search-panel
          [leftTitle]="
            'products.fund-info-section-fundInformation' | translate: fundId
          "
          titleLink=""
        >
          <div
            edsPadding
            edsPaddingLeft="sm"
            edsPaddingRight="sm"
            edsPaddingTop="sm"
            edsPaddingBottom="none"
          >
            <small class="exact-match__small">
              <ng-container *ngIf="netAssets$ | async as netAssets">
                {{ "products.as-of" | translate: fundId }}
                {{ netAssets.asOf }}
              </ng-container>
            </small>
          </div>
          <div
            edsPadding
            edsPaddingTop="xs"
            edsPaddingBottom="sm"
            edsPaddingLeft="sm"
            edsPaddingRight="sm"
          >
            <eds-info-item [info]="netAssets$ | async"> </eds-info-item>
            <eds-info-item *ngFor="let item of infoItem$ | async" [info]="item">
            </eds-info-item>
          </div>
        </eds-search-panel>
      </div>
      <div class="column-2" *ngIf="hasColumnNo2$ | async">
        <eds-search-panel
          [leftTitle]="'products.performance' | translate: fundId"
          titleLink=""
        >
          <div
            edsPadding
            edsPaddingAll="sm"
            *ngIf="highcharts && chartDataReady"
          >
            <small class="exact-match__small">
              <ng-container *ngIf="(chartAsOfDate$ | async).length > 0">
                {{ "products.as-of" | translate }} {{ chartAsOfDate$ | async }}
              </ng-container>
            </small>
            <div edsPadding edsPaddingTop="sm">
              <highcharts-chart
                edsSpacing="sm"
                [Highcharts]="highcharts"
                [options]="chartOptions"
                [(update)]="updateChart"
                [oneToOne]="true"
                style="width: 100%; height: 100%; display: block"
              ></highcharts-chart>
              <table class="table table--tertiary" edsSpacing="sm">
                <ng-container *ngFor="let serie of this.chartOptions.series">
                  <tr class="table__tr" *ngIf="serie && !serie.hideOnChart">
                    <td class="table__td">
                      <span class="small">
                        <span class="table__legend" edsSpacing="xs">
                          <span
                            class="table__legend-box"
                            [style.background]="serie.pointColor"
                          ></span>
                          {{ serie.name }}
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-container>
              </table>
              <a href="{{ fundLink + '#important-legal-info' }}">
                {{ chartDisclosure }}
              </a>
            </div>
          </div>
        </eds-search-panel>
      </div>
      <div class="column-3" *ngIf="hasColumnNo3$ | async">
        <div edsSpacing="sm" *ngIf="(investmentTeam$ | async).length > 0">
          <eds-search-panel
            [leftTitle]="'products.fund-management-header' | translate: fundId"
            titleLink=""
          >
            <div edsPadding edsPaddingAll="sm">
              <ng-container
                *ngFor="
                  let fundManager of investmentTeam$ | async;
                  last as isLast
                "
              >
                <eds-avatar
                  [title]="fundManager.managerNameWithSuffix"
                  [text1]="fundManager.managerCity"
                  [text2]="fundManager.managedSinceString"
                  [yrsIndustry]="
                    hasYearsOfExperience
                      ? yearsOfExperienceLabel +
                        ': ' +
                        fundManager.yearsInIndustry
                      : ''
                  "
                  [imgSrc]="fundManager.profile?.profileImage?.widenAsset?.url"
                  fallbackImgSrc="/assets/images/ben_head.png"
                  [edsSpacing]="isLast ? 'none' : 'lg'"
                >
                </eds-avatar>
              </ng-container>
            </div>
          </eds-search-panel>
        </div>
        <ng-container *ngIf="fundDocuments$ | async as fundDocuments">
          <eds-search-panel
            edsSpacing="sm"
            [leftTitle]="'products.fund-tab-documents' | translate: fundId"
            titleLink=""
            *ngIf="fundDocuments.length > 0"
          >
            <ng-container *ngFor="let doc of fundDocuments">
              <eds-thumbnail
                class="exact-match__search-thumbnail"
                [hasBorders]="false"
                [hasShadows]="false"
                [headingLevel]="2"
                [layoutCompact]="true"
                [docTypeSrc]="doc.docTypeSrc"
                [title]="'common.document.' + doc.title | translate"
                [titleLink]="doc.titleLink"
                [hasSearchIcon]="true"
                [noTitleColor]="true"
                [isNewWindow]="true"
              >
              </eds-thumbnail>
            </ng-container>
          </eds-search-panel>
          <eds-link-item
            [title]="'common.view-all-documents' | translate"
            [href]="fundLink + '#documents'"
          ></eds-link-item>
        </ng-container>
      </div>
    </div>
  </div>
</div>
