import { Injectable, Inject } from '@angular/core';
import {
  SEARCH_RESULTS,
  FtSearchItem,
  LiteratureData,
  ItemHeader,
  LiteratureItem,
} from '@search/interfaces/search.interface';
import { FullResultsCommonService } from '../../full-results-common.service';
import { ConfigService } from '@search/services/config.service';
import { TranslateService } from '@shared/translate/translate.service';
import { LiteratureGlobalConfigService } from '@literature/services/literature-global-config.service';
import { EMDASH } from '@products/utils/constants/product.constants';
import { FileInfo, extractFileInfoFromLink } from '@utils/link-utils';

export interface DocumentsConfig extends Array<string> {}
@Injectable({
  providedIn: 'root',
})
export class FullResultsLiteratureService {
  literatureData: Array<LiteratureData>;

  constructor(
    private configService: ConfigService,
    private translateService: TranslateService,
    private literatureConfigService: LiteratureGlobalConfigService,
    @Inject(SEARCH_RESULTS) private commonDataService: FullResultsCommonService
  ) {}

  /**
   * // TODO - this must be moved to service extracting data
   */
  getLiteratureHeader(displayedPage: number): ItemHeader {
    // TODO this should be dynamic, recognize which page is displayed.
    const counter = (displayedPage + 1) * this.commonDataService.resultsPerPage;
    const literatureKey = 'literature';
    const totalRecords = this.commonDataService.totalItems[literatureKey];
    const displayedRecords = Math.min(counter, totalRecords);
    const rightTitleText =
      this.translateService.instant('common.showing') +
      ' ' +
      displayedRecords +
      ' ' +
      this.translateService.instant('common.of') +
      ' ' +
      totalRecords;

    const itemHeader = {
      leftTitle: this.translateService.instant('common.forms-and-literature'),
      rightTitle: displayedRecords > 0 ? rightTitleText : '',
      viewMoreBtnText:
        totalRecords > displayedRecords
          ? this.translateService.instant('literature.view-more-lit')
          : '',
      nameLabel: this.translateService.instant(
        'literature.lit-listing-column-name'
      ),
      clientLabel: this.translateService.instant(
        'literature.column-client-use'
      ),
      literatureLabel: this.translateService.instant(
        'literature.column-lit-code'
      ),
      publicationLabel: this.translateService.instant(
        'literature.lit-listing-sort-publication-date'
      ),
    };
    return itemHeader;
  }

  /**
   *
   */
  getLiteratureItems(): LiteratureItem[] {
    const responseItems = this.commonDataService.results.filter((item) => {
      return item.name === 'literature';
    })[0].response.hits.hits;

    return this.extractLiteratureData(responseItems);
  }

  /**
   *
   * @param responseItems extracting literature data only / we use this also in on-search component
   */
  extractLiteratureData(responseItems: FtSearchItem[]): LiteratureItem[] {
    const literatureItems = responseItems.map((item) => {
      const litPathLocal = item._source.lit_path ? item._source.lit_path : '';
      const fileAttributes: FileInfo = extractFileInfoFromLink(litPathLocal);
      const documentIcon = this.configService.knownExtensions.includes(
        fileAttributes.fileExtension
      )
        ? fileAttributes.fileExtension
        : 'pdf';
      const items = this.getLiteratureCommonProperties(item);
      return {
        title: this.getLiteratureTitle(item),
        body: this.getLiteratureBody(item),
        docTypeSrc: this.configService.getIconPath(documentIcon),
        titleLink: this.getLiteratureLink(item),
        ...items,
      };
    });

    return literatureItems;
  }

  // TODO set type
  getLiteratureCommonProperties(item: FtSearchItem): LiteratureData {
    return {
      finraLink: '/', // this is defined in template
      customFilePath: item._source.customfilePath, // if there is no lit_code, this path can be used for download
      litPath: item._source.lit_path, // literaturePath - link to a document. We don't use it, because we use download servlet
      litType: item._source.lit_content_type, // dctermsType
      oneTisNumber: item._source.oneTisNumber,
      fund_name: item._source.fund_name,
      ticker_symbol: item._source.ticker_symbol,
      shclcode: item._source.shclcode,
      documentPath: item._source.lit_path || item._source.customfilePath, // we use this to generate document extension and icon
      publicationDate: item._source.pub_date_formatted || EMDASH,
      finraPath: item._source.finra_path,
      litCode: item._source.lit_code || EMDASH, // literatureCode
      isOrdItself: item._source.isOrdItself,
      isInv: item._source.isInv,
      mandatoryMaterial: item._source.mand_material,
    };
  }
  /**
   *
   */
  getLiteratureData(): LiteratureData[] {
    const responseItems = this.commonDataService.results.filter((item) => {
      return item.name === 'literature';
    })[0].response.hits.hits;

    const literatureItems = responseItems.map((item) => {
      const litPathLocal = item._source.lit_path ? item._source.lit_path : '';
      const fileAttributes: FileInfo = extractFileInfoFromLink(litPathLocal);
      const items = this.getLiteratureCommonProperties(item);
      return {
        litName: this.getLiteratureTitle(item),
        litDetail: this.getLiteratureDescription(item),
        litType: item._source.lit_content_type,
        litUrl: this.getLiteratureLink(item), // this is a link to download servlet - usually download path + literature code

        clientUse: item._source.client_use,
        clientUseValue:
          item._source.client_use === 'Y'
            ? this.translateService.instant('common.yes')
            : this.translateService.instant('common.no'),
        clientName: this.translateService.instant('literature.finra-letter'),

        ...items,
        docTypeSrc: item._source.lit_tnail_path,
        oneTisNumber: item._source.oneTisNumber || [],
        shclcode: item._source.shclcode || [],
        ticker_symbol: item._source.ticker_symbol || [],
        fund_name: item._source.fund_name || [],
        fundDetailLabel: {
          label: this.translateService.instant('common.client-use-search'),
          size: 'compact',
        },
        documentIcon: this.configService.knownExtensions.includes(
          fileAttributes.fileExtension
        )
          ? fileAttributes.fileExtension
          : 'pdf',
        downloadBtn:
          fileAttributes.fileExtension === 'pdf'
            ? this.translateService.instant('literature.download-pdf')
            : this.translateService.instant('literature.download'),
        cartBtn: this.translateService.instant('literature.add-to-cart'),
        fundDetails: [
          {
            label:
              this.translateService.instant('literature.literature-code') + ':',
            val: item._source.lit_code || EMDASH,
            size: 'compact',
          },
          {
            label:
              this.translateService.instant('literature.publication-date') +
              ':',
            val: item._source.pub_date_formatted || EMDASH,
            size: 'compact',
          },
        ],
      };
    });

    return literatureItems;
  }

  /**
   *
   */
  getLiteratureTitle(item: FtSearchItem): string {
    return item?.highlight?.title
      ? item.highlight.title[0]
      : item._source.title;
  }

  /**
   *
   * @param item item with literature data
   */
  getLiteratureDescription(item: FtSearchItem): string {
    return item?.highlight?.lit_desc
      ? item.highlight.lit_desc[0]
      : item._source.lit_desc;
  }

  /**
   * returns url for literature document
   * @param item data item
   */
  getLiteratureLink(item: FtSearchItem): string {
    const litCodeForUrl: string =
      item._source.lit_code && item._source.lit_code !== EMDASH
        ? item._source.lit_code
        : item._source.customfilePath;

    if (!litCodeForUrl) {
      return '';
    }

    return (
      this.literatureConfigService.navigationUrls.litDetailUrl +
      '/' +
      litCodeForUrl
    );
  }

  /**
   *
   */
  getLiteratureBody(item: FtSearchItem): string {
    // TODO last modified needs to be formatted - we may need to use separate service
    // TODO locale needs to be taken from config file
    const formattedDate = item._source.pub_date_formatted || '';
    const litDescriptionText = this.getLiteratureDesc(item);
    const contentType = this.translateService.instant(
      'literature.' + item._source.lit_content_type
    );

    return `<span class="text-meta-tag">${formattedDate} | ${contentType}</span><br/>${litDescriptionText}`;
  }

  /**
   *
   * @param item results item
   */
  getLiteratureDesc(item: FtSearchItem): string {
    return item.highlight?.lit_desc ? item.highlight.lit_desc[0] : '';
  }
}
