export enum SegmentId {
  DISTRIBUTOR = 'distributor', // Germany only. "Vertriebspartner" translates as both "Sales Partner" and "Distribution Partner"
  FINANCIAL_PROFESSIONALS = 'financial-professionals', // most Core-B+ sites
  GATEKEEPER = 'gatekeeper', // most Core-B+ sites going forward
  INSTITUTIONAL = 'institutional', // mix of Core-B+ and dedicated Inst sites
  INVESTOR = 'investor', // almost all sites
  SHARIAH = 'shariah', // Malaysia
  INSTITUTIONAL_REPORTS = 'institutional-reports', // Germany requirement
  INTERNAL = 'internal', // Internal user
  RA = 'ra', // RA user
  RIA = 'ria', // RIA user
}

export type Segment = {
  id: SegmentId;
  label: string;
  subLabel?: string; // for helper text in dropdown
  termsRequired: boolean;
  termsMustRead?: boolean; // it is required for agreement checkbox and 'Accept' button enable/disable functionality.
  termsWithSignIn?: boolean; // switch on for sign in for in terms modal
  termsAccepted: boolean;
  externalLink?: string;
  insightAudiences?: string;
  declineUrl?: string;
  isHiddenInBanner: boolean;
  isHiddenInSwitcher: boolean;
  isCustomMegaMenu?: boolean;
};
