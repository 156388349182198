import { PremiumDiscountElevated } from '@models';
import { FundId, PremiumDiscountElevatedDTO, ShareClassCode } from '@types';
import { MapperFactory } from './mapper-factory';
import { parseStdNumber } from './mapper.utils';
import { Mapper, MapperParams } from './type.mapper';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('PremiumDiscountElevatedMapper');

export class PremiumDiscountElevatedMapper extends Mapper<
  PremiumDiscountElevated[]
> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    premiumDiscountElevatedDtoArray: PremiumDiscountElevatedDTO[]
  ): PremiumDiscountElevated[] {
    logger.debug(
      'premiumDiscountElevatedDtoArray',
      premiumDiscountElevatedDtoArray
    );
    if (
      !premiumDiscountElevatedDtoArray ||
      !Array.isArray(premiumDiscountElevatedDtoArray) ||
      premiumDiscountElevatedDtoArray.length === 0
    ) {
      return null;
    }
    return premiumDiscountElevatedDtoArray.map(
      (
        premiumDiscountElevatedDto: PremiumDiscountElevatedDTO
      ): PremiumDiscountElevated => ({
        fundId: premiumDiscountElevatedDto.fundid as FundId,
        shareClassCode: premiumDiscountElevatedDto.shclcode as ShareClassCode,
        countryCode: premiumDiscountElevatedDto.cntrycode,
        languageCode: premiumDiscountElevatedDto.langcode,
        periodId: parseStdNumber(premiumDiscountElevatedDto.periodid),
        endDate: premiumDiscountElevatedDto.enddate,
        tradingDays: parseStdNumber(premiumDiscountElevatedDto.noofdays),
        eventType: premiumDiscountElevatedDto.eventtype,
      })
    );
  }
}
