import { Injectable } from '@angular/core';
import { MapperParams, Mapper } from '@products/utils/mappers/type.mapper';

@Injectable({
  providedIn: 'root',
})
export class MapperFactory {
  createMapper<T extends Mapper<any>>(
    mapper: MapperType<T>,
    params: MapperParams
  ): T {
    return new mapper(params, this);
  }
}

export type MapperType<T extends Mapper<any>> = new (
  params: MapperParams,
  mapperFactory: MapperFactory
) => T;
