import dayjs from 'dayjs';
// eslint-disable-next-line no-unused-vars
import { LANG_CONFIG, HIGHCHARTS_DEFAULT_CONFIG } from './all-langauges.config';
import { Logger } from '../logger';
import { LangOptions } from 'highcharts';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DuetDatePickerErrorMsg } from '@frk/eds-components';
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
const logger = Logger.getLogger('moment-il8n');
let highChartsLocaleCode = 'en';

/**
 * Locale Month Year format for moment
 * @param locale = null
 */
export function momentMonthYearFormat(locale: string = null): string {
  let longDateFormat;
  try {
    if (!locale) {
      dayjs.locale();
    }
    longDateFormat = dayjs.localeData().longDateFormat('L');
  } catch (e) {
    logger.debug('Locale not configured. Uses default [en-us]');
    dayjs.locale('en');
    longDateFormat = dayjs.localeData().longDateFormat('L');
  }
  return longDateFormat.replace(/((DD|D|dd|d)[-./])|([-./](DD|D|dd|d)$)/, '');
}

/**
 * Give Locale Time, e.g. when given 6 + yy(years) = 6 years(en-gb) or 6 Jahren(de-de)
 * @param locale e.g. de-de
 * @param amountOfUnit e.g. 6
 * @param units e.g. yy (years) in momentjs https://momentjs.com/docs/#/i18n/locale-data/
 * @returns output e.g. 6 Jahren
 */
// @TODO - Following method is not used in actual logic, do we need this?
/*export function momentTimePeriodFormat(
  locale: string,
  amountOfUnit: number,
  units: moment.RelativeTimeKey,
  isFuture: boolean
): string {
  let timePeriodFormat;
  try {
    if (!locale) {
      locale = moment.locale();
    }
    timePeriodFormat = moment
      .localeData(locale)
      .relativeTime(amountOfUnit, true, units, isFuture);
  } catch (e) {
    logger.debug('Locale not configured. Uses default [en-us]');
    timePeriodFormat = moment
      .localeData('en')
      .relativeTime(amountOfUnit, true, units, isFuture);
  }
  return timePeriodFormat;
}*/

/**
 * locale code [2 digit ISO-639 language][dash + optional ISO 3166 country code]
 */
export function setLocale(newLocaleCode: string) {
  const returnLocaleCode = dayjs.locale(newLocaleCode);
  if (newLocaleCode.indexOf(returnLocaleCode) === -1) {
    // WORKAROUND: this code runs before Aurelia logging is setup hence requires a actual console.error
    // rather than a logger.error
    // eslint-disable-next-line no-console
    console.error(`unknown locale '${newLocaleCode}' defaulting to english`);
    dayjs.locale('en');
  }
}

let CACHED_DATES = {};
/**
 * cached 6 months ago
 * cached because moment is very very slow (few ms per eval)
 * @returns 6 months ago in std format
 */
export function getCached6MonthsAgoStd(): string {
  if (!CACHED_DATES['6_MONTHS']) {
    CACHED_DATES['6_MONTHS'] = dayjs()
      .subtract(6, 'months')
      .format('YYYY-MM-DD');
  }
  return CACHED_DATES['6_MONTHS'];
}
/**
 * cached 1 year ago
 * cached because moment is very very slow (few ms per eval)
 * @returns 1 year ago in std format
 */
export function getCached1YearAgoStd(): string {
  if (!CACHED_DATES['1_YEAR']) {
    CACHED_DATES['1_YEAR'] = dayjs().subtract(1, 'years').format('YYYY-MM-DD');
  }
  return CACHED_DATES['1_YEAR'];
}
/**
 */
export function resetLocaleCache() {
  CACHED_DATES = {};
}

/**
 * cached 5 years ago
 * cached because moment is very very slow (few ms per eval)
 * @returns 5 years ago in std format
 */
export function getCached5YearAgoStd(): string {
  if (!CACHED_DATES['5_YEARS']) {
    CACHED_DATES['5_YEARS'] = dayjs().subtract(5, 'years').format('YYYY-MM-DD');
  }
  return CACHED_DATES['5_YEARS'];
}

/**
 * Set HighChart Locale setings
 */
export function setHighChartLangOptions(newLocaleCode): LangOptions {
  highChartsLocaleCode = newLocaleCode;

  const highChartsLocaleConfig = getHighChartLocale();

  // Set Locale Specific High charts settings

  // http://api.highcharts.com/highcharts/lang

  return {
    months: dayjs.localeData().months(),
    shortMonths: dayjs.localeData().monthsShort(),
    weekdays: dayjs.localeData().weekdays(),
    decimalPoint: highChartsLocaleConfig.decimalPoint,
    thousandsSep: highChartsLocaleConfig.thousandsSep,
  };
}

export function getHighChartLocale() {
  return LANG_CONFIG[highChartsLocaleCode]
    ? LANG_CONFIG[highChartsLocaleCode].highcharts
    : HIGHCHARTS_DEFAULT_CONFIG;
}

export function getDatePickerErrorMsg(
  translateService
): DuetDatePickerErrorMsg {
  return {
    badInput: translateService?.instant('common.dateError.badInput'),
    valueMissing: translateService?.instant('common.dateError.valueMissing'),
    rangeUnderflow: translateService?.instant(
      'common.dateError.rangeUnderflow'
    ),
    rangeOverflow: translateService?.instant('common.dateError.rangeOverflow'),
    disabledWithInWeekRange: translateService?.instant(
      'common.dateError.disabledWithInWeekRange'
    ),
  };
}
