/**
 * this list is of the Bloomreach ids of async components
 * Corresponds to BrComponent.getName() values
 * NB: it will need to be updated manually every time an async component is developed
 */
export const ASYNC_COMPONENTS: string[] = [
  // NB ignore product page components for now - that page uses section loading
  // // portfolio
  // 'holdings',
  // 'top-ten-holdings',
  // 'top-ten-holdings-money-funds',
  // 'asset-allocation',
  // 'geographic-breakdown',
  // 'sector-allocation',
  // 'currency-breakdown',
  // 'market-capitalisation-breakdown',
  // 'portfolio-exposure',
  // 'strategy-breakdown',
  // 'asset-class-exposure',
  // 'average-portfolio-exposure',
  // 'fund-measures',
  // 'geographic-exposure',
  // 'sector-exposure',
  // 'security-type-exposure',
  // 'portfolio-risk-and-return-analysis',
  // 'top-long-exposures',
  // 'top-short-exposures',
  // 'manager-roster',
  // 'maturity-allocation',
  // 'quality-allocation',
  // 'fund-portfolio',

  // US Servicing Performance
  'USServicingMyInvestments',
  'USServicingPerformance2',

  // Morningstar Tables
  'fund-morningStar',

  // us indices
  'allocations',

  // South Africa Effetive Annual Costs ('interactive-content')
  'SAEffectiveAnnualCosts',

  // TODO: add more async components that can appear on sticky tab/deep linking pages
  // WDE-5892 Adding highcharts as async component to perform scroll after chart loads
  'SimpleHighcharts',
];

export interface PdsRequestBody {
  operationName: // common
  | 'Labels'
    | 'ProductDetail'
    // overview
    | 'AnalizyRating'
    | 'FundContent'
    | 'FundFavorite'
    | 'FundManagement'
    | 'FundSummary'
    | 'TradingInfo'
    // portfolio
    | 'AssetAllocation'
    | 'CouponAllocation'
    | 'CurrencyAllocation'
    | 'FiAllocation'
    | 'GeographicAllocation'
    | 'HedgeAllocation'
    | 'Holdings'
    | 'ImpactPillar'
    | 'ManagerAllocation'
    | 'MarketCapAllocation'
    | 'QualityAllocation'
    | 'RiskReturnAnalysis'
    | 'SectorAllocation'
    | 'SecurityExposure'
    | 'PortfolioStatistics'
    | 'TopExposure'
    | 'TopHoldings'
    | 'TopTenHoldings'
    | 'Impactfocus'
    // performance
    | 'AnnualizedSnapshot'
    | 'Annualized'
    | 'CalendarYear'
    | 'CumulativeTimeSeries'
    | 'CumulativeSnapshot'
    | 'Cumulative'
    | 'CustomCumulativeTimeSeries'
    | 'Discrete'
    | 'RiskReturnOverview'
    | 'RiskReturnProfile'
    | 'RiskStatsSummary'
    // distributions
    | 'Distribution'
    | 'DistributionHistory'
    // prices
    | 'PricesHistory'
    | 'Pricing'
    | 'PremiumDiscount'
    // documents
    | 'FundDocuments'
    | 'FundDocumentsAll'
    | 'SimilarFundListing';
}
