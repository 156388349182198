import { ISODate } from '@frk/eds-components';
import { CalcTypeStd, CurrencyCode } from '@types';

export interface Performance {
  monthEnd?: PerformanceDetail[];
  quarterEnd?: PerformanceDetail[];
  historical?: PerformanceDetail[];
}

export enum PerformanceStatus {
  PRELIM = 'PRELIM',
  FINAL = 'FINAL',
}

export interface PerformanceDetail {
  currencyCode?: CurrencyCode;
  performanceAsOfDate?: string;
  performanceAsOfDateStd?: ISODate;
  calcType?: string;
  calcTypeStd?: CalcTypeStd;
  avgAnnual1YearReturn?: string;
  avgAnnual2YearReturn?: string;
  avgAnnual3YearReturn?: string;
  avgAnnual4YearReturn?: string;
  avgAnnual5YearReturn?: string;
  avgAnnual7YearReturn?: string;
  avgAnnual10YearReturn?: string;
  avgAnnual15YearReturn?: string;
  avgAnnual20YearReturn?: string;
  avgAnnual25YearReturn?: string;
  avgAnnual35YearReturn?: string;
  avgAnnualYearToDateReturn?: string;
  avgAnnualSinceInceptionReturn?: string;
  cummulativeReturn1Month?: string;
  cummulativeReturn3Month?: string;
  cummulativeReturn6Month?: string;
  cummulativeReturn1Year?: string;
  cummulativeReturn2Year?: string;
  cummulativeReturn3Year?: string;
  cummulativeReturn5Year?: string;
  cummulativeReturn10Year?: string;
  cummulativeReturn15Year?: string;
  cummulativeReturnYearToDate?: string;
  cummulativeReturnSinceInception?: string;
  calendarYearReturn1Year?: string;
  calendarYearReturn2Year?: string;
  calendarYearReturn3Year?: string;
  calendarYearReturn4Year?: string;
  calendarYearReturn5Year?: string;
  calendarYearReturn6Year?: string;
  calendarYearReturn7Year?: string;
  calendarYearReturn8Year?: string;
  calendarYearReturn9Year?: string;
  calendarYearReturn10Year?: string;
  discreteAnnualRollingYear1?: string;
  discreteAnnualRollingYear2?: string;
  discreteAnnualRollingYear3?: string;
  discreteAnnualRollingYear4?: string;
  discreteAnnualRollingYear5?: string;
  discreteAnnualRollingYear6?: string;
  discreteAnnualRollingYear7?: string;
  discreteAnnualRollingYear8?: string;
  discreteAnnualRollingYear9?: string;
  discreteAnnualRollingYear10?: string;
  performanceStatus?: PerformanceStatus;
  performanceYear1YearBack?: string;
  performanceYear2YearBack?: string;
  performanceYear3YearBack?: string;
  performanceYear4YearBack?: string;
  performanceYear5YearBack?: string;
  performanceYear6YearBack?: string;
  performanceYear7YearBack?: string;
  performanceYear8YearBack?: string;
  performanceYear9YearBack?: string;
  performanceYear10YearBack?: string;
}
