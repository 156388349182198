import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Logger } from '@utils/logger';
import { SchemaHomeComponentData, SchemaService } from '@services';
import { DOCUMENT } from '@angular/common';

const logger = Logger.getLogger('OrgSchemaHomepageComponent');

@Component({
  selector: 'ft-org-schema-homepage',
  template: '',
})
export class OrgSchemaHomepageComponent
  extends AbstractBaseComponent
  implements OnInit, OnDestroy {
  constructor(
    private schemaService: SchemaService,
    @Inject(DOCUMENT) private documentRef: Document
  ) {
    super();
  }

  ngOnInit(): void {
    const url = this.documentRef.location.origin;
    const schemaHomeComponent = this.page
      ?.getComponent('page-config')
      ?.getComponent('org-schema-homepage') as any;
    logger.debug('schemaHomeComponent:', schemaHomeComponent);
    const schemaHomeComponentData = schemaHomeComponent?.model?._meta?.params;
    this.schemaService.insertSchema(
      this.schemaService.orgSchemaHomepage(
        schemaHomeComponentData as SchemaHomeComponentData,
        url
      ),
      'structured-data-org'
    );
  }

  ngOnDestroy(): void {
    this.schemaService.removeStructuredData('structured-data-org');
  }
}
