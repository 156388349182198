<div edsRow>
  <div edsCol>
    <eds-pull-quote
      [body]="content.quote"
      [name]="content.quoteName"
      [team]="content.team"
      [hasFooter]="true"
    >
    </eds-pull-quote>
  </div>
</div>
