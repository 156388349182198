import { AccountsAlertsComponent } from './accounts-alerts/accounts-alerts.component';
import { SiteAlertComponent } from './site-alert/site-alert.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationsModule } from '@frk/eds-components';
import { GatewayModalService } from '@services';
import { ComponentMapping } from '@services/module-loader.config';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  imports: [CommonModule, NotificationsModule, SharedModule],
  declarations: [AccountsAlertsComponent, SiteAlertComponent],
  exports: [AccountsAlertsComponent, SiteAlertComponent],
  entryComponents: [AccountsAlertsComponent, SiteAlertComponent],
  providers: [GatewayModalService],
})
export class AccountsModule {}

/** Map Bloomreach components to Angular */
export const AccountsComponentMapping: ComponentMapping = {
  // Alerts
  'Accounts alert': AccountsAlertsComponent,
};
