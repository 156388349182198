import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';
import { DashIfEmptyPipe } from './dash-if-empty.pipe';
import { DecodeHtmlPipe } from './decode-html.pipe';
import { FormatPercentagePipe } from './format-percentage.pipe';
import { IsExternalLinkPipe } from './is-external-link.pipe';
import { IsInternalLinkPipe } from './is-internal-link.pipe';
import { LeavingSitePipe } from './leaving-site.pipe';
import { ToNumberPipe } from './to-number.pipe';
import { ContentBlockMarginPipe } from './content-block-margin.pipe';
import { IsSignInRequiredPipe } from './is-signin-required.pipe';
import { SafeInputModule } from '@frk/eds-components';

// Components
@NgModule({
  declarations: [
    DashIfEmptyPipe,
    DecodeHtmlPipe,
    FormatPercentagePipe,
    LeavingSitePipe,
    SafeHtmlPipe,
    ToNumberPipe,
    IsExternalLinkPipe,
    IsInternalLinkPipe,
    ContentBlockMarginPipe,
    IsSignInRequiredPipe,
  ],
  imports: [],
  exports: [
    DashIfEmptyPipe,
    DecodeHtmlPipe,
    FormatPercentagePipe,
    LeavingSitePipe,
    IsExternalLinkPipe,
    IsInternalLinkPipe,
    SafeHtmlPipe,
    ToNumberPipe,
    ContentBlockMarginPipe,
    IsSignInRequiredPipe,
    SafeInputModule,
  ],
  providers: [DashIfEmptyPipe],
})
export class PipesModule {}
