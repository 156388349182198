import { Page } from '@bloomreach/spa-sdk';
import {
  Link,
  LinkCollection,
  WidenDocumentJson,
} from '../types/doc-types.type';

export interface FileInfo {
  fileName: string;
  fileExtension: string;
  file: string;
  externalId: string;
}

/**
 * Parse widen document json and read url
 * @param widenDocument json string
 */
export function getWidenDocumentParams(
  widenDocument: string
): WidenDocumentJson | null {
  let params = null;
  if (widenDocument) {
    const widenJson = JSON.parse(widenDocument);
    params = {
      id: widenJson.id,
      url: widenJson.url,
      document_title: widenJson?.document_title,
      noindex: widenJson?.noindex as boolean,
    };
  }
  return params;
}

/**
 * Process the linkcompound for template usage
 * @param linkCompound link object
 * @param page brxm sdk page object
 * @param cmsURL optional url string
 */
export function processLink(
  linkCompound: Link,
  page: Page,
  cmsURL: string
): Link {
  if (linkCompound) {
    for (let link of linkCompound.linkCollection) {
      link = processLinkCollection(link, page, cmsURL);
    }
    return linkCompound.linkCollection[0] || linkCompound;
  }
}

/**
 * Process the link collection object
 * @param link link collection object
 * @param page brxm sdk page object
 * @param cmsURL optional url string
 */
export function processLinkCollection(
  link: LinkCollection,
  page: Page,
  cmsURL?: string
): LinkCollection {
  if (link) {
    if (link.document) {
      link.url = page.getContent(link.document).getUrl();
    } else if (link.widenAssetCompound) {
      const widenDocumentParams = getWidenDocumentParams(
        link.widenAssetCompound?.widenDocument
      );
      link.url = widenDocumentParams?.url;
      link.widenParameters = {
        id: widenDocumentParams.id,
        url: widenDocumentParams.url,
        document_title: widenDocumentParams.document_title,
        noindex: widenDocumentParams.noindex,
      };
    } else if (link.url) {
      const regex = new RegExp('(^http:|^https:|^mailto:)');
      if (regex.test(link.url)) {
        link.url = link.url;
      } else {
        if (cmsURL) {
          if (link.url.startsWith('/')) {
            link.url = `${cmsURL}${link.url}`;
          } else {
            link.url = `${cmsURL}/${link.url}`;
          }
        }
      }
    }
  }
  return link;
}

export function extractFileInfoFromLink(link: string): FileInfo {
  // remove params ? and # if present in url
  // then splits this string by /
  const linkSplit = link.split(/[?#]/)[0].split('/');
  const contentIndex = linkSplit.indexOf('content');
  // Extracting externalId from link
  let externalId = '';
  if (contentIndex !== -1) {
    externalId = linkSplit[contentIndex + 1];
  }

  const file = linkSplit[linkSplit.length - 1].split('.');
  return {
    fileName:
      file.length > 2 ? [...file].slice(0, file.length - 1).join('.') : file[0],
    fileExtension: file.length > 1 ? file[1].toLowerCase() : '',
    file: linkSplit[linkSplit.length - 1],
    externalId,
  };
}

/**
 *
 * @param themePath helper function extracting theme name from path
 */
export function getThemeNameFromPath(themePath: string): string {
  // Remove regexp for theme version format
  return themePath?.replace(
    /\/assets\/css\/([a-z0-9\-]+).theme.css\?v=.*$/,
    '$1'
  );
}

/**
 * Returns clicked Anchor element
 * @param event - click event
 */
export function checkLink(event: MouseEvent): EventTarget {
  if (
    event.target instanceof HTMLAnchorElement ||
    event.target instanceof HTMLButtonElement
  ) {
    return event.target;
  }
  // Try to find anchor element in event patch
  const anchorEl = event
    .composedPath()
    .find((el) => el instanceof HTMLAnchorElement);
  if (anchorEl) {
    return anchorEl;
  }
  // Try to find button element in event patch
  const buttonEl = event
    .composedPath()
    .find((el) => el instanceof HTMLButtonElement);
  if (buttonEl) {
    return buttonEl;
  }
}

/**
 * gets a url query param from a url string
 * returns null if param not found
 * see: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#no_url_parsing
 * @param url e.g. 'http://example.com/search?foo=bar&biz=baz'
 * example: getParamFromUrl('http://example.com/search?foo=bar&biz=baz', 'foo') // returns 'bar'
 */
export function getParamFromUrl(urlString: string, paramName: string): string {
  const url: URL = new URL(urlString);
  const params: URLSearchParams = new URLSearchParams(url.searchParams);
  return params.get(paramName);
}

/**
 * Removes parameter from URL
 * @param url - URL string
 */
export function removeParam(url: string, parameter: string): string {
  return url // Sometimes we are getting HEX code for =
    .replace(new RegExp('([?&])' + parameter + '(=|%3D)[^&#$]+(&)?'), '$1')
    .replace(/\?$|\?(#.*)$/, '$1'); // remove ? from URL end.
}
