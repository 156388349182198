<!--select toggle button wrapper/ scroll-->
<div class="scrollable-container">
  <!-- tabs component -->
  <ft-search-tabs
    [updatedQueryText]="updatedQueryText"
    [activeTab]="activeTab"
    [results]="results"
    [toggleButtons]="toggleButtons"
    (tabsChanged)="onTabChange($event)"
  >
  </ft-search-tabs>
</div>

<!-- filters component -->
<div class="filters" *ngIf="activeTab !== 'all' && aggregations">
  <div class="filters__wrapper">
    <ft-search-filters
      [filterType]="activeTab"
      [aggregations]="aggregations"
      (filtered)="onEmitFiltered(0)"
    ></ft-search-filters>
  </div>
</div>

<!--results page-->
<div class="full-results">
  <ng-container *ngIf="activeTab === 'all'">
    <ft-full-results-all
      [fundHeader]="fundHeader"
      [literatureHeader]="literatureHeader"
      [generalHeader]="generalHeader"
      [fundItems]="fundItems"
      [literatureItems]="literatureItems"
      [generalItems]="generalItems"
      [isSiteInternational]="isSiteIntl"
      (viewMoreClickedAll)="onViewMore($event)"
      [searchTerm]="searchTerm"
    ></ft-full-results-all>
  </ng-container>
  <ng-container *ngIf="activeTab === 'funds'">
    <ft-full-results-funds
      [searchTerm]="searchTerm"
      [fundHeader]="fundHeader"
      [fundItems]="fundItems"
      (viewMoreClickedFunds)="onViewMore($event)"
    ></ft-full-results-funds>
    <div id="searchResultsBottom" #searchScroll></div>
  </ng-container>
  <ng-container *ngIf="activeTab === 'literature'">
    <ft-full-results-literature
      [literatureHeader]="literatureHeader"
      [literatureItems]="literatureItems"
      [literatureData]="literatureData"
      [isSiteInternational]="isSiteIntl"
      (viewMoreClickedLiterature)="onViewMore($event)"
    ></ft-full-results-literature>
    <div id="searchResultsBottom" #searchScroll></div>
  </ng-container>
  <ng-container *ngIf="activeTab === 'pages'">
    <ft-full-results-general
      [searchTerm]="searchTerm"
      [generalHeader]="generalHeader"
      [generalItems]="generalItems"
      (viewMoreClickedGeneral)="onViewMore($event)"
    ></ft-full-results-general>
    <div id="searchResultsBottom" #searchScroll></div>
  </ng-container>
</div>
