import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from '@utils/logger';
import { Observable } from 'rxjs';
import { StockDataDTO } from './env-config.service';
import { AppStateService } from './app-state.service';
import { tap } from 'rxjs/operators';

const logger = Logger.getLogger('StockTickerServiceService');

@Injectable({
  providedIn: 'root',
})
export class StockTickerServiceService {
  constructor(
    private http: HttpClient,
    private appStateService: AppStateService
  ) {}

  public getStockData$(): Observable<StockDataDTO> {
    return this.http
      .get<StockDataDTO>(this.appStateService?.getStockQuoteList())
      .pipe(
        tap(
          // Log the result or error
          {
            next: (data) => logger.debug('tickerdata', data),
            error: (error) => logger.debug('error', error),
          }
        )
      );
  }
}
