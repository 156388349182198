import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root',
})
export class ViewModeService {
  constructor(@Inject(WINDOW) readonly windowRef: Window) {}

  /**
   * If SPA is loaded via an iFrame, we can assume this is being loaded
   * through the Experience Manager and therefore is in "Edit Mode".
   *
   * As of version 15 - Page.isPreview() returns true for content-preview.ftsites.com
   *
   * @returns editMode - a boolean.
   */
  public isEditMode(): boolean {
    // For unit tests condition should not be checked if location contains localhost and context.html
    if (
      !this.windowRef.location.href.includes('localhost:9876') &&
      !this.windowRef.location.href.includes('context.html')
    ) {
      return this.windowRef.location !== this.windowRef.parent.location;
    }
    return false;
  }
}
