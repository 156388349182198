import {
  Component,
  OnInit,
  OnChanges,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  FiltersService,
  Filter,
  FilterEntry,
} from '@search/services/filters.service';
import {
  Aggregation,
  BucketItem,
  FilterItem,
} from '@search/interfaces/search.interface';
import { FiltersConfig } from '@search/interfaces/filters.interface';
import { ConfigService } from '@search/services/config.service';
import { ResponsiveService } from '@frk/eds-components';
import { TranslateService } from '@shared/translate/translate.service';

@Component({
  selector: 'ft-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
})
export class SearchFiltersComponent implements OnInit, OnChanges {
  @Input() showFilters = false;
  @Input() aggregations: Aggregation;
  @Input() filterType: 'funds' | 'literature' | 'pages' | 'all';

  @Output() filtered = new EventEmitter<number>();

  showAdvanced = false;
  showAdvancedLabel = 'show advanced filters';
  hideAdvancedLabel = 'hide advanced filters';
  filtersToggleLabel = this.showAdvancedLabel;
  filterGroups: FiltersConfig[];
  filterItems: object[] = [];
  visibleFilter: string;
  activeFilters: string[];
  filterLabels: {};

  /**
   *
   * angular device detection
   */
  isMobile$: Observable<boolean>;
  isHandheld$: Observable<boolean>;

  constructor(
    private configService: ConfigService,
    private filtersService: FiltersService,
    private responsiveService: ResponsiveService,
    private translateService: TranslateService
  ) {}

  // TODO - change this to observables subscribed from filters
  ngOnInit(): void {
    this.filterGroups = this.filtersService.filterConfig;
    this.activeFilters = this.filtersService.activeFilters;
    this.filterLabels = this.filtersService.filterLabels;
    this.isMobile$ = this.responsiveService.isMobile$();
    this.isHandheld$ = this.responsiveService.isHandheld$();
  }

  /**
   *
   */
  ngOnChanges(): void {
    // TODO this should come from filters service
    // TODO - for selected tab/filter type we need to update
    // TODO - consired improving this condition this.filters.activeFilters[filterGroupName].includes(bucket.key) ? true : false,
    // we can probably update this after filter is changed
    if (this.filterType !== 'all') {
      this.filtersService.filterConfig.forEach((filterType: FilterEntry) => {
        filterType.filters.forEach((filterGroupName) => {
          const filterItems: FilterItem[] = [];
          this.aggregations[filterGroupName].buckets.forEach(
            (bucket: BucketItem) => {
              const filterItem: FilterItem = {
                label: this.getFilterLabel(
                  bucket.key,
                  filterType.name,
                  filterGroupName
                ),
                count: this.getDocumentsCount(bucket, filterType.name),
                checked: this.filtersService.activeFilters[
                  filterGroupName
                ].includes(bucket.key)
                  ? true
                  : false,
                value: bucket.key,
              };
              filterItems.push(filterItem);
            }
          );
          // sort productType filters
          if (filterGroupName === 'funds_producttype') {
            filterItems.sort(this.configService.productTypeSorter);
          }

          this.filterItems[filterGroupName] = filterItems;
        });
      });
    }
  }

  getFilterLabel(
    filterKey: string,
    filterTypeName: string,
    filterGroupName: string
  ): string {
    if (
      filterTypeName === 'funds' ||
      filterGroupName === 'literature_investmentManager'
    ) {
      return filterKey;
    }

    // for literature only - literature set
    if (filterTypeName === 'literature') {
      return this.translateService.instant(filterTypeName + '.' + filterKey);
    }

    // for general only
    return this.translateService.instant(
      'common.' + filterTypeName + '.' + filterKey
    );
  }

  getDocumentsCount(bucket: BucketItem, filterTypeName: string) {
    return filterTypeName === 'funds'
      ? bucket.collapsed_doc_count.value
      : bucket.doc_count;
  }
  /**
   *
   */
  onResetFilters($event: any) {
    $event.preventDefault();
    this.filtersService.resetFilters();
    this.activeFilters = this.filtersService.activeFilters;
    // we need to emit filtered only if filters were selected.
    this.filtered.emit(0);
  }

  /**
   *
   */
  removeFilter(filter: Filter) {
    this.filtersService.removeFilter(filter.type, filter.name);
    this.filtered.emit(0);
  }

  /**
   *
   * @param $event triggered event
   */
  toggleAdvanced($event: any) {
    $event.stopPropagation();
    $event.preventDefault();
    this.showAdvanced = !this.showAdvanced;
    this.visibleFilter = '';
    this.filtersToggleLabel = this.showAdvanced
      ? this.hideAdvancedLabel
      : this.showAdvancedLabel;
  }

  /**
   *
   */
  toggleFilter(filterName: string) {
    this.visibleFilter = this.visibleFilter === filterName ? '' : filterName;
  }

  /**
   *
   * @param aggregation aggregation- filters data
   */
  getDataBuckets(aggregation: any) {
    return aggregation.buckets;
  }

  /**
   *
   */
  onChange(
    type: string,
    value: { label: string; count: number; value: string }[]
  ): void {
    // pass selected filters to filters service
    // recognize filter type
    // pass all values to specific filter
    this.filtersService.addFilter(type, value);
    this.filtered.emit(0);
  }

  /**
   *
   */
  onReset(value: string): void {
    // TODO implement action - console.log('some action' + value);
  }
}
