import { ISODate } from '@frk/eds-components';
import { ConfigurationId } from '@types';
import { PerformanceDetail, PerformanceStatus } from './performance';
import { Product } from './product';
import { ShareClass } from './share-class';

export class FundListing {
  private _navAsOfDate: string;
  private _performanceAsOfDateMonthly: string;
  private _performanceAsOfDateMonthlyStd: ISODate;
  private _performanceAsOfDateQuarterly: string;
  private _performanceAsOfDateQuarterlyStd: ISODate;
  private _stdYieldThirtyDayAsofDate: string;
  private _ratingsAsOfDate: string;
  private _products: Product[];
  private _performanceStatus: PerformanceStatus;

  constructor(products: Product[]) {
    this._products = products;
    this.processProducts();
  }

  public get navAsOfDate(): string {
    return this._navAsOfDate;
  }

  public get performanceAsOfDateMonthly(): string {
    return this._performanceAsOfDateMonthly;
  }

  public get performanceAsOfDateMonthlyStd(): ISODate {
    return this._performanceAsOfDateMonthlyStd;
  }

  public get performanceAsOfDateQuarterly(): string {
    return this._performanceAsOfDateQuarterly;
  }

  public get performanceAsOfDateQuarterlyStd(): ISODate {
    return this._performanceAsOfDateQuarterlyStd;
  }

  public get stdYieldThirtyDayAsofDate(): string {
    return this._stdYieldThirtyDayAsofDate;
  }

  public get ratingsAsOfDate(): string {
    return this._ratingsAsOfDate;
  }

  public get products(): Product[] {
    return this._products;
  }

  public get performanceStatus(): PerformanceStatus {
    return this._performanceStatus;
  }

  private processProducts(): void {
    for (const product of this._products) {
      for (const shareClass of product.shareClasses) {
        if (
          product.productType === ConfigurationId.SMA &&
          !this._performanceStatus
        ) {
          this.extractPerformanceStatus(shareClass);
        }
        this.extractNavAsOfDate(shareClass);
        this.extractStdYieldThirtyDayAsofDate(shareClass);
        this.extractRatingsAsOfDate(shareClass);
        this.extractPerformanceAsOfDateMonthly(shareClass);
        this.extractPerformanceAsOfDateMonthlyStd(shareClass);
        this.extractPerformanceAsOfDateQuarterly(shareClass);
        this.extractPerformanceAsOfDateQuarterlyStd(shareClass);
        if (this.asOfDatesPopulated()) {
          return;
        }
      }
    }
  }

  private extractPerformanceAsOfDateQuarterly(shareClass: ShareClass) {
    const quarterEndPerformance = shareClass.performance?.quarterEnd;
    if (
      !this._performanceAsOfDateQuarterly &&
      quarterEndPerformance &&
      quarterEndPerformance.length > 0
    ) {
      const quarterEndPerfDate = this.findPerformanceAsOfDate(
        quarterEndPerformance
      );
      if (!this._performanceAsOfDateQuarterly && quarterEndPerfDate) {
        this._performanceAsOfDateQuarterly = quarterEndPerfDate;
      }
    }
  }

  private extractPerformanceAsOfDateQuarterlyStd(shareClass: ShareClass) {
    const quarterEndPerformance = shareClass.performance?.quarterEnd;
    if (
      !this._performanceAsOfDateQuarterlyStd &&
      quarterEndPerformance &&
      quarterEndPerformance.length > 0
    ) {
      const quarterEndPerfDateStd = this.findPerformanceAsOfDateStd(
        quarterEndPerformance
      );
      if (!this._performanceAsOfDateQuarterlyStd && quarterEndPerfDateStd) {
        this._performanceAsOfDateQuarterlyStd = quarterEndPerfDateStd;
      }
    }
  }

  private extractPerformanceStatus(shareClass: ShareClass) {
    const quarterEndPerformance = shareClass.performance?.quarterEnd;
    if (
      !this._performanceStatus &&
      quarterEndPerformance &&
      quarterEndPerformance.length > 0
    ) {
      const performanceStatus = this.findPerformanceStatus(
        quarterEndPerformance
      );
      if (!this._performanceStatus && performanceStatus) {
        this._performanceStatus = performanceStatus;
      }
    }
  }

  private extractPerformanceAsOfDateMonthly(shareClass: ShareClass) {
    const monthEndPerformance = shareClass.performance?.monthEnd;
    if (
      !this._performanceAsOfDateMonthly &&
      monthEndPerformance &&
      monthEndPerformance.length > 0
    ) {
      const monthEndPerfDate = this.findPerformanceAsOfDate(
        monthEndPerformance
      );
      if (!this._performanceAsOfDateMonthly && monthEndPerfDate) {
        this._performanceAsOfDateMonthly = monthEndPerfDate;
      }
    }
  }

  private extractPerformanceAsOfDateMonthlyStd(shareClass: ShareClass) {
    const monthEndPerformance = shareClass.performance?.monthEnd;
    if (
      !this._performanceAsOfDateMonthlyStd &&
      monthEndPerformance &&
      monthEndPerformance.length > 0
    ) {
      const monthEndPerfDateStd = this.findPerformanceAsOfDateStd(
        monthEndPerformance
      );
      if (!this._performanceAsOfDateMonthlyStd && monthEndPerfDateStd) {
        this._performanceAsOfDateMonthlyStd = monthEndPerfDateStd;
      }
    }
  }

  private extractRatingsAsOfDate(shareClass: ShareClass) {
    const ratings = shareClass.ratings;
    if (!this._ratingsAsOfDate && ratings?.asOfDate) {
      this._ratingsAsOfDate = ratings.asOfDate;
    }
  }

  private extractStdYieldThirtyDayAsofDate(shareClass: ShareClass) {
    const yields = shareClass.yields;
    if (!this._stdYieldThirtyDayAsofDate && yields?.stdYieldThirtyDayAsofDate) {
      this._stdYieldThirtyDayAsofDate = yields.stdYieldThirtyDayAsofDate;
    }
  }

  private extractNavAsOfDate(shareClass: ShareClass) {
    const nav = shareClass.nav;
    if (!this._navAsOfDate && nav?.navAsOfDate) {
      this._navAsOfDate = nav.navAsOfDate;
    }
  }

  private asOfDatesPopulated() {
    return (
      this._navAsOfDate && // NOSONAR
      this._stdYieldThirtyDayAsofDate &&
      this._ratingsAsOfDate &&
      this._performanceAsOfDateMonthly &&
      this._performanceAsOfDateMonthlyStd &&
      this._performanceAsOfDateQuarterly &&
      this._performanceAsOfDateQuarterlyStd
    );
  }

  private findPerformanceStatus(
    performanceDetail: PerformanceDetail[]
  ): PerformanceStatus {
    if (performanceDetail && performanceDetail.length > 0) {
      for (const performance of performanceDetail) {
        if (performance.performanceAsOfDate) {
          return performance.performanceStatus;
        }
      }
    }
  }

  private findPerformanceAsOfDate(
    performanceDetail: PerformanceDetail[]
  ): string {
    if (performanceDetail && performanceDetail.length > 0) {
      for (const performance of performanceDetail) {
        if (performance.performanceAsOfDate) {
          return performance.performanceAsOfDate;
        }
      }
    }
  }

  private findPerformanceAsOfDateStd(
    performanceDetail: PerformanceDetail[]
  ): ISODate {
    if (performanceDetail && performanceDetail.length > 0) {
      for (const performance of performanceDetail) {
        if (performance.performanceAsOfDateStd) {
          return performance.performanceAsOfDateStd;
        }
      }
    }
  }
}
