import { Component, OnInit } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Logger } from '@utils/logger';
import { AppStateService } from '@services';
import { LinkService } from '@services/link.service';
import { LinkCollection } from '@types';
import { ViewModeService } from '@services/view-mode.service';

/**
 * Logger
 */
const logger = Logger.getLogger('StaggeredBoxesComponent');

export interface StaggeredBoxesContent {
  preTitle?: string;
  sectionId?: string;
  title?: string;
  summary?: string;
  cards?: Array<BoxContent>;
  link?: { linkCollection?: Array<LinkCollection> };
}

export interface BoxContent {
  title?: string;
  content?: string;
  preTitle?: string;
}

@Component({
  selector: 'ft-staggered-boxes',
  templateUrl: './staggered-boxes.component.html',
})
export class StaggeredBoxesComponent
  extends AbstractBaseComponent
  implements OnInit {
  showfourBoxes: boolean;
  supportTextLeft: boolean;
  supportTextRight: boolean;
  /**
   * staggered content from BR
   */
  staggeredBoxesContent: StaggeredBoxesContent;
  /**
   * alignment for the content
   */
  alignment: string;

  /**
   * Constructor
   */
  constructor(
    private appStateService: AppStateService,
    private linkService: LinkService,
    private viewModeService: ViewModeService
  ) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  /**
   * Angular init hook
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.alignment = this.component?.getParameters().float;
    switch (this.alignment) {
      case 'Three Boxes On Right':
        this.showfourBoxes = false;
        this.supportTextLeft = true;
        this.supportTextRight = false;
        break;
      case 'Three Boxes On Left':
        this.showfourBoxes = false;
        this.supportTextLeft = false;
        this.supportTextRight = true;
        break;
      case 'Four Boxes On Right':
        this.showfourBoxes = true;
        this.supportTextLeft = true;
        this.supportTextRight = false;
        break;
      case 'Four Boxes On Left':
        this.showfourBoxes = true;
        this.supportTextLeft = false;
        this.supportTextRight = true;
        break;
      default:
        this.showfourBoxes = true;
        this.supportTextLeft = true;
        this.supportTextRight = false;
    }
    this.setStaggeredBoxesData();
  }

  /**
   * Get content from document
   */
  private setStaggeredBoxesData() {
    this.staggeredBoxesContent = this.document?.getData<StaggeredBoxesContent>();
    if (this.staggeredBoxesContent) {
      // setting up the link url if any internal doc is present
      if (this.staggeredBoxesContent?.link?.linkCollection?.length) {
        this.staggeredBoxesContent.link.linkCollection[0].downloadLocation = this.linkService.getDownloadLocationHref(
          this.page,
          this.staggeredBoxesContent.link.linkCollection[0],
          this.appStateService?.getHomePageUrl()
        );

        this.staggeredBoxesContent.link.linkCollection[0].url = this.linkService.getCTALink(
          this.page,
          this.staggeredBoxesContent.link.linkCollection[0],
          this.appStateService?.getHomePageUrl()
        );
      }
    }
  }

  setDownloadLocation(downloadLocation: string) {
    this.linkService.setUpdatedLinkData(downloadLocation);
  }
}
