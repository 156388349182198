<div *ngIf="isPopulated" [id]="uniqueElementId">
  <eds-section-header
    [level]="4"
    size="h4"
    edsSpacing="md"
    [title]="data.chartTitle"
    [caveatPlacement]="data.caveatPlacement"
    [secondaryCaveatPlacement]="data.secondaryCaveatPlacement"
    [analyticsName]="data.chartTitle"
    [tooltip]="data.tooltip"
    [asOfLabel]="data.asOfLabel"
    [asOfDate]="data.asOfDate"
    [calculationBasis]="data.calculationBasis"
    [calculationType]="data.calculationType"
    [updateFrequency]="data.updateFrequency"
  ></eds-section-header>
  <eds-proximal [placement]="data.proximalTopPlacement"></eds-proximal>
  <eds-proximal
    *ngIf="data.secondaryProximalTopPlacement"
    [placement]="data.secondaryProximalTopPlacement"
  ></eds-proximal>

  <!-- used by PremiumDiscountAnalysis to display additional panel -->
  <ng-content></ng-content>

  <div edsRow edsSpacing="lg">
    <div
      *ngIf="!data.hideChart && chartOptions"
      edsCol
      [columnNumbers]="{ s: 4, m: 8, l: 6 }"
      [edsSpacing]="(isMobile$ | async) ? 'lg' : ''"
    >
      <!--
        NGC-5416 eds-percentage-bar component has been removed from EDS.
        Leaving commented out code below should business request it reinstated
       -->
      <!-- <div *ngIf="data.showTotalPercentageBar && data.totalPercent">
        <eds-percentage-bar [coverage]="data.totalPercent"></eds-percentage-bar>
      </div> -->

      <highcharts-chart
        [Highcharts]="highcharts"
        [options]="chartOptions"
        [(update)]="updateChart"
        [callbackFunction]="chartCallback"
        style="width: 100%; display: block"
      >
      </highcharts-chart>
    </div>
    <div
      edsCol
      [columnNumbers]="{
        s: 4,
        m: 8,
        l:
          (!data.hideChart && chartOptions) ||
          data?.componentId === 'Impact Pillar'
            ? 4
            : 12
      }"
    >
      <!-- NGC-4242 comment out Expand/Collapse All buttons until new design in EDS -->
      <div *ngIf="hasNestedData">
        <button
          edsButton="icon-only"
          (click)="toggleAll(toggle)"
          class="print-hidden"
        >
          <eds-icon
            edsPadding
            edsPaddingLeft="xs"
            [edsPaddingRight]="(isMobile$ | async) ? 'lg' : 'xs'"
            type="carrot-right"
            size="small"
            *ngIf="toggle"
          ></eds-icon>

          <eds-icon
            edsPadding
            edsPaddingLeft="xs"
            [edsPaddingRight]="(isMobile$ | async) ? 'lg' : 'xs'"
            type="carrot-down"
            *ngIf="!toggle"
            size="small"
          ></eds-icon>
          <span *ngIf="toggle">{{
            "products.expand-all" | translate: data.fundId
          }}</span>
          <span *ngIf="!toggle">{{
            "products.collapse-all" | translate: data.fundId
          }}</span>
        </button>
      </div>

      <table
        *ngIf="tablePoints?.length > 0"
        class="table table--secondary tableExpand bar-chart-comp"
      >
        <thead class="table__thead">
          <tr class="table__tr">
            <th class="table__th"></th>
            <th class="table__th">{{ data.labelColumnTitle }}</th>
            <!-- NB: this is usually empty -->

            <th
              class="table__th small bold text-align-right"
              *ngIf="!data.hideBreakdownCol"
            >
              <!-- displays breakdownColumnTitle if passed, other wise translates "products.fund" -->
              {{
                data.breakdownColumnTitle ||
                  ("products.fund" | translate: data.fundId)
              }}
            </th>
            <th
              class="table__th small bold text-align-right"
              *ngIf="data.showBenchmarkCol"
            >
              <span edsFlexbox alignItems="center" justifyContent="end"
                ><span edsPadding edsPaddingRight="xs">{{
                  "products.benchmark" | translate: data.fundId
                }}</span>
                <eds-icon
                  *ngIf="data.benchmarkName"
                  edsTooltip
                  [tooltipContent]="data.benchmarkName"
                  type="info"
                  size="small"
                ></eds-icon>
              </span>
            </th>

            <th
              class="table__th small bold text-align-right"
              *ngIf="data.showDurationCol"
            >
              {{ "products.duration-contribution" | translate: data.fundId }}
              <eds-footnote
                placement="sector-duration-column-header"
              ></eds-footnote>
              <eds-icon
                class="print-hidden"
                *ngIf="tooltips['products.sector-duration-column-header']"
                edsTooltip
                [analyticsName]="
                  'products.duration-contribution' | translate: data.fundId
                "
                [tooltipContent]="
                  tooltips['products.sector-duration-column-header']
                "
                type="info"
                size="small"
              ></eds-icon>
            </th>
            <th
              class="table__th small bold text-align-right"
              *ngIf="data.showGrossExposureCol"
            >
              {{ "products.gross-exposure" | translate: data.fundId }}
              <p *ngIf="data.calculationType" class="small bold">
                {{ data.calculationType }}
              </p>
            </th>
            <th
              class="table__th small bold text-align-right"
              *ngIf="data.showLongExposureCol"
            >
              {{ "products.long-exposure" | translate: data.fundId }}
              <p *ngIf="data.calculationType" class="small bold">
                {{ data.calculationType }}
              </p>
            </th>
            <th
              class="table__th small bold text-align-right"
              *ngIf="data.showShortExposureCol"
            >
              {{ "products.short-exposure" | translate: data.fundId }}
              <p *ngIf="data.calculationType" class="small bold">
                {{ data.calculationType }}
              </p>
            </th>
            <th
              class="table__th small bold text-align-right"
              *ngIf="data.showNetExposureCol"
            >
              {{ "products.net-exposure" | translate: data.fundId }}
              <p *ngIf="data.calculationType" class="small bold">
                {{ data.calculationType }}
              </p>
            </th>
            <th
              class="table__th small bold text-align-right"
              *ngIf="data.showTargetAllocationCol"
            >
              {{ "products.target-allocation" | translate: data.fundId }}
              <p *ngIf="data.calculationType" class="small bold">
                {{ data.calculationType }}
              </p>
            </th>
            <th
              class="table__th small bold text-align-right"
              *ngIf="data.showActualAllocationCol"
            >
              {{ "products.actual-allocation" | translate: data.fundId }}
              <p *ngIf="data.calculationType" class="small bold">
                {{ data.calculationType }}
              </p>
            </th>
            <th
              class="table__th small bold text-align-right"
              *ngIf="data.showRiskContributionAllocationCol"
            >
              {{ "products.Risk-Contribution" | translate: data.fundId }}
              <p *ngIf="data.calculationType" class="small bold">
                {{ data.calculationType }}
              </p>
            </th>
            <th
              class="table__th small bold text-align-right"
              *ngIf="data.showPerformanceContributionAllocationCol"
            >
              {{ "products.Performance-Contribution" | translate: data.fundId }}
              <p *ngIf="data.calculationType" class="small bold">
                {{ data.calculationType }}
              </p>
            </th>
          </tr>
        </thead>
        <tbody class="table__body">
          <ng-container *ngFor="let point of tablePoints">
            <tr class="table__tr table__tr--white">
              <td
                class="table__td table__td--padding-right-none bar-chart__td--button"
              >
                <span class="table__legend bar-chart-comp__expand-span">
                  <button
                    *ngIf="point.hasChildren"
                    (click)="togglePoint(point)"
                    edsSpacing="none"
                    edsButton="icon-only"
                    size="small"
                    [hasIcon]="true"
                    [attr.aria-expanded]="point.isExpanded"
                    [attr.aria-controls]="point.ariaControls"
                  >
                    <eds-icon
                      type="carrot-down"
                      size="small"
                      *ngIf="point.isExpanded"
                      attr.aria-label="{{
                        'products.collapse' | translate: data.fundId
                      }}"
                    ></eds-icon>
                    <eds-icon
                      type="carrot-right"
                      size="small"
                      *ngIf="!point.isExpanded"
                      attr.aria-label="{{
                        'products.expand' | translate: data.fundId
                      }}"
                    ></eds-icon>
                  </button>
                </span>
              </td>
              <td class="table__td table__td--padding-left-none">
                <button
                  *ngIf="point.hasChildren"
                  (click)="togglePoint(point)"
                  edsSpacing="none"
                  edsButton="icon-only"
                >
                  <span
                    edsPadding
                    class="table__legend bar-chart-comp__expand-span"
                  >
                    <span
                      *ngIf="!data.hideTableLegend && !data.showBenchmarkCol"
                      class="table__legend-box"
                      [style.background]="point.pointColor"
                    ></span>

                    <span [class.text-primary]="point.hasChildren">{{
                      point.label
                    }}</span>
                  </span>
                </button>
                <span
                  *ngIf="!point.hasChildren"
                  edsPadding
                  class="table__legend bar-chart-comp__expand-span"
                >
                  <span
                    *ngIf="!data.hideTableLegend && !data.showBenchmarkCol"
                    class="table__legend-box"
                    [style.background]="point.pointColor"
                  ></span>
                  <span [class.text-primary]="point.hasChildren">{{
                    point.label
                  }}</span>
                </span>
              </td>
              <td
                class="table__td text-align-right bar-chart-comp__expand-span"
                *ngIf="!data.hideBreakdownCol"
              >
                {{ point.breakdown }}
              </td>
              <td
                class="table__td text-align-right"
                *ngIf="data.showBenchmarkCol"
              >
                <strong class="bold bar-chart-comp__expand-span">{{
                  point.benchmark
                }}</strong>
              </td>
              <td
                class="table__td text-align-right"
                *ngIf="data.showDurationCol"
              >
                <strong class="bold bar-chart-comp__expand-span">{{
                  point.duration
                }}</strong>
              </td>
              <td
                class="table__td text-align-right"
                *ngIf="data.showGrossExposureCol"
              >
                <strong class="bar-chart-comp__expand-span">{{
                  point.grossExposure
                }}</strong>
              </td>
              <td
                class="table__td text-align-right"
                *ngIf="data.showLongExposureCol"
              >
                <strong class="bar-chart-comp__expand-span">{{
                  point.longExposure
                }}</strong>
              </td>
              <td
                class="table__td text-align-right"
                *ngIf="data.showShortExposureCol"
              >
                <strong class="bar-chart-comp__expand-span">{{
                  point.shortExposure
                }}</strong>
              </td>
              <td
                class="table__td text-align-right"
                *ngIf="data.showNetExposureCol"
              >
                <strong class="bar-chart-comp__expand-span">{{
                  point.netExposure
                }}</strong>
              </td>
              <td
                class="table__td text-align-right"
                *ngIf="data.showTargetAllocationCol"
              >
                <strong class="bar-chart-comp__expand-span">{{
                  point.targetAllocation
                }}</strong>
              </td>
              <td
                class="table__td text-align-right"
                *ngIf="data.showActualAllocationCol"
              >
                <strong class="bar-chart-comp__expand-span">{{
                  point.actualAllocation
                }}</strong>
              </td>
              <td
                class="table__td text-align-right"
                *ngIf="data.showRiskContributionAllocationCol"
              >
                <strong class="bar-chart-comp__expand-span">{{
                  point.riskContributionAllocation
                }}</strong>
              </td>
              <td
                class="table__td text-align-right"
                *ngIf="data.showPerformanceContributionAllocationCol"
              >
                <strong class="bar-chart-comp__expand-span">{{
                  point.performanceContributionAllocation
                }}</strong>
              </td>
            </tr>
            <ng-container *ngFor="let childPoint of point.children">
              <!-- loop children points here (e.g. Geographic Allocation Chart) -->
              <tr
                *ngIf="!childPoint.isHidden"
                class="table__tr table__tr--white"
                [id]="childPoint.id"
              >
                <td class="table__td"></td>
                <td class="table__td">
                  <span
                    class="table__legend bar-chart-comp__expand-span"
                    edsPadding
                    edsPaddingLeft="lg"
                    [class.text-primary]="childPoint.hasChildren"
                  >
                    <button
                      *ngIf="childPoint.hasChildren"
                      (click)="togglePoint(childPoint)"
                      edsSpacing="none"
                      edsButton="icon-only"
                      size="small"
                      [hasIcon]="true"
                      [attr.aria-expanded]="childPoint.isExpanded"
                      [attr.aria-controls]="childPoint.ariaControls"
                    >
                      <eds-icon
                        type="carrot-down"
                        size="small"
                        *ngIf="childPoint.isExpanded"
                        attr.aria-label="{{
                          'products.collapse' | translate: data.fundId
                        }}"
                      ></eds-icon>
                      <eds-icon
                        type="carrot-right"
                        size="small"
                        *ngIf="!childPoint.isExpanded"
                        attr.aria-label="{{
                          'products.expand' | translate: data.fundId
                        }}"
                      ></eds-icon>
                      <span class="bar-chart-comp__expand-span">
                        {{ childPoint.label }}</span
                      >
                    </button>
                    <span
                      *ngIf="!childPoint.hasChildren"
                      class="bar-chart-comp__expand-span"
                      >{{ childPoint.label }}</span
                    >
                  </span>
                </td>
                <td
                  class="table__td text-align-right"
                  *ngIf="!data.hideBreakdownCol"
                >
                  {{ childPoint.breakdown }}
                </td>
                <td
                  class="table__td text-align-right"
                  *ngIf="data.showBenchmarkCol"
                >
                  {{ childPoint.benchmark }}
                </td>
                <td
                  class="table__td text-align-right"
                  *ngIf="data.showDurationCol"
                >
                  {{ childPoint.duration }}
                </td>
                <td
                  class="table__td text-align-right"
                  *ngIf="data.showGrossExposureCol"
                >
                  {{ childPoint.grossExposure }}
                </td>
                <td
                  class="table__td text-align-right"
                  *ngIf="data.showLongExposureCol"
                >
                  {{ childPoint.longExposure }}
                </td>
                <td
                  class="table__td text-align-right"
                  *ngIf="data.showShortExposureCol"
                >
                  {{ childPoint.shortExposure }}
                </td>
                <td
                  class="table__td text-align-right"
                  *ngIf="data.showNetExposureCol"
                >
                  {{ childPoint.netExposure }}
                </td>
                <td
                  class="table__td text-align-right"
                  *ngIf="data.showTargetAllocationCol"
                >
                  {{ childPoint.targetAllocation }}
                </td>
                <td
                  class="table__td text-align-right"
                  *ngIf="data.showActualAllocationCol"
                >
                  {{ childPoint.actualAllocation }}
                </td>
                <td
                  class="table__td text-align-right"
                  *ngIf="data.showRiskContributionAllocationCol"
                >
                  {{ childPoint.riskContributionAllocation }}
                </td>
                <td
                  class="table__td text-align-right"
                  *ngIf="data.showPerformanceContributionAllocationCol"
                >
                  {{ childPoint.performanceContributionAllocation }}
                </td>
              </tr>
              <ng-container *ngFor="let grandChildPoint of childPoint.children">
                <!-- loop grandchildren points here (e.g. Currency Exposure Chart) -->
                <tr
                  *ngIf="!grandChildPoint.isHidden"
                  class="table__tr table__tr--white"
                  [id]="grandChildPoint.id"
                >
                  <td class="table__td"></td>
                  <td class="table__td">
                    <span
                      edsPadding
                      edsPaddingLeft="xxl"
                      class="bar-chart-comp__expand-span"
                    >
                      {{ grandChildPoint.label }}
                    </span>
                  </td>
                  <td
                    class="table__td text-align-right"
                    *ngIf="!data.hideBreakdownCol"
                  >
                    {{ grandChildPoint.breakdown }}
                  </td>
                  <td
                    class="table__td text-align-right"
                    *ngIf="data.showBenchmarkCol"
                  >
                    {{ grandChildPoint.benchmark }}
                  </td>
                  <td
                    class="table__td text-align-right"
                    *ngIf="data.showDurationCol"
                  >
                    {{ grandChildPoint.duration }}
                  </td>
                  <td
                    class="table__td text-align-right"
                    *ngIf="data.showGrossExposureCol"
                  >
                    {{ grandChildPoint.grossExposure }}
                  </td>
                  <td
                    class="table__td text-align-right"
                    *ngIf="data.showLongExposureCol"
                  >
                    {{ grandChildPoint.longExposure }}
                  </td>
                  <td
                    class="table__td text-align-right"
                    *ngIf="data.showShortExposureCol"
                  >
                    {{ grandChildPoint.shortExposure }}
                  </td>
                  <td
                    class="table__td text-align-right"
                    *ngIf="data.showNetExposureCol"
                  >
                    {{ grandChildPoint.netExposure }}
                  </td>
                  <td
                    class="table__td text-align-right"
                    *ngIf="data.showTargetAllocationCol"
                  >
                    {{ grandChildPoint.targetAllocation }}
                  </td>
                  <td
                    class="table__td text-align-right"
                    *ngIf="data.showActualAllocationCol"
                  >
                    {{ grandChildPoint.actualAllocation }}
                  </td>
                  <td
                    class="table__td text-align-right"
                    *ngIf="data.showRiskContributionAllocationCol"
                  >
                    {{ grandChildPoint.riskContributionAllocation }}
                  </td>
                  <td
                    class="table__td text-align-right"
                    *ngIf="data.showPerformanceContributionAllocationCol"
                  >
                    {{ grandChildPoint.performanceContributionAllocation }}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
      <eds-expand-collapse
        *ngIf="!defaultShowAll && !hasNestedData && isExpandCollapseVisible"
        edsPadding
        #expandCollapse
        edsPaddingbottom="sm"
        edsPaddingLeft="md"
        [collapsedButton]="'products.show-more' | translate"
        [expandedButton]="'products.show-less' | translate"
        (click)="handleExpandCollapse(expandCollapse)"
      >
      </eds-expand-collapse>
    </div>
  </div>

  <eds-proximal [placement]="data.proximalBottomPlacement"></eds-proximal>
  <eds-proximal
    *ngIf="data.secondaryProximalBottomPlacement"
    [placement]="data.secondaryProximalBottomPlacement"
  ></eds-proximal>
</div>
