import { Component, Input } from '@angular/core';
import { SpacingValue } from '@frk/eds-components';

export type DocViewer = 'office' | 'pdf' | 'url';

export type DocViewerDisableContent = 'none' | 'all';

@Component({
  selector: 'ft-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss'],
})
export class DocViewerComponent {
  // url: document url.
  @Input() url: string;

  // Office - files supported .ppt, .pptx, .doc, .docx, .xls and .xlsx
  // PDF - .PDF
  // URL - PDF
  @Input() viewer: DocViewer = 'url';

  // Disable user interaction on document (works only with 'url' viewer)
  @Input() disableContent?: DocViewerDisableContent = 'none';

  /**
   * is signInRequired
   */
  @Input() signInRequired?: boolean;
  @Input() signInCustomIntro?: string;
  @Input() signInTooltip?: string;

  /**
   * Layout related inputs -
   * Wide container
   * Spacing Bottom
   * theme
   */
  @Input() isWide?: boolean;
  @Input() spacingBottom?: SpacingValue;
  @Input() theme?: string;

  @Input() hideDownloadOption = true;

  // TODO: need to inject LOCATION instead of accessing global window object directly
  location =
    window.location.origin +
    '/binaries/content/assets/global/franklin-templeton/anatomy-of-a-recession_q4-2022_external.pptx';
}
