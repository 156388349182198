import { Injectable } from '@angular/core';
import { ToggleButton } from '@frk/eds-components/lib/models/toggle.model';
import {
  FtSearchResponse,
  FullResultsService,
  FtSearchResponseItem,
  TabsLabels,
} from '@search/interfaces/search.interface';

import { TranslateService } from '@shared/translate/translate.service';

@Injectable({
  providedIn: 'root',
})
export class FullResultsCommonService implements FullResultsService {
  tabsLabels: TabsLabels;
  /**
   * stores results for full results services
   */
  results: FtSearchResponse[];

  /**
   * number of results per page - TODO read this from config
   */
  resultsPerPage = 8; // TODO this may be read from config service

  /**
   * stores total number of results for each results type
   */
  totalItems: { name: string; count: number }[] = [];
  itemCount: { name: string; count: number }[] = [];
  constructor(protected translateService: TranslateService) {
    this.tabsLabels = {
      all: this.translateService.instant('common.search.all'),
      funds: this.translateService.instant('common.funds-label'),
      literature: this.translateService.instant('common.forms-and-literature'),
      pages: this.translateService.instant('common.search.general'),
    };
  }

  /**
   *
   * // TODO - this must be moved to service extracting data
   * // total may be set as component attribute
   */
  getTotal(
    response: FtSearchResponseItem,
    type: string,
    apiVer: string
  ): number {
    if (response.hits === undefined) {
      return 0;
    }

    switch (type) {
      case 'funds':
        return Number(response.hits.total);
      case 'literature':
        return Number(
          apiVer === 'v2' ? response.hits.total.value : response.hits.total
        );
      case 'pages':
        return Number(
          apiVer === 'v2' ? response.hits.total.value : response.hits.total
        );
    }
  }

  temporaryTranslationService(key: string): string {
    return key;
  }
  public getTotalIetmsCount(item: ToggleButton): number {
    if (item.value === 'all') {
      return this.itemCount.reduce(
        (total, initial) => total + initial.count,
        0
      );
    }

    return this.itemCount.filter((itemIn) => itemIn.name === item.value)[0]
      .count;
  }
  /**
   * // TODO move this to a service extracting data
   */
  getToggleButtons(activeTab: string, apiVer: string) {
    const allTabsId = 'all';
    const toggleButtons = [
      {
        active: activeTab === 'all',
        value: 'all',
        text: this.tabsLabels[allTabsId], // this should come from labels
      },
    ];

    this.results.forEach((item: FtSearchResponse) => {
      if (Object.keys(item.response).length === 0) {
        return;
      }

      this.totalItems[item.name] = this.getTotal(
        item.response,
        item.name,
        apiVer
      );
      const button = {
        active: item.name === activeTab,
        value: item.name,
        text:
          this.tabsLabels[item.name] + ' (' + this.totalItems[item.name] + ')', // TODO - extract to method and use labels
      };

      this.itemCount.push({
        name: item.name,
        count: this.totalItems[item.name],
      });
      toggleButtons.push(button);
    });
    return toggleButtons;
  }

  /**
   * sets results
   */
  setResults(results: FtSearchResponse[]) {
    this.results = results;
  }

  /**
   * returns results
   */
  getResults(): FtSearchResponse[] {
    return this.results;
  }
}
