import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Component as BrComponent,
  Page,
  Reference,
} from '@bloomreach/spa-sdk/';
import { DropdownItem } from '@frk/eds-components';
import { AppStateService } from '@services/app-state.service';
import { ViewModeService } from '@services/view-mode.service';
import { TranslateService } from '@shared/translate/translate.service';
import { LabelLoader } from '@utils/label-loader';
import { Logger } from '@utils/logger';
import { getDate } from '@utils/text/date-utils';
import { generateUUID } from '@utils/text/string-utils';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface CustomFormFieldDTO {
  validationRuleId?: string;
  regExp?: string | RegExp;
  values?: Array<string>;
  displayValues?: Array<string>;
  type: string;
  invalid: boolean;
  invalidText: string;
  id?: string;
  initialValue?: string;
  name?: string;
  fieldName?: string;
  mandatory?: boolean;
  dateformat?: string;
  minLength?: number;
  maxLength?: number;
  expandHint?: boolean;
}

interface GroupItems {
  id?: string;
  value: string;
  label: string;
}

interface FormApiRsponseDTO {
  status?: number;
  message?: string;
}

/**
 * Logger
 */
const logger = Logger.getLogger('CustomFormComponent');

/**
 * The Custom Form component
 *
 * Selector: `ft-custom-form`
 *
 * Exported as: `CustomFormComponent`
 */
@Component({
  selector: 'ft-custom-form',
  templateUrl: './custom-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFormComponent implements OnInit, OnDestroy {
  /**
   * The Bloomreach component
   */
  @Input() component!: BrComponent;

  /**
   * The Bloomreach page
   */
  @Input() page!: Page;

  content: any;
  document: any;
  items: DropdownItem[] = [];
  radioItems: GroupItems[] = [];
  checkItems: GroupItems[] = [];
  siteKey = '';

  /**
   * Field data object received from bloomreach
   */
  fields: CustomFormFieldDTO[] = [];

  /**
   * Collection of form fields
   */
  formDetails: FormGroup;

  /**
   * Name of the form from bloomreach
   */
  formName: string;

  /**
   * Is form submitted
   */
  isFormSubmitted = false;

  /**
   * Is email sent
   */
  isMailSent = false;

  /**
   * form return error from server
   */
  isErrorResponse = false;

  /**
   * Unsubscribe
   */
  private unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Object for translation labels from resource bundle
   */
  translationLabelsDTO: any;

  recaptcha = 'recaptcha';

  isEditMode = false;

  /**
   * Constructor
   */
  constructor(
    private http: HttpClient,
    private appStateService: AppStateService,
    private changeDetectorRef: ChangeDetectorRef,
    private labelLoader: LabelLoader,
    private translateService: TranslateService,
    private viewModeService: ViewModeService
  ) {}

  /**
   * On initialisation
   */
  ngOnInit() {
    this.document = this.getDocument();
    this.content = this.getContent();
    this.formName = this.content?.name;
    const $ref: Reference = this.content?.pageBeans[0]?.$ref;
    this.translationLabelsDTO = this.component?.getModels()[
      'ft.core.custom-form'
    ];
    this.labelLoader.loadCustomFormTranslations(this.translationLabelsDTO);
    const data = this.page?.getContent($ref)?.getData();
    this.fields = data?.fieldBeans.map((fieldBean: CustomFormFieldDTO) => {
      return {
        ...fieldBean,
        invalid: false,
        invalidText: '',
        id: `field_${generateUUID()}`,
      };
    });
    this.siteKey = this.appStateService.getGoogleCaptchSiteKey();
    this.isEditMode = this.viewModeService.isEditMode();

    this.createFormGroup();
  }

  /**
   * Create reactive form group out of fields received from bloomreach
   */
  private createFormGroup(): void {
    this.formDetails = new FormGroup({});
    this.fields?.forEach((item: CustomFormFieldDTO) => {
      this.formDetails.addControl(
        item.fieldName,
        new FormControl(
          '',
          Validators.compose([
            item.mandatory ? Validators.required : null,
            item.minLength > 0 ? Validators.minLength(item.minLength) : null,
            item.maxLength > 0 ? Validators.maxLength(item.maxLength) : null,
            item.validationRuleId === 'custom.input' ||
            'number.general' ||
            'number.percent' ||
            'string.alphanumeric' ||
            'website'
              ? Validators.pattern(item.regExp)
              : null,
            item.validationRuleId === 'email' ? Validators.email : null,
          ])
        )
      );

      // Set current date to date fields in form group
      if (item.type === 'DATE_FIELD') {
        const currentDate = new Date();
        this.formDetails.patchValue({
          [item.fieldName]: getDate(
            currentDate.toLocaleDateString(),
            item.dateformat.toUpperCase()
          ),
        });
      }

      if (item.type === 'DROPDOWN') {
        this.items = this.getSelectionItemList(item);
      }
      if (item.type === 'RADIOGROUP') {
        this.radioItems = this.getSelectionItemList(item);
      }
      if (item.type === 'CHECKBOXGROUP') {
        this.checkItems = this.getSelectionItemList(item);
      }
    });
    this.formDetails.addControl(
      this.recaptcha,
      new FormControl('', Validators.compose([Validators.required]))
    );
  }

  /**
   * Set the list items like drop down, radio button and checkboxes
   * @param item  - This object received from bloomreach which have defination of field
   * @returns - processed object for EDS components
   */
  private getSelectionItemList(
    item: CustomFormFieldDTO
  ): GroupItems[] | DropdownItem[] {
    const itemObject: GroupItems[] | DropdownItem[] = [];
    for (let i = 0; i < item.values.length; i++) {
      itemObject.push({
        id: item.values[i].replace(/\s+/g, '-').toLowerCase(),
        value: item.values[i],
        label: item.displayValues[i],
      });
    }
    return itemObject;
  }

  /**
   * Read the content from the document
   */
  public getContent() {
    return this.document?.getData();
  }

  /**
   * Read document
   */
  public getDocument() {
    let document = this.component.getModels<DocumentModels>().formDocument;
    if (!document) {
      document = this.component.getModels<DocumentModels>().document;
    }
    return document && this.page.getContent(document);
  }

  /**
   * Patch the value of the fieds into FormGroup
   * @param value - Value received from form fields
   * @param fieldBean - This object received from bloomreach which have defination of field
   */
  public setFieldValues(value: string, fieldBean: CustomFormFieldDTO): void {
    // Patch updated value to form object
    if (fieldBean.type === 'DATE_FIELD') {
      this.formDetails.patchValue({
        [fieldBean.fieldName]: getDate(
          value,
          fieldBean.dateformat.toUpperCase()
        ),
      });
    } else {
      this.formDetails.patchValue({
        [fieldBean.fieldName]: value,
      });
    }

    this.checkValidation(fieldBean);
  }

  /**
   * Check the validation of the field base on validation ruled
   * @param fieldBean - This object received from bloomreach which have defination of field
   */
  private checkValidation(fieldBean: CustomFormFieldDTO): void {
    if (this.formDetails.controls[fieldBean.fieldName].status === 'INVALID') {
      fieldBean.invalid = true;
      if (
        fieldBean.mandatory &&
        this.formDetails.controls[fieldBean.fieldName].value === ''
      ) {
        fieldBean.invalidText = this.getErrorMessage(
          'required',
          this.formDetails.controls[fieldBean.fieldName].errors
        );
      } else {
        if (fieldBean.validationRuleId) {
          fieldBean.invalidText = this.getErrorMessage(
            fieldBean.validationRuleId
          );
        }

        if (fieldBean.minLength > 0 || fieldBean.maxLength > 0) {
          fieldBean.invalidText = this.getErrorMessage(
            'length',
            this.formDetails.controls[fieldBean.fieldName].errors
          );
        }
      }
    } else {
      fieldBean.invalid = false;
      fieldBean.invalidText = '';
    }
  }

  /**
   * Get error messages based on validation rule
   * @param validationRule - Rule from resource api for field
   * @param errors - Error type object from angular reactive forms
   * @returns - Error message as a string
   */
  private getErrorMessage(validationRule: string, errors?: any): string {
    let errorMessage;
    switch (validationRule) {
      case 'email':
        errorMessage = this.translateService.instant(
          'customForm.invalid-email'
        );
        break;
      case 'number.general':
        errorMessage = this.translateService.instant(
          'customForm.invalid-number'
        );
        break;
      case 'number.percent':
        errorMessage = this.translateService.instant(
          'customForm.invalid-percent'
        );
        break;
      case 'string.alphanumeric':
        errorMessage = this.translateService.instant(
          'customForm.invalid-alphanumeric'
        );
        break;
      case 'website':
        errorMessage = this.translateService.instant(
          'customForm.invalid-website'
        );
        break;
      case 'custom.input':
        errorMessage = this.translateService.instant(
          'customForm.invalid-custom-input'
        );
        break;
      case 'length':
        if (errors?.minlength) {
          errorMessage = `${this.translateService.instant(
            'customForm.invalid-minlength'
          )} ${errors?.minlength?.requiredLength}`;
        } else {
          errorMessage = `${this.translateService.instant(
            'customForm.invalid-maxlength'
          )} ${errors?.maxlength?.requiredLength}`;
        }
        break;
      case 'required':
        if (errors.required) {
          errorMessage = this.translateService.instant(
            'customForm.invalid-required'
          );
        }
    }

    return errorMessage;
  }

  /**
   * Submit Form if its valid
   */
  public submitCustomForm(): void {
    if (this.formDetails.status === 'VALID') {
      this.isFormSubmitted = true;
      this.submitFormData$(this.formDetails.value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response) => {
            if (response.status === 200) {
              this.isMailSent = true;
              this.isErrorResponse = false;
              this.changeDetectorRef.detectChanges();
            }
          },
          error: (error) => {
            if (error.status === 400) {
              this.isErrorResponse = true;
              this.isFormSubmitted = false;
              this.changeDetectorRef.detectChanges();
            }
          },
        });
    }
  }

  /**
   * Submit form data to form API
   * @param payload Form data
   * @returns response from API as string
   */
  private submitFormData$(payload: any): Observable<FormApiRsponseDTO> {
    const loginHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'custom-form-name': this.formName,
        'resource-api-url': this.getResourceAPIUrl(),
        captcha_token: payload[this.recaptcha],
      }),
    };
    delete payload[this.recaptcha];
    return this.http.post(
      `${this.appStateService.getCustomFormEmailUrl()}?clientId=apim-apimdev-usw`,
      payload,
      loginHttpOptions
    );
  }

  /**
   * Get current page resource API Url
   * @returns resource API Url as string
   */
  private getResourceAPIUrl(): string {
    return this.page.toJSON()?.links?.self?.href;
  }

  /**
   * Handle captcha response (success/failed)
   * @param captchaResponse response
   */
  handleCaptcha(captchaResponse: string) {
    this.formDetails.patchValue({
      [this.recaptcha]: captchaResponse,
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
