import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import {
  FlexboxModule,
  GridModule,
  PaddingModule,
  SearchModule,
  SpacingModule,
  TitleModule,
  CardModule,
  WebinarModule,
} from '@frk/eds-components';
import { WebinarComponent } from './webinar.component';

@NgModule({
  declarations: [WebinarComponent],
  imports: [
    CommonModule,
    BrSdkModule,
    FlexboxModule,
    GridModule,
    PaddingModule,
    SearchModule,
    SpacingModule,
    TitleModule,
    CardModule,
    WebinarModule,
  ],
})
export class WebinarsModule {}
