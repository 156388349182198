import { Inject, Injectable } from '@angular/core';
import { LiteratureDocumentInfo, RecordInfo } from '@frk/eds-components';
import { LiteratureDataService } from '@literature/services/literature-data.service';
import { WINDOW } from '@ng-web-apis/common';
import { Observable } from 'rxjs';
import { IUserProfile, IUserProfileInfo } from '@services/profile.interface';
import {
  LiteratureDocument,
  LiteratureDocumentData,
  LiteratureDocumentListingData,
  LiteratureHistoryData,
  OrderHistoryDetail,
  OrderHistoryDocument,
} from '@types';
import { LiteratureGlobalConfigService } from '@literature/services/literature-global-config.service';
import { AppStateService, LiteratureFlow } from '@services/app-state.service';
import { LiteratureApiService } from '@literature/services/api-services/literature-api.service';
import { CartHandlerService } from '@literature/services/cart-handler.service';

@Injectable()
export class MyliteratureService {
  constructor(
    private literatureDataService: LiteratureDataService,
    @Inject(WINDOW) readonly windowRef: Window,
    private literatureConfig: LiteratureGlobalConfigService,
    private appStateService: AppStateService,
    private literatureApiService: LiteratureApiService,
    private cartHandlerService: CartHandlerService
  ) {}

  /**
   * Popuplate Litrature histrory by invoking web service
   * @param profileInfo loggedin user's info
   * @returns Observable of LiteratureHistoryData
   */
  public populateLiteratureHistory(
    profileInfo: IUserProfileInfo
  ): Observable<LiteratureHistoryData> {
    return this.literatureDataService.getLiteratureHistory(profileInfo);
  }
  /**
   * Convert @LiteratureHistoryData to array of @LiteratureDocumentInfo
   * @param data LiteratureHistoryData
   * @returns Array<LiteratureDocumentInfo>
   */
  public mapLiteratureHistory(
    data: LiteratureHistoryData,
    orderData?: OrderHistoryDetail,
    maximumRecords?: number
  ): Array<LiteratureDocumentInfo> {
    const litHists: Array<LiteratureDocumentInfo> = [];
    const viewedDoc: LiteratureDocumentInfo = {
      category: 'viewed',
      records: new Array<RecordInfo>(),
    };
    const recentDoc: LiteratureDocumentInfo = {
      category: 'ordered',
      records: new Array<RecordInfo>(),
    };
    if (data?.litHistDetail) {
      viewedDoc.records = this.getRecentTop(data.litHistDetail, maximumRecords);
    }
    if (orderData?.document) {
      recentDoc.records = this.getRecentOrderedDoc(
        orderData.document,
        maximumRecords
      );
    }
    litHists.push(viewedDoc);
    litHists.push(recentDoc);
    return litHists;
  }

  checkCartStatus(litCode: string): boolean {
    return this.cartHandlerService.checkCartStatus(litCode);
  }

  /**
   * Get Recent three literature records to display on Recently vewed tab
   * @param literatureData LiteratureDocumentData[]
   * @returns Array<RecordInfo>
   */
  private getRecentTop(
    literatureData: LiteratureDocumentData[],
    maximumRecords?: number
  ): Array<RecordInfo> {
    const records: Array<RecordInfo> = [];
    let noOfRecordRequired =
      literatureData.length > 3 ? 3 : literatureData.length;
    if (maximumRecords && literatureData.length > maximumRecords) {
      noOfRecordRequired = maximumRecords;
    }
    for (let index = 0; index < noOfRecordRequired; index++) {
      const detailData = this.literatureDataService.literatureMapper(
        literatureData[index]
      );
      this.literatureDataService.mapLiteratureUrl(detailData);
      records.push({
        type: detailData.fileExtension,
        name: detailData.fundName,
        url: `${this.literatureConfig.navigationUrls?.litDetailUrl}/${detailData.docId}`,
        docId: detailData.docId,
        imgSrc: detailData.imageUrl,
        downloadUrl: detailData.pdfDownloadLink,
        added: this.checkCartStatus(detailData.docId),
      });
    }
    return records;
  }
  /**
   *
   * @param data - order history documents
   * @param maximumRecords - number of records required
   * @returns - returns all documents ordered recently
   */
  getRecentOrderedDoc(
    data: OrderHistoryDocument[],
    maximumRecords?: number
  ): Array<RecordInfo> {
    const records: Array<RecordInfo> = [];
    let noOfRecordRequired = data.length > 3 ? 3 : data.length;
    if (maximumRecords && data.length > maximumRecords) {
      noOfRecordRequired = maximumRecords;
    }
    for (let index = 0; index < noOfRecordRequired; index++) {
      this.literatureDataService.mapLiteratureUrl(data[index]);
      records.push({
        type: data[index].fileExtension,
        name: data[index].fundName,
        url: `${this.literatureConfig.navigationUrls?.litDetailUrl}/${data[index].literatureCode}`,
        docId: data[index].literatureCode,
        imgSrc: data[index].imageUrl,
        downloadUrl: data[index].pdfDownloadLink,
        added: this.checkCartStatus(data[index].literatureCode),
      });
    }
    return records;
  }

  /**
   * Convert orderd date string to date
   * @param date input string date
   * @returns formatted date
   */
  private process(date: string): Date {
    const parts = date.split('/');
    return new Date(parseInt(parts[1], 10), parseInt(parts[0], 10) - 1);
  }

  /**
   * Open new tab to download document
   * @param url download link
   */
  public downloadDocument(url: string): void {
    this.windowRef.open(url, '_blank');
  }

  /**
   * Navigate to history page on click of See All button
   * @param url navigation url
   */
  public navigateToUrl(url: string): void {
    this.literatureConfig.navigateToUrl(url);
  }

  /**
   * Get Selected literature document for add to cart
   * @param literatureHistoryData LiteratureHistoryData
   * @param recordInfo RecordInfo
   * @returns LiteratureDocument
   */
  public getLiteratureDocument(
    literatureHistoryData: LiteratureHistoryData,
    data: OrderHistoryDocument[],
    recordInfo: RecordInfo
  ): LiteratureDocument {
    const litDoc: LiteratureDocumentData = literatureHistoryData.litHistDetail.find(
      (el) => {
        return (
          recordInfo.docId === el.literatureCode &&
          recordInfo.name === el.fundName
        );
      }
    );
    if (litDoc) {
      const detailData = this.literatureDataService.literatureMapper(litDoc);
      this.literatureDataService.mapLiteratureUrl(detailData);
      return detailData;
    }
    return this.getOrderHistoryLiteratureDocument(data, recordInfo);
  }

  /**
   * Gets order history of literature document
   * @param data - OrderHistoryDocument
   * @param recordInfo -RecordInfo
   * @returns -LiteratureDocument
   */
  public getOrderHistoryLiteratureDocument(
    data: OrderHistoryDocument[],
    recordInfo: RecordInfo
  ): LiteratureDocument {
    const litDoc: OrderHistoryDetail = data.find((el) => {
      return (
        recordInfo.docId === el.literatureCode &&
        recordInfo.name === el.fundName
      );
    });
    if (litDoc) {
      this.literatureDataService.mapLiteratureUrl(litDoc);
      return litDoc;
    }
    return;
  }

  /**
   * Get Link to orderHistoryList url from site config
   * @returns return link from site config
   */
  public getLink(): string {
    return `${this.literatureConfig.navigationUrls?.orderHistoryList}`;
  }

  /**
   * map from LiteratureDocumentData to LiteratureDocumentListingData for my literature
   * @param litHistDetail LiteratureDocumentData[]
   * @returns LiteratureDocumentListingData[]
   */
  public mapToLiteratureDocumentListingData(
    litHistDetail: LiteratureDocumentData[]
  ): LiteratureDocumentListingData[] {
    return this.literatureDataService.mapToLiteratureDocument(litHistDetail);
  }

  /**
   * delete document
   * @param profileInfo loggedin user's info
   * @param expressNum document express nummber
   * @returns Observable of LiteratureHistoryData
   */
  public deleteLiteratureDocument(
    profileInfo: IUserProfileInfo,
    litCode: string
  ): Observable<any> {
    return this.literatureDataService.deleteLiteratureDocument(
      profileInfo,
      litCode
    );
  }

  getRecentOrderHistoryList(
    profileInfo: IUserProfileInfo
  ): Observable<OrderHistoryDetail> {
    const profile = this.literatureDataService.literatureUserProfileMapper(
      profileInfo
    );
    if (
      this.appStateService.getUseAPIM(LiteratureFlow.RECENT_ORDER_HISTORY_LIST)
    ) {
      return this.literatureApiService.getRecentOrderHistoryListAPIMFlow(
        this.getRecentOrderHistoryListAPIMUrl(profileInfo.userSysNo),
        profile
      );
    } else {
      return this.literatureApiService.getRecentOrderHistoryList(
        this.appStateService.getRecentOrderHistoryListUrl(),
        profile
      );
    }
  }

  getProfileData$(): Observable<IUserProfile> {
    return this.literatureDataService.getProfileData$();
  }

  getRecentOrderHistoryListAPIMUrl(userSysNo: string): string {
    return this.appStateService.getRecentOrderAPIMUrl() + `/${userSysNo}/order`;
  }
}
