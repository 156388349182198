import {
  GridModule,
  SectionTitleModule,
  SpacingModule,
  TitleModule,
} from '@frk/eds-components';

import { BrSdkModule } from '@bloomreach/ng-sdk';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TitleBlockComponent } from './title-block.component';

@NgModule({
  declarations: [TitleBlockComponent],
  imports: [
    BrSdkModule,
    CommonModule,
    GridModule,
    SpacingModule,
    TitleModule,
    SectionTitleModule,
  ],
  exports: [TitleBlockComponent],
  entryComponents: [TitleBlockComponent],
})

/**
 * Image Block Module
 */
export class TitleBlockModule {}
