import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { SignInService } from '@services';

@Component({
  selector: 'ft-auth0-sign-up',
  template: '',
})
export class Auth0SignUpComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) readonly documentRef: Document,
    private signInService: SignInService
  ) {}

  ngOnInit(): void {
    const previousPageUri = this.documentRef.referrer;
    this.signInService.submitAuth0('signup', previousPageUri);
  }
}
