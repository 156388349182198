import { Injectable, OnDestroy } from '@angular/core';
import { HighchartsThemeService, PagedRecords } from '@frk/eds-components';
import { ProfileService } from '@services';
import { TranslateService } from '@shared/translate/translate.service';
import {
  CurrencyCode,
  FundPerformanceCalendarYearState,
  FundPerformanceMonthlyTotalReturnsAnnualData,
  FundPerformanceMonthlyTotalReturnsCardData,
  FundPerformanceMonthlyTotalReturnsState,
  FundPerformanceMonthlyTotalReturnsStateData,
  FundPerformanceMonthlyTotalReturnsTableData,
  ProductDetailConfiguration,
} from '@types';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import cumulativeChartDataQuery from '@graphql/monthly-total-returns/monthly-total-returns.graphql';
import { BaseFundPerformanceService } from './base-fund-performance.service';
import moment from 'moment';
import { EMDASH } from '@products/utils/constants/product.constants';
import { FundPerformanceHistoryService } from '@products/services/fund-performance-history.service';
import { PerformanceHistory } from '@products/models/performance-history';
import { getHighchartsLocaleFormat } from '@utils/text/date-utils';
import { SeriesOptionsType } from 'highcharts';
import { parseNumber } from '@utils/text/number-utils';
import { Logger } from '@utils/logger';
import { FundPerformanceCalendarYearService } from './fund-performance-calendar-year.service';
import { FundOverviewService } from '@products/services/fund-overview.service';
import { Product, ShareClass } from '@models';
import { symbols } from '@utils/il8n/currency';

const logger = Logger.getLogger('FundPerformanceMonthlyTotalReturnsService');

@Injectable({
  providedIn: 'root',
})
export class FundPerformanceMonthlyTotalReturnsService
  extends BaseFundPerformanceService
  implements OnDestroy {
  private state: FundPerformanceMonthlyTotalReturnsState = {};
  private fundPerformanceStateSubject$: Subject<any>;
  private dataSubscription: Subscription[] = [];
  public currentAsOfDate: string;

  // pagination helpers
  protected pagedRecords: PagedRecords<FundPerformanceMonthlyTotalReturnsAnnualData>;
  // default pageSize for table
  public pageSize = 10;
  public currencyCode: CurrencyCode;

  constructor(
    private fundPerformanceHistoryService: FundPerformanceHistoryService,
    private fundPerformanceService: FundPerformanceCalendarYearService,
    private fundOverviewService: FundOverviewService,
    translateService: TranslateService,
    protected highchartsTheme: HighchartsThemeService,
    protected profileService: ProfileService
  ) {
    super(translateService, highchartsTheme, profileService);
    this.pagedRecords = new PagedRecords<FundPerformanceMonthlyTotalReturnsAnnualData>(
      this.pageSize
    ); // Page size set to '10', ref. by prod site.
    this.fundPerformanceStateSubject$ = new Subject();
  }

  /**
   * Triggers and replicated changes on component that are made to state in service.
   */
  public get fundPerformanceState$(): Observable<FundPerformanceMonthlyTotalReturnsState> {
    return this.fundPerformanceStateSubject$.asObservable();
  }

  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    combineLatest([
      this.fundPerformanceHistoryService.register(cumulativeChartDataQuery, {
        fundId: productDetailConfiguration.fundId,
        shareClassCode: productDetailConfiguration.shareClassCode,
      }),
      this.fundPerformanceService.fundPerformanceState$,
      this.fundOverviewService.register(cumulativeChartDataQuery, {
        fundId: productDetailConfiguration.fundId,
        shareClassCode: productDetailConfiguration.shareClassCode,
      }),
    ]).subscribe(
      ([perfHistorytData, cumulativeYtdData, overviewData]: [
        PerformanceHistory,
        FundPerformanceCalendarYearState,
        Product
      ]) => {
        this.mapState(perfHistorytData, cumulativeYtdData, overviewData);
        this.fundPerformanceStateSubject$.next(this.state);
      }
    );
  }

  private mapYtdData(
    ytdData: FundPerformanceCalendarYearState,
    overviewData: Product
  ): void {
    const years: Array<string> = [];
    const yearToDateReturns: Array<string> = [];
    // WDE-5208 : Product - CPREIF Share Class T 2023 YTD Wrong Mapping
    // PDS data is random, sometimes we recieve market_price first and NAV data after that or
    // vice versa. To eliminate the possibility of selecting market_price data, adding a check
    // on NAV data.
    let currentYTD: string;
    overviewData?.shareClasses[0]?.performance?.monthEnd.forEach((returns) => {
      if (returns?.calcTypeStd === 'NAV') {
        currentYTD = returns?.cummulativeReturnYearToDate;
      }
    });
    this.state.data.annualData[0].ytd = currentYTD;
    if (ytdData?.data?.monthEnd) {
      ytdData?.data?.monthEnd?.years.forEach((year) => {
        years.push(year);
      });

      const rawYearlyData = ytdData?.data?.monthEnd?.graphData[0]?.data;

      rawYearlyData.forEach((yearlyReturns) => {
        yearToDateReturns.push(yearlyReturns?.y);
      });
    }

    for (let i = 1; i < yearToDateReturns.length + 1; i++) {
      this.state.data.annualData[i].ytd = yearToDateReturns[i - 1].toString();
    }
  }

  /**
   * create cards for mobile/handheld device display.
   */
  public createCards(): void {
    if (this.state.data.annualData.length) {
      let card: FundPerformanceMonthlyTotalReturnsCardData[] = [];
      this.state.data.annualData.forEach((yearlyData) => {
        card = [
          {
            // change this to dynamic accept different values
            label: this.state?.data?.shareClass,
            val: yearlyData?.year.toString(),
          },
          {
            label: yearlyData?.monthlyReturns[0]?.month,
            val: yearlyData?.monthlyReturns[0]?.returns,
          },
          {
            label: yearlyData?.monthlyReturns[1]?.month,
            val: yearlyData?.monthlyReturns[1]?.returns,
          },
          {
            label: yearlyData?.monthlyReturns[2]?.month,
            val: yearlyData?.monthlyReturns[2]?.returns,
          },
          {
            label: yearlyData?.monthlyReturns[3]?.month,
            val: yearlyData?.monthlyReturns[3]?.returns,
          },
          {
            label: yearlyData?.monthlyReturns[4]?.month,
            val: yearlyData?.monthlyReturns[4]?.returns,
          },
          {
            label: yearlyData?.monthlyReturns[5]?.month,
            val: yearlyData?.monthlyReturns[5]?.returns,
          },
          {
            label: yearlyData?.monthlyReturns[6]?.month,
            val: yearlyData?.monthlyReturns[6]?.returns,
          },
          {
            label: yearlyData?.monthlyReturns[7]?.month,
            val: yearlyData?.monthlyReturns[7]?.returns,
          },
          {
            label: yearlyData?.monthlyReturns[8]?.month,
            val: yearlyData?.monthlyReturns[8]?.returns,
          },
          {
            label: yearlyData?.monthlyReturns[9]?.month,
            val: yearlyData?.monthlyReturns[9]?.returns,
          },
          {
            label: yearlyData?.monthlyReturns[10]?.month,
            val: yearlyData?.monthlyReturns[10]?.returns,
          },
          {
            label: yearlyData?.monthlyReturns[11]?.month,
            val: yearlyData?.monthlyReturns[11]?.returns,
          },
          {
            label: this.translateService.instant('products.yearToDate'),
            val: yearlyData?.ytd ? yearlyData?.ytd : EMDASH,
          },
        ];

        // Assigned card as a state variable.
        yearlyData.cards = card;
      });
    }
  }

  /**
   * Map the fund performance response.
   * @param product received product performance details
   */
  private mapState(
    historyData: PerformanceHistory,
    cumulativeYtdData: FundPerformanceCalendarYearState,
    overviewData: Product
  ): void {
    const shareClassDetail: ShareClass = overviewData.shareClasses[0];
    this.currencyCode = shareClassDetail.shareClassCurrency;
    this.state.data = this.mapMonthlyReturnsToState(historyData, overviewData);
    this.state.data.shareClass = shareClassDetail.shareClassName;
    this.state.data.currentAsOfDate = this.currentAsOfDate;
    this.state.data.annualData[0].ytd = EMDASH;
    this.state.data.annualData[
      this.state.data.annualData.length - 1
    ].ytd = EMDASH;
    if (cumulativeYtdData) {
      this.mapYtdData(cumulativeYtdData, overviewData);
    }

    this.createCards();
  }

  private mapMonthlyReturnsToState(
    rawData: PerformanceHistory,
    overviewData: Product
  ) {
    const rawTable = new Map();
    let inceptionYear: number;
    let currentYear: number = Number(moment().year());
    const fundData = rawData?.cumulative?._10k?.sinceInception?.fund;
    if (rawData?.cumulative?._10k?.sinceInception?.fund) {
      // fetching inceptionYear as per PDS first year asOfDate
      inceptionYear = Number(
        moment(
          rawData?.cumulative?._10k?.sinceInception?.fund[0]?.asOfDate
        ).format('YYYY')
      );
      // fetching currentyear as per PDS last year asOfDate if fund data is available.
      currentYear = Number(
        moment(fundData[fundData.length - 1].asOfDate).format('YYYY')
      );
    }

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    if (rawData?.cumulative?._10k?.sinceInception?.fund) {
      rawData?.cumulative?._10k?.sinceInception?.fund.forEach((fund) => {
        if (fund?.cumulativeMonthlyReturnCalculatedStd && fund?.asOfDateStd) {
          rawTable.set(
            moment(fund?.asOfDate).format('YYYY') +
              moment(fund.asOfDate).format('MMM'),
            {
              returns: fund?.cumulativeMonthlyReturnCalculatedStd,
              cumulative10kReturns: fund?.cumulativeMonthlyReturnCalculated,
              date: fund?.asOfDateStd,
            }
          );
        }
      });
    }

    const rawAnnualData: FundPerformanceMonthlyTotalReturnsAnnualData[] = [];
    for (let i = inceptionYear; i <= currentYear; i++) {
      const singleAnnualData: FundPerformanceMonthlyTotalReturnsAnnualData = {};
      singleAnnualData.year = i;
      const allMonthData: FundPerformanceMonthlyTotalReturnsTableData[] = [];
      months.forEach((month) => {
        const singleMonthData: FundPerformanceMonthlyTotalReturnsTableData = {};
        if (rawTable.get(i.toString() + month)) {
          singleMonthData.month = month;
          singleMonthData.returns = parseFloat(
            rawTable.get(i.toString() + month).returns
          ).toFixed(2);
          singleMonthData.asOfDateStd = rawTable.get(i.toString() + month).date;
          const filteredString = rawTable
            .get(i.toString() + month)
            .cumulative10kReturns.replace(',', '');
          const parsedString = parseFloat(filteredString).toFixed(2);
          singleMonthData.cumulativeMonthlyReturnCalculated = parsedString;
          singleMonthData.year = i;
        } else {
          singleMonthData.month = month;
          singleMonthData.returns = EMDASH;
          singleMonthData.asOfDateStd = EMDASH;
          singleMonthData.cumulativeMonthlyReturnCalculated = EMDASH;
          singleMonthData.year = i;
        }
        allMonthData.push(singleMonthData);
      });
      if (overviewData) {
        // WDE-5208 : Product - CPREIF Share Class T 2023 YTD Wrong Mapping
        // PDS data is random, sometimes we recieve market_price first and NAV data after that or
        // vice versa. To eliminate the possibility of selecting market_price data, adding a check
        // on NAV data.
        let previousMonthReturns: string;
        overviewData?.shareClasses[0]?.performance?.monthEnd.forEach(
          (returns) => {
            if (returns?.calcTypeStd === 'NAV') {
              previousMonthReturns = returns?.cummulativeReturn1Month;
            }
          }
        );

        const asOfDate: string =
          overviewData?.shareClasses[0]?.performance?.monthEnd[1]
            ?.performanceAsOfDate;
        rawTable.set(
          moment(asOfDate).format('YYYY') + moment(asOfDate).format('MMM'),
          {
            returns: previousMonthReturns,
            cumulative10kReturns: EMDASH,
            date: asOfDate,
          }
        );

        this.currentAsOfDate = asOfDate;
      }
      singleAnnualData.monthlyReturns = allMonthData;
      rawAnnualData.push(singleAnnualData);
    }

    const rawTableData: FundPerformanceMonthlyTotalReturnsStateData = {};
    // We need to display table data going from current date -> since inception.
    const reversedTableData = rawAnnualData.slice().reverse();
    rawTableData.annualData = reversedTableData;
    // Highcharts option
    const highChartOptions = this.fetchChartOptions();
    highChartOptions.series = this.fetchMonthlyTotalReturnsSeries(
      rawAnnualData
    );
    this.state.highChartOptions = highChartOptions;

    // pagination events
    this.pagedRecords.records = reversedTableData;
    this.pagedRecords.pageNumber = 1; // post new selection, set page number to 1.
    return rawTableData;
  }

  /**
   * Fetch initial chart options.
   */
  private fetchChartOptions(): Highcharts.Options {
    return {
      title: {
        text: undefined,
      },
      chart: {
        spacingLeft: 5,
        spacingRight: 0,
        marginRight: 20,
        events: {
          render(this) {
            const that = this;
            setTimeout(() => {
              that.reflow();
            }, 0);
          },
        },
        reflow: true,
      },
      xAxis: {
        type: 'datetime',
        gridLineDashStyle: 'LongDash',
        // COREWEB-2921 increase tickPixelInterval from default 100, to force extra width for labels
        // NB: new value found by trial and error, and doesn't seem to reflect the actual pixel width
        tickPixelInterval: 110,
        // WORKAROUND: COREWEB-1543 set hour/minute/second to day display
        dateTimeLabelFormats: {
          millisecond: '%e. %b',
          second: '%e. %b',
          minute: '%e. %b',
          hour: '%e. %b',
        },
      },
      yAxis: [
        {
          gridLineDashStyle: 'LongDash',
          labels: {
            format: `${symbols.get(this.currencyCode)}{value}`,
          },
          title: {
            text: null,
          },
          minorTickInterval: 'auto',
        },
      ],
      legend: {
        enabled: true,
        verticalAlign: 'bottom',
      },
      tooltip: {
        shared: true,
        useHTML: true,
        // COREWEB-1277 set to day precision
        // http://api.highcharts.com/highcharts/tooltip.dateTimeLabelFormats
        xDateFormat: getHighchartsLocaleFormat(),
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
        line: {
          marker: {
            enabled: false,
          },
        },
      },
    };
  }

  /**
   *
   * @param FundPerformanceMonthlyTotalReturnsAnnualData performance Monthly returns data
   * @returns SeriesOptionsType
   */
  private fetchMonthlyTotalReturnsSeries(
    fundPerformanceMonthlyTotalReturns: FundPerformanceMonthlyTotalReturnsAnnualData[]
  ): SeriesOptionsType[] {
    const PerformanceMonthlyTotalReturnsValues = this.convertPerformanceMonthlyTotalReturnsIntoSeries(
      fundPerformanceMonthlyTotalReturns
    );
    const label = this.getMonthlyTotalReturnsPerShareLabel();
    const MonthlyTotalReturnSeries: SeriesOptionsType = {
      name: label,
      type: 'line',
      tooltip: {
        pointFormat: `
            <span style='font-size:14px;color:{series.color}'>\u25CF</span>
            <span>{series.name}: </span>
            <b>
            ${symbols.get(this.currencyCode)}
            {point.y}</b>
          `,
      },
      data: PerformanceMonthlyTotalReturnsValues,
    };

    return [MonthlyTotalReturnSeries];
  }

  /**
   * Get label for MonthlyTotalReturnsPerShare
   * @returns label
   */
  private getMonthlyTotalReturnsPerShareLabel(): string {
    return this.translateService.instant(
      'products.performance-monthly-total-returns'
    );
  }

  private convertPerformanceMonthlyTotalReturnsIntoSeries(
    fundPerformanceMonthlyTotalReturns: FundPerformanceMonthlyTotalReturnsAnnualData[]
  ) {
    const monthlyTotalReturnsValue = [];

    if (fundPerformanceMonthlyTotalReturns) {
      const data = fundPerformanceMonthlyTotalReturns;
      for (const yearData of data) {
        for (const monthData of yearData.monthlyReturns) {
          if (monthData.returns === EMDASH || monthData.returns === null) {
            continue; // skip this iteration if returns is '-' or null
          }
          const returns = monthData.cumulativeMonthlyReturnCalculated;
          let ts: number = null;
          if (monthData.asOfDateStd) {
            ts = this.formatStdDateToUnixTimeStamp(monthData.asOfDateStd);
          }

          let returnVal: number = null;
          returnVal = parseNumber(returns);
          if (
            (returnVal || returnVal === 0) &&
            !isNaN(returnVal) &&
            ts !== null
          ) {
            monthlyTotalReturnsValue.push([ts, returnVal]);
          }
        }
      }
    }
    return monthlyTotalReturnsValue;
  }

  /**
   * Return UTC standard unix timestamp, when given std format day eg YYYY-MM-DD
   * @param val date in format locale or YYYY-MM-DD
   * @returns UTC unix timestamp
   */
  private formatStdDateToUnixTimeStamp(val) {
    if (!val || val === '-') {
      return null;
    }

    const stdDateFormatMatch = val.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})/);
    if (!stdDateFormatMatch) {
      return null;
    }

    const year = stdDateFormatMatch[1];
    const month = stdDateFormatMatch[2] - 1;
    const day = stdDateFormatMatch[3];
    const date = Date.UTC(year, month, day, 0, 0);

    return date.valueOf();
  }

  /* Update toggle
   * @param selectedToggle selected toggle
   */
  public updateToggle(selectedToggle: string) {
    this.state.selectedTab = selectedToggle;
    this.fundPerformanceStateSubject$.next(this.state);
  }

  /**
   * ******************** Pagination helper methods ********************
   */

  /**
   * Return total number of pages as observable.
   */
  public getNumberOfPages$(): Observable<number> {
    return this.pagedRecords.numberOfPages$;
  }

  /**
   * Return current page records as observable.
   */
  public getCurrentPage$(): Observable<
    FundPerformanceMonthlyTotalReturnsAnnualData[]
  > {
    return this.pagedRecords.currentPage$;
  }

  /**
   * Trigger page number selection accordingly.
   * @param n page number selected
   */
  public goToPage(n: number): void {
    if (this.pagedRecords) {
      this.pagedRecords.pageNumber = n;
    }
  }

  ngOnDestroy(): void {
    this.dataSubscription.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
