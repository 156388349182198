import dayjs from 'dayjs';
import zhHkConfig from 'dayjs/locale/zh-hk';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

dayjs.locale('zh-hk', { ...zhHkConfig });
export const customZhHkConfig = dayjs.updateLocale('zh-hk', {
  ...zhHkConfig,
  formats: {
    ...zhHkConfig.formats,
    L: 'DD/MM/YYYY',
  },
});
