import { Component, Content, Menu, Page } from '@bloomreach/spa-sdk';

export function getMenus(
  page: Page,
  component: Component,
  name = 'menu'
): Menu {
  return (getModelFromRef(page, component, name) as any)?.model?.data as Menu;
}

export function getModelFromRef(
  page: Page,
  component: Component,
  name: string
): Content {
  const ref = component.getModels() ? component.getModels()[name] : null;
  return ref ? page.getContent(ref) : null;
}

export function getPagination(
  page: Page,
  component: Component,
  name = 'pagination'
) {
  return (getModelFromRef(page, component, name) as any)?.model;
}
