<div [edsSpacing]="spacingBottom">
  <div edsGrid>
    <div class="container">
      <div edsRow *ngIf="content?.cardCompound?.length > 0">
        <div edsCol>
          <ng-container [ngSwitch]="content?.sectionType">
            <ng-template ngSwitchCase="media_cards_list">
              <eds-media-card
                *ngFor="let card of cards; let last = last"
                [title]="card.title"
                [bodyContent]="card.content"
                [isIcon]="card.isIcon"
                [isStacked]="false"
                [imgSrc]="card.imageSrc"
                [linkText]="card.link?.displayText"
                [url]="card.href"
                [isNewWindow]="card.link?.target === '_blank'"
                [externalLink]="card.link?.external"
                [hasMediaLeft]="!!card.imageSrc"
                [class.spacing--md]="'isStacked' && !last"
                [externalSuffix]="card.link?.externalSuffix"
                [signInRequired]="
                  card.link?.signInLink | ftIsSignInRequired | async
                "
                [hideLockIcon]="card.link?.hideLockIcon"
                [signInTooltip]="'common.locked-link-tooltip' | translate"
                [widenParams]="card.widenParams"
              >
              </eds-media-card>
            </ng-template>
            <ng-template ngSwitchCase="media_cards">
              <!--default for all cards 1|1 or 1|1|1-->
              <div>
                <div
                  edsRow
                  *ngIf="
                    columns === 2 || columns === 4;
                    then subGridLayout1;
                    else subGridLayout
                  "
                ></div>

                <!--layout 1|1-->
                <ng-template #subGridLayout1>
                  <div
                    edsSubGrid="layout-1"
                    edsSubGridTabletGap="lg"
                    edsSubGridTablet="layout-1"
                    edsSubGridGap="lg"
                    edsSubGridRowGap="xl"
                  >
                    <eds-media-card
                      *ngFor="let card of content?.cardCompound"
                      [title]="card.title"
                      [bodyContent]="card.content"
                      [isIcon]="card.isIcon"
                      [isStacked]="true"
                      [imgSrc]="card.imageSrc"
                      [linkText]="card.link?.displayText"
                      [url]="card.link?.url"
                      [isNewWindow]="card.link?.target === '_blank'"
                      [externalLink]="card.link?.external"
                      [hasMediaLeft]="!!card.imageSrc"
                      [externalSuffix]="card.link?.externalSuffix"
                      [signInRequired]="
                        card.link?.signInLink | ftIsSignInRequired | async
                      "
                      [hideLockIcon]="card.link?.hideLockIcon"
                      [signInTooltip]="'common.locked-link-tooltip' | translate"
                      [widenParams]="card.widenParams"
                    >
                    </eds-media-card>
                  </div>
                </ng-template>
                <!--end layout 1|1-->

                <!--layout 1|1|1-->
                <ng-template #subGridLayout>
                  <div
                    edsSubGrid="layout-2"
                    edsSubGridTabletGap="lg"
                    edsSubGridTablet="layout-1"
                    edsSubGridGap="lg"
                    edsSubGridRowGap="xl"
                  >
                    <eds-media-card
                      *ngFor="let card of content?.cardCompound"
                      [title]="card.title"
                      [bodyContent]="card.content"
                      [isIcon]="card.isIcon"
                      [isStacked]="true"
                      [imgSrc]="card.imageSrc"
                      [linkText]="card.link?.displayText"
                      [url]="card.link?.url"
                      [isNewWindow]="card.link?.target === '_blank'"
                      [externalLink]="card.link?.external"
                      [hasMediaLeft]="!!card.imageSrc"
                      [externalSuffix]="card.link?.externalSuffix"
                      [signInRequired]="
                        card.link?.signInLink | ftIsSignInRequired | async
                      "
                      [hideLockIcon]="card.link?.hideLockIcon"
                      [signInTooltip]="'common.locked-link-tooltip' | translate"
                      [widenParams]="card.widenParams"
                    >
                    </eds-media-card>
                  </div>
                </ng-template>
                <!--end layout 1|1|1-->
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
