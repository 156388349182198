<!-- New create/edit button -->
<div
  [ngClass]="{ 'has-edit-button': page?.isPreview() }"
  class="sales-team--full-height"
>
  <!-- edit button -->
  <ng-container
    *ngIf="page?.isPreview()"
    [brManageContentButton]="document"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>

  <eds-card-dashboard
    *ngIf="showSalesTeamCard"
    (btnSubscribe)="bookPhoneConsultation()"
    [card]="salesTeamCard"
    [fullHeight]="true"
  >
    <eds-avatar
      *ngFor="let salesTeam of salesTeamList"
      [title]="salesTeam.name"
      [text1]="salesTeam.role"
      [text2]="salesTeam.telephone"
      [imgSrc]="salesTeam.profileImage"
      fallbackImgSrc="/assets/images/ben_head.png"
      edsSpacing="lg"
    >
    </eds-avatar>
  </eds-card-dashboard>

  <!-- No Document -->
  <div *ngIf="!showSalesTeamCard && page?.isPreview()" class="no-document">
    <div edsGrid class="no-document__component">
      <div class="no-document__overlay">
        Sales Team Widget is available for logged in user with FP role.
      </div>
    </div>
  </div>
</div>
