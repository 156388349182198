import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Directive,
  Inject,
  OnDestroy,
} from '@angular/core';
import { AnalyticsService } from '@frk/eds-components';
import { WindowScrollService } from '@services';
import { Logger } from '@utils/logger';
import { Observable, Subscription, timer } from 'rxjs';

const logger = Logger.getLogger('PageEndDirective');

// TODO: is this Directive still used? Can't find references to it
@Directive({
  selector: '[ftPageEnd]',
})
export class PageEndDirective
  implements AfterContentInit, AfterViewInit, AfterViewChecked, OnDestroy {
  /**
   * Analytics file conversion flag
   */
  private fileConversion: boolean;
  private timeCounter: number;
  private subscription$: Subscription;

  constructor(
    private analytics: AnalyticsService,
    @Inject(DOCUMENT) private documentRef: Document,
    private scrollService: WindowScrollService
  ) {
    this.fileConversion = false;
    this.timeCounter = 0;
  }

  ngAfterViewInit(): void {
    this.scrollService.scrollToTop();
    // // TODO: this if(...) condition is pointless, as scrollTo(0, 0) anyway ???
    // if (this.windowRef.pageYOffset > 0) {
    //   this.windowRef.scrollTo(0, 0);
    // }
    // this.windowRef.scrollTo(0, 0);
  }

  ngAfterContentInit(): void {
    const windowHeight: number = this.documentRef.documentElement.clientHeight;
    const scrollHeight: number = this.documentRef.documentElement.scrollHeight;

    if (windowHeight >= scrollHeight) {
      // According to requirement from NGC-3116 we need to fire all scroll_deep one by one when page have no scroll
      const analyticTracking: Array<number> = [25, 50, 75, 100];
      this.fireAnalytics(analyticTracking);
    }

    // Start watching user time on the page;
    // timePeriod in seconds
    const timePeriod = 30;
    const interval$: Observable<number> = timer(
      timePeriod * 1000,
      timePeriod * 1000
    );
    this.subscription$ = interval$.subscribe(() => {
      this.timeCounter = this.timeCounter + timePeriod;
      const time = this.timeConvert(this.timeCounter);
      this.analytics.trackEvent({
        event: 'timer',
        increment: this.timeCounter < 60 ? 'seconds' : 'minutes',
        action: time,
      });
    });
  }

  ngAfterViewChecked(): void {
    if (!this.fileConversion) {
      this.fileConversion = this.trackDownloadExists(this.documentRef);
    }
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  /**
   * Returns converted time string
   * @param seconds - number of secunds
   */
  private timeConvert(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    if (minutes < 1) {
      return seconds + 's';
    }
    return minutes + ':' + (seconds - minutes * 60) + 'm';
  }

  /**
   * Fires analytics with numbers defined in array
   * @param analyticTracking - array with tracking percentage #
   */
  private fireAnalytics(analyticTracking: Array<number>): void {
    analyticTracking.forEach((scroll) => {
      return this.analytics.trackEvent({
        event: 'scroll_depth',
        action: `${scroll}%`,
        detailed_event: 'Scroll Depth Tracking',
        event_data: {
          milestone: `${scroll}%`,
        },
      });
    });
  }

  /**
   * Fires analytics if download object exists on page
   * @param document - HTMLDocument
   */
  private trackDownloadExists(document: HTMLDocument): boolean {
    // Checking if content contains 'ft-document-download' elements
    const ftDocDownload: HTMLCollection = this.documentRef.getElementsByTagName(
      'ft-document-download'
    );
    // Checking if content contains 'ft-fund-document-cell-renderer' elements
    const ftFundDocumentCell: HTMLCollection = this.documentRef.getElementsByTagName(
      'ft-fund-document-cell-renderer'
    );

    // set to true if any component has data-analytics-track-impression="true"
    const pageComponentHasImpressionTrackingTag: boolean =
      this.documentRef.querySelector(
        '[data-analytics-track-impression="true"]'
      ) !== null;

    // This covers PDF files extensions. It might be extended for other files eg. XLS, XSLS, DOC, DOCX
    if (
      ftDocDownload.length > 0 ||
      ftFundDocumentCell.length > 0 ||
      pageComponentHasImpressionTrackingTag
    ) {
      this.analytics.trackEvent({
        event: 'file_conversion',
        interaction_type: 'impression',
        file_extension: 'pdf',
      });
      return true;
    }
    return false;
  }
}
