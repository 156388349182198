import { HttpParams } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import {
  FundId,
  FundIdentifier,
  Ticker,
  ShareClassCode,
  ShareClassName,
} from '@types';

export interface FullResultsService {
  results: FtSearchResponse[];
}

export const SEARCH_RESULTS = new InjectionToken<FullResultsService>(
  'search.full-results-common'
);

export interface TabsLabels {
  all: string;
  funds: string;
  literature: string;
  pages: string;
}
/**
 *
 */
export interface BucketItem {
  key: string;
  doc_count: number;
  collapsed_doc_count?: {
    value: number;
  };
}

export interface FilterItem {
  label: string;
  count: number;
  checked: boolean;
  value: string;
}
/**
 *
 */
export interface Aggregation {
  literature_lit_content_type: {
    buckets: BucketItem[];
  };
  funds_assetClass: {
    buckets: BucketItem[];
  };
  // TODO - provide complete list of available aggregations
}

/**
 *
 */
export interface Aggregations {
  aggregations: Aggregation;
  secondary?: Aggregation;
}

/**
 *
 */
export interface ExactMatch {
  data: ExactMatchDataNode[];
  type?: string;
  name?: string;
}

export interface FundAlert {
  segments: Array<string>;
  ppssMessage: string;
  ppssTooltip: string;
  priority: number;
}

export interface ExactMatchDataNode {
  name?: string; // this is for instant results
  response: ExactMatchFundResponse | ExactMatchDocumentsResponse;
  hits?: ExactMatchGeneralData[];
}

export interface ExactMatchGeneralDataNode {
  hits: ExactMatchGeneralData[];
  name?: string; // this is for instant results
}
export interface ExactMatchGeneralData {
  columnCombos: string[];
  richText: string;
  title: string;
  terms: string[];
  fundsViewAllLink: string;
  pagesViewAllurls: string;
  relatedFunds: FundDataItem[];
  relatedPages: FundThumbnailItem[];
  quickLinks: QuickLinkItem[];
}

/**
 * this contains information about metrics for Exact Match fund view
 */
export interface ExactMatchFundResponse {
  hits: {
    hits: {
      _source: ExactMatchFundData;
    };
  };
}

/**
 * this contains information about fund documents for Exact Match fund view
 */
export interface ExactMatchDocumentsResponse {
  hits: {
    hits: ExactMatchLitDataNode[];
  };
}

/**
 *
 */
export interface ExactMatchFundData {
  asofdate: string;
  title: string;
  producttype: string;
  shclcode: ShareClassCode;
  shclname: ShareClassName;
  ticker: FundIdentifier;
  fundid: FundId;
  mngrname: string[];
  navvalue: string;
  navchngvalue: string;
  ytdtotretatnav: string;
  expratgross: string;
  expratnet: string;
  maxinitchrg: string;
  alert?: FundAlert;
}

export interface ExactMatchLitDataNode {
  _source: {
    customfilePath: string;
    lit_code: string;
    lit_path: string;
    lit_content_type: string;
    title: string;
  };
}
/**
 *
 */
export interface FtSearchResults {
  error?: boolean;
  apiVersion?: string;
  originalQueryText?: string;
  updatedQueryText?: string;
  totalResults?: string;
  parametrics?: {
    results: {
      aggregations: Aggregations;
    };
  };
  results?: FtSearchResponse[];
  fundInstant?: [{}];
  instantDisplays?: {
    total: number;
    hits: [
      {
        entityType: string;
      }
    ];
  };
}

/**
 *
 */
export interface FtSearchOptions {
  term: string;
  dataSource?: string;
  config: {
    ftPreSearchUrl?: string;
    ftSearchUrl: string;
    ftInsightsUrl?: string;
    ftAutoCompleteUrl?: string;
    httpParams: HttpParams;
    httpOptions: object;
    jsonParams?: {
      locale?: string;
      env?: string;
      start?: string;
      number?: string;
    };
  };
  counters: {
    funds: number;
    literature?: number;
    pages?: number;
  };
  page: number;
  articleType?: string;
  current?: string;
  collection?: string;
}

/**
 *
 */
export interface FtSearchStatus {
  hosts: [
    {
      type: string;
      status: string;
    }
  ];
  config: {};
  error?: {};
}

/**
 *
 */
export interface FtSearchResponse {
  name: string;
  response: FtSearchResponseItem;
}

/**
 *
 */
export interface ResponseHits {
  total: {
    value?: number;
  };
}

/**
 *
 */
export interface FtSearchParams {
  options: FtSearchOptions;
  filters: string;
}

/**
 *
 */
export interface FtSearchResponseItem {
  hits: {
    total: {
      value?: number;
    };
    hits: FtSearchItem[];
  };
}

/**
 *
 */
export interface FtSearchItem {
  _source?: {
    customfilePath?: string;
    description?: string;
    lit_content_type?: string;
    last_modified: string;
    last_modified_formatted: string;
    lit_path?: string;
    finra_path?: string;
    pub_date?: string;
    pub_date_formatted?: string;
    lit_code?: string;
    next_update?: string;
    next_update_formatted?: string;
    pageType?: string;
    pageTypeCustom?: string;
    reference?: string;
    referenceDate?: string;
    referenceDate_formatted?: string;
    relativePath?: string;
    title?: string;
    lit_desc?: string;
    lit_tnail_path?: string;
    isOrdItself?: string;
    exempt_customers?: string;
    isInv?: string;
    mand_material?: string[];
    client_use?: string;
    fundid?: FundId;
    searchIdentifier?: string;
    ticker?: Ticker;
    assetclass?: string;
    shclname?: ShareClassName;
    oneTisNumber?: FundId[];
    shclcode?: ShareClassCode[]; // TODO - we use common type for full results  and exact match - to verify
    ticker_symbol?: FundIdentifier[];
    fund_name?: string[];
    fundumbrlla?: string;
  };
  inner_hits?: {
    shareClassesMatched: {
      hits: {
        hits: {
          _source: FundDataItem;
        }[];
      };
    };
  };
  lit_content_type?: string[];
  highlight: {
    title: string;
    description?: string;
    lit_desc?: string[];
    content?: string[];
    'content.ngrams': string[];
  };
}

export interface FtSearchItemExact {
  _source?: {
    customfilePath?: string;
    description?: string;
    lit_content_type?: string;
    last_modified: string;
    last_modified_formatted: string;
    lit_path?: string;
    finra_path?: string;
    pub_date?: string;
    pub_date_formatted?: string;
    lit_code?: string;
    next_update?: string;
    next_update_formatted?: string;
    pageType?: string;
    pageTypeCustom?: string;
    reference?: string;
    referenceDate?: string;
    referenceDate_formatted?: string;
    relativePath?: string;
    title?: string;
    lit_desc?: string;
    lit_tnail_path?: string;
    isOrdItself?: string;
    exempt_customers?: string;
    isInv?: string;
    client_use?: string;
    fundid?: FundId;
    searchIdentifier?: string;
    ticker?: Ticker;
    assetclass?: string;
    shclname?: ShareClassName;
    oneTisNumber?: FundId[];
    shclcode?: ShareClassCode[] | ShareClassCode;
    ticker_symbol?: FundIdentifier[];
    fund_name?: string[];
  };
}

export interface FtPreSearchResults {
  results: FtSearchResponseItem[];
}

export interface FtSearchResponseItem {
  name: string;
  response: {
    hits: {
      hits: FtPreSearchResultsSource[];
    };
  };
}

export interface FtPreSearchResultsSource {
  _source: {
    audience: string;
    quickLinks: FtPreSearchResultsItem[];
  };
}

export interface FtPreSearchResultsItem {
  title: string;
  subTitle: string;
  url: string;
}

export interface Audiences {
  distributor?: ShareClassObject[];
  'financial-professionals'?: ShareClassObject[];
  gatekeeper?: ShareClassObject[];
  institutional?: ShareClassObject[];
  investor?: ShareClassObject[];
  shariah?: ShareClassObject[];
  'institutional-reports'?: ShareClassObject[];
  internal?: ShareClassObject[];
}

export interface ShareClassObject {
  code: ShareClassCode;
  name: ShareClassName;
  curr?: string;
}

// do not change shareclass to shareClass - it is matching EDS component property
export interface ShareClassObjectEds {
  shareclass: ShareClassName;
  link: string;
  ariaTitle?: string;
}

export interface FundDataItem {
  allShareClassTickerSymbol?: Ticker[];
  allShareClassShareClass?: ShareClassCode[];
  allShareClassShareClassName?: ShareClassName[];
  allShareClassNameMap?: ShareClassObject[] | Audiences;
  title?: string;
  fundid?: FundId;
  ticker?: Ticker;
  searchIdentifier?: string;
  assetclass?: string;
  shclcode?: ShareClassCode[] | ShareClassCode;
  shclname?: ShareClassName;
  alert?: FundAlert;
}

export interface FundThumbnailItem {
  title: string;
  reference: string;
  referenceDate: string;
  referenceDate_formatted: string;
  description: string;
  pageType: string;
  pageTypeCustom: string;
}

export interface QuickLinkItem {
  title: string;
  subTitle: string;
  url: string;
}

/**
 *
 */
export interface FtSearchTab {
  name: string;
  total: number;
}

/**
 *
 */
export interface ItemHeader {
  leftTitle: string;
  rightTitle: string;
  viewMoreBtnText: string;
  nameLabel?: string;
  clientLabel?: string;
  literatureLabel?: string;
  publicationLabel?: string;
}

/**
 *
 */
export type ToggleState = 'full' | 'exact';

/**
 *
 */
export interface FundDetailLabel {
  labelSmallText: string;
}
/**
 *
 */
export interface FundDetails {
  labelSmallText: string;
  valueSmallText: string;
}

/**
 *
 */
export interface LiteratureData {
  downloadNotAvailble?: boolean;
  noFinraLink?: boolean;
  hardcopyNotAvailable?: boolean;
  isOrdItself?: string;
  isInv?: string;
  mandatoryMaterial?: string[];
  errorMessage?: string;
  litName?: string;
  litUrl?: string;
  litDetail?: string;
  litType: string;
  clientUseValue?: string;
  finraLink: string;
  showFinra?: boolean;
  litCode: string;
  customFilePath?: string;
  litPath?: string;
  publicationDate: string;
  fundDetailLabel?: { label: string; size: string };
  fundDetails?: { label: string; val: string; size: string }[];
  docTypeSrc?: string;
  thumbnailUrl?: string;
  documentIcon?: string;
  documentPath?: string;
  oneTisNumber?: FundId[];
  shclcode?: ShareClassCode[];
  ticker_symbol?: FundIdentifier[];
  fund_name?: string[];
  finraPath?: string;
}

export interface FundThumbnail {
  title: string;
  titleLink: string;
  body: string;
  docTypeSrc: string;
  ctaText?: string;
  viewMore?: string;
}

export interface FundItem {
  fundName: string;
  fundFamily: string;
  fundLink: string;
  shareClasses?: object[];
  fundTitle?: string;
  fundShareClassName?: ShareClassName;
  fundUmbrella?: string;
  alert?: FundAlert;
}

export interface LiteratureItem {
  title: string;
  body: string;
  docTypeSrc: string;
  titleLink: string;
  litCode: string;
}
export interface FundItemElastic {
  title: string;
  assetclass: string;
  ticker?: Ticker;
}

export interface Avatar {
  imgSrc: string;
  fallbackImgSrc: string;
  title: string;
  text1: string;
  text2: string;
}

export interface DocThumbnail {
  title: string;
  titleLink: string;
  docTypeSrc: string;
}

export interface NavData {
  asOfDate: string;
  nav?: string;
  navChg?: string;
  ytdTotal?: string;
  navChgDirection?: string;
  ytdCumNet?: string;
  ytdCumGross?: string;
  ytdCumPureGross?: string;
  updatedDaily?: boolean;
}

export interface FundData {
  fundId: FundId;
  shareClass: ShareClassCode;
  fundName: string;
  identifier: FundIdentifier;
}

export interface FtBrResponse {
  content: {
    [key: string]: FtBrContentBlocks;
  };
}

export interface FtBrContentBlocks {
  category: string;
  contentblocks: FtBrContentBlock[];
}

export interface FtBrContentBlock {
  name: string;
  contentType: string;
  content: string;
}
