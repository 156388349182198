<ng-container *ngIf="page">
  <ng-container *ngIf="brConfig?.fundId; else previewBlock">
    <div>
      <eds-section-header
        [level]="3"
        theme="basic"
        size="h3"
        [asOfLabel]="'products.as-of' | translate: brConfig?.fundId"
        [asOfDate]="fundPerformanceData?.performanceDates?.asOfDate"
        edsSpacing="sm"
        caveatPlacement="Discrete:DiscretePerformance"
        >{{ heading }}
      </eds-section-header>
      <div *ngIf="isError">
        <div>
          <span>{{ "products.no-performance-data" | translate }}</span>
        </div>
      </div>
      <div *ngIf="!isError">
        <div>
          <div *ngIf="periodEndToggle?.length > 1">
            <eds-toggle
              [toggleButtons]="periodEndToggle"
              (toggleActive)="onPeriodEndUpdate($event)"
            ></eds-toggle>
          </div>
        </div>
        <br />
        <ng-container *ngIf="performanceStatusCaveat">
          <eds-proximal
            edsSpacing="lg"
            edsPadding
            edsPaddingBottom="sm"
            [placement]="performanceStatusCaveat"
          ></eds-proximal>
          <br />
        </ng-container>
        <ng-container *ngIf="fundPerformanceData?.tableData?.length">
          <eds-proximal
            placement="PerformanceDiscreteProximalTop"
          ></eds-proximal>
        </ng-container>
        <ng-container
          *ngIf="
            fundPerformanceData?.graphData && !fundPerformanceConfig.hideChart
          "
        >
          <eds-proximal
            placement="PerformanceDiscreteChartProximalTop"
          ></eds-proximal>
          <ft-fund-performance-column-chart
            [seriesData]="fundPerformanceData.graphData"
            [xAxisCategories]="xAxisCategories"
          >
          </ft-fund-performance-column-chart>
          <eds-proximal
            placement="PerformanceDiscreteChartProximalBottom"
          ></eds-proximal>
        </ng-container>

        <br />
        <ft-fund-performance-discrete-returns-table
          [fundPerformanceData]="fundPerformanceData?.tableData"
          [fundPerformanceDates]="fundPerformanceData?.performanceDates"
          [fundPerformanceConfig]="fundPerformanceConfig"
          [tableLabel]="fundPerformanceData?.tableLabel"
          [years]="fundPerformanceData?.years"
          [tableCols]="fundPerformanceData?.tableCols"
          [fundId]="brConfig.fundId"
        >
        </ft-fund-performance-discrete-returns-table>
        <div *ngIf="fundPerformanceData?.tableData.length === 0">
          <div>
            <h6>{{ "products.no-performance-hist-data" | translate }}</h6>
            <br />
          </div>
        </div>
        <div *ngIf="fundPerformanceConfig?.showMifid" edsSpacing="sm">
          <eds-title size="h6" theme="basic" [isLight]="true">
            {{ "products.perf-inception-mifid" | translate: brConfig.fundId }}
            {{ fundPerformanceData.performanceDates.inceptionDate }}
            {{
              "products.perf-inception-mifid-end" | translate: brConfig.fundId
            }}
          </eds-title>
        </div>
      </div>
      <eds-expand-collapse
        [collapsedButton]="
          'products.view-all-class-perf' | translate: brConfig.fundId
        "
        [expandedButton]="
          'products.view-all-class-perf' | translate: brConfig.fundId
        "
        top="true"
        paddingNone="true"
        borderNone="true"
        edsSpacing="md"
        *ngIf="fundPerformanceData?.allShareClassesTableData?.tableData?.length"
      >
        <ft-fund-performance-discrete-returns-table
          [fundPerformanceData]="
            fundPerformanceData?.allShareClassesTableData?.tableData
          "
          [fundPerformanceDates]="fundPerformanceData?.performanceDates"
          [fundPerformanceConfig]="fundPerformanceConfig"
          [tableLabel]="fundPerformanceData?.tableLabel"
          [years]="fundPerformanceData?.allShareClassesTableData?.years"
          [tableCols]="fundPerformanceData?.allShareClassesTableData?.tableCols"
          [fundId]="brConfig.fundId"
          [isAllShareClasses]="true"
        >
        </ft-fund-performance-discrete-returns-table>
      </eds-expand-collapse>
      <ng-container *ngIf="fundPerformanceData?.tableData?.length">
        <eds-proximal
          placement="PerformanceDiscreteProximalBottom"
        ></eds-proximal>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #previewBlock>
  <h5>Fund Performance Component - Discrete Returns</h5>
</ng-template>
